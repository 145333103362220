import React, { useEffect, useState } from "react";
import TitleElement from "../../components/LitElements/TitleElement";

function IotInsights() {

    return (
        <React.Fragment>
            <div className="page-content">
                <title-element></title-element>
            </div>
        </React.Fragment>
);
}

export default IotInsights;
