// Echart
import * as echarts from 'echarts';
// Util
import { CHART_COLOR } from 'src/util';

export const deviceDataHistoryBaseOption: echarts.EChartOption = {
  color: CHART_COLOR,
  grid: {
    containLabel: true,
    left: 0,
    right: 40,
    top: 10,
    bottom: 0,
  },
  legend: {
    show: false,
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    axisLabel: {
      width: 30,
      formatter(v: any, i: number) {
        if (i == 0) return '';
        return v;
      },
    },
    splitLine: {
      show: true,
    },
  },
  yAxis: {
    show: true,
    axisLine: {
      show: true,
    },
    min(value) {
      const t = Math.floor(value.min - (value.max - value.min) / 10);
      if (value.min < 0) return t;
      if (0 > t) return 0;
      return t;
    },
  },
  tooltip: {
    show: true,
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
    },
  },
  series: [],
};

export const deviceDataKPIOption: echarts.EChartOption = {
  color: CHART_COLOR,
  grid: {
    left: -15,
    right: 10,
    top: 0,
    bottom: 0,
    containLabel: false,
  },
  xAxis: {
    show: false,
    type: 'category',
  },
  yAxis: {
    show: false,
    type: 'value',
  },
  series: [],
};
