import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { isEmpty } from "lodash";

import {
    Button,
    Form,
    Card,
    CardBody, CardHeader, CardSubtitle, CardTitle,
    Col,
    Container, Input, Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row, Table,
} from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";

/** Import Full Calendar  */
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";

//import images
// import calendar from "../../assets/images/undraw-calendar.svg";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import {getTokenById as onGetTokenById} from "../../../store/settings/Tokens/actions";

//i18n
import { useTranslation } from "react-i18next";

import {
    addNewEvent as onAddNewEvent,
    deleteEvent as onDeleteEvent,
    getCategories as onGetCategories,
    getEvents as onGetEvents,
    updateEvent as onUpdateEvent,
} from "../../../store/apps/Organize/actions";

// import DeleteModal from "./DeleteModal";

//css
import "@fullcalendar/bootstrap/main.css";

//redux
import { useSelector, useDispatch } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
    SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";

import {Link} from "react-router-dom";
import {useLocation} from "react-router";
interface TokensAddProps {
    className: string;
}
// TODO: 函数头
const TokensAdd = ({ className }: TokensAddProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { state } = useLocation();

    const localState:any = state;

    useEffect(() => {
        dispatch(onGetTokenById(localState.id));
    }, [dispatch]);

    let { result } = useSelector(function (state: any) {
        return {
            result: state.Tokens.tokens,
        }
    });

    if(!result){
        result = [];
    }

    if(localState.ifAdd){
        result = null
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{t("tokensAdd")}</title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumbs title={t("Authentication")} breadcrumbItem={t("tokensAdd")} />
                    <Card>
                        <CardBody>
                            <div className="mb-3 d-flex flex-wrap gap-2">
                                <button
                                    type="button"
                                    className="btn btn-outline-primary waves-effect waves-light"
                                >
                                    {t("OP_Save")}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-success"
                                >
                                    {t("OP_SaveAndExit")}

                                </button>
                                {/*<button
                                    type="button"
                                    className="btn btn-soft-warning waves-effect waves-light"
                                >
                                    {t("OP_Cancel")}
                                </button>*/}
                                <Link to="/page-tokens" className="btn btn-secondary">
                                    {t("OP_Cancel")}
                                </Link>
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("th_Name")}</Label>
                                <i className="bx bx-help-circle" title={t("th_Name")} />
                                <Input className="form-control" type="text" defaultValue={result?result.name:""} id="example-text-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("PageApps")}</Label>
                                <i className="bx bx-help-circle" title={t("PageApps")} />
                                <Input className="form-control" type="text" defaultValue={result?result.application:""} id="example-text-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("th_Organize")}</Label>
                                <i className="bx bx-help-circle" title={t("th_Organize")} />
                                <Input className="form-control" type="text" defaultValue={result?result.organization:""} id="example-text-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("User")}</Label>
                                <i className="bx bx-help-circle" title={t("User")} />
                                <Input className="form-control" type="text" defaultValue={result?result.owner:""} id="example-text-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("th_AuthCode")}</Label>
                                <i className="bx bx-help-circle" title={t("th_AuthCode")} />
                                <Input className="form-control" type="text" defaultValue={result?result.refreshToken:""} id="example-text-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("th_AccessToken")}</Label>
                                <i className="bx bx-help-circle" title={t("th_AccessToken")} />
                                <Input className="form-control" type="text" defaultValue={result?result.accessToken:""} id="example-text-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("th_Expiresin")}</Label>
                                <i className="bx bx-help-circle" title={t("th_Expiresin")} />
                                <Input className="form-control" type="text" defaultValue={result?result.expiresIn:""} id="example-text-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("th_Scope")}</Label>
                                <i className="bx bx-help-circle" title={t("th_Scope")} />
                                <Input className="form-control" type="text" defaultValue={result?result.scope:""} id="example-text-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("Tokens")+t("th_Type")}</Label>
                                <i className="bx bx-help-circle" title={t("Tokens")+t("th_Type")} />
                                <Input className="form-control" type="text" defaultValue={result?result.tokenType:""} id="example-text-input" />
                            </div>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default TokensAdd;
