
// Login Method
import {post, get, put, del, patch} from "./api_helper";
import * as url from "./iot_url_helper";
import {query} from "lit-element";
import { IOT_SEND_EMAIL } from './iot_url_helper';
const postIotLogin = (data : any, oAuthParams : any) => post(url.POST_IOT_LOGIN, data)

const existsUser =(query : any, oAuthParams : any) => get(`${url.IOT_USER}/is-exist`, {params: query})
const createIotUser = (data : any, oAuthParams : any) => post(url.IOT_USER, data)
const registerIotUser = (data : any, oAuthParams : any) => post(`${url.IOT_USER}/register`, data)
const getIotUsers = (query : any, oAuthParams : any) => get(url.IOT_USER, {params: query})
const activateUser = (id : any, oAuthParams : any) => patch(`${url.IOT_USER}/enable/${id}`)
const deleteIotUser = (id : string, oAuthParams : any) => del(`${url.IOT_USER}/${id}`)
const updateIotUser = (id : string, data : any, oAuthParams : any) => put(`${url.IOT_USER}/${id}`, data)
const changeWorkspace = (data : any, oAuthParams : any) => put(`${url.IOT_USER}/change_workspace`, data)
const createIotOrganization = (data : any) => post(url.IOT_ORGANIZATION, data)
const addIotOrganizationUser = (orgId:any, data : any) => post(`${url.IOT_ORGANIZATION}/${orgId}/add-user`, data)
const getIotOrganization = (id : string, oAuthParams : any) => get(`${url.IOT_ORGANIZATION}/${id}`)
const updateIotOrganization = (id: string, data : any, oAuthParams : any) => put(`${url.IOT_ORGANIZATION}/${id}`, data)
const deleteIotOrganization = (id : any, oAuthParams : any) => del(`${url.IOT_ORGANIZATION}/${id}`)

const createIotWorkspaces = (data : any, oAuthParams : any) => post(url.IOT_WORKSPACE, data)
const addIotWorkspacesUser = (wsId:any, data : any, oAuthParams : any) => post(`${url.IOT_WORKSPACE}/${wsId}/add-user`, data)
const getIotWorkspaces = (query : any, oAuthParams : any) => get(url.IOT_WORKSPACE, {params: query})
const getIotWorkspace = (id : string, oAuthParams : any) => get(`${url.IOT_WORKSPACE}/${id}`)
const getIotWorkspaceDevices = (id : string, oAuthParams : any) => get(`${url.IOT_WORKSPACE}/${id}/devices`)
const deleteIotWorkspace = (id : string, oAuthParams : any) => del(`${url.IOT_WORKSPACE}/${id}`)
const updateIotWorkspace = (id : string, data : any, oAuthParams : any) => put(`${url.IOT_WORKSPACE}/${id}`, data)

const createIotDeviceType = (data : any, oAuthParams : any) => post(url.IOT_DEVICE_TYPE, data)
const updateIotDeviceType = (id: string, data : any, oAuthParams : any) => put(`${url.IOT_DEVICE_TYPE}/${id}`, data)
const getIotDeviceTypeList = (query : any, oAuthParams : any) => get(url.IOT_DEVICE_TYPE, {params: query})
const getIotDeviceType = (id: string, oAuthParams : any) => get(`${url.IOT_DEVICE_TYPE}/${id}`)
const deleteIotDeviceType = (id : any, oAuthParams : any) => del(`${url.IOT_DEVICE_TYPE}/${id}`)
const uploadFile = (data : any, oAuthParams : any) => post(url.IOT_FILE_UPLOAD, data, {
    headers: {
        "Content-Type": "multipart/form-data",
    }
});
const createIotDeviceConnector = (data : any, oAuthParams : any) => post(url.IOT_DEVICE_CONNNECTOR, data)
const getIotDeviceConnectorList = (query : any, oAuthParams : any) => get(url.IOT_DEVICE_CONNNECTOR, {params: query})
const updateIotDeviceConnector = (id: string, data : any, oAuthParams : any) => put(`${url.IOT_DEVICE_CONNNECTOR}/${id}`, data)
const deleteIotDeviceConnector = (id : string, oAuthParams : any) => del(`${url.IOT_DEVICE_CONNNECTOR}/${id}`)
const getIotOutputConnectorList = (query : any, oAuthParams : any) => get(url.IOT_OUTPUT_CONNNECTOR, {params: query})
const createIotOutputConnector = (data : any, oAuthParams : any) => post(url.IOT_OUTPUT_CONNNECTOR, data)
const updateIotOutputConnector = (id: string, data : any, oAuthParams : any) => put(`${url.IOT_OUTPUT_CONNNECTOR}/${id}`, data)
const deleteIotOutputConnector = (id : string, oAuthParams : any) => del(`${url.IOT_OUTPUT_CONNNECTOR}/${id}`)
const getIotDataFlowList = (query : any, oAuthParams : any) => get(url.IOT_DATA_FLOW, {params: query})
const getIotDataFlow = (id : string, oAuthParams : any) => get(`${url.IOT_DATA_FLOW}/${id}`)
const createIotDataFlow = (data : any, oAuthParams : any) => post(url.IOT_DATA_FLOW, data)
const deleteIotDataFlow = (id : string, oAuthParams : any) => del(`${url.IOT_DATA_FLOW}/${id}`)
const updateIotDataFlow = (id : string, data : any, oAuthParams : any) => put(`${url.IOT_DATA_FLOW}/${id}`, data)
const createIotDevice = (data : any, oAuthParams : any) => post(url.IOT_DEVICE, data)
const updateIotDevice = (id: string, data:any, oAuthParams : any) => put(`${url.IOT_DEVICE}/${id}`, data)
const getIotDevice = (id: string, oAuthParams : any) => get(`${url.IOT_DEVICE}/${id}`)
const getIotDevices = (query : any, oAuthParams : any) => get(url.IOT_DEVICE, {params: query})
const deleteIotDevice = (id : string, oAuthParams : any) => del(`${url.IOT_DEVICE}/${id}`)

const createIotLogicBlock = (data : any, oAuthParams : any) => post(url.IOT_LOGIC_BLOCK, data)
const getIotLogicBlocks = (query : any, oAuthParams : any) => get(url.IOT_LOGIC_BLOCK, {params: query})
const getIotLogicBlock = (id: string, oAuthParams : any) => get(`${url.IOT_LOGIC_BLOCK}/${id}`)
const updateIotLogicBlock = (id : string, data : any, oAuthParams : any) => put(`${url.IOT_LOGIC_BLOCK}/${id}`, data)
const deleteIotLogicBlock = (id : string, oAuthParams : any) => del(`${url.IOT_LOGIC_BLOCK}/${id}`)

const getIotCollectDataList = (query : any, oAuthParams : any) => get(url.IOT_COLLECT_DATA, {params: query})
const deleteIotDeviceData = (id : string, oAuthParams : any) => del(`${url.IOT_COLLECT_DATA}/${id}`)
const exportIotCollectData = (query : any, oAuthParams : any) => get(url.IOT_COLLECT_DATA + "/export", {
    params: query,
    headers: {
        'Content-type': 'application/json',
        'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    },
    responseType: 'blob'
})

const createIotRule = (data : any, oAuthParams : any) => post(url.IOT_RULE, data)
const getIotRules = (query : any, oAuthParams : any) => get(url.IOT_RULE, {params: query})
const deleteRule = (id : string, oAuthParams : any) => del(`${url.IOT_RULE}/${id}`)
const getIotRule = (id : string, oAuthParams : any) => get(`${url.IOT_RULE}/${id}`)
const updateIotRule = (id : string, data : any, oAuthParams : any) => put(`${url.IOT_RULE}/${id}`, data)

const getIotTags = (query : any, oAuthParams : any) => get(url.IOT_TAG, {params: query})
const createIotTag= (data : any, oAuthParams : any) => post(url.IOT_TAG, data)
const deleteTag = (id : string, oAuthParams : any) => del(`${url.IOT_TAG}/${id}`)
const getIotTag = (id : string, oAuthParams : any) => get(`${url.IOT_TAG}/${id}`)
const updateIotTag= (id : string, data : any, oAuthParams : any) => put(`${url.IOT_TAG}/${id}`, data)
const createIotDeviceTags= (data : any, oAuthParams : any) => post(`${url.IOT_DEVICE}/tags`, data)
const deleteIotDeviceTag = (query : any, oAuthParams : any) => del(`${url.IOT_DEVICE}/tags`, {params: query})

const getIotAssetsDeviceDataOverview = (query: any) => get(url.IOT_ASSETS.DEVICE_DATA_OVERVIEW,{params: query})
const getIotAssetsDeviceDataOverviewCombine = (query: any) => get(url.IOT_ASSETS.DEVICE_DATA_OVERVIEW_COMBINE,{params: query})

const sendEmail= (id : any, oAuthParams : any) => post(`${IOT_SEND_EMAIL}/${id}`, oAuthParams)

export {
    postIotLogin,
    createIotWorkspaces,
    createIotDeviceType,
    updateIotDeviceType,
    getIotDeviceTypeList,
    getIotDeviceType,
    deleteIotDeviceType,
    uploadFile,
    createIotDeviceConnector,
    getIotDeviceConnectorList,
    deleteIotDeviceConnector,
    updateIotDeviceConnector,
    getIotOutputConnectorList,
    createIotOutputConnector,
    updateIotOutputConnector,
    deleteIotOutputConnector,
    createIotDataFlow,
    getIotDataFlowList,
    getIotDataFlow,
    deleteIotDataFlow,
    updateIotDataFlow,
    createIotDevice,
    updateIotDevice,
    getIotDevice,
    getIotDevices,
    deleteIotDevice,
    getIotWorkspaces,
    getIotWorkspace,
    deleteIotWorkspace,
    updateIotWorkspace,
    getIotUsers, 
    deleteIotUser, 
    createIotUser, 
    updateIotUser,
    changeWorkspace,
    createIotLogicBlock,
    getIotLogicBlocks,
    getIotLogicBlock,
    updateIotLogicBlock,
    deleteIotLogicBlock,
    getIotCollectDataList,
    deleteIotDeviceData,
    exportIotCollectData,
    createIotOrganization,
    createIotRule,
    getIotRules,
    getIotRule,
    deleteRule,
    updateIotRule,
    createIotTag,
    getIotTags,
    getIotTag,
    deleteTag,
    updateIotTag,
    getIotAssetsDeviceDataOverview,
    getIotAssetsDeviceDataOverviewCombine,
    getIotOrganization,
    createIotDeviceTags,
    deleteIotDeviceTag,
    registerIotUser,
    existsUser,
    updateIotOrganization,
    deleteIotOrganization,
    sendEmail,
    activateUser,
    addIotOrganizationUser,
    addIotWorkspacesUser,
    getIotWorkspaceDevices,
}
