import {Key} from "react";

import {PASS_DEVICE_TYPE} from "./contant";

export {}

export const fetchDeviceTypeInfo = (deviceTypeId: Key) => {
    if (deviceTypeId == PASS_DEVICE_TYPE) return {
        title: "Passthrough",
        description: "The payload is forwarded without changes.",
    }
    return {
        title: "ALEVEL 1x5 V1",
        subtitle: "Aiut",
        technology: "LORA",
        description: "WisNode Button (RAK612 model) is a remote wireless trigger and supports user-defined functions for each key.",
        availableSensors: ["Button", "PIR", "Light"],
        firmwareVersion: "V1.0.0",
        imgURL: "https://raw.githubusercontent.com/akenza-io/device-type-library/master/types/aiut/alevelV1/Alevel-01F5_992x722.jpeg",
    }
}
