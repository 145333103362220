import { OrganizeTypes } from "./actionTypes";

export const getEvents = () => ({
  type: OrganizeTypes.GET_EVENTS,
});

export const apiSuccess = (actionType : any, data : any) => ({
  type: OrganizeTypes.API_SUCCESS,
  payload: { actionType, data },
});

export const apiFail = (actionType : any, error : any) => ({
  type: OrganizeTypes.API_FAIL,
  payload: { actionType, error },
});

export const addNewEvent = (event : any) => ({
  type: OrganizeTypes.ADD_NEW_EVENT,
  payload: event,
});

export const updateEvent = (event : any) => ({
  type: OrganizeTypes.UPDATE_EVENT,
  payload: event,
});

export const updateEventSuccess = (event : any) => ({
  type: OrganizeTypes.UPDATE_EVENT_SUCCESS,
  payload: event,
});

export const updateEventFail = (error : any) => ({
  type: OrganizeTypes.UPDATE_EVENT_FAIL,
  payload: error,
});

export const deleteEvent = (event : any) => ({
  type: OrganizeTypes.DELETE_EVENT,
  payload: event,
});

export const deleteEventSuccess = (event : any) => ({
  type: OrganizeTypes.DELETE_EVENT_SUCCESS,
  payload: event,
});

export const deleteEventFail = (error : any) => ({
  type: OrganizeTypes.DELETE_EVENT_FAIL,
  payload: error,
});

export const getCategories = () => ({
  type: OrganizeTypes.GET_CATEGORIES,
});

export const getCategoriesSuccess = (categories : any) => ({
  type: OrganizeTypes.GET_CATEGORIES_SUCCESS,
  payload: categories,
});

export const getCategoriesFail = (error : any) => ({
  type: OrganizeTypes.GET_CATEGORIES_FAIL,
  payload: error,
});

export const getOrganize = (id:string) => ({
  type: OrganizeTypes.GET_ORGANIZE,
  payload: id
});

export const getOrganizeSuccess = (organize : any) => ({
  type: OrganizeTypes.GET_ORGANIZE_SUCCESS,
  payload: organize,
});

export const getOrganizeFail = (error : any) => ({
  type: OrganizeTypes.GET_ORGANIZE_FAIL,
  payload: error,
});

export const getOrganizes = () => ({
  type: OrganizeTypes.GET_ORGANIZES,
});

export const getOrganizesSuccess = (organizes : any) => ({
  type: OrganizeTypes.GET_ORGANIZES_SUCCESS,
  payload: organizes,
});

export const getOrganizesFail = (error : any) => ({
  type: OrganizeTypes.GET_ORGANIZES_FAIL,
  payload: error,
});
