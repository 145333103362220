'use strict';

import { Key } from 'react';
// Util
import dayjs, { Dayjs, ManipulateType } from 'dayjs';
import { StateMachine } from './StateMachine';
import { AMalloc } from './AMalloc';
import { Payload } from 'echarts/types/dist/echarts';
// Echart
import * as echarts from 'echarts';

const formatStr = 'YYYY-MM-DD HH:mm:ss';

// 前一个为 x 轴的数据，后一个为 y 轴的数据
export type ChartData = [Key, number];

export class ChartUtil {
  static ErrInvalidDate = { message: 'Invalid Date' };

  /**
   * 列出开始时间和结束时间之间的所有间隔
   */
  static genTimeList(
    st: string | Dayjs,
    et: string | Dayjs,
    sp: [number, ManipulateType],
    f = formatStr,
  ): [null, { message: any }] | [string[], undefined] {
    // 检测能否格式化
    let [s, e] = [dayjs(st), dayjs(et)];
    if (
      s.format(f) == this.ErrInvalidDate.message ||
      e.format(f) == this.ErrInvalidDate.message ||
      s.isAfter(e)
    ) {
      return [null, this.ErrInvalidDate];
    }
    // 生成时间轴
    let t = [];
    while (!s.isAfter(e)) {
      t.push(s.format(f));
      s = s.add(sp[0], sp[1]);
    }
    return [t, undefined];
  }

  // 归并数据，将一定范围的点合并
  static incorporate(
    a: ChartData[],
    s: number /* 指定多少个元素为一组 */,
    f?: (n: number) => number /* 对结果进行处理 */,
  ) {
    if (!a?.length) {
      return [];
    }

    const sm = new StateMachine(s);
    let sum = 0;
    const ret = new AMalloc<ChartData>(~~a.length / s);
    const cap = a.length;
    let len = 0;
    a.forEach(([k, v]) => {
      if (sm.round()) {
        sum += v;
      } else {
        sum += v;
        const t = f ? f(sum / s) : sum / s;
        ret.push([k, t]);
        sum = 0;
      }
      len++;
    });

    // 处理尾部数据
    if (sum != 0) {
      const [k] = a[cap - 1];
      ret.push([k, sum / (cap - len)]);
    }

    return ret.get();
  }

  // 根据给定的时间轴补齐数据
  static completeTimeData(d: [string, number][], x: string[]) {
    // 收集时间坐标
    const m: { [k: string]: [number, boolean] } = {};
    d.forEach(n => {
      m[n[0]] = [n[1], true];
    });
    // 补齐数据
    const t = new AMalloc<ChartData>(x.length);
    x.forEach(n => {
      if (m[n]) {
        if (m[n][1]) {
          t.push([n, m[n][0]]);
          m[n][1] = false;
        }
      } else {
        t.push([n, 0]);
        m[n] = [0, false];
      }
    });
    return t.get();
  }
}

enum CATE {
  CHART,
}

export class ChartDrawer {
  _baseOption: echarts.EChartOption = {};
  _container: HTMLDivElement | HTMLCanvasElement | null = null;
  chart: echarts.EChartsType | undefined;
  theme: string | undefined;
  option: echarts.EChartOption = {};

  constructor(
    baseOption: echarts.EChartOption /* 用于生成具有共同配置项的子类 */,
    container: HTMLDivElement | HTMLCanvasElement | null,
    option: echarts.EChartOption /* 个性化配置 */,
    theme: string = 'light',
  ) {
    this._baseOption = baseOption;
    // 初始化配置项
    this.option = {
      ...this._baseOption,
      ...option,
    };
    this.theme = theme;
    if (!container) return;
    this._container = container;
    this.chart = echarts.getInstanceByDom(this._container);
  }

  // 此方法会尝试修复错误
  check(k: CATE): boolean {
    let r = false;
    switch (k) {
      case CATE.CHART:
        // 检查图表是否成功初始化
        if (this.chart) {
          r = true;
          break;
        }
        // 尝试初始化
        if (!this._container) break;
        this.chart = echarts.getInstanceByDom(this._container);
        if (!this.chart) {
          this.chart = echarts.init(this._container, this.theme);
        }
        break;
    }
    return r;
  }

  resize() {
    if (!this.chart) return;
    this.chart.resize();
  }

  handleEvent(e: Event) {
    switch (e.type) {
      case 'resize':
        this.resize();
        break;
    }
  }

  setOption() {
    // 防止意外因容器不存在而导致的 panic
    if (!this._container) return;
    this.check(CATE.CHART);
    if (!this.chart) return;
    this.chart.setOption(this.option, true);
    window.addEventListener('resize', this);
  }

  init(
    container: HTMLDivElement | HTMLCanvasElement | null,
    option: echarts.EChartOption = {},
  ) {
    // 先更新配置项
    this.option = {
      ...this.option,
      ...option,
    };
    // 如果容器已加载，就直接去设置配置
    if (this._container) {
      this.setOption();
      return;
    }
    // 如果此时容器仍未加载，则退出
    // 请尽量确保此时容器已加载
    if (!container) return;
    this._container = container;
    // 复用已存在的图表对象
    this.chart = echarts.getInstanceByDom(this._container);
    this.chart = echarts.init(this._container, this.theme);
    // 重新设置配置
    this.setOption();
  }

  update(option: echarts.EChartOption, flag?: boolean, theme?: string) {
    // flag 为 true 则会忽视以前设置过的配置项，为 false 则会合并以前的配置
    this.option = {
      ...this._baseOption,
      ...option,
    };
    this.check(CATE.CHART);
    if (!this.chart) return;
    this.chart.setOption(this.option, flag);
  }

  dispatchAction(
    payload: Payload,
    opt?:
      | boolean
      | {
          silent?: boolean;
          flush?: boolean | undefined;
        },
  ) {
    this.check(CATE.CHART);
    if (!this.chart) return;
    this.chart.dispatchAction(payload);
  }

  destroy() {
    if (!this.chart) return;
    this.chart.dispose();
    window.removeEventListener('resize', this);
  }
}
