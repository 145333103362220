export const fetchHeadOptionInfo = () => {
    return {
        title: "pass through",
        description: "the payload is forwarded without changes."
    }
}

export const fetchDeviceTypeOptions = () => {
    return [
        {
            id: "612 WisNode Button1s",
            title: "612 WisNode Button",
            subtitle: "RAK",
            technology: "LORA",
            description: "WisNode Button (RAK612 model) is a remote wireless trigger and supports user-defined functions for each key.",
            availableSensors: ["Button", "PIR", "Light"],
            firmwareVersion: "V1.0.0",
            imgURL: "https://raw.githubusercontent.com/akenza-io/device-type-library/master/types/rak/RAK612/Rak2.png",
        },
        {
            id: "ALEVEL 1x5 V1",
            title: "ALEVEL 1x5 V1",
            subtitle: "Aiut",
            technology: "LORA",
            description: "WisNode Button (RAK612 model) is a remote wireless trigger and supports user-defined functions for each key.",
            availableSensors: ["Button", "PIR", "Light"],
            firmwareVersion: "V1.0.0",
            imgURL: "https://raw.githubusercontent.com/akenza-io/device-type-library/master/types/aiut/alevelV1/Alevel-01F5_992x722.jpeg",
        },
        {
            id: "612 WisNode Button2",
            title: "AM107",
            subtitle: "Milesight",
            technology: "LORA",
            description: "WisNode Button (RAK612 model) is a remote wireless trigger and supports user-defined functions for each key.",
            availableSensors: ["Button", "PIR", "Light"],
            firmwareVersion: "V1.0.0",
            imgURL: "https://raw.githubusercontent.com/akenza-io/device-type-library/master/types/rak/RAK612/Rak2.png",
        }
    ]
}
