import React, {Dispatch, useEffect, useReducer, useState} from "react";

//i18n
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";

import {createIotDataFlow, getIotDataFlow, updateIotDataFlow} from "src/helpers/iot_backend_helper";

import TopBar from "./TopBar";
import Guide from "./Guide";
import TemplateContainer from "./TemplateContainer";
import CustomCreation from "./CustomCreation";
import AddFlowModal from "./AddFlowModal";
import {templateConfigurations} from "./TemplateContainer/configuration";

import {ACTION_TYPE} from "./contant";
import {Action, DataFlowContext, init, reducer, State} from "./reducer";

import "./newDataFlow.scss"

const NewDataFlow = (props: any) => {
    let {history, match} = props;
    let flowDataId = match.params.id;
    const {wsId, orgId} = match.params;
    const [submittable, setSubmittable] = useState(false)
    const [visiable, setVisiable] = useState(false)
    const [createMode, setCreateMode] = useState(flowDataId ? "CUSTOM" : "INIT")
    const [state, dispatch]: [State, Dispatch<Partial<Action>>] = useReducer(reducer, {}, init);
    const [flowData, setFlowData] = useState({});

    const {deviceConnector, deviceType, outputConnectors} = state;

    useEffect(() => {
        setSubmittable(Boolean(deviceConnector) && Boolean(deviceType) && (outputConnectors.length > 0))
    }, [state]);

    useEffect(() => {
        if (flowDataId) {
            getIotDataFlow(flowDataId, null).then(result => {
                if (result && result.Code == 200) {
                    let data = {
                        deviceConnector: result.Data.device_connector,
                        deviceType: result.Data.device_type,
                        outputConnectors: result.Data.output_connectors? result.Data.output_connectors.map((row: any) => row.output_connector): [],
                    }
                    const action = {
                        type: ACTION_TYPE.PUSH_DATA_FLOW,
                        payload: data
                    }
                    setFlowData(data);
                    dispatch(action)
                }
            })
        }
    }, [])

    // 创建Data Flow
    const createDataFlow = ({name, description}: { name: string, description: string }) => {
        let {
            deviceConnector,
            deviceType,
            outputConnectors
        }: { deviceConnector: any, deviceType: any, outputConnectors: any } = state;
        let flowData = {
            name,
            description,
            device_connector_id: deviceConnector.id,
            workspace_id: wsId,
            device_type_id: deviceType?deviceType.id: "00000000000000000",
            output_connector_ids: outputConnectors.map((row: any) => row.id)
        }
        createIotDataFlow(flowData, null).then(result => {
            if (result && result.Code == 200) {
                history.push(`/org/${orgId}/ws/${wsId}/data-flows`)
            }
        });
    }

    // 更新Data Flow
    const updateDataFlow = ({name, description}: { name: string, description: string }) => {
        let {
            deviceConnector,
            deviceType,
            outputConnectors
        }: { deviceConnector: any, deviceType: any, outputConnectors: any } = state;
        let flowData = {
            name,
            description,
            device_connector_id: deviceConnector.id,
            workspace_id: wsId,
            device_type_id: deviceType.id,
            output_connector_ids: outputConnectors.map((row: any) => row.id)
        }
        updateIotDataFlow(flowDataId, flowData, null).then(result => {
            if (result && result.Code == 200) {
                history.push(`/org/${orgId}/ws/${wsId}/data-flows`)
            }
        });
    }

    const modalProps = {
        flowData,
        visiable,
        togModal: () => setVisiable(!visiable),
        hideModal: () => setVisiable(false),
        save: flowDataId ? updateDataFlow : createDataFlow
    }
    return (<React.StrictMode>
        <div className="d-flex flex-column h-100">
            <DataFlowContext.Provider value={{state, dispatch}}>
                <header className="flex-grow-0 flex-shrink-0">
                    <TopBar disableSave={!submittable} onSave={() => setVisiable(true)}/>
                </header>
                <main className="flex-grow-1 bg-soft-light">
                    {createMode == "INIT" && <Guide onClick={setCreateMode} defaultValue={'CUSTOM'}>
                        {templateConfigurations.map((configuration) => (
                            <TemplateContainer key={configuration.id} {...configuration} onClick={setCreateMode}/>
                        ))}
                    </Guide>}
                    {createMode != "INIT" && <CustomCreation/>}
                </main>
            </DataFlowContext.Provider>
        </div>
        <AddFlowModal {...modalProps} />
    </React.StrictMode>)
}

export default withTranslation()(withRouter(NewDataFlow));
