import React, { Key, StrictMode } from "react";
import { useTranslation } from "react-i18next";

import { insure } from "src/util";

type Props = {
    id: Key;
    group: Key;
    name: Key;
    img: string;
    type: string;
    description: string;
    onClick: Function;
}

export default ({ id, group, name, img, description, type, onClick }:Props) => {
    const { t } = useTranslation();
    return (<StrictMode>
        <div className="d-flex border border-blue-hover px-3 py-3 rounded-2 cus-bg-white mb-3"
            onClick={insure(onClick, id, group)}>
            <div className="uw-3 me-2">
                <div>
                    <img className="object-contain w-100"
                        src={img}
                        alt="load..." />
                </div>
            </div>
            <div className="flex-2">
                <header className="mb-2 font-size-16 fw-bold">{name}</header>
                <main>
                    {t(type)}
                </main>
            </div>
        </div>
    </StrictMode>)
}
