export enum INPUTS {
    PUSH = "PUSH",
    REMOVE = "REMOVE",
    CLEAR = "CLEAR",
    SELECT_INPUT = "SELECT_INPUT",
}

export enum LOGIC {
    SELECT_LOGIC_BLOCK,
    SELECT_TYPE,
    CLEAR,
    ADD_DATA_SOURCE,
    SELECT_DATA_SOURCE,
    UPDATE_DATA_SOURCE
}

export enum ACTIONS {
    PUSH,
    REMOVE,
    EDIT,
    CLEAR,
}
