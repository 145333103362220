import { Fragment, Key, useEffect, useRef, useState } from 'react';
// Chart
import { DeviceDataHistoryDrawer } from '../drawer';
// Component
import ControlBar from './ControlBar';
// Util
import { Dayjs, ManipulateType } from 'dayjs';
import { Recycler } from 'src/util';
import { fetchHistoryData } from '../../api';

type props = {
  className?: string;
  theme?: 'dark' | 'light';
  idt?: Key;
  fields?: Key[];
};

export default function ({ className, theme, idt, fields }: props) {
  // 控制栏
  const [tags, setTags] = useState<{ name: Key }[]>();
  const [selected, setSelected] = useState<{ [k: Key]: boolean }>();
  // 图表
  const recycler = new Recycler();
  const containerRef = useRef<HTMLDivElement>(null);
  // 自动拉取数据
  const [pullOpen, setPullOpen] = useState<Key>('r');
  const [pullRange, setPullRange] = useState<ManipulateType>('hour');
  let pullLoop = useRef<NodeJS.Timer>();

  const drawer = new DeviceDataHistoryDrawer(containerRef.current, {}, theme);
  recycler.register(drawer);

  // 自动拉取请求，默认间隔 1 minute
  const genInterval = (
    t: Key,
    v: ManipulateType,
    delay: number = 15 * 1000,
  ) => {
    if (!(idt && fields)) return;
    return setInterval(async () => {
      const [res, err] = await fetchHistoryData(idt, fields, {
        type: t,
        value: v,
      });
      if (err || !res) {
        return;
      }
      const option = {
        series: res?.map((n: { name: any; data: any }) => ({
          type: 'line',
          smooth: true,
          showSymbol: false,
          name: n.name,
          data: n.data,
        })),
      };
      drawer.update(option);
    }, delay);
  };

  // 初始化页面
  useEffect(() => {
    if (!fields) return;
    (async function () {
      if (!(idt && fields)) return;
      const [res, err] = await fetchHistoryData(idt, fields, {
        type: pullOpen,
        value: pullRange,
      });

      if (err || !res) {
        return;
      }
      setTags(res.map((n: { name: Key }) => ({ name: n.name })));
      const t = res.reduce((pre: any, cur: { name?: Key }) => {
        if (cur.name) {
          return {
            ...pre,
            [cur.name]: cur.name == res?.[0]?.name,
          };
        }
        return pre;
      }, {});
      setSelected(t);

      const opt = {
        legend: {
          show: false,
          selected: t,
        },
        series: res?.map((n: { name: any; data: any }) => ({
          type: 'line',
          smooth: true,
          showSymbol: false,
          name: n.name,
          data: n.data,
        })),
      };
      drawer.init(containerRef.current, opt);

      // 开启自动拉取
      pullLoop.current = genInterval(pullOpen, pullRange);
    })();

    // 清理页面
    return function () {
      if (pullLoop.current) clearInterval(pullLoop.current);
      recycler.drop();
    };
  }, [containerRef, idt, fields]);
  // 点击 label
  const handleChange = (name: Key) => {
    drawer.dispatchAction({
      type: 'legendToggleSelect',
      name,
    });
    const sl = {
      ...selected,
      [name]: !selected?.[name],
    };
    setSelected(sl);
  };

  const handleClear = () => {
    const sl = {
      ...selected,
    };
    for (const k in sl) {
      sl[k] = false;
    }
    drawer.update({
      legend: {
        show: false,
        selected: sl,
      },
    });
    setSelected(sl);
  };

  const handleTimeRangeChange = async (opt: {
    type: Key;
    value: ManipulateType;
    st: Dayjs;
    et: Dayjs;
  }) => {
    if (!(idt && fields)) return;
    if (pullLoop.current) clearInterval(pullLoop.current);

    // 如果时间由结束时间减去偏差值算出，则将开始时间设置未与结束时间相同
    if (!opt.value.includes('custom')) {
      opt.st = opt.et;
    }

    const [res] = await fetchHistoryData(idt, fields, opt);
    const option = {
      series: res?.map((n: { name: any; data: any }) => ({
        type: 'line',
        smooth: true,
        showSymbol: false,
        name: n.name,
        data: n.data,
      })),
    };
    drawer.update(option);

    setPullOpen(opt.type);
    if (opt.type == 'r') {
      setPullRange(opt.value);
      pullLoop.current = genInterval(opt.type, opt.value);
    }
  };

  return (
    <Fragment>
      <ControlBar
        className="mx-0"
        tags={tags}
        selectedList={selected}
        clickTag={handleChange}
        onClear={handleClear}
        onTimeChange={handleTimeRangeChange}
      />
      <div className={className} ref={containerRef} />
    </Fragment>
  );
}
