import { takeEvery, put, call, all, fork } from "redux-saga/effects"

// Calender Redux States
import { OrganizeTypes } from "./actionTypes"

import {
  getOrganize, getOrganizeSuccess, getOrganizeFail,
  getOrganizes, getOrganizesSuccess, getOrganizesFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  api_getOrganize,
  api_getOrganizes,
} from "../../../API/api"

// 获取单个组织信息
function* onGetOrganize({payload:id}:any) {
  try {
    const response : Promise<any> = yield call(api_getOrganize,id)
    yield put(getOrganizeSuccess(response))
  } catch (error) {
    yield put(getOrganizeFail(error))
  }
}

// 批量获取组织信息
function* onGetOrganizes() {
  try {
    const response : Promise<any> = yield call(api_getOrganizes)
    yield put(getOrganizesSuccess(response))
  } catch (error) {
    yield put(getOrganizesFail(error))
  }
}

export function* watchOnGetOrganize() {
  yield takeEvery(OrganizeTypes.GET_ORGANIZE, onGetOrganize);
}

export function* watchOnGetOrganizes() {
  yield takeEvery(OrganizeTypes.GET_ORGANIZES, onGetOrganizes);
}

function* organizeSaga() {
  yield all([fork(watchOnGetOrganize)]);
  yield all([fork(watchOnGetOrganizes)]);
}

export default organizeSaga
