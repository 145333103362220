"use strict"

/**
 * 只会保留选中的字段
 * 根据一个对照表对对象的键进行修改
 * 只会保留对照表中注明的字段
 * 对照表约定样式：{[k: 原始对象字段]：修改后的字段}
 * @r 原始对象
 * @m 对照表
 */
export const transField = (r: object, m: { [k: string]: string }) => {
  let ret: { [k: string]: any } = {}
  for (const [k, v] of Object.entries(r)) {
    if (m[k]) {
      ret[m[k]] = v
    }
  }
  return ret
}