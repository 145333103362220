import React, {Key} from "react";

import {useTranslation} from "react-i18next";

type Props = {
    className: string;
    title: Key;
    description: string;
    onClick: Function
}

export default ({className, title = "", description = "", onClick}: Partial<Props>) => {
    const {t} = useTranslation()

    const handleClick = () => {
        onClick && onClick()
    }

    return (<React.StrictMode>
        <div
            className={`d-flex flex-column gutter-t-normal border border-blue-hover py-2 rounded-2 cursor-pointer ${className}`}
            onClick={handleClick}>
            <header className="flex-grow-0 flex-shrink-0 border-bottom px-3 pb-2">
                <i className="mdi mdi-link-variant me-2"/>
                <span className="font-size-16 fw-bold">{t(title.toString())}</span>
            </header>
            <main className="d-flex px-3 pt-3">
                {t(description)}
            </main>
        </div>
    </React.StrictMode>)
}
