import { Key, StrictMode, useContext } from 'react';
// Component
import ControlBar from './ControlBar';
import TagOption from './TagOption';
// Util
import { INPUTS } from '../../reducer/constant';
import { InputContext, InputDetail } from '../../reducer/input';
import { tagConfiguration } from '../../common/configuration';
import { insure, repeat2bool } from 'src/util';
import { fetchInputTagDetail, fetchInputTags } from '../api';
// Api

type Props = {
  onBack: Function;
  onCancel: Function;
  onSelect: Function;
};

export default ({ onBack, onCancel, onSelect }: Partial<Props>) => {
  const originTags = fetchInputTags();
  const tagOptionProps = {
    iconClassName: tagConfiguration.iconClassName,
  };

  const {
    state: { inputs },
    dispatch,
  } = useContext(InputContext);
  const handleSelect = (id: Key) => {
    const tagDetail: InputDetail | undefined = fetchInputTagDetail(id);

    if (tagDetail) {
      const actions = {
        type: INPUTS.PUSH,
        payload: tagDetail,
      };

      dispatch(actions);

      insure(onSelect)();
    } else {
      console.log('id is not exists');
    }
  };

  return (
    <StrictMode>
      <div className="d-flex flex-column h-100">
        <ControlBar
          className="flex-shrink-0 mb-4"
          onBack={onBack}
          onCancel={onCancel}
        />
        <main className="flex-grow-1 overflow-auto">
          {originTags.length > 0 &&
            originTags.map(tag => (
              <TagOption
                key={tag.id}
                id={tag.id}
                disable={repeat2bool(inputs, tag.id)}
                title={tag.name}
                {...tagOptionProps}
                onSelect={handleSelect}
              />
            ))}
        </main>
      </div>
    </StrictMode>
  );
};
