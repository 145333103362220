import React, { useState } from "react";
import PropTypes from "prop-types";
//i18n
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import { Col, Row, Modal } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

const AddInputModal = (props: any) => {
  let {
    t, visiable, togModal, hideModal, save, update, inputData = {}
  } = props;

  const handleValidSubmit = (e: any, v: any) => {
    let data = { ...v, id: String(Date.now()) }
    if (inputData && inputData.id) {
      data.id = inputData.id
      update(data)
    } else {
      save(data);
    }
  }
  const variableNamePlaceHolder = t("variable name")
  const topicNamePlaceholder = t("topic name")
  const keyNamePlaceholder = t("key name")

  return (
    <React.Fragment>
      <Modal
        isOpen={visiable}
        toggle={() => {
          togModal();
        }}
        centered={true}
      >
        <AvForm
          onValidSubmit={(e: any, v: any) => {
            handleValidSubmit(e, v);
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0 first-letter-upper">{t((inputData && inputData.id) ? 'update input' : 'add input')}</h5>
            <button
              type="button"
              onClick={() => {
                hideModal();
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Row>
              <Col sm={12}>
                <div>
                  <div className="mb-3">
                    <AvField
                      name="name"
                      defaultValue={inputData.name || ''}
                      label={t('input label name')}
                      placeholder=""
                      type="text"
                      errorMessage={t("Name is required")}
                      validate={{ required: { value: true } }}
                    />
                  </div>
                  <div className="mb-3">
                    <AvField
                      name="description"
                      defaultValue={inputData.description || ''}
                      label={`${t('description')} (${t("optional")})`}
                      placeholder=""
                      type="text"
                      errorMessage=""
                      validate={{ required: { value: false } }}
                    />
                  </div>
                  <div className="mb-3">
                    <AvField
                      name="variable_name"
                      defaultValue={inputData.variable_name || ''}
                      label={variableNamePlaceHolder}
                      placeholder={t(variableNamePlaceHolder)}
                      type="text"
                      errorMessage={t("VariableName is required")}
                      validate={{ required: { value: true } }}
                    />
                  </div>
                  <div className="mb-3">
                    <AvField
                      name="topic"
                      defaultValue={inputData.topic || ''}
                      label={`${t('default topic')} (${t("optional")})`}
                      placeholder={t(topicNamePlaceholder)}
                      type="text"
                      errorMessage=""
                      validate={{ required: { value: false } }}
                    />
                  </div>
                  <div className="mb-3">
                    <AvField
                      name="datakey"
                      defaultValue={inputData.datakey || ''}
                      label={`${t('default datakey')} (${t("optional")})`}
                      placeholder={t(keyNamePlaceholder)}
                      type="text"
                      errorMessage=""
                      validate={{ required: { value: false } }}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="modal-footer" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <button
              type="button"
              style={{ fontSize: '16px' }}
              onClick={hideModal}
              className="btn btn-outline-light waves-effect"
            >
              {props.t('Cancel')}
            </button>
            <button type="submit" className="btn btn-primary text-capitalize">
              {t((inputData && inputData.id) ? 'update input' : 'add input')}
            </button>
          </div>
        </AvForm>
      </Modal>
    </React.Fragment>
  )
}

AddInputModal.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  visiable: PropTypes.bool,
  togModal: PropTypes.func,
  hideModal: PropTypes.func,
  save: PropTypes.func
}

export default withTranslation()(withRouter(AddInputModal));