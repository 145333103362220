// Echart
import * as echarts from 'echarts';
// option
import {
  deviceDataHistoryBaseOption,
  deviceDataKPIOption,
} from './baseOptions';
// Util
import { ChartDrawer } from 'src/util';

export class DeviceDataHistoryDrawer extends ChartDrawer {
  constructor(
    container: HTMLDivElement | HTMLCanvasElement | null,
    option: echarts.EChartOption,
    theme: string = 'light',
  ) {
    super(deviceDataHistoryBaseOption, container, option, theme);
  }
}

export class DeviceDataKPIDrawer extends ChartDrawer {
  constructor(
    container: HTMLDivElement | HTMLCanvasElement | null,
    option: echarts.EChartOption,
    theme: string = 'light',
  ) {
    super(deviceDataKPIOption, container, option, theme);
  }
}
