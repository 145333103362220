import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";

//i18n
import { useTranslation, withTranslation } from 'react-i18next';
import { withRouter, Link, useRouteMatch } from 'react-router-dom';
import ReactJson from 'react-json-view'
import {getIotOrganization} from 'src/helpers/iot_backend_helper';
import { awaitWrap } from 'src/util';

const ApiConfiguration = (props: any) => {
  const {t} = useTranslation()
  const { data} = props;
  const [showUplink, setShowUplink] = useState(false);
  const [showDownlink, setShowDownlink] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [mqttAccount, setMqttAccount] = useState<{user_name:string,password:string}>()
  const json = { key1: "value1", temperature: 22 };
  const json2 = {
    "limit": 100,
    "timestamp": {
      "gte": "2020-12-15T14:39:41.821Z",
      "lte": "2021-02-03T14:39:41.821Z"
    },
    "topic": "*",
    "skip": 0
  }

  const {orgId} = useRouteMatch<any>().params
  useEffect(()=>{
    (async function(){
      const [res, err] = await awaitWrap(getIotOrganization(orgId,null))
      if (err) {
        return
      }

      setMqttAccount(res.Data.mqtt)
    })();
  },[])
  return (
    <React.Fragment>
      <div className="mt-4">
        <div style={{ backgroundColor: '#fafafa', padding: '20px', marginBottom: '30px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div className="card-title me-2">{t("Connecting to the API")}</div>
            <i style={{ color: '#8c8c8c' }} className="mdi mdi-close-thick"></i>
          </div>
          <div>
            {t("R_M_O")}
            <Link to='#'>{t("Documentation")}</Link>
          </div>
        </div>
        <div>
          <h5 className="mt-5 mb-4 font-size-16" >{t("Send data to this device")}</h5>
          <div className="mb-2" style={{ display: 'flex', flexDirection: 'row' }} >
            <div style={{ color: '#8c8c8c' }}>MQTT Broker URL: </div>
            <div className="text-dark" style={{ marginLeft: '6px' }}>tcp://mqtt.metavun.io:1883</div>
          </div>
          <div className="mb-2" style={{ display: 'flex', flexDirection: 'row' }} >
            <div style={{ color: '#8c8c8c' }}>MQTTs Broker URL: </div>
            <div className="text-dark" style={{ marginLeft: '6px' }}>tls://mqtt.metavun.io:8883</div>
          </div>
          <div className="mb-2" style={{ display: 'flex', flexDirection: 'row' }} >
            <div style={{ color: '#8c8c8c', minWidth: "90px" }}>{t("Uplink Topic")}: </div>
            <div className="text-dark" style={{ marginLeft: '6px', overflowWrap: "anywhere" }}>
              {showUplink? `/up/${data.workspace_id}/id/${data.id}`: "••••••••••••••••••••••••••••••••••••••"} 
              {showUplink?<i onClick={()=> setShowUplink(false)} className="fas fa-eye-slash px-2"></i>:<i onClick={()=> setShowUplink(true)} className="fas fa-eye px-2"></i>}
            </div>
          </div>
          <div className="mb-2" style={{ display: 'flex', flexDirection: 'row' }} >
            <div style={{ color: '#8c8c8c', minWidth: "100px"  }}>{t("Downlink Topic")}: </div>
            <div className="text-dark" style={{ marginLeft: '6px', overflowWrap: "anywhere" }}>
              {showDownlink? `/down/${data.workspace_id}/id/${data.id}`: "••••••••••••••••••••••••••••••••••••••"} 
              {showDownlink? <i onClick={()=> setShowDownlink(false)} className="fas fa-eye-slash px-2"></i>: <i onClick={()=> setShowDownlink(true)} className="fas fa-eye px-2"></i>}
            </div>
          </div>
          <div className="mb-1" style={{ display: 'flex', flexDirection: 'row' }} >
            <div style={{ color: '#8c8c8c' }}>{t("username")}: </div>
            <div className="text-dark" style={{ marginLeft: '6px' }}>{mqttAccount?.user_name}</div>
          </div>
          <div className="mb-1" style={{ display: 'flex', flexDirection: 'row' }} >
            <div className="whitespace-nowrap" style={{ color: '#8c8c8c' }}>{t("password")}: </div>
            <div className="text-dark" style={{ marginLeft: '6px', overflowWrap: "anywhere" }}>
              {showPassword? mqttAccount?.password: "••••••••••••••••••••••••••••••••••••••"}
              {showPassword? <i onClick={()=> setShowPassword(false)} className="fas fa-eye-slash px-2"></i>: <i onClick={()=> setShowPassword(true)} className="fas fa-eye px-2"></i>}
            </div>
          </div>
          <div className="mt-4">
            <div style={{ color: '#8c8c8c' }}>{t("Body (example)")}: </div>
            <div className="mt-1" style={{ border: '1px solid #e8e8e8', backgroundColor: '#fafafa', borderRadius:'4px', padding: '12px' }} >
              <ReactJson name={false} displayObjectSize={false} displayDataTypes={false} src={json} />
            </div>
          </div>
        </div>

        {/* <div>
          <h5 className="mt-5 mb-4 font-size-16" >Receive data from the API</h5>
          <div className="mb-2" style={{ display: 'flex', flexDirection: 'row' }} >
            <div style={{ color: '#8c8c8c' }}>URL: </div>
            <div className="text-dark" style={{ marginLeft: '6px' }}>https://api.akenza.io/v3/devices/0217d414e9456596/query</div>
          </div>
          <div className="mb-1" style={{ display: 'flex', flexDirection: 'row' }} >
            <div style={{ color: '#8c8c8c' }}>Method: </div>
            <div className="text-dark" style={{ marginLeft: '6px' }}>POST</div>
          </div>
          <div className="mt-4">
            <div style={{ color: '#8c8c8c' }}>Body (example): </div>
            <div className="mt-1" style={{ border: '1px solid #e8e8e8', backgroundColor: '#fafafa', borderRadius:'4px', padding: '12px' }} >
              <ReactJson name={false} displayObjectSize={false} displayDataTypes={false} src={json2} />
            </div>
          </div>
        </div> */}
      </div>
    </React.Fragment>
  );
}

ApiConfiguration.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withTranslation()(withRouter(ApiConfiguration));