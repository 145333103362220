import {Key} from "react";

export type DeviceConnectorInfo = {
    connectorName: string;
    technology: string;
    uplinkURL: string;
    uplinkSecret: string;
}

export const fetchDeviceConnectorInfo = (deviceConnector: Key): DeviceConnectorInfo => {
    return {
        connectorName: "HTTP Device Connector",
        technology: "http",
        uplinkURL: `WisNode Button (RAK612 model) is a remote wireless trigger and supports user-defined functions for each key.`,
        uplinkSecret: "WisNode Button (RAK612 model) is a remote wireless trigger and supports user-defined functions for each key.",
    }
}
