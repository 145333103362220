import {StrictMode} from "react";

import {useTranslation} from "react-i18next";

import {insure} from "src/util";

type Props = {
    prompt: string;
    onAdd: Function;
}

export default ({prompt="", onAdd}: Partial<Props>) => {
    const {t} = useTranslation()

    return (<StrictMode>
        <div
            className={`position-absolute top-50 start-50 translate-middle
                border-dashed border-blue-hover border-1 border-light rounded-2
                px-2 py-2
                w-75
                text-center cursor-pointer`}
            onClick={insure(onAdd)}>
            <i className="fas fa-plus d-block font-size-20 text-primary"/>
            <span className="text-capitalize">
                    {t(prompt)}
            </span>
        </div>
    </StrictMode>)
}
