import React from "react";
//i18n
import { useTranslation } from "react-i18next";
import "./style.scss"

type Step = {
  step: number;
  des: string;
}

export type StepProps = {
  inProgress: Number;
  steps: Array<Step>
}

const Steps = (props: StepProps):React.ReactElement => {
  let { steps, inProgress } = props;
  const {t} = useTranslation()
  const renderRow = (row:Step):React.ReactNode => {
    if(row.step > inProgress) {
      return (
        <div key={row.step} className="flex-row">
            <div className="flex-column center">
              <div className="center circle waiting border-light">
                <span>{row.step}</span>
              </div>
              {row.step !== steps.length && <div className="line"></div>}
            </div>
            <div className="ml-1 pt-2">
              <div>{t('Waiting')}</div>
              <div className="mt-1 text-muted font-size-12">{t(row.des)}</div>
            </div>
          </div>
      )
    }else if(row.step === inProgress) {
      return (
        <div key={row.step} className="flex-row">
            <div className="flex-column center">
              <div className="center circle in-progress">
                <span>{row.step}</span>
              </div>
              {row.step !== steps.length && <div className="line"></div>}
            </div>
            <div className="ml-1 pt-2">
              <div>{t('In Progress')}</div>
              <div className="mt-1 font-size-12">{t(row.des)}</div>
            </div>
          </div>
      )
    }else {
      return (
        <div key={row.step} className="flex-row">
            <div className="flex-column center">
              <div className="center circle finished">
              <i className=" dripicons-checkmark"></i>
              </div>
              {row.step !== steps.length && <div className="line line-active"></div>}
            </div>
            <div className="ml-1 pt-2">
              <div>{t('Finished')}</div>
              <div className="mt-1 text-muted font-size-12">{t(row.des)}</div>
            </div>
          </div>
      )
    }
  }
  return (
    <div className="flex-column">
      {
        steps.map((item:Step) => renderRow(item))
      }
    </div>
  );
}

export default Steps;