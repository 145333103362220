/**
 * interface for Certs type
 */
 export enum CertsTypes {
    API_SUCCESS = '@@certs/API_SUCCESS',
    API_FAIL = '@@certs/API_FAIL',
    GET_EVENTS = '@@certs/GET_EVENTS',
    ADD_NEW_EVENT = '@@certs/ADD_NEW_EVENT',
    UPDATE_EVENT = '@@certs/UPDATE_EVENT',
    UPDATE_EVENT_SUCCESS = '@@certs/UPDATE_EVENT_SUCCESS',
    UPDATE_EVENT_FAIL = '@@certs/UPDATE_EVENT_FAIL',
    DELETE_EVENT = '@@certs/DELETE_EVENT',
    DELETE_EVENT_SUCCESS = '@@certs/DELETE_EVENT_SUCCESS',
    DELETE_EVENT_FAIL = '@@certs/DELETE_EVENT_FAIL',
    GET_CATEGORIES = '@@certs/GET_CATEGORIES',
    GET_CATEGORIES_SUCCESS = '@@certs/GET_CATEGORIES_SUCCESS',
    GET_CATEGORIES_FAIL = '@@certs/GET_CATEGORIES_FAIL',
    GET_CERTS = '@@certs/GET_CERTS',
    GET_CERTS_SUCCESS = '@@certs/GET_CERTS_SUCCESS',
    GET_CERTS_FAIL = '@@certs/GET_CERTS_FAIL',
    GET_CERTBYID = '@@certs/GET_CERTBYID',
    GET_CERTBYID_SUCCESS = '@@certs/GET_CERTBYID_SUCCESS',
    GET_CERTBYID_FAIL = '@@certs/GET_CERTBYID_FAIL',
 }

 export interface CertsState {
   events  : Array<any>;
   categories : Array<any>;
   error : Object;
}