import { PermissionsTypes, PermissionsState } from "./actionTypes"

export const INIT_STATE : PermissionsState = {
  events: [],
  categories: [],
  error: {},
}

const Permission = (state = INIT_STATE, action : any) => {
  switch (action.type) {
    case PermissionsTypes.API_SUCCESS:
      switch (action.payload.actionType) {
        case PermissionsTypes.GET_EVENTS:
          return {
            ...state,
            events: action.payload.data,
          };

        case PermissionsTypes.ADD_NEW_EVENT:
          return {
            ...state,
            events: [...state.events, action.payload.data],
          }

        default:
          return { ...state };
      }
    case PermissionsTypes.API_FAIL:
      switch (action.payload.actionType) {
        case PermissionsTypes.GET_EVENTS:
          return {
            ...state,
            events: action.payload.error,
          };

        case PermissionsTypes.ADD_NEW_EVENT:
          return {
            ...state,
            events: action.payload.error,
          };

        default:
          return { ...state };
      }

    case PermissionsTypes.UPDATE_EVENT_SUCCESS:
      return {
        ...state,
        events: state.events.map(event =>
          event.id.toString() === action.payload.id.toString()
            ? { event, ...action.payload }
            : event
        ),
      }

    case PermissionsTypes.UPDATE_EVENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case PermissionsTypes.DELETE_EVENT_SUCCESS:
      return {
        ...state,
        events: state.events.filter(
          event => event.id.toString() !== action.payload.id.toString()
        ),
      }

    case PermissionsTypes.DELETE_EVENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case PermissionsTypes.GET_PERMISSIONS:
      return {
        ...state,
        // events: action.payload.data,
        payload: state
      }

    case PermissionsTypes.GET_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissions: action.payload,
      }

    case PermissionsTypes.GET_PERMISSIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case PermissionsTypes.GET_PERMISSIONBYID:
      return {
        ...state,
        // events: action.payload.data,
        payload: state
      }

    case PermissionsTypes.GET_PERMISSIONBYID_SUCCESS:
      return {
        ...state,
        permissions: action.payload,
      }

    case PermissionsTypes.GET_PERMISSIONBYID_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default Permission
