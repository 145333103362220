import { useState } from 'react';
// i18n
import { useTranslation } from 'react-i18next';
// Component
import { Input, InputGroup, InputGroupText } from 'reactstrap';
// Util
import { insure } from 'src/util';

type Props = {
  className: string;
  onBack: Function;
  onCancel: Function;
  onSearch: Function;
};

export default ({ className, onBack, onCancel, onSearch }: Partial<Props>) => {
  const { t } = useTranslation();

  const [focus, setFocus] = useState(false);

  const handleFocus = () => {
    setFocus(true);
  };

  const handleBlur = () => {
    setFocus(false);
  };

  const searchbarPlaceholder = t('Search device');

  return (
    <div className={className}>
      <div className="mb-4 clearfix">
        <span className="float-start font-size-18 fw-bolder cursor-pointer">
          <i className="fas fa-arrow-left me-2" onClick={insure(onBack)} />
          <span className="text-capitalize">{t('select devices')}</span>
        </span>
        <span
          className="float-end lh-lg text-primary text-capitalize cursor-pointer"
          onClick={insure(onCancel)}>
          {t('cancel')}
        </span>
      </div>
      <InputGroup
        className={`border rounded-2 border-blue-hover
        ${focus && 'shadow-blue'}`}>
        <InputGroupText className="border-0 cus-bg-white pe-1">
          <i className="fas fa-search" />
        </InputGroupText>
        <Input
          className="border-0 ps-1 cus-bg-white"
          placeholder={searchbarPlaceholder}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={e => onSearch && onSearch(e.target.value)}
        />
      </InputGroup>
    </div>
  );
};
