export default {
  routes: [
    {
      groupName: '',
      menus: [
        {
          path: '/org/:orgId/ws/:wsId/overview',
          description: 'Overview',
          iconClassName: 'bx bx-customize',
        },
        {
          path: '/org/:orgId/ws/:wsId/assets',
          description: 'Assets',
          iconClassName: 'bx bx-list-ol',
        },
        {
          path: '/org/:orgId/ws/:wsId/rules',
          description: 'Rules',
          iconClassName: 'bx bx-link',
        },
        {
          path: '/org/:orgId/ws/:wsId/data-flows',
          description: 'Data Flows',
          iconClassName: 'bx bx-git-branch',
        },
        // {
        //     path: "/org/:orgId/ws/:wsId/akenza-logs",
        //     description: "Logs",
        //     iconClassName: "bx bx-dock-bottom"
        // },
        // {
        //     path: "/org/:orgId/ws/:wsId/integrations",
        //     description: "Integrations",
        //     iconClassName: "bx bx-analyse"
        // }
      ],
    },
    {
      groupName: 'Settings',
      menus: [
        {
          path: '/org/:orgId/ws/:wsId/general',
          description: 'General',
          iconClassName: 'bx bx-cog',
        },
        {
          path: '/org/:orgId/ws/:wsId/users',
          description: 'Users',
          iconClassName: 'bx bx-user',
        },
      ],
    },
  ],
};


