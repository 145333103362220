import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { isEmpty } from "lodash";

import {
    Button,
    Card,
    CardBody, CardHeader, CardSubtitle, CardTitle,
    Col,
    Container, Input, Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row, Table,
} from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";

/** Import Full Calendar  */
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";

//import images
// import calendar from "../../assets/images/undraw-calendar.svg";

import moment from 'moment'
import {getCharacter as onGetCharacter} from "../../../store/apps/Character/actions";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

//i18n
import { useTranslation } from "react-i18next";

// import DeleteModal from "./DeleteModal";

//css
import "@fullcalendar/bootstrap/main.css";

//redux
import { useSelector, useDispatch } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
    SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import {Link} from "react-router-dom";
import {useLocation} from "react-router";

interface OrganizeProps {
    className: string;
}
// TODO: 函数头
const Character = ({ className }: OrganizeProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { state } = useLocation();

    const columns = [
        {
            dataField: "owner",
            text: t("th_Organize"),
            sort: true,
        },
        {
            dataField: "name",
            text: t("th_Name"),
            sort: true,
        },
        {
            dataField: "createdTime",
            text: t("th_createdTime"),
            sort: true,
        },
        {
            dataField: "displayName",
            text: t("th_displayName"),
            sort: true,
        },
        {
            dataField: "users",
            text: t("chaTable_ContainUsers"),
            sort: true,
        },
        {
            dataField: "roles",
            text: t("chaTable_ContainCharacters"),
            sort: true,
        },
        {
            dataField: "isEnabled",
            text: t("th_Enabled"),
            sort: false,
        },
        {
            dataField: "Operator",
            text: t("th_Operator"),
            sort: false,
        }
    ];

    /*const { owner } = useSelector(function (state: any) {
        return {
            owner: state.location.owner,
        }
    });*/

    useEffect(() => {
        dispatch(onGetCharacter());
    }, [dispatch]);

    let { result } = useSelector(function (state: any) {
        return {
            result: state.Role.character,
        }
    });

    if(!result)
        result = [];

    let localId:string;
    let idToOrganize: string;

    let handleResult:any = ((result:any,index:number)=>{
        localId = result.owner+'/'+result.name;
        idToOrganize = 'admin/'+result.owner;

        result.owner = (<Link to={{pathname:'/apps-organizeadd', state:{id:idToOrganize,ifAdd:false}}}>{result.owner}</Link>)
        result.name = (<Link to={{pathname:'/apps-characteradd', state:{id:localId,ifAdd:false}}}>{result.name}</Link>)
        result.createdTime = moment(result.createdTime).format('YYYY-MM-DD HH:mm:ss')

        result.isEnabled = returnSwitch(result.isEnabled,index)
        result.Operator = returnButton(localId)
    })

    function returnSwitch(ifChecked:boolean,index:number): any{
        return (
            <div className="d-flex flex-wrap gap-2">
                <Input type="checkbox" id={"switch"+index} switch="none" defaultChecked={ifChecked} />
                <Label className="me-1" htmlFor={"switch"+index} data-on-label="On" data-off-label="Off"></Label>
            </div>
        );
    }

    function returnButton(id:string): any{
        return (
            <div className="d-flex flex-wrap gap-2">
                {/*<button
                    type="button"
                    className="btn btn-outline-primary waves-effect waves-light"
                >
                    {t("OP_AddData")}
                </button>*/}
                <Link to={{pathname:'/apps-characteradd', state:{id:id,ifAdd:false}}} className="btn btn-outline-warning waves-effect waves-light">
                    {t("OP_Edit")}
                </Link>

                <button
                    type="button"
                    className="btn btn-outline-danger waves-effect waves-light"
                >
                    {t("OP_Del")}
                </button>
            </div>);
    }

    // TODO 需要处理返回值
    for(let i = 0;i<result.length;i++){
        handleResult(result[i],i)
    }

    // Table Data
    const productData = result;

    const pageOptions: any = {
        sizePerPage: 10,
        totalSize: productData.length, // replace later with size(customers),
        custom: true,
    };

    const { SearchBar } = Search;

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{t("Character")}</title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumbs title={t("Dashboard")} breadcrumbItem={t("Character")} />
                    <Card>
                        <CardBody>
                            <PaginationProvider
                                pagination={paginationFactory(pageOptions)}
                                // columns={columns}
                                // data={productData}
                            >
                                {({ paginationProps, paginationTableProps }) => (
                                    <ToolkitProvider
                                        keyField="id"
                                        columns={columns}
                                        data={productData}
                                        search
                                    >
                                        {toolkitProps => (
                                            <React.Fragment>
                                                <Row className="mb-2">
                                                    <Col md="3">
                                                        <div className="search-box me-2 mb-2 d-inline-block">
                                                            <div className="position-relative">
                                                                <SearchBar {...toolkitProps.searchProps} />
                                                                <i className="bx bx-search-alt search-icon" />
                                                            </div>

                                                        </div>
                                                    </Col>
                                                </Row>

                                                <div className="d-flex flex-wrap gap-2">
                                                    {/*<button
                                                        type="button"
                                                        className="btn btn-success"
                                                    >
                                                        {t("OP_AddData")}
                                                    </button>*/}
                                                    <Link to="/apps-characteradd" className="btn btn-success">
                                                        {t("OP_AddData")}
                                                    </Link>
                                                </div>

                                                <Row>
                                                    <Col xl="12">
                                                        <div className="table-responsive">
                                                            <BootstrapTable
                                                                // responsive
                                                                bordered={false}
                                                                striped={false}
                                                                classes={"table align-middle table-nowrap"}
                                                                headerWrapperClasses={"thead-light"}
                                                                {...toolkitProps.baseProps}
                                                                {...paginationTableProps}
                                                            >
                                                            </BootstrapTable>
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className="align-items-md-center mt-30">
                                                    <Col className="inner-custom-pagination d-flex">
                                                        <div className="d-inline">
                                                            <SizePerPageDropdownStandalone
                                                                {...paginationProps}
                                                            />
                                                        </div>
                                                        <div className="text-md-right ms-auto">
                                                            <PaginationListStandalone
                                                                {...paginationProps}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        )}
                                    </ToolkitProvider>
                                )}
                            </PaginationProvider>
                        </CardBody>
                    </Card>
                </Container>
            </div>

        </React.Fragment>
    );
};

export default Character;
