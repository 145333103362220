import { Key, StrictMode, useState, useRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import { RuleContext } from "../../../reducer";
import Point from "../../../components/point"
import { insure } from "src/util";
import { includes } from "lodash";
type Props = {
    data: any;
    id: Key;
    onClear?: Function;
    onEdit?: Function;
    change: string;
}

export default ({ data, id, onClear, onEdit, change }: Props) => {
    const { t } = useTranslation()
    const { state: { logicState }, dispatch } = useContext(RuleContext);
    const conditionSelectedPoint: any = logicState.conditionSelectedPoint;
    const customLogicSelectedPoint: any = logicState.customLogicSelectedPoint;
    const logic_blocks: any = logicState.logic_blocks;
    let custom_logic: any = logicState.custom_logic;
    const [open, setOpen] = useState(true)
    const handleClose = () => {
        setOpen(!open)
    }

    const handlePointCheck = (x: number, y: number) => {
        let point = { id, x, y };
        if (conditionSelectedPoint && conditionSelectedPoint.id) {
            let find = logic_blocks.find((o: any) => o.id == conditionSelectedPoint.id);
            if (includes(find.action_ids, id)) {
                let ids = find.action_ids.filter((o: string) => o != id);
                find.action_ids = ids;
            } else {
                find.action_ids.push(id);
            }
            const action = {
                type: "UPDATE_LOGIC_CONDITION",
                payload: { ...find }
            }
            dispatch(action);
        } else if (customLogicSelectedPoint && customLogicSelectedPoint.id) {
            if (includes(custom_logic.action_ids, id)) {
                let ids = custom_logic.action_ids.filter((o: string) => o != id);
                custom_logic.action_ids = ids;
            } else {
                custom_logic.action_ids.push(id);
            }
            const action = {
                type: "UPDATE_CUSTOM_LOGIC",
                payload: custom_logic
            }
            dispatch(action);
        } else {
            const action = {
                type: "SELECT_ACTION",
                payload: point,
            }
            dispatch(action);
        }
    }

    const updatePointOffset = (x: number, y: number) => {
        data.offsetX = x;
        data.offsetY = y;
        const action = {
            type: "UPDATE_ACTION",
            payload: data
        }
        dispatch(action);
    }

    const component: any = {
        "CUSTOM_METAVUN_DB": <main className="border-top border-bottom px-3 py-2" hidden={!open}>
            <div className="mt-narrow-pre text-secondary text-capitalize">
                <span className="me-2  text-capitalize">{t("topic")}:</span>
                <span>{data.attribute.topic}</span>
            </div>
            {
                data.attribute.use_current_device ? <div className="mt-narrow-pretext-secondary">
                    <span className="me-2  text-capitalize">{t("use current device")}:</span>
                    <span>{"True"}</span>
                </div> : <div className="mt-narrow-pretext-secondary">
                    <span className="me-2  text-capitalize">{t("device")}:</span>
                    <span>{data.attribute.target_device_id}</span>
                </div>
            }
        </main>,
        "INFLUXDB": <main className="border-top border-bottom px-3 py-2" hidden={!open}>
            <div className="mt-narrow-pre text-secondary text-capitalize">
                <span className="me-2  text-capitalize">{t("InfluxDB Url")}:</span>
                <span>{data.attribute.uri}</span>
            </div>
            <div className="mt-narrow-pre text-secondary text-capitalize">
                <span className="me-2  text-capitalize">{t("InfluxDB Org")}:</span>
                <span>{data.attribute.org}</span>
            </div>
            <div className="mt-narrow-pre text-secondary text-capitalize">
                <span className="me-2  text-capitalize">{t("InfluxDB Bucket")}:</span>
                <span>{data.attribute.bucket}</span>
            </div>
            <div className="mt-narrow-pre text-secondary text-capitalize">
                <span className="me-2  text-capitalize">{t("Measurement Name")}:</span>
                <span>{data.attribute.measurement_name}</span>
            </div>
        </main>,
        "KAFKA": <main className="border-top border-bottom px-3 py-2" hidden={!open}>
            <div className="mt-narrow-pre text-secondary text-capitalize">
                <span className="me-2  text-capitalize">{t("Kafka Broker")}:</span>
                <span>{data.attribute.broker}</span>
            </div>
            <div className="mt-narrow-pre text-secondary text-capitalize">
                <span className="me-2  text-capitalize">{t("Kafka Topic")}:</span>
                <span>{data.attribute.topic}</span>
            </div>
            <div className="mt-narrow-pre text-secondary text-capitalize">
                <span className="me-2  text-capitalize">{t("Security Protocol")}:</span>
                <span>{data.attribute.security_protocol}</span>
            </div>
            <div className="mt-narrow-pre text-secondary text-capitalize">
                <span className="me-2  text-capitalize">{t("SASL Mechanism")}:</span>
                <span>{data.attribute.sasl_mechanism}</span>
            </div>
        </main>,
        "EMAIL": <main className="border-top border-bottom" hidden={!open}>
            <div className="px-3 py-2">
                <div className="mt-narrow-pre text-secondary text-capitalize">
                    <span className="me-2  text-capitalize">{t("Recipients")}:</span>
                    <span>{data.attribute.recipients}</span>
                </div>
            </div>
            <div className="bg-soft-light px-3 py-2 mt-narrow-pre text-secondary text-capitalize">
                <div className="mt-1">Subject</div>
                <div className="me-2 mt-2 text-capitalize text-dark" style={{ fontWeight: 'bold' }}>{data.attribute.subject}</div>
                <div className="mt-3">Message</div>
                <div className="me-2 mt-2 text-capitalize text-dark">{data.attribute.body}</div>
            </div>
        </main>
    }

    return (<StrictMode>
        <div className="mt-narrow-pre shadow border rounded-2 cus-bg-white">
            <header className="px-3 py-2 font-size-16 clearfix" style={{ position: 'relative' }}>
                <Point updateOffect={updatePointOffset} change={change} pointCheck={handlePointCheck} style={{left: -14}} />
                <div className="float-start fw-bold">
                    <i className="me-2 mdi mdi-link-variant" />
                    <span>{data.name}</span>
                </div>

                <div className="float-end">
                    <i className={`fas ${open ? "fa-angle-down" : "fa-angle-up"}`} onClick={handleClose} />
                </div>
            </header>
            {component[data.type]}
            <footer className="px-3 py-2 clearfix">
                <div className="float-start text-primary text-capitalize cursor-pointer"
                    onClick={insure(onClear, id)}>
                    {t("clear")}
                </div>
                <div className="float-end text-primary text-capitalize cursor-pointer"
                    onClick={insure(onEdit, data)}>
                    {t("edit")}
                </div>
            </footer>
        </div>
    </StrictMode>)
}
