import React, { useState } from "react";
import PropTypes from "prop-types";

//i18n
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

const Subscription = (props: any) => {
  return (
    <React.Fragment>
      <div className="page-content">
      Subscription
      </div>
    </React.Fragment>
  );
}

Subscription.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withTranslation()(withRouter(Subscription));