import React, { useState } from "react";
import PropTypes from "prop-types";
//i18n
import { withTranslation } from "react-i18next";
import { withRouter, Link } from "react-router-dom";
import Select from "react-select";
import { Col, Row, Container, InputGroup, Input, Label } from "reactstrap";
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

const Logs = (props: any) => {
  const {t} = props
  const [selectedGroup, setselectedGroup] = useState<any>(null);
  const optionMulti = [
    { label: "Choice 1", value: "choice-1" },
    { label: "Choice 2", value: "choice-2" },
    { label: "Choice 3", value: "choice-3" },
  ];
  const optionGroup = [
    { label: "Mustard", value: "Mustard" },
    { label: "Ketchup", value: "Ketchup" },
    { label: "Relish", value: "Relish" }
  ];
  const columns = [
    {
      dataField: "Status",
      text: t('status'),
    },
    {
      dataField: "Uplink",
      text: t('uplink'),
    },
    {
      dataField: "Downlink",
      text: t('downlink'),
    },
    {
      dataField: "Rules",
      text: t('Rules'),
    },
    {
      dataField: "Timestamp",
      text: t('timestamp'),
    },
    {
      dataField: "Duration",
      text: t('duration'),
    },
    {
      dataField: "Correlation Id",
      text: t('correlation id'),
    },
    {
      dataField: "Devices",
      text: t('devices'),
    }
  ];
  let returnButton = () => {
    return (
      <div className="d-flex flex-wrap gap-2">
        <Link to={{ pathname: '/#' }} className="btn btn-outline-warning waves-effect waves-light">
          {t("edit")}
        </Link>
        <button
          type="button"
          className="btn btn-outline-danger waves-effect waves-light"
        >
          {t("Delete")}
        </button>
      </div>);
  }
  // Table Data
  const productData = [
    {
      Name: "Moyun",
      Description: "Moyun",
      CreationDate: "2008/11/28",
      ModificationDate: "2008/11/28",
      Action: returnButton(),
    }
  ];

  const defaultSorted: any = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const pageOptions: any = {
    sizePerPage: 10,
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
  };

  // Select All Button operation
  const selectRow: any = {
    mode: "checkbox",
  };
  function handleSelectGroup(selectedGroup: any) {
    setselectedGroup(selectedGroup);
  }
  return (
    <React.Fragment>
      <div className="px-4 py-4 flex-col">
        <div className="flex-row items-center justify-between px-2 mb-4" >
          <div className="text-dark font-size-20 text-capitalize" style={{ fontWeight: 700 }}>{t('data processing logs')}</div>
        </div>
        <div className="container-fluid">
          <Row>
            <Container fluid>
              <Row>
                <Col xl={3} md={6}>
                  <div className="mb-3">
                    <label
                      htmlFor="choices-multiple-default"
                      className="form-label font-size-13 text-muted text-capitalize"
                    >
                        {t("device")}
                    </label>
                    <Select
                      defaultValue={[optionMulti[1]]}
                      isMulti
                      options={optionMulti}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </div>
                </Col>
                <Col xl={3} md={6}>
                  <div className="mb-3">
                    <label
                      htmlFor="choices-single-default"
                      className="form-label font-size-13 text-muted text-capitalize"
                    >
                        {t("data flow")}
                    </label>
                    <Select
                      value={selectedGroup}
                      onChange={() => {
                        handleSelectGroup(0);
                      }}
                      options={optionGroup}
                      classNamePrefix="select2-selection"
                    />
                  </div>
                </Col>
                <Col xl={3} md={6}>
                  <div className="mb-3">
                    <label
                      htmlFor="choices-single-default"
                      className="form-label font-size-13 text-muted text-capitalize"
                    >
                        {t("Rule")}
                    </label>
                    <Select
                      value={selectedGroup}
                      onChange={() => {
                        handleSelectGroup(0);
                      }}
                      options={optionGroup}
                      classNamePrefix="select2-selection"
                    />
                  </div>
                </Col>
                <Col xl={3} md={6}>
                  <div className="mb-3">
                    <label
                      htmlFor="choices-single-default"
                      className="form-label font-size-13 text-muted text-capitalize"
                    >
                        {t("Rule")}
                    </label>
                    <InputGroup>
                      <Flatpickr
                        className="form-control d-block"
                        placeholder="dd M,yyyy"
                        options={{
                          mode: "range",
                          dateFormat: "Y-m-d",
                        }}
                      />
                    </InputGroup>
                  </div>
                </Col>
                <Col xl={3} md={6}>
                  <div className="mb-3 mt-1">
                    <label
                      htmlFor="choices-single-default"
                      className="form-label font-size-13 text-muted"
                    >
                        {t("show error logs only")}
                    </label>
                    <div>
                      <Input type="checkbox" id="switch1" switch="none" defaultChecked />
                      <Label className="me-1" htmlFor="switch1" data-on-label="" data-off-label=""></Label>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
            <Col className="col-12">
              <PaginationProvider
                pagination={paginationFactory(pageOptions)}
              // columns={columns}
              // data={productData}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="id"
                    columns={columns}
                    data={productData}
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                // responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                // selectRow={selectRow}
                                classes={"table align-middle table-nowrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                              />
                            </div>
                          </Col>
                        </Row>

                        <Row className="align-items-md-center mt-30">
                          <Col className="inner-custom-pagination d-flex">
                            <div className="d-inline">
                              <SizePerPageDropdownStandalone
                                {...paginationProps}
                              />
                            </div>
                            <div className="text-md-right ms-auto">
                              <PaginationListStandalone
                                {...paginationProps}
                              />
                            </div>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

Logs.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withTranslation()(withRouter(Logs));