import { takeEvery, put, call, all, fork } from "redux-saga/effects"

// Calender Redux States
import { UserTypes } from "./actionTypes"

import {
  getGlobalUsersFail,
  getGlobalUsersSuccess, getUserInfoFail, getUserInfoSuccess

} from "./actions"

//Include Both Helper File with needed methods
import {
  api_getGlobalUsers,
  api_getUserInfoById,
} from "../../../API/api"

// 批量获取用户信息
function* onGetGlobalUsers() {
  try {
    const response : Promise<any> = yield call(api_getGlobalUsers)
    yield put(getGlobalUsersSuccess(response))
  } catch (error) {
    yield put(getGlobalUsersFail(error))
  }
}

// 获取单个用户信息
function* onGetUserInfo({payload:id}:any) {
  try {
    const response : Promise<any> = yield call(api_getUserInfoById,id)
    yield put(getUserInfoSuccess(response))
  } catch (error){
    yield put(getUserInfoFail(error))
  }
}

export function* watchOnGetGlobalUsers() {
  yield takeEvery(UserTypes.GET_GLOBALUSERS, onGetGlobalUsers);
}

export function* watchOnGetUserInfo() {
  yield takeEvery(UserTypes.GET_USERINFO, onGetUserInfo);
}

function* userSaga() {
  yield all([fork(watchOnGetGlobalUsers)]);
  yield all([fork(watchOnGetUserInfo)]);
}

export default userSaga
