export const companySizeOpts = [
  {
    value: 10,
    label: "1 ~ 10"
  },
  {
    value: 50,
    label: "10 ~ 50"
  },
  {
    value: 100,
    label: "50 ~ 100"
  },
  {
    value: 500,
    label: "100 ~ 500"
  },
  {
    value: 1000,
    label: "500 ~ 1000"
  }
]
