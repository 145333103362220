import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { ActionTypes } from "./actionTypes";
import { 
  addDeviceConnectorError, addDeviceConnectorSuccess, getDeviceConnectorListSuccess, getDeviceConnectorListError,
  deleteDeviceConnectorSuccess, deleteDeviceConnectorError
} from "./actions";

import {
  createIotDeviceConnector, getIotDeviceConnectorList, deleteIotDeviceConnector
} from "../../../helpers/iot_backend_helper";
// const fireBaseBackend = getFirebaseBackend();

function* addDeviceConnector({ payload: { data, history } }: any) {
  try {
    const response: Promise<any> = yield call(createIotDeviceConnector, data, null);
    yield put(addDeviceConnectorSuccess(response));
  } catch (error) {
    yield put(addDeviceConnectorError(error));
  }
}

function* getDeviceConnectorList({ payload: { data, history } }: any) {
  try {
    const response: Promise<any> = yield call(getIotDeviceConnectorList, data, null);
    yield put(getDeviceConnectorListSuccess(response));
  } catch (error) {
    yield put(getDeviceConnectorListError(error));
  }
}

function* deleteDeviceConnectorList({ payload: { data, history } }: any) {
  try {
    const response: Promise<any> = yield call(deleteIotDeviceConnector, data, null);
    yield put(deleteDeviceConnectorSuccess(response));
  } catch (error) {
    yield put(deleteDeviceConnectorError(error));
  }
}

function* authSaga() {
  yield takeEvery(ActionTypes.ADD_DEVICE_CONNECTOR, addDeviceConnector);
  yield takeEvery(ActionTypes.GET_DEVICE_CONNECTOR_LIST, getDeviceConnectorList);
  yield takeEvery(ActionTypes.DELETE_DEVICE_CONNECTOR, deleteDeviceConnectorList);
}

export default authSaga;
