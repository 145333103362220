import { StrictMode, useEffect, useState } from "react";

import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  FormText,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  UncontrolledDropdown
} from "reactstrap";

import { insure } from "src/util";
import AceEditor from "react-ace";
import { getIotDevices } from "src/helpers/iot_backend_helper";

export default withTranslation()(withRouter((props: any) => {
  const { t, onBack, onCancel, onSave, onUpdate, type, connector, typeName, match } = props;
  const { wsId } = match.params;
  const [useCustomPayload, setUseCustomPayload] = useState<boolean>(connector && connector.attribute.use_custom_payload);
  const [useCurrentDevice, setUseCurrentDevice] = useState<boolean>(connector ? connector.attribute.use_current_device:true);
  const [customPayload, setCustomPayload] = useState<string>(connector&& connector.attribute.use_custom_payload?connector.attribute.custom_payload:`{
    "message": {{data}} 
}`);
  const [name, setName] = useState<string>(connector && connector.name);
  const [topic, setTopic] = useState<string>(connector && connector.attribute.topic);
  const [targetDeviceId, setTargetDeviceId] = useState<string>(connector && connector.attribute.target_device_id);
  const [targetDeviceName, setTargetDeviceName] = useState<string>("");
  const [devices, setDevices] = useState<any>([]);
  const isCompleted = (): boolean => {
    if (!name || !topic) return false;
    if (!useCurrentDevice && !targetDeviceId) {
      return false;
    }
    return true;
  }
  useEffect(() => {
    getIotDevices({ workspace_id: wsId }, null).then(result => {
      if (result.Code == 200) {
        if(targetDeviceId) {
          let find = result.Data.find((row:any) => row.id == targetDeviceId);
          if(find) {
            setTargetDeviceName(find.name)
          }
        }
        setDevices(result.Data)
      }
    });
  }, []);
  const onCustomPayloadChange = (data:any) => {
    setCustomPayload(data);
  }
  const handleSave = () => {
    let data = {
      name,
      workspace_id: wsId,
      attribute: {
        topic, use_current_device: useCurrentDevice, target_device_id:targetDeviceId, 
        use_custom_payload: useCustomPayload, custom_payload: {}
      }
    }
    if (useCustomPayload) {
      data.attribute.custom_payload = customPayload
    } 
    if(connector && connector.id) {
      onUpdate(connector.id, data);
    }else {
      onSave(data);
    }
  }
  return (<StrictMode>
    <header className="flex-shrink-0 mb-5 user-select-none clearfix">
      <span onClick={insure(onBack)} className="float-start font-size-18 fw-bolder cursor-pointer">
        <i className="fas fa-arrow-left me-2" />
        <span className="text-capitalize">{typeName}</span>
      </span>
      <span className="float-end lh-lg text-primary text-capitalize cursor-pointer"
        onClick={insure(onCancel)}>
        {t("cancel")}
      </span>
    </header>
    <Form>
      <FormGroup className="mt-wide-pre">
        <Label className="first-letter-upper">{t("connector name")}</Label>
        <Input value={name} onChange={(e) => { setName(e.target.value) }} />
      </FormGroup>
      <FormGroup className="mt-wide-pre">
        <Label className="first-letter-upper">{t("topic")}</Label>
        <Input value={topic} onChange={(e) => { setTopic(e.target.value) }} placeholder="default" />
      </FormGroup>
      <FormGroup className="mt-wide-pre">
        <Label className="first-letter-upper">{t("use current device")}</Label>
        <div className="form-check form-switch">
          <input className="form-check-input" type="checkbox" checked={useCurrentDevice} onChange={(e) => { setUseCurrentDevice(e.target.checked) }} />
        </div>
      </FormGroup>
      {
        !useCurrentDevice && <FormGroup className="mt-wide-pre">
          <Label className="first-letter-upper">{t("device")}</Label>
          <UncontrolledDropdown>
            <DropdownToggle className="w-100 h-100" tag="div">
              <InputGroup>
                <Input value={targetDeviceName} />
                <InputGroupText>
                  <i className="fas fa-caret-down" />
                </InputGroupText>
              </InputGroup>
            </DropdownToggle>
            <DropdownMenu className="w-100">
              {
                devices.map((device: any) => <DropdownItem onClick={() => { setTargetDeviceId(device.id); setTargetDeviceName(device.name) }} className="px-1 user-select-none">
                  <i className="me-2 mdi mdi-link-variant" />
                  <span className="fw-bold">text 1</span>
                  <span className="mx-2">-</span>
                  <span>{device.name}</span>
                </DropdownItem>)
              }
            </DropdownMenu>
          </UncontrolledDropdown>
        </FormGroup>
      }

      <FormGroup className="mt-wide-pre mt-3">
        <Label className="first-letter-upper">{t("use custom payload")}</Label>
        <div className="form-check form-switch">
          <input className="form-check-input" type="checkbox" checked={useCustomPayload} onChange={(e) => { setUseCustomPayload(e.target.checked) }} />
        </div>
      </FormGroup>
      {
        useCustomPayload && <FormGroup className="mt-wide-pre mt-3">
          <Label className="first-letter-upper">{t("custom payload")}</Label>
          <div className="mt-1">
            <div className="mt-1" style={{ border: '1px solid #e8e8e8', backgroundColor: '#fafafa', borderRadius: '4px', padding: '12px', height: "150px" }} >
              <AceEditor
                  placeholder={""}
                  mode="javascript"
                  theme="github"
                  name="blah2"
                  style={{ width: '100%', height: '100%' }}
                  onChange={onCustomPayloadChange}
                  fontSize={14}
                  showPrintMargin={true}
                  showGutter={true}
                  highlightActiveLine={true}
                  value={customPayload}
                  setOptions={{
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: true,
                    enableSnippets: true,
                    showLineNumbers: true,
                    tabSize: 2,
                  }} />
            </div>
          </div>
          <FormText className="font-size-14">
            {t("make use of template variables by using the following syntax {{data.dataKey}}")}
          </FormText>
        </FormGroup>
      }
    </Form>
    <footer className="py-4 clearfix">
      <Button onClick={handleSave} className="float-end first-letter-upper" color="primary" disabled={!isCompleted()}>
        <i className="me-2 fas fa-save" />
        {t("save connector")}
      </Button>
    </footer>
  </StrictMode>)
}))
