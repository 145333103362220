import { createContext, Dispatch, Key } from "react";

import { INPUTS } from "./constant";

import { Action } from "../common/type";
export type InputDetail = {
    id: Key;
    name?: Key;
    description?: string;
    availableSensors?: string[];
}
type Point = {
    id: string;
    top: number;
    left: number;
}
export type InputState = {
    inputs: InputDetail[],
    selectedPoint?: Point
}

export const defaultInputState = {
    inputs: [],
    selectedPoint: { id: '', top: 0, left: 0 }
}

export const initInput = (initialState: Partial<InputState>) => {
    return {
        ...defaultInputState,
        ...initialState,
    }
}

export const inputReducer = (state: InputState, action: Action): InputState => {
    switch (action.type) {
        case INPUTS.PUSH:
            return {
                ...state,
                inputs: [...state.inputs, action.payload],
            }
        case INPUTS.REMOVE:
            return {
                ...state,
                inputs: state.inputs.filter(input => input.id != action.payload),
            }
        case INPUTS.CLEAR:
            return {
                ...state,
                inputs: defaultInputState.inputs,
            }
        case INPUTS.SELECT_INPUT:
            return {
                ...state,
                selectedPoint: action.payload
            }
        default:
            return {
                ...state
            }
    }
}


type Context = {
    state: InputState,
    dispatch: Dispatch<Action>
}

export const InputContext = createContext<Context>({
    state: defaultInputState,
    dispatch: (_v: Action): void => {
    }
})
