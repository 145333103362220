// Component
import { Col, Row } from 'reactstrap';
import KPICage, { props as KPIProps } from './Cage';
// Echart
import echarts from 'echarts';

type props = {
  KPIs?: KPIProps[];
  supplement?: echarts.EChartOption;
  chartClassName?: string;
};

export default function ({ chartClassName, KPIs, supplement }: props) {
  return (
    <Row className="mx-0 mt-sparse-pre" xs={4}>
      {KPIs &&
        KPIs.map(kpi => {
          return (
            <Col className="px-0 pb-3" key={kpi.name}>
              <KPICage
                chartClassName={chartClassName}
                {...kpi}
                supplement={supplement}
              />
            </Col>
          );
        })}
    </Row>
  );
}
