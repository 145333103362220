'use strict';

// Map
import { ILayer, ISceneConfig, Scene } from '@antv/l7';

type L7SceneEvents = 'loaded' | ' resize';
type L7BoxEvents =
  | 'loaded'
  | 'mapmove'
  | 'movestart'
  | 'moveend'
  | 'zoomchange'
  | 'zoomstart'
  | 'zoomend';
type L7MouseEvents =
  | 'click'
  | 'dbclick'
  | 'mousemove'
  | 'mousewheel'
  | 'mouseover'
  | 'mouseout'
  | 'mouseup'
  | 'mousedown'
  | 'contextmenu'
  | 'dragstart'
  | 'dragging'
  | 'dragend';
type L7Events = L7SceneEvents | L7BoxEvents | L7MouseEvents | string;

export class TracePlotL7 {
  scene: Scene;

  constructor(container: HTMLDivElement, scene: Omit<ISceneConfig, 'id'>) {
    this.scene = new Scene({
      id: container,
      ...scene,
    });
  }

  addLayer(layer: ILayer) {
    this.scene.addLayer(layer);
  }

  on(type: L7Events, handle: (...args: any[]) => void) {
    this.scene.on(type, handle);
  }

  destroy() {
    this.scene.destroy();
  }
}
