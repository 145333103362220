import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { isEmpty } from "lodash";

import {
    Card,
    CardBody,
    Container, Input, Label,
} from "reactstrap";

/** Import Full Calendar  */
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";

//import images
// import calendar from "../../assets/images/undraw-calendar.svg";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import {getProviderById as onGetProviderById} from "../../../store/settings/Provider/actions";

//i18n
import { useTranslation } from "react-i18next";

import {
    addNewEvent as onAddNewEvent,
    deleteEvent as onDeleteEvent,
    getCategories as onGetCategories,
    getEvents as onGetEvents,
    updateEvent as onUpdateEvent,
} from "../../../store/apps/Organize/actions";

// import DeleteModal from "./DeleteModal";

//css
import "@fullcalendar/bootstrap/main.css";

//redux
import { useSelector, useDispatch } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
    SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";

import {Link} from "react-router-dom";
import {useLocation} from "react-router";

interface ProviderAddProps {
    className: string;
}

// TODO: 函数头
const ProviderAdd = ({ className }: ProviderAddProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { state } = useLocation();

    const localState:any = state;

    useEffect(() => {
        dispatch(onGetProviderById(localState.id));
    }, [dispatch]);

    let { result } = useSelector(function (state: any) {
        return {
            result: state.Providers.providers,
        }
    });

    if(localState.ifAdd){
        result = null
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{t("providerAdd")}</title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumbs title={t("Dashboard")} breadcrumbItem={t("providerAdd")} />
                    <Card>
                        <CardBody>
                            <div className="mb-3 d-flex flex-wrap gap-2">
                                <button
                                    type="button"
                                    className="btn btn-outline-primary waves-effect waves-light"
                                >
                                    {t("OP_Save")}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-success"
                                >
                                    {t("OP_SaveAndExit")}

                                </button>
                                {/*<button
                                    type="button"
                                    className="btn btn-soft-warning waves-effect waves-light"
                                >
                                    {t("OP_Cancel")}
                                </button>*/}
                                <Link to="/page-provider" className="btn btn-secondary">
                                    {t("OP_Cancel")}
                                </Link>
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("th_Name")}</Label>
                                <i className="bx bx-help-circle" title={t("th_Name")} />
                                <Input className="form-control" type="text" defaultValue={result?result.name:""} autoComplete="" id="example-text-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("th_displayName")}</Label>
                                <i className="bx bx-help-circle" title={t("th_displayName")} />
                                <Input className="form-control" type="text" defaultValue={result?result.displayName:""} id="example-text-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("Category")}</Label>
                                <i className="bx bx-help-circle" title={t("Category")} />
                                <Input className="form-control" type="text" defaultValue={result?result.category:""} id="example-text-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("th_Type")}</Label>
                                <i className="bx bx-help-circle" title={t("th_Type")} />
                                <Input className="form-control" type="text" defaultValue={result?result.type:""} id="example-text-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("th_ClientID")}</Label>
                                <i className="bx bx-help-circle" title={t("th_ClientID")} />
                                <Input className="form-control" type="text" defaultValue={result?result.clientId:""} id="example-text-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("th_ClientKey")}</Label>
                                <i className="bx bx-help-circle" title={t("th_ClientKey")} />
                                <Input className="form-control" type="text" defaultValue={result?result.clientSecret:""} id="example-text-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("provider_URL")}</Label>
                                <i className="bx bx-help-circle" title={t("provider_URL")} />
                                <Input className="form-control" type="text" defaultValue={result?result.providerUrl:""} id="example-text-input" />
                            </div>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};
export default ProviderAdd;
