import React, { Key, useContext, useState, useEffect } from "react";
import { Button } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
//i18n
import { useTranslation, withTranslation } from 'react-i18next';
import { withRouter, Link } from "react-router-dom";

//redux
import { useSelector, useDispatch } from "react-redux";
// actions
import { getDeviceConnectorList, addDeviceConnector } from "src/store/actions";
import {
    createIotDeviceConnector
  } from "src/helpers/iot_backend_helper";

import { ACTION_TYPE } from "../../../../contant";
import { DataFlowContext } from "../../../../reducer";

import OptionContainer from "./OptionContainer";


const DeviceConnectorOption = (props: any) => {
    const {t} = useTranslation()

    let { connectivity, history, match } = props;
    const { wsId } = match.params;
    const [addConnector, setAddConnector] = useState(false);

    const reduxDispatch = useDispatch();

    const { dispatch } = useContext(DataFlowContext)

    const handleSelect = (connector: Key) => {
        const action = {
            type: ACTION_TYPE.SELECT_DEVICE_CONNECTOR,
            payload: connector
        }
        dispatch(action)
    }

    useEffect(() => {
        fetchData({ connectivity });
    }, []);

    // 获取设备连接器列表数据
    const fetchData = (params: any) => {
        reduxDispatch(getDeviceConnectorList({
            workspace_id: wsId, ...params
        }, history));
    }
    const { error, list } = useSelector((state: any) => (state.deviceConnector));

    // handleValidSubmit
    const handleValidSubmit = (event: any, values: any) => {
        let data = { 
            workspace_id: wsId,
            connectivity, 
            ...values 
        };
        createIotDeviceConnector(data, null).then((result:any )=> {
            if(result && result.Code == 200) {
                const action = {
                    type: ACTION_TYPE.SELECT_DEVICE_CONNECTOR,
                    payload: result.Data
                }
                dispatch(action)
            }
        });
    };

    return (<React.StrictMode>
        {
            addConnector ? <React.Fragment>
                <AvForm
                    onValidSubmit={(e: any, v: any) => {
                        handleValidSubmit(e, v);
                    }}
                >
                    <div className="mb-4">
                        <AvField
                            name="name"
                            // defaultValue={formData.name}
                            label={props.t('Connector name')}
                            placeholder=""
                            type="text"
                            errorMessage={t("Enter Connector Name")}
                            validate={{ required: { value: true } }}
                        />
                    </div>
                    <div className="mb-4">
                        <AvField
                            name="description"
                            // defaultValue={formData.name}
                            label={props.t('Connector description')}
                            placeholder=""
                            type="text"
                            errorMessage="Enter Connector description"
                            validate={{ required: { value: false } }}
                        />
                    </div>
                    <div className="flex-row mb-4 justify-end">
                        <Button color="primary" type="submit">
                            <i className="fas fa-save me-2"/>
                            {props.t('save connector')}
                        </Button>
                    </div>
                </AvForm>
            </React.Fragment> : <React.Fragment>
                <header className="mb-2">{t(`Available ${connectivity} device connectors`)}</header>
                <main className="mb-2">
                    {list && list.map((item: any) => (
                        <OptionContainer key={item.id} data={item} onSelect={handleSelect} />
                    ))}
                </main>
                <footer>
                    <div className="mb-2">{t(`Create new ${connectivity} device connector`)}</div>
                    <button
                        className="btn btn-outline-light font-size-16"
                        onClick={() => setAddConnector(true)}
                    >
                        <i className="fas fa-plus me-2 font-size-14 text-secondary" />
                        {t("Create new connector")}
                    </button>
                </footer>
            </React.Fragment>
        }

    </React.StrictMode>)
}

export default withTranslation()(withRouter(DeviceConnectorOption));
