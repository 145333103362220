import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
//i18n
import { withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
// datatable related plugins
import BootstrapTable, {
  ColumnDescription,
  PaginationOptions,
} from 'react-bootstrap-table-next';
//redux
import paginationFactory from 'react-bootstrap-table2-paginator';
import Loading from 'src/components/Loading';
import ConfirmModal, { ModalProps } from '../components/ConfirmModal';
import {
  deleteIotDeviceType,
  getIotDeviceTypeList,
} from 'src/helpers/iot_backend_helper';

type TParams = {
  page_size: number;
  page_number: number;
  order?: string;
};

type DeviceRow = {
  id: string
}

const DeviceTypes = (props: any) => {
  let {t, match} = props;
  const {orgId} = match.params;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentRow, setCurrentRow] = useState<DeviceRow>({id: ""});
  const [list, setList]: [Array<any>, Function] = useState([]);
  const [loadding, setLoadding]: [boolean, Function] = useState(true);
  // const { error, list, Total } = useSelector((state: any) => (state.deviceType));
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const[total, setTotal] = useState(0)
  const { user } = useSelector((state: any) => ({
    user: state.login.user
  }));

  const role = user.user_organizations.find((org : any)=>org.organization.id == orgId)?.role|| "viewer"

  useEffect(() => {
    fetchData({page_number: 1, page_size: 10});
  }, []);

  // 获取设备类型列表数据
  const fetchData = (params: TParams): void => {
    getIotDeviceTypeList({org_id: orgId, ...params}, null).then((result) => {
      if (result.Code == 200 && result.Data && result.Data.length) {
        setTotal(result.Total)
        setList(result.Data);
      }
    }).finally(() => setLoadding(false));
  }

  // 删除设备类型
  const removeDeviceType = (row: DeviceRow): void => {
    setIsOpen(true);
    setCurrentRow(row);
  }

  // 确认删除设备类型
  const confirmRemove = (): void => {
    deleteIotDeviceType(currentRow.id, null).then(result => {
      if (result && result.Code == 200) {
        let data = list.filter((item: DeviceRow) => item.id != currentRow.id);
        setList(data);
        setIsOpen(false);
      }
    }).catch(e => {
      alert(e.response.data.message);
    });
  }

  const handleSort = (field:string, order:"asc"|"desc") => {
    fetchData({page_size:pageSize, page_number:pageNumber, order:`${field}=${order}`})
  }

  const columns: any[] = [
    {
      dataField: "name",
      text: t('Name'),
      formatter: (value: any, row: any, index: any) => (
        <div className="flex-row">
          <Link className="me-4 cursor-pointer" to={`/org/${orgId}/device-types/${row.id}/edit`}>
            {row.name}
          </Link>
        </div>
      ),
      sort: true,
      onSort: handleSort
    },
    {
      dataField: "description",
      text: t('Description'),
      sort: true,
      onSort: handleSort
    },
    {
      dataField: "connectivity",
      text: t('Connectivity'),
      sort: true,
    },
    {
      dataField: "manufacturer",
      text: t('Device manufacturer'),
      sort: true,
      onSort: handleSort
    },
    {
      dataField: "Action",
      text: t('Action'),
      formatter: (value:any, row:any)=> (
        <div className="flex-row">
          {
            (role === "editor" || role === "admin") ?
              <Link className="me-4 cursor-pointer" to={`/org/${orgId}/device-types/${row.id}/edit`}>
                {t('Edit')}
              </Link>:<div className="me-4 cursor-pointer text-muted cursor-not-allowed">
                {t('Edit')}
              </div>
          }
          {
            (role === "editor" || role === "admin")? <div onClick={() => removeDeviceType(row)} className="text-danger">{t('Delete')}</div>:
              <div className="me-4 cursor-pointer text-muted cursor-not-allowed">{t('Delete')}</div>
          }
        </div>
      )
    },
  ];

  const defaultSorted: [{ dataField: any; order: 'asc' | 'desc' }] = [
    {
      dataField: "name",
      order: "asc",
    }
  ];

  const pageOptions: PaginationOptions = {
    page: pageNumber,
    sizePerPage: pageSize,
    totalSize: total, // replace later with size(customers),
  };

  const handleTableChange = async (type: string, { page, sizePerPage }: any) => {
    if (type == "sort") {
      return
    }
    fetchData({ page_size:sizePerPage, page_number: page });
    setPageNumber(page);
    setPageSize(sizePerPage);
  }


  const confirmModalProps: ModalProps = {
    isOpen: isOpen,
    hideModal: () => setIsOpen(false),
    title: 'Do you really want to delete this Device Type?',
    description: 'This action cannot be undone',
    confirm: confirmRemove
  }

  return (
    <React.Fragment>
      {loadding && <Loading loading={loadding}/>}
      <div className="flex-col px-4 py-4">
        <div className="flex-row items-center justify-between mb-4 px-3">
          <div className="font-size-20 text-gray-900" style={{fontWeight: 700}}>{t('device types')}</div>
          <div>
            <button
              type="button"
              className="btn btn-primary waves-effect waves-light font-size-16"
              disabled={role == "viewer"}
            >
                <span style={{fontSize: '18px', marginRight: '10px'}}> +
                  <Link to={`/org/${orgId}/device-types/add`} style={{color: '#fff'}}> {t('Create Device Type')} </Link>
                </span>
            </button>
          </div>
        </div>
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <div>
                    {t("_Showing")} 1 - 1 {t("_of")} {list.length} {t("_assets")}
                  </div>
                  <BootstrapTable bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  classes={"table align-middle table-nowrap"}
                                  headerWrapperClasses={"thead-light"}
                                  keyField="id"
                                  remote
                                  columns={columns}
                                  data={list}
                                  pagination={paginationFactory(pageOptions)}
                                  onTableChange={handleTableChange}/>
                  {/* ---------- * ---------- * ---------- * ---------- * ---------- * ---------- * ----------*/}
                  {/*<PaginationProvider*/}
                  {/*  pagination={paginationFactory(pageOptions)}*/}
                  {/*// columns={columns}*/}
                  {/*// data={productData}*/}
                  {/*>*/}
                  {/*  {({ paginationProps, paginationTableProps }) => {*/}
                  {/*    return (*/}
                  {/*      <ToolkitProvider*/}
                  {/*        keyField="id"*/}
                  {/*        columns={columns}*/}
                  {/*        data={list}*/}
                  {/*        search*/}
                  {/*      >*/}
                  {/*        {toolkitProps => (*/}
                  {/*          <React.Fragment>*/}
                  {/*            <Row className="mb-2">*/}
                  {/*              <Col md="8" className="flex-row items-center">*/}
                  {/*                <div>*/}
                  {/*                  {t("_Showing")} 1 - 1 {t("_of")} {list.length} {t("_assets")}*/}
                  {/*                </div>*/}
                  {/*              </Col>*/}
                  {/*            </Row>*/}
                  {/*            <Row>*/}
                  {/*              <Col xl="12">*/}
                  {/*                <div className="table-responsive">*/}
                  {/*                  <BootstrapTable*/}
                  {/*                    // responsive*/}
                  {/*                    bordered={false}*/}
                  {/*                    striped={false}*/}
                  {/*                    defaultSorted={defaultSorted}*/}
                  {/*                    classes={"table align-middle table-nowrap"}*/}
                  {/*                    headerWrapperClasses={"thead-light"}*/}
                  {/*                    {...toolkitProps.baseProps}*/}
                  {/*                    {...paginationTableProps}*/}
                  {/*                  />*/}
                  {/*                </div>*/}
                  {/*              </Col>*/}
                  {/*            </Row>*/}
                  {/*          </React.Fragment>*/}
                  {/*        )}*/}
                  {/*      </ToolkitProvider>*/}
                  {/*    )*/}
                  {/*  }}*/}
                  {/*</PaginationProvider>*/}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <ConfirmModal {...confirmModalProps} />
    </React.Fragment>
  );
}

DeviceTypes.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withTranslation()(withRouter(DeviceTypes));