import { useRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import { RuleContext } from "../../../../reducer";
import { Input, Col, PopoverBody, UncontrolledPopover, Button } from "reactstrap";
import { includes } from "lodash";
import Point from "../../../../components/point"
type Props = {
  onClear: Function;
  index: number;
  data: any;
  change: string;
}

export default ({ data, index, change, onClear }: Props) => {
  const ref = useRef<any>(null);
  const { t } = useTranslation();
  const { state: { input }, dispatch } = useContext(RuleContext);
  const inputSelectedPoint: any = input.selectedPoint;

  const handleDataSourceCheck = (x: number, y: number) => {
    let point = { id: data.id, left: x, top: y };
    if (inputSelectedPoint && inputSelectedPoint.id) {
      if (includes(data.input_ids, inputSelectedPoint.id)) {
        let ids = data.input_ids.filter((o: string) => o != inputSelectedPoint.id);
        data.input_ids = ids;
      } else {
        data.input_ids.push(inputSelectedPoint.id);
      }
      const action = {
        type: "UPDATE_DATA_SOURCE",
        payload: data
      }
      dispatch(action);
    } else {
      const action = {
        type: "SELECT_DATA_SOURCE",
        payload: point,
      }
      dispatch(action);
    }
  }
  const handleChange = (key: string, value: string) => {
    if (key == 'trigger_on_uplink') {
      data.trigger_on_uplink = value;
      data.last_sample = !value;
    } else if (key == 'last_sample') {
      data.last_sample = value;
      data.trigger_on_uplink = !value;
    } else {
      data[key] = value;
    }
    const action = {
      type: "UPDATE_DATA_SOURCE",
      payload: data
    }
    dispatch(action);
  }

  const updatePointOffset = (x: number, y: number) => {
    data.offsetX = x;
    data.offsetY = y;
    const action = {
      type: "UPDATE_DATA_SOURCE",
      payload: data
    }
    dispatch(action);
  }

  return (
    <div key={data.id} style={{ position: 'relative' }}>
      <Point change={change} updateOffect={updatePointOffset} pointCheck={handleDataSourceCheck} style={{ left: "-29px" }} />
      <div className="first-letter-upper mt-3">{data.variable_name}</div>
      {
        data.input_ids.length ? <div>
          <div className="flex-row mt-1">
            <div>
              <Input onChange={(e) => handleChange("topic", e.target.value)} className="form-control" placeholder={t("Enter topic")} style={{ height: "25px", width: "120px" }} type="text" defaultValue={data.topic} id="example-text-input" />
            </div>
            <div style={{ marginLeft: '8px' }}>
              <Input onChange={(e) => handleChange("key", e.target.value)} className="form-control" placeholder={t("Enter key")} style={{ height: "25px", width: "120px" }} type="text" defaultValue={data.key} id="example-text-input" />
            </div>
            <div style={{ marginLeft: '8px' }} className="flex-row">
              <Button id={`data_source_${index}`} className="items-center" style={{ display: "flex", justifyContent: "center", width: "25px", height: "25px", borderRadius: "25px", border: "1px solid #e9e9ef" }}>
                <i className="far fa-sun"></i>
              </Button>
            </div>
            <UncontrolledPopover
              placement="bottom"
              target={`data_source_${index}`}
              trigger="legacy"
            >
              <PopoverBody>
                <div className="form-check mb-1">
                  <input onChange={(e: any) => handleChange("trigger_on_uplink", e.target.checked)} className="form-check-input" type="radio" name={`radio${index}`}
                    id={`formRadios1${index}`} defaultChecked={data.trigger_on_uplink} />
                  <label className="form-check-label" htmlFor={`formRadios1${index}`}>
                    {t("Trigger on uplink")}
                  </label>
                </div>
                <div className="form-check">
                  <input onChange={(e: any) => handleChange("last_sample", e.target.checked)} className="form-check-input" defaultChecked={data.last_sample} type="radio" name={`radio${index}`}
                    id={`formRadios2${index}`} />
                  <label className="form-check-label" htmlFor={`formRadios2${index}`}>
                    {t("Access last sample")}
                  </label>
                </div>
              </PopoverBody>
            </UncontrolledPopover>
          </div>
        </div> :
          <div className="flex-row mt-1">
            <Col sm={10}>
              <select disabled className="form-select" style={{ height: "25px", padding: "0px 10px" }}>
                <option>{t("Select topic and key combination")}</option>
              </select>
            </Col>
            <Col sm={2} className="flex-row">
              <div className="items-center" style={{ display: "flex", justifyContent: "center", width: "25px", height: "25px", borderRadius: "25px", marginLeft: "8px", border: "1px solid #e9e9ef" }}>
                <i className="far fa-sun"></i>
              </div>
            </Col>
          </div>
      }
    </div>
  )
}
