import { useState } from 'react';
// Router
import { useHistory } from 'react-router-dom';
// Component
import Guide from './Guide';
import New from './New';
// Util
import { awaitWrap } from 'src/util';
// API
import {
  changeWorkspace,
  createIotOrganization,
  createIotWorkspaces,
  uploadFile,
} from 'src/helpers/iot_backend_helper';
// Redux
import { useDispatch } from 'react-redux';
import { loginSuccess } from 'src/store/auth/login/actions';

export default function () {
  // 检查是否有组织，如果已经拥有组织则不用再新建组织
  let step = 0;
  let orgId = '';
  (function () {
    let auth: any = localStorage.getItem('authUser');

    auth = JSON.parse(auth);
    if (auth.Data.curr_org_id) {
      step = 1;
      orgId = auth.Data.curr_org_id;
    }
  })();

  const h = useHistory();
  const dispatch = useDispatch();

  const [gud, setGud] = useState(false);
  const onStart = () => {
    setGud(false);
  };

  const handleLocalStorage = async (
    organizationData: any,
    workspaceData: any,
  ) => {
    let authUser: any = localStorage.getItem('authUser');
    if (!authUser) return;
    authUser = JSON.parse(authUser);

    if (organizationData) {
      authUser.Data.curr_org_id = organizationData.id;
      authUser.Data.curr_org = organizationData;
      if (!authUser.Data.organizations?.length) {
        authUser.Data.organizations = [];
      }
      authUser.Data.organizations.push(organizationData);
      if (!authUser.Data.user_organizations?.length) {
        authUser.Data.user_organizations = [];
      }
      authUser.Data.user_organizations.push({
        id: organizationData.id,
        role: 'admin',
        organization: organizationData,
      });
    }

    authUser.Data.curr_wspace_id = workspaceData.id;
    authUser.Data.curr_workspace = workspaceData;
    if (!authUser.Data.workspaces?.length) {
      authUser.Data.workspaces = [];
    }
    authUser.Data.workspaces.push(workspaceData);
    if (!authUser.Data.user_workspaces?.length) {
      authUser.Data.workspaces = [];
    }
    authUser.Data.user_workspaces.push({
      id: workspaceData.id,
      role: 'admin',
      workspace: workspaceData,
    });

    const [, e] = await awaitWrap(
      changeWorkspace(
        {
          org_id: organizationData?.id || orgId,
          workspace_id: workspaceData.id,
        },
        null,
      ),
    );
    if (e) {
      alert(`自动切换工作区失败：${e}`);
    }
    dispatch(loginSuccess({ Data: authUser.Data }));
    localStorage.setItem('authUser', JSON.stringify(authUser));
  };

  const onNew = async (organizationData: any, workspaceData: any, f?: File) => {
    let rf: string | undefined = undefined;
    if (f) {
      const t = new FormData();
      t.append('file', f);
      const [r, e] = await awaitWrap(uploadFile(t, null));
      if (e) {
        alert(`上传组织徽标失败：${e}`);
        setGud(true);
        return;
      }
      rf = r?.Data ? r.Data?.host + r.Data?.file : undefined;
    }

    let r1: any, e1: any;
    // 如果不返回空对象对象则新建组织
    if (Reflect.ownKeys(organizationData).length) {
      const newOrganizationData = {
        name: organizationData?.name,
        description: organizationData?.description,
        logo: rf,
      };
      [r1, e1] = await awaitWrap(createIotOrganization(newOrganizationData));
      if (e1 || r1.Code != 200) {
        alert(`创建组织失败：${e1 || r1}`);
        setGud(true);
        return;
      }
    }

    const newWorkspaceData = {
      org_id: r1?.Data.id || orgId,
      name: workspaceData?.name,
      description: workspaceData?.description,
    };
    const [r2, e2] = await awaitWrap(
      createIotWorkspaces(newWorkspaceData, null),
    );
    if (e2 || r2.Code != 200) {
      alert(`创建工作区失败：${e2 || r2}`);
      setGud(true);
      return;
    }

    await handleLocalStorage(r1?.Data, r2.Data);
    h.push('/');
  };

  return (
    <div className="position-relative w-100 vh-100 bg-soft-light">
      <div className="position-absolute top-50 start-50 translate-middle">
        {gud ? (
          <Guide onStart={onStart} />
        ) : (
          <New initialStep={step} onNew={onNew} />
        )}
      </div>
    </div>
  );
}
