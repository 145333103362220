import { takeEvery, put, call, all, fork } from "redux-saga/effects"

// Calender Redux States
import {SyncersState, SyncersTypes} from "./actionTypes"
import {
  getSyncersSuccess, getSyncersFail, getSyncerByIdSuccess, getSyncerByIdFail
} from "./actions"

//Include Both Helper File with needed methods
import {
  api_getSyncerById,
  api_getSyncers,
} from "../../../API/api"

// 获取全部同步器信息
function* onGetSyncers() {
  try {
    const response : Promise<any> = yield call(api_getSyncers)
    yield put(getSyncersSuccess(response))
  } catch (error) {
    yield put(getSyncersFail(error))
  }
}

export function* watchOnGetSyncers() {
  yield takeEvery(SyncersTypes.GET_SYNCERS, onGetSyncers);
}

// 获取单个同步器信息
function* onGetSyncerById({payload:id}:any) {
  try {
    const response : Promise<any> = yield call(api_getSyncerById,id)
    yield put(getSyncerByIdSuccess(response))
  } catch (error) {
    yield put(getSyncerByIdFail(error))
  }
}

export function* watchOnGetSyncerById() {
  yield takeEvery(SyncersTypes.GET_SYNCERBYID, onGetSyncerById);
}

function* syncersSaga() {
  yield all([fork(watchOnGetSyncers)]);
  yield all([fork(watchOnGetSyncerById)]);
}

export default syncersSaga
