import React, { Fragment, RefObject, useRef, useState } from 'react';
// Router
import { useHistory, useRouteMatch } from 'react-router-dom';
//i18n
import { useTranslation } from 'react-i18next';
// Redux
import { useDispatch, useSelector } from 'react-redux';
// Component
import { Button, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { useDropzone } from 'react-dropzone';
import { SubmitHandler, useForm } from 'react-hook-form';
// Api
import {
  deleteIotOrganization,
  updateIotOrganization,
  uploadFile,
} from 'src/helpers/iot_backend_helper';
// Util
import { awaitWrap, currying } from 'src/util';
import { loginSuccess } from '../../../store/auth/login/actions';

type FormInputs = {
  name: string;
  description?: string;
};

// 表单校验规则
const formSchema = {
  name: {
    required: 'organization name is required',
    maxLength: {
      value: 16,
      message: 'the maximum length of the organization name is 16',
    },
  },
  description: {
    maxLength: {
      value: 500,
      message: 'the maximum length of the organization description is 500',
    },
  },
};

export default () => {
  const h = useHistory();
  const { t } = useTranslation();

  const m = useRouteMatch<any>();
  const { orgId } = m.params;

  const dispatch = useDispatch();

  let authUser: any = localStorage.getItem('authUser');
  if (!authUser) return;
  authUser = JSON.parse(authUser);

  const { user } = useSelector((state: any) => ({
    user: state.login.user,
  }));
  const role =
    user.user_organizations.find((org: any) => org.organization.id == orgId)
      ?.role || 'viewer';

  // 加载初始值
  const name = authUser.Data.curr_org.name;
  const description = authUser.Data.curr_org.description;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInputs>();

  // Logo
  const defaultLogo = authUser.Data.curr_org.logo;

  const [files, setFiles] = useState<(File & { preview?: string })[]>([]);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    maxSize: 2 << 20,
    onDrop: acceptedFiles => {
      setFiles(
        acceptedFiles.map(f =>
          Object.assign(f, {
            preview: URL.createObjectURL(f),
          }),
        ),
      );
    },
  });

  // Form
  const handleSave: SubmitHandler<FormInputs> = data => {
    (async function () {
      let logo: string | undefined;
      if (acceptedFiles[0]) {
        const t = new FormData();
        t.append('file', acceptedFiles[0]);
        const [rf, ef] = await awaitWrap(uploadFile(t, null));
        if (ef) {
          alert(`上传组织徽标失败：${ef}`);
          return;
        }
        logo = rf?.Data ? rf.Data?.host + rf.Data?.file : undefined;
      }

      const p = {
        name: data.name,
        description: data.description,
        logo,
      };
      const [res, err] = await awaitWrap(updateIotOrganization(orgId, p, null));
      if (err) {
        return;
      }
      if (res && res.Code != 200) {
        return;
      }
      const currOrg = {
        id: orgId,
        ...p,
      };

      // 修改当前工作区信息
      authUser.Data.curr_org = currOrg;
      // 修改 organizations 的信息
      authUser.Data.organizations = authUser.Data.organizations.map(
        (org: any) => {
          if (org.id == orgId) {
            return currOrg;
          }
          return org;
        },
      );
      // 修改 user_organizations 的信息
      authUser.Data.user_organizations = authUser.Data.user_organizations.map(
        (org: any) => {
          if (org.organization.id == orgId) {
            return {
              ...org,
              organization: currOrg,
            };
          }
          return org;
        },
      );

      dispatch(loginSuccess({ Data: authUser.Data }));
      localStorage.setItem('authUser', JSON.stringify(authUser));

      h.push('/');
    })();
  };

  const handleDelete = async () => {
    if (authUser.Data.user_organizations.length == 1) {
      alert('不能删除唯一的工作区！');
      return;
    }
    const [res, err] = await awaitWrap(deleteIotOrganization(orgId, null));
    if (err) {
      return;
    }
    if (res && res.Code != 200) {
      return;
    }

    // 处理 organizations
    authUser.Data.organizations = authUser.Data.organizations.reduce(
      (pre: any, cur: any) => {
        if (cur.id != orgId) {
          pre.push(cur);
        }
        return pre;
      },
      [],
    );
    // 处理 curr_org
    authUser.Data.curr_org = authUser.Data.organizations[0];
    // 处理 user_organizations
    authUser.Data.user_organizations = authUser.Data.user_organizations.reduce(
      (pre: any, cur: any) => {
        if (cur.organization.id != orgId) {
          pre.push(cur);
        }
        return pre;
      },
      [],
    );

    dispatch(loginSuccess({ Data: authUser.Data }));
    localStorage.setItem('authUser', JSON.stringify(authUser));

    h.push('/');
  };

  const actionRef = useRef<HTMLDivElement>(null);
  const scrollIntoView = (element: RefObject<HTMLDivElement>) => {
    element.current && element.current.scrollIntoView(true);
  };

  console.log(!(files?.length || defaultLogo));
  console.log(files?.[0]?.preview || defaultLogo);

  return (
    <Fragment>
      <header className="p-4 font-size-20 fw-bold">
        {t('Organization Settings')}
      </header>

      <div className="border-bottom" />

      <main className="d-flex p-4">
        <div className="cus-mw-4 w-50 me-4">
          {/* Name */}
          <FormGroup className="mt-sparse-pre">
            <Label className="text-capitalize">
              <span>{t('organization name')}</span>
            </Label>
            <Input
              placeholder={t('Please enter the organization name')}
              defaultValue={name}
              invalid={Boolean(errors.name)}
              {...register('name', formSchema.name)}
            />
            <FormFeedback className="mt-narrow-pre first-letter-upper">
              {t(errors.name?.message || '')}
            </FormFeedback>
          </FormGroup>

          {/* Description */}
          <FormGroup className="mt-sparse-pre">
            <Label>
              <span className="me-2 text-capitalize">
                {t('organization description')} ({t('optional')})
              </span>
            </Label>
            <Input
              placeholder={t('Please enter the organization description')}
              defaultValue={description}
              invalid={Boolean(errors.description)}
              {...register('description', formSchema.description)}
            />
            <FormFeedback className="mt-narrow-pre first-letter-upper">
              {t(errors.description?.message || '')}
            </FormFeedback>
          </FormGroup>

          {/* Logo */}
          <FormGroup className="mt-sparse-pre">
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              <h2
                className="py-5 text-center text-capitalize"
                hidden={Boolean(files?.length|| defaultLogo)}>
                {t('upload logo file')}
              </h2>
              <div
                className="p-3 w-100 h-100"
                hidden={!(files?.length || defaultLogo)}>
                <img
                  className="d-block border rounded-1 w-100"
                  hidden={!(files?.length || defaultLogo)}
                  src={files?.[0]?.preview || defaultLogo}
                  alt="..."
                />
              </div>
            </div>
          </FormGroup>

          {/* Action */}
          <div className="mt-sparse-pre clearfix" ref={actionRef}>
            <Button
              className="float-start"
              color="primary"
              disabled={role == 'viewer'}
              outline
              onClick={handleSubmit(handleSave)}>
              {t('Save')}
            </Button>
            <Button
              className="float-end"
              color="danger"
              outline
              disabled={role == 'viewer'}
              onClick={handleDelete}>
              {t('Delete')}
            </Button>
          </div>
        </div>

        {/* Nav */}
        <nav className="ps-4 border-start">
          <a
            href="javascript:void(0);"
            onClick={currying(scrollIntoView, actionRef)}>
            {t('Action')}
          </a>
        </nav>
      </main>
    </Fragment>
  );
};
