// Component
import DeviceConnectorSelector from './DeviceConnectorSelector';

export default () => {
  return (
    <div className="px-4 py-4">
      <main className="device-connector-content-height overflow-auto">
        <DeviceConnectorSelector />
      </main>
    </div>
  );
};
