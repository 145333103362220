// import React, { useContext } from "react";
// //i18n
// import { withTranslation } from "react-i18next";
// import { withRouter } from "react-router-dom";
// import { AvForm, AvField } from "availity-reactstrap-validation";
// import { Button } from "reactstrap";
// import {
//   createIotOutputConnector
// } from "src/helpers/iot_backend_helper";

// import { DataFlowContext } from "../../reducer";
// import { ACTION_TYPE } from "../../contant";

// const AddOutputConnector = (props: any) => {
//   const { dispatch } = useContext(DataFlowContext);
//   const { t, back, type, match } = props;
//   const { wsId } = match.params;
//   const handleValidSubmit = (event: any, values: any) => {
//     let authUser: any = localStorage.getItem("authUser");
//     authUser = JSON.parse(authUser);
//     let { name, description, host, db, username, password } = values;
//     let data = {
//       "name": name,
//       "description": description,
//       "workspace_id": wsId,
//       "type": type,
//       "scope": 'DATA_FLOW',
//       "attribute": { host, db, username, password },
//     }

//     createIotOutputConnector(data, null).then((result: any) => {
//       if (result && result.Code == 200) {
//         const action = {
//           type: ACTION_TYPE.PUSH_OUTPUT_CONNECTOR,
//           payload: result.Data
//         }
//         dispatch(action);
//       }
//     });

//   };
//   return (
//     <React.StrictMode>
//       <div>
//         <header className="flex-row flex-grow-0 flex-shrink-0 mb-4 font-size-16 justify-between">
//           <div className="fw-bold" onClick={back}>
//             <i className="mdi mdi-arrow-left me-2 cursor-pointer" /> {type}
//           </div>
//           {/* <div className="text-primary">Cancel</div> */}

//         </header>
//         <main>
//           <AvForm
//             onValidSubmit={(e: any, v: any) => {
//               handleValidSubmit(e, v);
//             }}
//           >
//             <div className="mb-4">
//               <AvField
//                 name="name"
//                 label={t('Connector name')}
//                 placeholder=""
//                 type="text"
//                 errorMessage="Enter Connector Name"
//                 validate={{ required: { value: true } }}
//               />
//             </div>
//             <div className="mb-4">
//               <AvField
//                 name="description"
//                 label={t('Connector description')}
//                 placeholder=""
//                 type="text"
//                 errorMessage="Enter Connector Description"
//                 validate={{ required: { value: true } }}
//               />
//             </div>
//             <div className="mb-4">
//               <AvField
//                 name="host"
//                 label={t('Mysql Host')}
//                 placeholder=""
//                 type="text"
//                 errorMessage="Enter Mysql Host"
//                 validate={{ required: { value: false } }}
//               />
//             </div>
//             <div className="mb-4">
//               <AvField
//                 name="db"
//                 label={t('Mysql Database')}
//                 placeholder=""
//                 type="text"
//                 errorMessage="Enter Mysql Database"
//                 validate={{ required: { value: false } }}
//               />
//             </div>
//             <div className="mb-4">
//               <AvField
//                 name="username"
//                 label={t('Mysql Username')}
//                 placeholder=""
//                 type="text"
//                 errorMessage="Enter Mysql Username"
//                 validate={{ required: { value: false } }}
//               />
//             </div>
//             <div className="mb-4">
//               <AvField
//                 name="password"
//                 label={t('Mysql Password')}
//                 placeholder=""
//                 type="text"
//                 errorMessage="Enter Mysql Password"
//                 validate={{ required: { value: false } }}
//               />
//             </div>
//             <div className="flex-row mb-4 justify-end">
//               <Button color="primary" type="submit">
//                 <i className="fas fa-save me-2" />
//                 {t('Save connector')}
//               </Button>
//             </div>
//           </AvForm>
//         </main>
//       </div>
//     </React.StrictMode>
//   )
// }

// export default withTranslation()(withRouter(AddOutputConnector));

import { StrictMode, useContext } from "react";

import CustomMetavunDb from '../../../../Rules/NewRule/RuleAction/EditConnector/custom_metavun_db';
import InfluxDb from '../../../../Rules/NewRule/RuleAction/EditConnector/influx_db';
import Kafka from '../../../../Rules/NewRule/RuleAction/EditConnector/kafka';
import Email from '../../../../Rules/NewRule/RuleAction/EditConnector/email';

export default (props: any) => {
  const component: any = {
    "CUSTOM_METAVUN_DB": <CustomMetavunDb {...props} />,
    "INFLUXDB": <InfluxDb {...props} />,
    "KAFKA": <Kafka {...props} />,
    "EMAIL": <Email {...props} />
  }
  return (<StrictMode>{component[props.type]}</StrictMode>)
}