import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { isEmpty } from "lodash";

import {
    Alert,
    Card,
    CardBody,
    Col,
    Container, Input, Label,
    Row,
} from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";

/** Import Full Calendar  */
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";

//import images
// import calendar from "../../assets/images/undraw-calendar.svg";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

//i18n
import { useTranslation } from "react-i18next";

import {
    addNewEvent as onAddNewEvent,
    deleteEvent as onDeleteEvent,
    getCategories as onGetCategories,
    getEvents as onGetEvents,
    updateEvent as onUpdateEvent,
} from "../../../store/apps/Organize/actions";

// import DeleteModal from "./DeleteModal";

//css
import "@fullcalendar/bootstrap/main.css";
import "src/assets/scss/inproject/projstyle.scss"

//redux
import { useSelector, useDispatch } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
    SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";

import {Link} from "react-router-dom";
import {useLocation} from "react-router";
import {getCertById as onGetCertById} from "../../../store/settings/Certs/actions";
interface CertsAddProps {
    className: string;
}
// TODO: 函数头
const CertsAdd = ({ className }: CertsAddProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { state } = useLocation();

    const localState:any = state;

    const [copyText, setCopyText] = useState('');
    const [publicKey, setPublicKey] = useState('');
    const [pubKeyCopySuccess, setPubCopySuccess] = useState(false);
    const [pubKeyCopyFaild, setPubCopyFaild] = useState(false);
    const [privateKey, setPrivateKey] = useState('');
    const [privKeyCopySuccess, setPrivCopySuccess] = useState(false);
    const [privKeyCopyFaild, setPrivCopyFaild] = useState(false);

    // 事件-复制
    // TODO
    const clickPubKeyToCopy = (event:any) => {
        navigator.clipboard.writeText(publicKey);
        setPubCopySuccess(true)
        setTimeout(()=>{
            setPubCopySuccess(false)
        },3000)
    }

    const clickPrivKeyToCopy = (event:any) => {
        navigator.clipboard.writeText(privateKey);
        setPrivCopySuccess(true)
        setTimeout(()=>{
            setPrivCopySuccess(false)
        },3000)
    }

    useEffect(() => {
        dispatch(onGetCertById(localState.id));
    }, [dispatch]);

    let { result } = useSelector(function (state: any) {
        return {
            result: state.Certs.certs,
        }
    });

    if(!result){
        result = [];
    }

    if(localState.ifAdd){
        result = null
        localState.publicKey = ''
        localState.privateKey = ''
    } else {
        setTimeout(()=>{
            setPublicKey(result.publicKey);
            setPrivateKey(result.privateKey);
        },500)
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{t("certsAdd")}</title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumbs title={t("Authentication")} breadcrumbItem={t("certsAdd")} />
                    <Card>
                        <CardBody>
                            <div className="mb-3 d-flex flex-wrap gap-2">
                                <button
                                    type="button"
                                    className="btn btn-outline-primary waves-effect waves-light"
                                >
                                    {t("OP_Save")}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-success"
                                >
                                    {t("OP_SaveAndExit")}

                                </button>
                                {/*<button
                                    type="button"
                                    className="btn btn-soft-warning waves-effect waves-light"
                                >
                                    {t("OP_Cancel")}
                                </button>*/}
                                <Link to="/page-certs" className="btn btn-secondary">
                                    {t("OP_Cancel")}
                                </Link>
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("th_Name")}</Label>
                                <i className="bx bx-help-circle" title={t("th_Name")} />
                                <Input className="form-control" type="text" defaultValue={result?result.name:""} id="example-text-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("th_displayName")}</Label>
                                <i className="bx bx-help-circle" title={t("th_displayName")} />
                                <Input className="form-control" type="text" defaultValue={result?result.displayName:""} id="example-text-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("th_UseTo")}</Label>
                                <i className="bx bx-help-circle" title={t("th_UseTo")} />
                                <Input className="form-control" type="text" defaultValue={result?result.scope:""} id="example-text-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("th_Type")}</Label>
                                <i className="bx bx-help-circle" title={t("th_Type")} />
                                <Input className="form-control" type="text" defaultValue={result?result.type:""} id="example-text-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("th_CryptoAlgorithm")}</Label>
                                <i className="bx bx-help-circle" title={t("th_CryptoAlgorithm")} />
                                <Input className="form-control" type="text" defaultValue={result?result.cryptoAlgorithm:""} id="example-text-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("th_BitSize")}</Label>
                                <i className="bx bx-help-circle" title={t("th_BitSize")} />
                                <Input className="form-control" type="text" defaultValue={result?result.bitSize:""} id="example-text-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("th_ExpiresinAnnually")}</Label>
                                <i className="bx bx-help-circle" title={t("th_ExpiresinAnnually")} />
                                <Input className="form-control" type="text" defaultValue={result?result.expireInYears:""} id="example-text-input" />
                            </div>
                            <Row>
                                <Col xl={6} className="gap-2">
                                    <Row>
                                        <Col xl={1}>
                                            <Label htmlFor="example-text-input" className="form-Label">{t("PublicKey")}</Label>
                                            <i className="bx bx-help-circle" title={t("PublicKey")} />
                                        </Col>
                                        <Col xl={11} className="d-flex flex-wrap gap-2">
                                            <div className="d-flex flex-wrap gap-2">
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-light waves-effect"
                                                    onClick={(e)=>clickPubKeyToCopy(e)}
                                                >
                                                    {t("OP_CopyPublicKey")}
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                >
                                                    {t("OP_DownloadPublicKey")}
                                                </button>
                                                <Alert color="success" isOpen={pubKeyCopySuccess} className={`hide-backcolor ${pubKeyCopySuccess?"":"fade"}`}>
                                                    <strong>{t("Success")}</strong> - {t("hint_CopyPublicKeySuccess")}
                                                </Alert>
                                                <Alert color="danger" isOpen={pubKeyCopyFaild} className={`hide-backcolor ${pubKeyCopyFaild?"":"fade"}`}>
                                                    <strong>{t("Faild")}</strong> - {t("hint_CopyPublicKeyFaild")}
                                                </Alert>
                                            </div>
                                            <Input
                                                className="form-control set-min-height" type="textarea"
                                                value={publicKey || ''} id="example-text-input"
                                                onChange={(e)=>{setPublicKey(e.target.value)}}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xl={6} className="gap-2">
                                    <Row>
                                        <Col xl={1}>
                                            <Label htmlFor="example-text-input" className="form-Label">{t("PrivateKey")}</Label>
                                            <i className="bx bx-help-circle" title={t("PrivateKey")} />
                                        </Col>
                                        <Col xl={11} className="d-flex flex-wrap gap-2">
                                            <div className="d-flex flex-wrap gap-2">
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-light waves-effect"
                                                    onClick={(e)=>clickPrivKeyToCopy(e)}
                                                >
                                                    {t("OP_CopyPrivateKey")}
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                >
                                                    {t("OP_DownloadPrivateKey")}
                                                </button>
                                                <Alert color="success" isOpen={privKeyCopySuccess} className={`hide-backcolor ${privKeyCopySuccess?"":"fade"}`}>
                                                    <strong>{t("Success")}</strong> - {t("hint_CopyPrivateKeySuccess")}
                                                </Alert>
                                                <Alert color="danger" isOpen={privKeyCopyFaild} className={`hide-backcolor ${privKeyCopyFaild?"":"fade"}`}>
                                                    <strong>{t("Faild")}</strong> - {t("hint_CopyPrivateKeyFaild")}
                                                </Alert>
                                            </div>
                                            <Input
                                                className="form-control set-min-height" type="textarea"
                                                value={privateKey||''} id="example-text-input"
                                                onChange={e=>{setPrivateKey(e.target.value)}}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CertsAdd;
