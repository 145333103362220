import {CertsTypes,CertsState} from "./actionTypes"

export const INIT_STATE : CertsState = {
  events: [],
  categories: [],
  error: {},
}

const Certs = (state = INIT_STATE, action : any) => {
  switch (action.type) {
    case CertsTypes.API_SUCCESS:
      switch (action.payload.actionType) {
        case CertsTypes.GET_EVENTS:
          return {
            ...state,
            events: action.payload.data,
          };

        case CertsTypes.ADD_NEW_EVENT:
          return {
            ...state,
            events: [...state.events, action.payload.data],
          }

        default:
          return { ...state };
      }
    case CertsTypes.API_FAIL:
      switch (action.payload.actionType) {
        case CertsTypes.GET_EVENTS:
          return {
            ...state,
            events: action.payload.error,
          };

        case CertsTypes.ADD_NEW_EVENT:
          return {
            ...state,
            events: action.payload.error,
          };

        default:
          return { ...state };
      }

    case CertsTypes.UPDATE_EVENT_SUCCESS:
      return {
        ...state,
        events: state.events.map(event =>
          event.id.toString() === action.payload.id.toString()
            ? { event, ...action.payload }
            : event
        ),
      }

    case CertsTypes.UPDATE_EVENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case CertsTypes.DELETE_EVENT_SUCCESS:
      return {
        ...state,
        events: state.events.filter(
          event => event.id.toString() !== action.payload.id.toString()
        ),
      }

    case CertsTypes.DELETE_EVENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case CertsTypes.GET_CERTS:
      return {
        ...state,
        // events: action.payload.data,
        payload: state
      }

    case CertsTypes.GET_CERTS_SUCCESS:
      return {
        ...state,
        certs: action.payload,
      }

    case CertsTypes.GET_CERTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case CertsTypes.GET_CERTBYID:
      return {
        ...state,
        // events: action.payload.data,
        payload: state
      }

    case CertsTypes.GET_CERTBYID_SUCCESS:
      return {
        ...state,
        certs: action.payload,
      }

    case CertsTypes.GET_CERTBYID_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default Certs
