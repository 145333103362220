import { Key, useEffect, useContext } from "react";

import { useTranslation } from "react-i18next";

import { Button } from "reactstrap";
import { RuleContext } from "../../reducer";

import BlankGuide from "../../common/BlankGuide";
import SelectedInput from "./SelectedInput";

import { inputConfiguration } from "../../common/configuration";
import { insure } from "src/util";

type Props = {
    title: string;
    prompt: string;
    onAdd: Function;
}

export default ({ title = "", prompt, onAdd }: Partial<Props>) => {
    const { t } = useTranslation()
    const { state: { input, logicState, actions }, dispatch } = useContext(RuleContext)
    const inputs = input.inputs;
    const empty = !inputs.length;

    // 控制是否显示连接线
    useEffect(()=> {
        const action = {
            type: "TOGGLE_INPUT_LINE",
            payload: true
        }
        dispatch(action);
        return () => {
            const action = {
                type: "TOGGLE_INPUT_LINE",
                payload: false
            }
            dispatch(action);
        }
    }, []);
    
    const selectedInputProps = {
        iconClassName: inputConfiguration.iconClassName,
        change: `${inputs.length}_${actions.length}_${logicState.data_sources.length}_${logicState.logic_blocks.length}`
    }

    const handleClear = (id: Key) => {
        const action = {
            type: "CLEA_INPUT",
            payload: id
        }
        dispatch(action);
    }
    
    return (
        <div className="position-relative d-flex flex-column h-100 user-select-none">
            <header className="flex-shrink-0">
                <span className="me-2 text-capitalize">{t(title)}</span>
                <Button className="border-dashed" color="primary" size="sm" outline hidden={empty}
                    onClick={insure(onAdd)}>
                    <span className="me-2">+</span>
                    <span className="text-capitalize">{t(`add ${title}`)}</span>
                </Button>
            </header>
            {empty && <BlankGuide prompt={prompt} onAdd={onAdd} />}
            <main className="d-flex flex-column justify-content-center flex-grow-1">
                {!empty && inputs.map(input =>
                    <SelectedInput data={input} key={input.id}
                        {...selectedInputProps}
                        onClear={handleClear} />)}
            </main>
        </div>
    )
}
