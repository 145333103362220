import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
//i18n
import { withTranslation } from "react-i18next";
import { withRouter, Link } from "react-router-dom";

import { Col, Row } from "reactstrap";
import Table from 'src/components/Table'
import AddUserModal from "./components/AddUserModal";
import ConfirmModal from "./components/ConfirmModal";

import {
  getIotUsers, deleteIotUser, createIotUser, updateIotUser
} from "src/helpers/iot_backend_helper";
import dayjs from "dayjs";
import { useSelector } from 'react-redux';

const Users = (props: any) => {
  let { t, match } = props;
  let { orgId, wsId } = match.params;
  const [visiable, setVisiable] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [sizePerPage, setSizePerPage] = useState<number>(10)

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentRow, setCurrentRow] = useState<any>(null);
  const [list, setList] = useState<any>([]);
  const [total, setTotal] = useState(0);

  const { user } = useSelector((state: any) => ({
    user: state.login.user
  }));
  const pageRole = (wsId ? user.user_workspaces.find((ws : any)=>ws.workspace.id == wsId)?.role : user.user_organizations.find((org : any)=>org.organization.id == orgId)?.role) || "viewer"

  useEffect(() => {
    fetchData({ page_size: sizePerPage, page_number: page })
  }, [visiable]);

  const fetchData = (params: any) => {
    if (orgId) {
      params.org_id = orgId
    }
    if (wsId) {
      params.workspace_id = wsId
    }
    return getIotUsers({ ...params }, null).then((result) => {
      if (result && result.Code == 200) {
        let arr = result.Data.map(({ id, user_name, name, description, password, email, created_at,user_organizations, user_workspaces}: any) => {
          let role = user_organizations.find((org:any) => org.organization.id == orgId)?.role
          if (wsId) {
            role = user_workspaces.find((ws:any) => ws.workspace.id == wsId)?.role
          }
          return { id, user_name, name, description, role, password, email, created_at }
        });
        setTotal(result.Total);
        setList(arr);
        return result
      }
    });
  }

  const handleSort = (field:string, order:"asc"|"desc") => {
    fetchData({page_size:sizePerPage, page_number:page, order:`${field}=${order}`})
  }

  const columns = [
    {
      dataField: "name",
      text: t('Name'),
      sort: true,
      onSort:handleSort,
    },
    {
      dataField: "email",
      text: t('E-Mail'),
    },
    {
      dataField: "role",
      text: wsId?  t('Workspace Role'):t("Organization Role"),
      sort: true,
      onSort:handleSort,
    },
    {
      dataField: "created_at",
      text: t('Join date'),
      formatter: (value: any, row: any, index: any) => (
        <div>{dayjs(row.created_at).format("YYYY-MM-DD HH:mm:ss")}</div>
      ),
      sort: true,
      onSort:handleSort,
    },
    {
      dataField: "Action",
      text: t('Action'),
      formatter: (value: any, row: any, index: any) => {
        if (pageRole=="admin") {
          return (<div className="flex-row">
            <div onClick={() => handleUpdate(row)} className="text-primary me-4">{t("Edit")}</div>
            <div onClick={() => handleremove(row)} className="text-danger">{t("Delete")}</div>
          </div>)
        }
        return (<div className="flex-row">
          <div className="text-muted me-4">{t("Edit")}</div>
          <div className="text-muted">{t("Delete")}</div>
        </div>)
      },
    },
  ];

  const handleremove = (row: any) => {
    setIsOpen(true);
    setCurrentRow(row);
  }

  const handleUpdate = (row: any) => {
    setCurrentRow(row);
    setVisiable(true);
  }

  // 显示隐藏新增用户Modal
  const togModal = () => {
    setVisiable(!visiable);
  }
  // 隐藏新增用户Modal
  const hideModal = () => {
    setVisiable(false);
  }

  // 保存用户信息
  const createUser = (data: any) => {
    data.curr_org_id = orgId;
    data.curr_wspace_id = wsId;
    createIotUser(data, null).then(result => {
      if (result && result.Code == 200) {
        setVisiable(false);
        fetchData({});
      }
    })
  }

  const updateUser = (id: string, data: any) => {
    data.curr_org_id = orgId;
    data.curr_wspace_id = wsId;
    updateIotUser(id, data, null).then(result => {
      if (result && result.Code == 200) {
        setVisiable(false);
        fetchData({});
      }
    });
  }

  const addUserProps = {
    visiable,
    user: currentRow,
    togModal,
    hideModal,
    updateUser,
    createUser
  }

  // 确认删除
  const confirmRemove = () => {
    let { id } = currentRow;
    deleteIotUser(id, null).then(result => {
      if (result && result.Code == 200) {
        let data = list.filter((item: any) => item.id != currentRow.id);
        setList(data);
        setIsOpen(false);
      }
    });
  }
  const confirmModalProps = {
    isOpen: isOpen,
    hideModal: () => setIsOpen(false),
    title: 'Do you really want to delete this User?',
    description: 'This action cannot be undone',
    confirmText: 'delete',
    confirm: confirmRemove
  }

  type Pagination = {
    page_size: number;
    page_number: number;
  }

  const onChannge = async (page: Pagination) => {
    setPage(page.page_number);
    setSizePerPage(page.page_size);
    const result = await fetchData(page);
    let arr: any = result.Data.map(({ id, user_name, name, description, role, password, email, created_at }: any) => {
      return { id, user_name, name, description, role, password, email, created_at }
    });
    setList([...list, ...arr])
  }

  const handleTableChange = async (type: string, { page, sizePerPage }: any) => {
    if (type == "sort") {
      return
    }
    await fetchData({ page_size: sizePerPage, page_number: page });
    setPage(page);
    setSizePerPage(sizePerPage);
  }

  return (
    <React.Fragment>
      <div className="px-4 py-4 flex-col">
        <div className="flex-row items-center justify-between px-3 mb-3">
          <div className="text-dark font-size-20" style={{ fontWeight: 700 }}>{t(`${wsId ? 'workspace users': "organization users"}`)}</div>
          <div>
            <button
              disabled={pageRole!='admin'}
              type="button"
              style={{ background: '#1890ff', fontSize: '16px' }}
              onClick={() => {
                setCurrentRow(null);
                togModal();
              }}
              className="btn btn-primary waves-effect waves-light"
            >
              <span className="text-capitalize" style={{ fontSize: '18px', marginRight: '10px' }}>{`+ ${t("add user")}`}</span>
            </button>
          </div>
        </div>
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Table
                data={list}
                page={page}
                columns={columns}
                sizePerPage={sizePerPage}
                totalSize={total}
                onTableChange={handleTableChange}
              />
            </Col>
          </Row>
        </div>
      </div>
      {visiable && <AddUserModal {...addUserProps} />}
      <ConfirmModal {...confirmModalProps} />
    </React.Fragment>
  );
}

Users.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withTranslation()(withRouter(Users));