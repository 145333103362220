import { WebhooksTypes } from "./actionTypes";
import webhooks from "../../../pages/Settings/Webhooks";

export const getEvents = () => ({
  type: WebhooksTypes.GET_EVENTS,
});

export const apiSuccess = (actionType : any, data : any) => ({
  type: WebhooksTypes.API_SUCCESS,
  payload: { actionType, data },
});

export const apiFail = (actionType : any, error : any) => ({
  type: WebhooksTypes.API_FAIL,
  payload: { actionType, error },
});

export const addNewEvent = (event : any) => ({
  type: WebhooksTypes.ADD_NEW_EVENT,
  payload: event,
});

export const updateEvent = (event : any) => ({
  type: WebhooksTypes.UPDATE_EVENT,
  payload: event,
});

export const updateEventSuccess = (event : any) => ({
  type: WebhooksTypes.UPDATE_EVENT_SUCCESS,
  payload: event,
});

export const updateEventFail = (error : any) => ({
  type: WebhooksTypes.UPDATE_EVENT_FAIL,
  payload: error,
});

export const deleteEvent = (event : any) => ({
  type: WebhooksTypes.DELETE_EVENT,
  payload: event,
});

export const deleteEventSuccess = (event : any) => ({
  type: WebhooksTypes.DELETE_EVENT_SUCCESS,
  payload: event,
});

export const deleteEventFail = (error : any) => ({
  type: WebhooksTypes.DELETE_EVENT_FAIL,
  payload: error,
});

export const getCategories = () => ({
  type: WebhooksTypes.GET_CATEGORIES,
});

export const getCategoriesSuccess = (categories : any) => ({
  type: WebhooksTypes.GET_CATEGORIES_SUCCESS,
  payload: categories,
});

export const getCategoriesFail = (error : any) => ({
  type: WebhooksTypes.GET_CATEGORIES_FAIL,
  payload: error,
});

export const getWebhooks = () => ({
  type: WebhooksTypes.GET_WEBHOOKS,
});

export const getWebhooksSuccess = (webhooks : any) => ({
  type: WebhooksTypes.GET_WEBHOOKS_SUCCESS,
  payload: webhooks,
});

export const getWebhooksFail = (error : any) => ({
  type: WebhooksTypes.GET_WEBHOOKS_FAIL,
  payload: error,
});

export const getWebhookById = (id:string) => ({
  type: WebhooksTypes.GET_WEBHOOKBYID,
  payload:id
});

export const getWebhookByIdSuccess = (webhooks : any) => ({
  type: WebhooksTypes.GET_WEBHOOKBYID_SUCCESS,
  payload: webhooks,
});

export const getWebhookByIdFail = (error : any) => ({
  type: WebhooksTypes.GET_WEBHOOKBYID_FAIL,
  payload: error,
});

