import { takeEvery, put, call, all, fork } from "redux-saga/effects"

// Calender Redux States
import { CharacterTypes } from "./actionTypes"
import {
  getCharacter, getCharacterSuccess, getCharacterFail, getRoleSuccess, getRoleFail
} from "./actions"

//Include Both Helper File with needed methods
import {
  api_getCharacter, api_getRole,
} from "../../../API/api"

// 获取全部角色信息
function* onGetCharacter() {
  try {
    const response : Promise<any> = yield call(api_getCharacter)
    yield put(getCharacterSuccess(response))
  } catch (error) {
    yield put(getCharacterFail(error))
  }
}

// 获取单个角色信息
function* onGetRole({ payload : id }:any) {
  try {
    const response : Promise<any> = yield call(api_getRole, id)
    yield put(getRoleSuccess(response))
  } catch (error) {
    yield put(getRoleFail(error))
  }
}

export function* watchOnGetCharacter() {
  yield takeEvery(CharacterTypes.GET_CHARACTER, onGetCharacter);
}

export function* watchOnGetRole() {
  yield takeEvery(CharacterTypes.GET_ROLE, onGetRole);
}

function* roleSaga() {
  yield all([fork(watchOnGetCharacter)]);
  yield all([fork(watchOnGetRole)]);
}

export default roleSaga
