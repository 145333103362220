import React from "react";
import { Link, useHistory} from "react-router-dom";
// import createHistory from "history/createBrowserHistory"
import { Container, Row, Col , Button} from "reactstrap";

import error from "../../assets/images/error-img.png";
import {useTranslation} from "react-i18next";

const Error404 = () => {
  const { t } = useTranslation();
  const createHistory = require("history").createBrowserHistory();

  return (
    <React.Fragment>
      <div className="my-5 pt-5">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center mb-5">
                <h1 className="display-1 fw-semibold">
                  4<span className="text-primary mx-2">0</span>4
                </h1>
                <h4 className="text-uppercase">{t("Error404Hint")}</h4>
                <div className="mt-5 text-center">
                  <button
                      type="button"
                      className="btn btn-primary"
                      onClick={()=>createHistory.goBack()}
                  >
                    {t("BackToPrev")}
                  </button>
                  {/*<Link className="btn btn-primary" to={''}>
                    {t("BackToPrev")}
                  </Link>*/}
                </div>
              </div>
            </Col>
          </Row>
          <div className="row justify-content-center">
            <div className="col-md-10 col-xl-8">
              <div>
                <img src={error} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Error404;
