import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
//i18n
import { withTranslation } from 'react-i18next';
// Redux
import { withRouter, Link } from 'react-router-dom';
// users
import user1 from 'src/assets/images/users/avatar-1.jpg';
//redux
import { useSelector } from 'react-redux';

const ProfileMenu = (props: any) => {
  const { t } = props;
  const { success } = useSelector((state: any) => ({
    success: state.profile.success,
  }));

  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState<boolean>(false);

  const [username, setUsername] = useState('Admin');

  useEffect(() => {
    const authStr = localStorage.getItem('authUser');
    if (!authStr) return;
    const auth = JSON.parse(authStr);
    if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
      setUsername(auth.displayName);
      return;
    }
    if (
      process.env.REACT_APP_DEFAULTAUTH === 'fake' ||
      process.env.REACT_APP_DEFAULTAUTH === 'jwt'
    ) {
      setUsername(auth.username);
      return;
    }
    setUsername(auth?.Data?.user_name?.toString());
  }, [success]);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block">
        <DropdownToggle
          className="btn header-item bg-soft-light border-start border-end"
          id="page-header-user-dropdown"
          tag="button">
          <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" href="/profile">
            <span className="badge bg-success float-end">11</span>
            <i className="bx bx-wrench font-size-16 align-middle me-1" />
            {t('Settings')}
          </DropdownItem>
          <DropdownItem tag="a" href="/page-lock-screen">
            <i className="bx bx-lock-open font-size-16 align-middle me-1" />
            {t('Lock screen')}
          </DropdownItem>
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{t('Logout')}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};

export default withTranslation()(withRouter(ProfileMenu));
