import { Fragment, Key } from 'react';

import SwitchOption from './SwitchOption';

export interface IOption {
  id: Key;
  name?: Key;
  description?: string;
}

type Props = {
  simplify: boolean;
  selected: Key;
  data: IOption[];
  onSelect: Function;
}

export const SwitchSelect = ({ simplify, selected, data, onSelect }: Props) => {
  return (<Fragment>
    {data?.length > 0 && data.map((item: any) =>
      <SwitchOption key={item.id} id={item.id}
                    simplify={simplify} selected={item.id == selected}
                    name={item.name} description={item.description}
                    onSelect={() => onSelect(item)}
      />)}
  </Fragment>)
}
