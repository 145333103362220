import React, {Fragment, useState} from "react";
// Router
import {useHistory} from "react-router-dom";
// i18n
import {useTranslation} from "react-i18next";
// Component
import MetaTags from "react-meta-tags";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Progress
} from "reactstrap";
import Breadcrumb from "src/components/Common/Breadcrumb";
import {SubmitHandler, useForm} from "react-hook-form";
// Assets
import avatar from "src/assets/images/users/avatar-1.jpg";
import {mutateUser} from "./api";

type FormInputs = {
  username: string;
  password: string;
}

export default function () {
  const {t} = useTranslation()
  const h = useHistory()

  let uid = "", name = "", email = "", username = "", role = ""
    // Initial
  ;(function () {
    const authUser = localStorage.getItem("authUser")
    if (!authUser) return
    const auth = JSON.parse(authUser).Data
    name = auth.name
    email = auth.email
    uid = auth.id
    username = auth.user_name
    role = auth.role
  })()
  // Form validate
  const FormSchema = {
    username: {
      required: "username is required",
      minLength: {
        value: 2,
        message: "the minimum username length is 2",
      },
      maxLength: {
        value: 16,
        message: "the maximum username length is 16"
      }
    },
    password: {
      minLength: {
        value: 6,
        message: "the minimum password length is 6",
      },
      maxLength: {
        value: 16,
        message: "the maximum password length is 16"
      }
    },
  }
  const {register, handleSubmit, formState: {errors}} = useForm<FormInputs>()
  // Password progress
  const progressInitialStyle = {
    value: 0,
    color: "",
  }
  const [progressStyle, setProgressStyle] = useState(progressInitialStyle)
  const handleProgressStyle = (v: string) => {
    if (v.length < 6) {
      setProgressStyle({value: 1, color: "danger"})
      return
    }
    if (v.length < 16) {
      setProgressStyle({value: 2, color: "success"})
      return
    }
    if (v.length > 16) {
      setProgressStyle({value: 3, color: "danger"})
    }
  }
  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    if (data.username == username && !data.password) return
    const p = {
      name,
      role,
      user_name: data.username,
      password: data.password,
    }
    const [res, err] = await mutateUser(uid, p)
    if (err) {
      alert(err)
    }
    if (res.Code != 200) {
      alert(res)
    }
    h.push('/login')
  }

  return <Fragment>
    <MetaTags>
      <title>Profile | Metavun</title>
    </MetaTags>
    <Container className="p-4" fluid>
      <Breadcrumb title="Metavun" breadcrumbItem={t("Profile")}/>
      {/* Information display */}
      <Card>
        <CardBody>
          <div className="d-flex px-3">
            <div className="me-3">
              <img src={avatar} alt="avatar..." className="avatar-md rounded-circle img-thumbnail"/>
            </div>
            <div className="flex-grow-1 align-self-center">
              <div className="text-muted">
                <h5 className="mb-1">{t("Name")}: {name}</h5>
                <p className="mb-1">{t("Email")}: {email}</p>
                <p className="mb-0">ID: {uid}</p>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
      <CardTitle className="text-capitalize">
        {t("change profile")}
      </CardTitle>
      {/* edit profile */}
      <Card>
        <CardBody>
          {/* Username */}
          <FormGroup className="mt-sparse-pre">
            <Label className="text-capitalize">{t("username")}</Label>
            <Input defaultValue={username}
                   invalid={Boolean(errors.username)}
                   placeholder={t("enter username to register with")}
                   {...register("username", FormSchema.username)}/>
            <FormFeedback className="first-letter-upper">
              {t(errors.username?.message || "")}
            </FormFeedback>
          </FormGroup>
          {/* Password */}
          <FormGroup className="mt-sparse-pre">
            <Label className="text-capitalize">{t("password")}</Label>
            <Input className="mt-narrow-pre"
                   type="password"
                   defaultValue=""
                   invalid={Boolean(errors.password)}
                   placeholder={t("enter password")}
                   {...register("password", FormSchema.password)}
                   onChange={e => handleProgressStyle(e.target.value)}/>
            <FormFeedback
              className="mt-narrow-pre first-letter-upper">
              {t(errors.password?.message || "")}
            </FormFeedback>
            <Progress className="mt-narrow-pre" max={3} {...progressStyle}/>
          </FormGroup>
          {/* Submit */}
          <FormGroup className="mt-sparse-pre">
            <Button className="d-block m-auto text-capitalize" color="danger"
                    onClick={handleSubmit(onSubmit)}>
              {t("update user profile")}
            </Button>
          </FormGroup>
        </CardBody>
      </Card>
    </Container>
  </Fragment>
}
