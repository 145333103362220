import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { isEmpty } from "lodash";

import {
    Button,
    Form,
    Card,
    CardBody, CardHeader, CardSubtitle, CardTitle,
    Col,
    Container, Input, Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row, Table,
} from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";

/** Import Full Calendar  */
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";

//import images
// import calendar from "../../assets/images/undraw-calendar.svg";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

//i18n
import { useTranslation } from "react-i18next";

//import Effect
import {
    getPermissionById as onGetPermissionById
} from "../../../store/apps/Authority/actions";

// import DeleteModal from "./DeleteModal";

//css
import "@fullcalendar/bootstrap/main.css";

//redux
import { useSelector, useDispatch } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
    SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";

import {Link} from "react-router-dom";
import {getUserInfo as onGetUserInfo} from "../../../store/apps/User/actions";
import {useLocation} from "react-router";
interface AuthorityProps {
    className: string;
}
// TODO: 函数头
const AuthorityAdd = ({ className }: AuthorityProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { state } = useLocation();

    const localState:any = state;

    useEffect(() => {
        // let external: any = document.getElementById("external-events");
        if(localState && localState.hasOwnProperty('id')){
            dispatch(onGetPermissionById(localState.id));
        }
        // dispatch(onGetEvents());
    }, [dispatch]);

    let { result } = useSelector(function(state:any){
        return {
            result: state.Permission.permissions,
        }
    })
    if(localState.ifAdd){
        result = null
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{t("authorityAdd")}</title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumbs title={t("Dashboard")} breadcrumbItem={t("authorityAdd")} />
                    <Card>
                        <CardBody>
                            <div className="mb-3 d-flex flex-wrap gap-2">
                                <button
                                    type="button"
                                    className="btn btn-outline-primary waves-effect waves-light"
                                >
                                    {t("OP_Save")}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-success"
                                >
                                    {t("OP_SaveAndExit")}

                                </button>
                                {/*<button
                                    type="button"
                                    className="btn btn-soft-warning waves-effect waves-light"
                                >
                                    {t("OP_Cancel")}
                                </button>*/}
                                <Link to="/apps-authority" className="btn btn-secondary">
                                    {t("OP_Cancel")}
                                </Link>
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("th_Organize")}</Label>
                                <i className="bx bx-help-circle" title={t("th_Organize")} />
                                <Input className="form-control" type="text" defaultValue={result?result.owner:""} id="example-text-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("th_Name")}</Label>
                                <i className="bx bx-help-circle" title={t("th_Name")} />
                                <Input className="form-control" type="text" defaultValue={result?result.name:""} id="example-text-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("th_displayName")}</Label>
                                <i className="bx bx-help-circle" title={t("th_displayName")} />
                                <Input className="form-control" type="text" defaultValue={result?result.displayName:""} id="example-text-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("chaTable_ContainUsers")}</Label>
                                <i className="bx bx-help-circle" title={t("chaTable_ContainUsers")} />
                                <Input className="form-control" type="text" defaultValue={result?result.users:""} id="example-text-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("chaTable_ContainCharacters")}</Label>
                                <i className="bx bx-help-circle" title={t("chaTable_ContainCharacters")} />
                                <Input className="form-control" type="text" defaultValue={result?result.roles:""} id="example-text-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("AssetsType")}</Label>
                                <i className="bx bx-help-circle" title={t("AssetsType")} />
                                <Input className="form-control" type="text" defaultValue={result?result.resourceType:""} id="example-text-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("authority_Action")}</Label>
                                <i className="bx bx-help-circle" title={t("authority_Action")} />
                                <Input className="form-control" type="text" defaultValue={result?result.actions:""} id="example-text-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("authority_Effect")}</Label>
                                <i className="bx bx-help-circle" title={t("authority_Effect")} />
                                <Input className="form-control" type="text" defaultValue={result?result.effect:""} id="example-text-input" />
                            </div>
                            <div className="div-switch">
                                <Label htmlFor="isEnabled"
                                       className="col-sm-1 col-form-label">
                                    {t("th_Enabled")}
                                    <i className="bx bx-help-circle col-form-label" title={t("th_Enabled")} />
                                </Label>
                                <Input type="checkbox" id="isEnabled" switch="none" defaultChecked={result?result.isEnabled:""} />
                                <Label htmlFor="isEnabled" data-on-label="On" data-off-label="Off"></Label>
                            </div>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AuthorityAdd;
