export const POST_IOT_LOGIN = "/api/v1/iot-users/login"

export const IOT_USER = "/api/v1/iot-users"

export const IOT_SEND_EMAIL = "/api/v1/pub/send-email"

export const IOT_DEVICE_TYPE = "/api/v1/iot-device-types"

export const IOT_FILE_UPLOAD = "/api/v1/iot-users/upload-file"

export const IOT_DEVICE_CONNNECTOR = "/api/v1/iot-device-connectors"

export const IOT_OUTPUT_CONNNECTOR = "/api/v1/iot-output-connectors"

export const IOT_DATA_FLOW = "/api/v1/iot-data-flows"

export const IOT_DEVICE = "/api/v1/iot-devices"

export const IOT_WORKSPACE = "/api/v1/iot-workspaces"

export const IOT_LOGIC_BLOCK = "/api/v1/iot-logic-blocks"

export const IOT_COLLECT_DATA = "/api/v1/iot-collect-data"

export const IOT_ORGANIZATION = "/api/v1/iot-organizations"

export const IOT_RULE = "/api/v1/iot-rules"

export const IOT_TAG = "/api/v1/iot-tags"

export enum IOT_ASSETS {
  DEVICE_DATA_OVERVIEW = "/api/v1/iot-collect-data/aggregation",
  DEVICE_DATA_OVERVIEW_COMBINE = "/api/v1/iot-collect-data/aggregation-combined",
}
