import { takeEvery, put, call, all, fork } from "redux-saga/effects"

// Calender Redux States
import {WebhooksState, WebhooksTypes} from "./actionTypes"
import {
  getWebhooks, getWebhooksSuccess, getWebhooksFail, getWebhookByIdSuccess, getWebhookByIdFail
} from "./actions"

//Include Both Helper File with needed methods
import {
  api_getWebhookById,
  api_getWebhooks,
} from "../../../API/api"

// 获取全部webhooks信息
function* onGetWebhooks() {
  try {
    const response : Promise<any> = yield call(api_getWebhooks)
    yield put(getWebhooksSuccess(response))
  } catch (error) {
    yield put(getWebhooksFail(error))
  }
}

export function* watchOnGetWebhooks() {
  yield takeEvery(WebhooksTypes.GET_WEBHOOKS, onGetWebhooks);
}

// 获取单个webhooks信息
function* onGetWebhookById({payload:id}:any) {
  try {
    const response : Promise<any> = yield call(api_getWebhookById,id)
    yield put(getWebhookByIdSuccess(response))
  } catch (error) {
    yield put(getWebhookByIdFail(error))
  }
}

export function* watchOnGetWebhookById() {
  yield takeEvery(WebhooksTypes.GET_WEBHOOKBYID, onGetWebhookById);
}

function* webhooksSaga() {
  yield all([fork(watchOnGetWebhooks)]);
  yield all([fork(watchOnGetWebhookById)]);
}

export default webhooksSaga
