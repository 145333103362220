import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
//i18n
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Row, Input, Label, Button } from "reactstrap";
import {
  getIotDataFlowList
} from "src/helpers/iot_backend_helper";
const tabDir:any = {
  3600: "1h",
  43200: "12h",
  86400: "1day",
  604800: "1week",
}
const AdditionalInformation = (props: any) => {
  let { t, next, pre, formData, match } = props;
  let { wsId } = match.params;
  const [dataFlow, setDataFlow] = useState<any>(formData.dataFlow || {});
  const [integration, setIntegration] = useState<any>({});
  const [tab, setTab] = useState<any>('tabOne');
  const [dateTab, setDateTab] = useState<any>(tabDir[formData.online_timeout] || '1h');
  const [dataFlows, setDataFlows] = useState<any>([]);
  const [lifecycle, setLifecycle] = useState<boolean>(formData.lifecycle);

  useEffect(() => {
    getIotDataFlowList({ workspace_id: wsId }, null).then((result: any) => {
      if (result.Code == 200 && result.Data && result.Data.length) {
        let data = result.Data.map((row: any) => ({
          value: row.id,
          label: row.name,
        }));

        setDataFlows(data);
      }
    })
  }, []);

  const nextSetp = () => {
    let online_timeout = 0;
    switch (dateTab) {
      case '1h':
        online_timeout = 3600;
        break;
      case '12h':
        online_timeout = 43200;
        break;
      case '1day':
        online_timeout = 86400;
        break;
      case '1week':
        online_timeout = 604800;
        break;

      default:
        break;
    }
    next({ dataFlow, online_timeout, lifecycle })
  }

  return (
    <React.Fragment>
      <div>
        <Row>
          <h5 style={{ marginBottom: '30px' }}>{t('DataProcessing')}</h5>
          <div className="mb-3">
            <div>
              <Label htmlFor="example-search-input" className="form-Label">{t('DataProcessing')}</Label>
            </div>
            <div
              className="btn-group btn-group-lg"
              role="group"
              aria-label="Basic example"
            >
              <button type="button" className={`text-capitalize text-nowrap ${tab == "tabOne" ? "btn btn-primary" : "btn btn-outline-light"}`} onClick={() => setTab('tabOne')}>
                {t("data flow")}
              </button>
              <button type="button" className={`first-letter-upper ${tab == "tabTwo" ? "btn btn-primary" : "btn btn-outline-light"}`} onClick={() => setTab('tabTwo')}>
                {t("connectivity mgmt only")}
              </button>
            </div>
          </div>
          {
            tab == 'tabOne' && <div className="mt-4">
              <Label htmlFor="example-search-input" className="form-Label">{t('DataFlow')}</Label>
              <Select
                noOptionsMessage={()=>t("no options")}
                value={dataFlow}
                onChange={setDataFlow}
                options={dataFlows}
                placeholder={t("select a data flow")}
                classNamePrefix="select2-selection"
              />
            </div>
          }
          {
            tab == 'tabTwo' && <div className="mt-4">
              <Label htmlFor="example-search-input" className="form-Label">{t('Integration')}</Label>
              <Select
                noOptionsMessage={()=>t("no options")}
                value={integration}
                onChange={setIntegration}
                options={[
                  { label: "Mustard", value: "Mustard" },
                  { label: "Ketchup", value: "Ketchup" },
                  { label: "Relish", value: "Relish" },
                ]}
                placeholder='Select An Integration'
                classNamePrefix="select2-selection"
              />
            </div>
          }
          <h5 className="mt-5 mb-4">{t('lifecycleSettings')}</h5>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div>
              <Input type="checkbox" onChange={(e) => setLifecycle(e.target.checked)} id="switch6" switch="primary" defaultChecked={lifecycle} />
              <Label className="me-1" htmlFor="switch6" data-on-label="" data-off-label=""></Label>
            </div>
            <div style={{ marginLeft: '8px' }}>{t("include in lifecycle notifications")}</div>
          </div>
          <div className="mt-3 mb-5">
            <div>
              <Label htmlFor="example-search-input" className="form-Label">{t('offline timeout')}</Label>
            </div>
            <div
              className="btn-group btn-group-lg"
              role="group"
              aria-label="Basic example"
            >
              <button type="button" className={dateTab == "1h" ? "btn btn-primary" : "btn btn-outline-light"} onClick={() => setDateTab('1h')}>
                1 {t("hour")}
              </button>
              <button type="button" className={dateTab == "12h" ? "btn btn-primary" : "btn btn-outline-light"} onClick={() => setDateTab('12h')}>
                12 {t("hours")}
              </button>
              <button type="button" className={dateTab == "1day" ? "btn btn-primary" : "btn btn-outline-light"} onClick={() => setDateTab('1day')}>
                1 {t("day")}
              </button>
              <button type="button" className={dateTab == "1week" ? "btn btn-primary" : "btn btn-outline-light"} onClick={() => setDateTab('1week')}>
                1 {t("week")}
              </button>
            </div>
          </div>
          <div style={{ marginTop: '40px', marginBottom: '40px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <button
              type="button"
              style={{ fontSize: '16px' }}
              className="btn btn-outline-light waves-effect text-capitalize"
              onClick={pre}
            >
              {t('back')}
            </button>
            <Button type="button" disabled={!dataFlow.value} color="primary" onClick={nextSetp}>
              {t('Next')}
            </Button>
          </div>
        </Row>
      </div>
    </React.Fragment>
  );
}

AdditionalInformation.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  next: PropTypes.func
};
export default withTranslation()(withRouter(AdditionalInformation));