import {Key} from "react";
// Util
import {insure} from "src/util";

type props = {
  className?: string;
  tgt: Key;
  opts: Key[];
  onChange?: Function;
}

export default function ({className, tgt, opts, onChange}: props) {
  return (<div className={`d-flex flex-row justify-content-center ${className}`}>
    {opts.map(opt =>
      <div key={opt}
           className={`ms-narrow-pre rounded-circle cus-sq-1 
           ${opt == tgt ? " bg-primary" : "bg-light"}`}
           onClick={insure(onChange)}/>)}
  </div>)
}
