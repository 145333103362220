import React from "react";
import "./style.scss";
interface IProps {
  title: string;
  icon?: string;
  close?: boolean;
  id?: string;
  handleRemove?: Function;
}
const Tags:React.FC<IProps> = (props) => {
  let { title, icon, close, id, handleRemove } = props
  return (
    <React.Fragment>
      <div className="tag mb-2">
        {
          icon && <i className={`${icon} tag-icon`}></i>
        }
        {title}
        {
          close && <i onClick={() => handleRemove && handleRemove(id)} className=" bx bx-x cursor-pointer close-icon"></i>
        }
      </div>
    </React.Fragment>
  );
}

export default Tags;