import { Key } from 'react';
// Interface
import { registerIotUser, sendEmail } from 'src/helpers/iot_backend_helper';
// Util
import { awaitWrap } from 'src/util';

export const registerUser = async (data: any) => {
  const { username, password, name, email, nationality, phoneNumber } = data;
  const p = {
    user_name: username,
    password,
    name,
    email,
    nationality,
    phone_number: phoneNumber,
    role: 'admin',
  };

  const [res, err] = await awaitWrap(registerIotUser(p, null));
  if (err) {
    alert(`注册用户失败：${err}`);
    return [null, err];
  }

  return [res, undefined];
};

export const sendActiveEmail = async (uid: Key) => {
  const [res, err] = await awaitWrap(sendEmail(uid, null));

  if (err) {
    return [null, err];
  }
  if (res && res.Code != 200) {
    return [res, undefined];
  }

  return [res, undefined];
};
