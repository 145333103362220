import React, { Fragment, useState } from 'react';
import PropTypes from "prop-types";
import Select from "react-select";
//i18n
import { withTranslation } from "react-i18next";
import { withRouter, Link } from "react-router-dom";
import { Col, Row, Input, Label, Button } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useRole } from '../../../../util';

const AdditionalInformation = (props: any) => {
  let { t, next, pre, formData, match } = props;

  const {orgId} = match.params;
  const pageRole = useRole(orgId,undefined)

  const [connectivity, setConnectivity] = useState<any>(formData.connectivity);
  const [encoding, setEncoding] = useState<any>(formData.encoding);
  const handleValidSubmit = (event: any, values: any) => {
    values.encoding = encoding;
    values.connectivity = connectivity;
    next(values);
  };

  const [
      connectSelectBarPlaceholder,
      payloadEncodingPlaceholder,
  ]= [t("Select a connectivity"),t("Select enncoding type")]

  return (
    <React.Fragment>
      <AvForm
      onValidSubmit={(e: any, v: any) => {
        handleValidSubmit(e, v);
      }}
      >
        <h5 className="text-capitalize" style={{ marginBottom: '30px' }}>{t('additional information')}</h5>
        <Row>
          <div className="mb-4">
            {pageRole=="viewer" ?
              <Fragment>
                <Label>{`${t("device manufacturer")} (${t("option")})`}</Label>
                <Input disabled value={formData.manufacturer} />
              </Fragment>
              :<AvField
                name="manufacturer"
                defaultValue={formData.manufacturer}
                label={`${t("device manufacturer")} (${t("option")})`}
                placeholder=""
                type="text"
                errorMessage="Enter Device Manufacturer"
                validate={{ required: { value: false } }}
              />}
          </div>
          <div className="mb-4">
            {pageRole=="viewer" ?
              <Fragment>
                <Label>{`${t("Datasheet URL")} (${t("option")})`}</Label>
                <Input disabled value={formData.datasheet_url} />
              </Fragment>
              :<AvField
                name="datasheet_url"
                defaultValue={formData.datasheet_url}
                label={`${t("Datasheet URL")} (${t("option")})`}
                placeholder=""
                type="text"
                errorMessage="Enter Datasheet URL"
                validate={{ required: { value: false } }}
              />}
          </div>
          <div className="mb-4">
            {pageRole=="viewer" ?
              <Fragment>
                <Label>{`${t("Script author")} (${t("option")})`}</Label>
                <Input disabled value={formData.script_author} />
              </Fragment>
              :<AvField
                name="script_author"
                defaultValue={formData.script_author}
                label={`${t("Script author")} (${t("option")})`}
                placeholder=""
                type="text"
                errorMessage="Enter Script Author"
                validate={{ required: { value: false } }}
              />}
          </div>
          <div className="mb-4">
            {pageRole=="viewer" ?
              <Fragment>
                <Label>{`${t("Firmware version")} (${t("option")})`}</Label>
                <Input disabled value={formData.firmware_version} />
              </Fragment>
              :<AvField
                name="firmware_version"
                defaultValue={formData.firmware_version}
                label={`${t("Firmware version")} (${t("option")})`}
                placeholder=""
                type="text"
                errorMessage="Enter Firmware Version"
                validate={{ required: { value: false } }}
              />}
          </div>
          <div className="mb-4">
            <Label htmlFor="example-search-input" className="form-Label">{t('Connectivity')}</Label>
            {pageRole=="viewer" ?
                <Input disabled value={connectivity.label} />
              : <Select
                value={connectivity}
                onChange={setConnectivity}
                options={[{ label: "LoRa", value: "LoRa" },
                  { label: "HTTP", value: "HTTP" },
                  { label: "MQTT", value: "MQTT" },
                  { label: "CoAP", value: "CoAP" }]}
                placeholder={connectSelectBarPlaceholder}
                classNamePrefix="select2-selection"
              />}
          </div>
          <div className="mb-4">
            <Label htmlFor="example-search-input" className="form-Label"> {`${t('payload encoding')} (${t("option")})`}</Label>
            {pageRole=="viewer" ?
              <Input disabled value={encoding.label} />
              :  <Select
                value={encoding}
                onChange={setEncoding}
                options={[{ label: "JSON", value: "JSON" },
                  { label: "HEX", value: "HEX" }]}
                placeholder={payloadEncodingPlaceholder}
                classNamePrefix="select2-selection"
              />}
          </div>
          <div style={{ marginTop: '40px', marginBottom: '40px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <button
              type="button"
              style={{ fontSize: '16px' }}
              className="btn btn-outline-light waves-effect text-capitalize"
              onClick={pre}
            >
              {t('back')}
            </button>
            <Button type="submit" color="primary" >
              {t('Next')}
            </Button>
          </div>
        </Row>
      </AvForm>
    </React.Fragment>
  );
}

AdditionalInformation.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  next: PropTypes.func
};
export default withTranslation()(withRouter(AdditionalInformation));