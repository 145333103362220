import { Fragment, Key, useEffect, useState } from 'react';
// Component
import { Col, Row } from 'reactstrap';
import Cell, { DeviceItem } from './Cell';
import Adder from './Adder';
import DndContainer from './DndContainer';
// Util
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { useRouteMatch } from 'react-router-dom';
// API
import { fetchDevices, fetchDevicesLayout, updateDevicesLayout } from './api';

type TempCompProps = {
  item: DeviceItem;
  deselect?: (uid: Key) => any;
  listeners: SyntheticListenerMap;
};
// 需要拖动的数据需要接收 listeners 属性，将其绑定到自己设定的拖动触发器上（感应拖动的区域）
const TempComp = ({ item, deselect, listeners }: TempCompProps) => {
  return (
    <Col className="mb-4 ps-0 pe-2">
      <Cell device={item} deselect={deselect} listeners={listeners} />
    </Col>
  );
};

export default function () {
  const { orgId, wsId } = useRouteMatch<any>().params;
  const [orgNm, setOrgNm] = useState('');
  // 所有的设备
  const [origins, setOrigins] = useState<DeviceItem[]>();
  // 展示的设备
  const [selected, setSelected] = useState<DeviceItem[]>([]);
  // 未展示的设备
  const [options, setOptions] = useState<DeviceItem[] | undefined>();
  // 移除图表
  const deselect = async (uid: Key) => {
    if (origins && selected) {
      const t1 = selected.filter(n => n.id != uid);
      setSelected(t1);

      const t2 = origins.filter(n => !t1.find(el => el.id == n.id));
      setOptions(t2);

      await updateDevicesLayout(orgId, orgNm, t1);
    }
  };
  const transmission = {
    deselect,
  };
  // 拖拽图表
  const handleDragEnd = async (orders: Key[]) => {
    if (!origins) return;
    const t1: DeviceItem[] = [];
    orders.forEach(n => {
      const t = origins.find(el => el.id == n);
      if (t) {
        t1.push(t);
      }
    });

    setSelected(t1);
    await updateDevicesLayout(orgId, orgNm, t1);
  };
  // 增加图表
  const select = async (uid: Key) => {
    if (origins && selected) {
      const t1 = selected;
      const t = origins.find(el => el.id == uid);
      t && t1.push(t);
      setSelected(t1);

      const t2 = origins.filter(n => !t1.find(el => el.id == n.id));
      setOptions(t2);

      await updateDevicesLayout(orgId, orgNm, t1);
    }
  };
  // initial
  useEffect(() => {
    (async () => {
      const [r1, e1] = await fetchDevices(orgId, wsId);
      if (e1) {
        return;
      }
      if (!r1) {
        return;
      }
      // init 所有设备
      setOrigins(r1);
      // init 展示设备
      let t1 = r1;
      const [r2, nm] = await fetchDevicesLayout(orgId);
      if (nm) {
        setOrgNm(nm);
      }
      if (r2) {
        t1 = r2;
      }
      setSelected(t1);
      // init 未展示设备
      const t2 = r1.filter(
        (n: DeviceItem) => !t1.find((el: DeviceItem) => el.id == n.id),
      );
      setOptions(t2);
    })();
  }, []);

  return (
    <Fragment>
      <Row className="mx-0 mt-4 ps-4 pe-3" lg={1} xl={2}>
        {/* 可拖动的盒子内部容器通过 container 属性注入到 DndContainer 中 */}
        <DndContainer
          items={selected}
          container={TempComp}
          transmission={transmission}
          onDragEnd={handleDragEnd}
        />
        <Col className="mb-4 ps-0 pe-2">
          <Adder options={options} select={select} />
        </Col>
      </Row>
    </Fragment>
  );
}
