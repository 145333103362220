import { Key, useEffect, useRef } from 'react';
// Component
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
// Util
import { insure, Recycler } from 'src/util';
// API
import { fetchCellData } from '../api';
// Echart
import * as echarts from 'echarts';
// Chart
import { CellDrawer } from '../drawer';

export type DeviceItem = {
  id: Key;
  name: string;
};

interface Device {
  id: Key;

  name?: string;
}

type props = {
  device: Device;
  deselect?: (uid: Key) => any;
  // 拖动
  listeners?: any;
};

export default function ({ device, deselect, listeners = {} }: props) {
  if (!device) {
    return <></>;
  }

  const recycler = new Recycler();
  const containerRef = useRef<HTMLDivElement>(null);

  const drawer = new CellDrawer(containerRef.current, {});
  recycler.register(drawer);

  useEffect(() => {
    (async () => {
      const [res, err] = await fetchCellData(device.id);
      if (err) {
        return;
      }
      if (!res) {
        return;
      }

      const t = res.reduce(
        (pre: any, cur: { name?: Key }) => {
          if (cur.name) {
            return {
              ...pre,
              [cur.name]: cur.name == res?.[0]?.name,
            };
          }
          return pre;
        },
        [device.id],
      );

      const opt: echarts.EChartOption = {
        legend: {
          type: 'scroll',
          padding: [4, 0, 0, 0],
          left: 0,
          icon: 'roundRect',
          itemWidth: 12,
          itemHeight: 12,
          textStyle: {
            // gray-500
            color: '#6B7280',
          },
          // blue-400
          pageIconColor: '#60A5FA',
          pageTextStyle: {
            // gray-500
            color: '#6B7280',
          },
          selected: t,
        },
        series: res?.map((n: { name: any; data: any }) => ({
          type: 'line',
          smooth: true,
          showSymbol: false,
          name: n.name,
          data: n.data,
        })),
      };

      drawer.init(containerRef.current, opt);
    })();

    return () => {
      recycler.drop();
    };
  }, [containerRef]);

  return (
    <Card className="m-0 h-100 w-100">
      <CardHeader className="py-2">
        <CardTitle
          className={`m-0 user-select-none clearfix
          ${listeners && 'cursor-move'}`}
          {...listeners}>
          <div className="float-start">{device.name}</div>
          <i
            className="float-end ps-2 lh-base cursor-pointer fas fa-times"
            onMouseUp={insure(deselect, device.id)}
          />
        </CardTitle>
      </CardHeader>
      <CardBody className="py-2">
        {/* 设置盒子宽高比 16:9 */}
        <div
          className="position-relative w-100 cus-h-1"
          style={{ paddingBottom: '56.25%' }}>
          <div className="position-absolute w-100 h-100">
            <div className="h-100 w-100" ref={containerRef} />
          </div>
        </div>
      </CardBody>
    </Card>
  );
}

