import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
//i18n
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Col, Row, Modal } from "reactstrap";
import { awaitWrap } from 'src/util';
import {getIotDataFlow} from 'src/helpers/iot_backend_helper';

const AddFlowModal = (props: any) => {
  let {
    t, visiable,  togModal, hideModal, save, match
  } = props;

  const {id} = match.params
  const [name, setName] = useState<string>()
  const [description, setDescription] = useState<string>()

  useEffect(()=>{
    (async function(){
      const [res, err] = await awaitWrap(getIotDataFlow(id, null))
      if (err){
        return
      }
      if (res&&res.Code!=200) {
        return
      }

      setName(res.Data.name)
      setDescription(res.Data.description)
    })()
  },[id])

  return (
    <React.Fragment>
      <Modal
        isOpen={visiable}
        toggle={() => {
          togModal();
        }}
        centered={true}
      >
        <AvForm
          onValidSubmit={(e: any, v: any) => {
            save(v);
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">{t('Save Dataflow')}</h5>
            <button
              type="button"
              onClick={() => {
                hideModal();
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Row>
              <Col sm={12}>

                <div className="mb-4">
                  <AvField
                    name="name"
                    defaultValue={name}
                    label={t('Name')}
                    placeholder=""
                    type="text"
                    errorMessage={t("Enter Dataflow Name")}
                    validate={{ required: { value: true } }}
                  />
                </div>
                <div className="mb-4">
                  <AvField
                    name="description"
                    defaultValue={description}
                    label={t('Description')}
                    placeholder=""
                    type="text"
                    errorMessage={t("Enter Connector Description")}
                    validate={{ required: { value: false } }}
                  />
                </div>
                <div className="modal-footer" style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="formCheck2" defaultChecked={false} />
                    <label className="form-check-label" htmlFor="formCheck2">
                      {t("Create Device with this Dataflow")}
                    </label>
                  </div>
                  <button type="submit" className="btn btn-primary">
                    {t('Save Dataflow')}
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        </AvForm>
      </Modal>
    </React.Fragment>
  );
}

AddFlowModal.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  visiable: PropTypes.bool,
  togModal: PropTypes.func,
  hideModal: PropTypes.func,
  save: PropTypes.func
};
export default withTranslation()(withRouter(AddFlowModal));