import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { isEmpty } from "lodash";

import {
    Button,
    Card,
    CardBody, CardHeader, CardSubtitle, CardTitle,
    Col,
    Container, Input, Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row, Table,
} from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";

/** Import Full Calendar  */
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";

//import images
// import calendar from "../../assets/images/undraw-calendar.svg";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

//i18n
import { useTranslation } from "react-i18next";

import {
    getOrganizes as onGetOrganizes,
    getOrganize as onGetOrganize,
} from "../../../store/apps/Organize/actions";

// import DeleteModal from "./DeleteModal";

//css
import "@fullcalendar/bootstrap/main.css";

//redux
import { useSelector, useDispatch } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
    SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";

import {Link} from "react-router-dom";
import {useLocation} from "react-router";
interface OrganizeProps {
    className: string;
}
// TODO: 函数头
const OrganizeAdd = ({ className }: OrganizeProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { state } = useLocation();

    const localState:any = state;

    useEffect(() => {
        // let external: any = document.getElementById("external-events");
        if(localState && localState.hasOwnProperty("id")){
            dispatch(onGetOrganize(encodeURI(localState.id)));
        }
        // dispatch(onGetEvents());
    }, [dispatch]);

    let { result } = useSelector((state: any) => ({
        result: state.Organize.organize,
    }));
    if(localState.ifAdd){
        result = null
    }

    const columns = [
        {
            dataField: "ldapServer",
            text: t("LDAP_ldapServer"),
            sort: true,
        },
        {
            dataField: "Server",
            text: t("LDAP_Server"),
            sort: true,
        },
        {
            dataField: "BaseDN",
            text: t("LDAP_BaseDN"),
            sort: true,
        },
        {
            dataField: "AutoSync",
            text: t("LDAP_AutoSync"),
            sort: true,
        },
        {
            dataField: "LastSync",
            text: t("LDAP_LastSync"),
            sort: true,
        },
        {
            dataField: "Operator",
            text: t("th_Operator"),
            sort: false,
        }
    ];

    const productData: any = [];

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{t("OrganizeAdd")}</title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumbs title={t("Dashboard")} breadcrumbItem={t("OrganizeAdd")} />
                    <Card>
                        <CardBody>
                            <div className="mb-3 d-flex flex-wrap gap-2">
                                <button
                                    type="button"
                                    className="btn btn-outline-primary waves-effect waves-light"
                                >
                                    {t("OP_Save")}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-success"
                                >
                                    {t("OP_SaveAndExit")}

                                </button>
                                {/*<button
                                    type="button"
                                    className="btn btn-soft-warning waves-effect waves-light"
                                >
                                    {t("OP_Cancel")}
                                </button>*/}
                                <Link to="/apps-organize" className="btn btn-secondary">
                                    {t("OP_Cancel")}
                                </Link>
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("th_Name")}</Label>
                                <i className="bx bx-help-circle" title={t("th_Name")} />
                                <Input className="form-control" type="text" value={result?result.name:""} autoFocus id="example-text-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("th_displayName")}</Label>
                                <i className="bx bx-help-circle" title={t("th_displayName")} />
                                <Input className="form-control" type="text" value={result?result.displayName:""} id="example-text-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("th_Icon")}</Label>
                                <i className="bx bx-help-circle" title={t("th_Icon")} />
                                <Input className="form-control" type="text" value={result?result.favicon:""} id="example-text-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("th_websiteUrl")}</Label>
                                <i className="bx bx-help-circle" title={t("th_websiteUrl")} />
                                <Input className="form-control" type="text" value={result?result.websiteUrl:""} id="example-text-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("th_PswType")}</Label>
                                <i className="bx bx-help-circle" title={t("th_PswType")} />
                                <Input className="form-control" type="text" value={result?result.passwordType:""} id="example-text-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("th_PswSaltVal")}</Label>
                                <i className="bx bx-help-circle" title={t("th_PswSaltVal")} />
                                <Input className="form-control" type="text" value={result?result.passwordSalt:""} id="example-text-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("th_Phoneprefix")}</Label>
                                <i className="bx bx-help-circle" title={t("th_Phoneprefix")} />
                                <Input className="form-control" type="text" value={result?result.phonePrefix:""} id="example-text-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("th_defaultAvatar")}</Label>
                                <i className="bx bx-help-circle" title={t("th_defaultAvatar")} />
                                <Input className="form-control" type="text" value={result?result.defaultAvatar:""} id="example-text-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("Tag")}</Label>
                                <i className="bx bx-help-circle" title={t("Tag")} />
                                <Input className="form-control" type="text" value={result?result.tags:""} id="example-text-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("th_Masterpassword")}</Label>
                                <i className="bx bx-help-circle" title={t("th_Masterpassword")} />
                                <Input className="form-control" type="text" value={result?result.masterPassword:""} id="example-text-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("organizeTalbe_SoftDelete")}</Label>
                                <i className="bx bx-help-circle" title={t("organizeTalbe_SoftDelete")} />
                                <div className="d-flex flex-wrap gap-2">
                                    <Input type="checkbox" id="switch1" switch="none" defaultChecked={result?result.enableSoftDeletion:false} />
                                    <Label className="me-1" htmlFor="switch1" data-on-label="On" data-off-label="Off"></Label>
                                </div>
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("organizeTalbe_LDAPs")}</Label>
                                <i className="bx bx-help-circle" title={t("organizeTalbe_LDAPs")} />
                                <Card>
                                    <CardBody>
                                        <ToolkitProvider
                                            keyField="id"
                                            columns={columns}
                                            data={productData}
                                            search
                                        >
                                            {toolkitProps => (
                                                <React.Fragment>
                                                    <Row>
                                                        <Col xl="12">
                                                            <div className="table-responsive">
                                                                <BootstrapTable
                                                                    // responsive
                                                                    bordered={false}
                                                                    striped={false}
                                                                    classes={"table align-middle table-nowrap"}
                                                                    headerWrapperClasses={"thead-light"}
                                                                    {...toolkitProps.baseProps}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </React.Fragment>
                                            )}
                                        </ToolkitProvider>
                                    </CardBody>
                                </Card>
                            </div>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default OrganizeAdd;
