import axios from "axios"
import accessToken from "./jwt-token-access/accessToken"

//pass new generated access token here
const token = accessToken;
//apply base url for axios
const API_URL = "https://iot.metavun.com"
// const API_URL = "https://localhost:9223"
// const API_URL = ""
const axiosApi = axios.create({
  baseURL: API_URL,
  headers: {}
})

// axiosApi.defaults.baseURL = API_URL
// axiosApi.defaults.headers.common["Authorization"] = token
// axiosApi.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
// axiosApi.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';

const notAuthUrl = ['/api/v1/iot-users/login',
  '/api/v1/iot-users/register',
  '/api/v1/iot-users/is-exist',
  '/api/v1/pub/send-email',
  '/api/v1/iot-users/enable'];

// 添加请求拦截器
axiosApi.interceptors.request.use((config: any) => {
  // 判断一下接口是否需要授权
  for (const r of notAuthUrl) {
    if (config.url?.includes(r)) return config
  }

  if (notAuthUrl.includes(config.url)) return config;
  let authUser: any = localStorage.getItem("authUser");
  if (authUser) authUser = JSON.parse(authUser);
  config.headers.Authorization = `Bearer ${authUser.Data.token.access_token}`;
  return config;
}, error => Promise.reject(error));

// 响应拦截
axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)
interface IResultJson {
  Code: number;
  Message: string;
  Total?: number;
  Data: Array<any> | {
    [propName: string]: any;
  }
}
export async function patch(url: string, config = {}) {
  return await axiosApi.patch(url, { ...config }).then(response => response.data)
}

export async function get(url: string, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url: string, data: any, config = {}) {
  return axiosApi
    .post(url, data, { ...config })
    .then(response => response.data)
}

export async function put(url: string, data: any, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url: string, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
