import { takeEvery, put, call, all, fork } from "redux-saga/effects"

// Calender Redux States
import { PermissionsTypes } from "./actionTypes"
import {
  getPermissionsSuccess, getPermissionsFail, getPermissionByIdSuccess, getPermissionByIdFail
} from "./actions"

//Include Both Helper File with needed methods
import {
  api_getPermissionById,
  api_getPermissions,
} from "../../../API/api"

// 获取全部权限信息
function* onGetPermissions() {
  try {
    const response : Promise<any> = yield call(api_getPermissions)
    yield put(getPermissionsSuccess(response))
  } catch (error) {
    yield put(getPermissionsFail(error))
  }
}

export function* watchOnGetPermissions() {
  yield takeEvery(PermissionsTypes.GET_PERMISSIONS, onGetPermissions);
}

// 获取单个权限信息
function* onGetPermissionById({payload:id}:any) {
  try {
    const response : Promise<any> = yield call(api_getPermissionById,id)
    yield put(getPermissionByIdSuccess(response))
  } catch (error) {
    yield put(getPermissionByIdFail(error))
  }
}

export function* watchOnGetPermissionById() {
  yield takeEvery(PermissionsTypes.GET_PERMISSIONBYID, onGetPermissionById);
}

function* permissionSaga() {
  yield all([fork(watchOnGetPermissions)]);
  yield all([fork(watchOnGetPermissionById)]);
}

export default permissionSaga
