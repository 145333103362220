'use strict';

// API
import {
  getIotAssetsDeviceDataOverviewCombine,
  getIotDevices,
  getIotOrganization,
  updateIotOrganization,
} from 'src/helpers/iot_backend_helper';
// Util
import { awaitWrap } from 'src/util';
import { Key } from 'react';
import dayjs from 'dayjs';

interface Device {
  id: Key;

  name?: String;
}

// 获取完整设备列表
export const fetchDevices = async (oid: Key, wid: Key) => {
  const p = {
    org_id: oid,
    workspace_id: wid,
    page_number: 1,
    page_size: 1024,
  };
  const [res, err] = await awaitWrap(getIotDevices(p, null));
  if (err) {
    return [null, err];
  }
  if (res.Code != 200) {
    return [null, res];
  }
  if (!res.Data?.length) {
    return [null, undefined];
  }

  const t = res.Data.map((n: any) => ({
    id: n.id,
    name: n.name,
  }));
  return [t, undefined];
};
// 获取组织的分析页面布局
export const fetchDevicesLayout = async (orgId: string) => {
  const [res, err] = await awaitWrap(getIotOrganization(orgId, null));
  if (err) {
    return [null, null, err];
  }
  if (res.Code != 200) {
    return [null, null, res];
  }
  if (!res.Data?.exhibition_devices?.list?.length) {
    return [null, res.Data.name, undefined];
  }

  const t = res.Data.exhibition_devices.list.map((n: any) => ({
    id: n.device_id,
    name: n.name,
  }));
  return [t, res.Data.name, undefined];
};
// 更新分析页面布局
export const updateDevicesLayout = async (
  orgId: string,
  nm: string,
  devices: Device[],
) => {
  const p = {
    name: nm,
    exhibition_devices: {
      list: devices.map((v, i) => ({
        device_id: v.id,
        name: v.name,
        sequence: i,
      })),
    },
  };
  const [res, err] = await awaitWrap(updateIotOrganization(orgId, p, null));
  if (err) {
    return [null, err];
  }
  if (res.Code != 200) {
    return [null, res];
  }

  return [res, undefined];
};
// 获取单元格数据
const formatStr = 'YYYY-MM-DD HH:mm:ss';
export const fetchCellData = async (uid: Key) => {
  const [start_at, end_at] = [
    dayjs().subtract(1, 'day').format(formatStr),
    dayjs().format(formatStr),
  ];
  const query = {
    start_at,
    end_at,
    device_id: uid,
  };

  const [res, err] = await awaitWrap(
    getIotAssetsDeviceDataOverviewCombine(query),
  );
  if (err) {
    return [null, err];
  }
  if (res.Code != 200) {
    return [null, undefined];
  }

  const r = res.Data.filter((n: any) => n.values && n.values.length > 0).map(
    (n: any) => ({
      name: n.dataKey,
      data: n.values.map((n: any) => [
        dayjs(n.date).format('MM-DD HH:mm'),
        n.value,
      ]),
    }),
  );

  return [r, undefined];
};
