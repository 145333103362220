import React, { Fragment, useState } from 'react';
// i18n
import { useTranslation } from 'react-i18next';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from 'src/store/auth/login/actions';
// Component
import MetaTags from 'react-meta-tags';
import { Alert, Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import CarouselPage from '../AuthenticationInner/CarouselPage';
// Assets
import logo from 'src/assets/images/logo-sm.svg';

type FormInputs = {
  username: string;
  password: string;
};

export default function () {
  const { t } = useTranslation();
  const h = useHistory();

  const YEAR = new Date().getFullYear();

  // 用来标识是否点击过注册按钮
  const [f, setF] = useState(false);

  const { e } = useSelector((state: any) => ({ e: state.login.error }));
  const dispatch = useDispatch();

  // FormSchema
  const FormSchema = {
    username: {
      required: t('username is required'),
    },
    password: {
      required: t('password is required'),
    },
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInputs>();

  // Submit
  const onSubmit: SubmitHandler<FormInputs> = data => {
    const { username, password } = data;
    const p = {
      user_name: username,
      password,
    };
    dispatch(loginUser(p, h));
    e && setF(true);
  };

  return (
    <Fragment>
      <MetaTags>
        <title>Login | Metavun</title>
      </MetaTags>
      <Row className="m-0">
        <Col
          lg={4}
          md={5}
          className="d-flex flex-column col-xxl-3 auth-full-page-content p-sm-5 p-4">
          <header className="auth-logo text-center">
            <img src={logo} alt="" height="28" />
            <span className="logo-txt">Metavun</span>
          </header>

          <main className="auth-content my-auto">
            <div className="text-center">
              <h5 className="mb-0 text-capitalize">{t('welcome')}</h5>
              <p className="text-muted mt-2 text-capitalize">
                {t('login Metavun')}
              </p>
            </div>
            {/* Error: errors.username?.message\ errors.password?.message\ e */}
            {(errors.username?.message ||
              errors.password?.message ||
              // 防止上次注销后显示上次登录的错误
              (e && f)) && (
              <Alert color="danger">
                {errors.username?.message || errors.password?.message || e}
              </Alert>
            )}
            {/* UserName */}
            <FormGroup className="mt-sparse-pre">
              <Label className="text-capitalize">{t('username')}</Label>
              <Input
                autoComplete="off"
                defaultValue=""
                placeholder={t('enter username to login with')}
                {...register('username', FormSchema.username)}
              />
            </FormGroup>
            {/* Password */}
            <FormGroup className="mt-sparse-pre">
              <Label className="d-block text-capitalize clearfix">
                <div className="float-start">{t('password')}</div>
              </Label>
              <Input
                className="mt-narrow-pre"
                autoComplete="new-password"
                type="password"
                defaultValue=""
                placeholder={t('enter password')}
                {...register('password', FormSchema.password)}
              />
            </FormGroup>
            {/* Submit */}
            <FormGroup className="mt-sparse-pre">
              <Button
                className="mt-4 w-100 text-capitalize"
                color="primary"
                onClick={handleSubmit(onSubmit)}>
                {t('Login')}
              </Button>
            </FormGroup>
            <FormGroup className="mt-sparse-pre text-center">
              <span className="me-2">{t("Don't have an account ?")}</span>
              <Link to="/register" className="fw-bold">
                {t('Signup now')}
              </Link>
            </FormGroup>
          </main>

          <footer className="text-center">
            <p className="mb-0">&copy; {YEAR} MetaVun .</p>
          </footer>
        </Col>
        <CarouselPage />
      </Row>
    </Fragment>
  );
}
