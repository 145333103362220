import { takeEvery, put, call, all, fork } from "redux-saga/effects"

// Calender Redux States
import { AppsTypes } from "./actionTypes"
import {
  getApps, getAppsFail, getAppsSuccess
} from "./actions"

//Include Both Helper File with needed methods
import {
  api_getApps,
} from "../../../API/api"

// 获取全部用户信息
function* onGetApps() {
  try {
    const response : Promise<any> = yield call(api_getApps)
    yield put(getAppsSuccess(response))
  } catch (error) {
    yield put(getAppsFail(error))
  }
}

export function* watchOnGetApps() {
  yield takeEvery(AppsTypes.GET_APPS, onGetApps);
}

function* appsSaga() {
  yield all([fork(watchOnGetApps)]);
}

export default appsSaga
