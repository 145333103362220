import usFlag from '../assets/images/flags/us.jpg';
import zhCNFlag from '../assets/images/flags/zhCNFlag.jpg';

const languages = {
  en: {
    label: 'English',
    flag: usFlag,
  },
  zhcn: {
    label: '简体中文',
    flag: zhCNFlag,
  },
};

export default languages;
