import { takeEvery, put, call, all, fork } from "redux-saga/effects"

// Calender Redux States
import {CertsState, CertsTypes} from "./actionTypes"
import {
  getCerts, getCertsSuccess, getCertsFail, getCertByIdSuccess, getCertByIdFail
} from "./actions"

//Include Both Helper File with needed methods
import {
  api_getCertById,
  api_getCerts,
} from "../../../API/api"

// 获取全部用户信息
function* onGetCerts() {
  try {
    const response : Promise<any> = yield call(api_getCerts)
    yield put(getCertsSuccess(response))
  } catch (error) {
    yield put(getCertsFail(error))
  }
}

export function* watchOnGetCerts() {
  yield takeEvery(CertsTypes.GET_CERTS, onGetCerts);
}

// 获取单个证书信息
// 获取全部用户信息
function* onGetCertById({payload:id}:any) {
  try {
    const response : Promise<any> = yield call(api_getCertById,id)
    yield put(getCertByIdSuccess(response))
  } catch (error) {
    yield put(getCertByIdFail(error))
  }
}

export function* watchOnGetCertById() {
  yield takeEvery(CertsTypes.GET_CERTBYID, onGetCertById);
}

function* certsSaga() {
  yield all([fork(watchOnGetCerts)]);
  yield all([fork(watchOnGetCertById)]);
}

export default certsSaga
