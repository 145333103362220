import {ReactChild} from "react";

export type MenuItem = {
    name: string;
    trigger: ReactChild
}

export const options: MenuItem[] = [
    {
        name: 'organization',
        trigger: <i className="fas fa-home"/>
    },
    {
        name: "workspace",
        trigger: <i className="fas fa-folder-open"/>
    }
]

export const tips: MenuItem[] = [
    {
        name: 'user',
        trigger: <i className="fas fa-user"/>
    }
]

export const menuList = ["organization", "workspace"]
