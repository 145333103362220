import { useContext } from 'react';
// i18n
import { useTranslation } from 'react-i18next';
// Redux
import { DataFlowContext } from '../../../../../reducer';
// Component
import { Badge } from 'reactstrap';
import CovertLabel from './CovertLabel';

export type DeviceConnectorInfo = {
  connectorName: string;
  technology: string;
  uplinkURL: string;
  uplinkSecret: string;
};

type Props = {
  deviceConnectorInfo: DeviceConnectorInfo;
  onClear: Function;
  onEdit: Function;
};

export default ({ deviceConnectorInfo, onClear, onEdit }: Partial<Props>) => {
  const { t } = useTranslation();

  const { state } = useContext(DataFlowContext);

  const deviceConnector: any = state.deviceConnector;
  const { name, connectivity, uplinkSecret, uplinkUrl } = deviceConnector;

  const handleClear = () => {
    onClear && onClear();
  };

  const handleEdit = () => {
    onEdit && onEdit();
  };

  return (
    <div className="border rounded-2 shadow-lg">
      <header className="border-bottom px-3 py-2">
        <i className="mdi mdi-link-variant me-2" />
        <span className="fw-bold">{name}</span>
      </header>

      <main className="border-bottom px-3 py-2">
        <div className="d-flex gutter-t-normal">
          <div className="flex-shrink-0 me-2 text-secondary text-capitalize">
            {t('technology')}:
          </div>
          <div className="flex-grow-1">
            <Badge
              className="gutter-s-normal border border-secondary bg-light"
              color="black">
              {connectivity}
            </Badge>
          </div>
        </div>

        <CovertLabel label={t('Uplink URL')} content={uplinkUrl} />
        <CovertLabel label={t('Uplink Secret')} content={uplinkSecret} />
      </main>

      <footer className="clearfix px-3 py-2">
        <div
          className="float-start text-primary text-capitalize cursor-pointer"
          onClick={handleClear}>
          {t('clear')}
        </div>
        <div
          className="float-end text-primary text-capitalize cursor-pointer"
          onClick={handleEdit}>
          {t('edit')}
        </div>
      </footer>
    </div>
  );
};
