import React, { useState } from "react";
import PropTypes from "prop-types";
//i18n
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Col, Row, Modal } from "reactstrap";

const AddRuleModal = (props: any) => {
  let {
    t, visiable, data, togModal, hideModal, save
  } = props;
  return (
    <React.Fragment>
      <Modal
        isOpen={visiable}
        toggle={() => {
          togModal();
        }}
        centered={true}
      >
        <AvForm
          onValidSubmit={(e: any, v: any) => {
            save(v);
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">{t('Save Rule')}</h5>
            <button
              type="button"
              onClick={() => {
                hideModal();
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Row>
              <Col sm={12}>

                <div className="mb-4">
                  <AvField
                    name="name"
                    defaultValue={data.name}
                    label={t('Name')}
                    placeholder=""
                    type="text"
                    errorMessage={t("Enter Rule Name")}
                    validate={{ required: { value: true } }}
                  />
                </div>
                <div className="mb-4">
                  <AvField
                    name="description"
                    defaultValue={data.description}
                    label={t('Description')}
                    placeholder=""
                    type="text"
                    errorMessage="Enter Rule Description"
                    validate={{ required: { value: false } }}
                  />
                </div>
                <div className="modal-footer" style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div className="form-check">
                    {/* <input className="form-check-input" type="checkbox" id="formCheck2" defaultChecked={false} />
                    <label className="form-check-label" htmlFor="formCheck2">
                      Create Device with this DataFlow
                    </label> */}
                  </div>
                  <button type="submit" className="btn btn-primary">
                    {t('Save Rule')}
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        </AvForm>
      </Modal>
    </React.Fragment>
  );
}

AddRuleModal.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  visiable: PropTypes.bool,
  togModal: PropTypes.func,
  hideModal: PropTypes.func,
  save: PropTypes.func
};
export default withTranslation()(withRouter(AddRuleModal));