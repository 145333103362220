import React, {useEffect, useState} from "react";
import MetaTags from "react-meta-tags";

import {
    Card,
    CardBody,
    CardHeader, CardText,
    CardTitle,
    Col,
    Container,
    Input,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row, TabContent, TabPane,
} from "reactstrap";


//i18n
import {useTranslation} from "react-i18next";

//css
import "@fullcalendar/bootstrap/main.css";
import "src/assets/scss/inproject/projstyle.scss"

//redux
import {useDispatch, useSelector} from "react-redux";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
    SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import {useLocation} from "react-router";
import {Link} from "react-router-dom";
import classnames from "classnames";

interface LabelArray{
    arr:Array<string>
}

// TODO: 函数头
const CommonFilter = ( {arr} : LabelArray) => {
    const { t } = useTranslation();
    const { state } = useLocation();

    const localState:any = state;

    const [activeTab, setactiveTab] = useState("1");
    const [showSecond, setShowSecond] = useState(true);
    const [showThird, setShowThird] = useState(false);
    const [firstInput,setFirstInput] = useState('');
    const [secInput,setSecInput] = useState('');
    const [thirdInput, setThirdInput] = useState('');

    const fBackNum:()=>number = ()=>{
        switch (arr.length){
            case 3:
                return 1
            case 2:
                return 2
            case 1:
                return 3
            default:
                return 2
        }
    }

    if(arr.length > 2 && !showThird){
        setShowThird(true)
    }

    if(arr.length ==1 && showSecond){
        setShowSecond(false)
    }

    const backNum = fBackNum();

    return (
        <React.Fragment>
            <Row className="mb-4">
                <Col xl={arr.length*2}>
                    <Row>
                        <Col xl={backNum} className="m-label">
                            <Label
                                htmlFor="horizontal-firstname-input"
                            >
                                {t(arr[0])}
                            </Label>
                        </Col>
                        <Col xl={arr.length==1?9:6-arr.length}>
                            <Input
                                type="text"
                                className="form-control"
                                id="horizontal-firstname-input"
                                onChange={(e)=>setFirstInput(e.target.value)}
                                value={firstInput||''}
                            />
                        </Col>

                        <Col xl={backNum} className={showSecond?"m-label":"m-label hide-dom"}>
                            <Label
                                htmlFor="horizontal-firstname-input"
                            >
                                {t(arr[1])}
                            </Label>
                        </Col>
                        <Col xl={6-arr.length} className={showSecond?"m-label":"m-label hide-dom"}>
                            <Input
                                type="text"
                                className="form-control"
                                id="horizontal-firstname-input"
                                onChange={(e)=>setSecInput(e.target.value)}
                                value={secInput||''}
                            />
                        </Col>

                        <Col xl={2} className={showThird?"m-label":"m-label hide-dom"}>
                            <Label
                                htmlFor="horizontal-firstname-input"
                            >
                                {t(arr[2])}
                            </Label>
                        </Col>
                        <Col xl={10-backNum*2-(6-arr.length)*2} className={showThird?"m-label":"m-label hide-dom"}>
                            <Input
                                type="text"
                                className="form-control"
                                id="horizontal-firstname-input"
                                onChange={(e)=>setThirdInput(e.target.value)}
                                value={thirdInput||''}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col xl={2}>
                    <div className="d-flex flex-wrap gap-2" >
                        <button
                            type="button"
                            className="btn btn-warning"
                            onClick={()=>{setFirstInput('');setSecInput('');setThirdInput('')}}
                        >
                            {t("OP_Reset")}
                        </button>
                        <button
                            type="button"
                            className="btn btn-success"
                        >
                            {t("OP_Serch")}
                        </button>
                    </div>

                </Col>
            </Row>

            <hr/>
        </React.Fragment>
    );
};

export default CommonFilter;
