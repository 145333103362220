import {StrictMode} from "react";

import {useTranslation} from "react-i18next";

import AkenzaBlockSelector from "../AkenzaBlockSelector";

import {insure} from "src/util";

import {getLogicTypeInfo, LogicBlockConfiguration} from "../../common/configuration";

type Props = {
    onCancel: Function;
    onSelect: Function;
}

const mutateLogicTypeConfiguration = (logicTypeConfigurations: LogicBlockConfiguration[]) =>
    logicTypeConfigurations.map(configuration =>
        ({
            value: configuration.id,
            label: configuration.name,
            iconClassName: configuration.iconClassName,
        }))

export default ({onCancel, onSelect}: Partial<Props>) => {
    const {t} = useTranslation()

    const logicTypeInfo = getLogicTypeInfo(mutateLogicTypeConfiguration)
    const [akenzaBlockInfo] = logicTypeInfo

    return (<StrictMode>
        <div className="d-flex flex-column h-100">
            <header className="flex-shrink-0 mb-5 user-select-none clearfix">
                <span className="float-start font-size-18 fw-bolder text-capitalize">{t("select logic type")}</span>
                <span className="float-end lh-lg text-primary text-capitalize cursor-pointer"
                      onClick={insure(onCancel)}>
                    {t("cancel")}
                </span>
            </header>
            <main className="flex-grow-1 overflow-auto">
                <AkenzaBlockSelector optionProps={akenzaBlockInfo} onSelect={onSelect}/>
            </main>
        </div>
    </StrictMode>)
}
