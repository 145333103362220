import {Key} from "react";

import {INPUT_TYPE} from "../RuleInput/constant";
import {LOGIC_TYPE} from "../RuleLogic/constant";
import {ACTION_TYPE_GROUP, DATA_SINK, NOTIFICATION_SERVICE} from "../RuleAction/constant";

export type Configuration = {
    id: any;
    name: Key;
    description?: string;
    img?: string;
    iconClassName?: string;
}

type ReloadID<T = Configuration> = Omit<T, "id">

export type Mutate<T = Configuration> = (configurations: T[]) => any[]

// Input Configuration
// region
export type InputConfiguration = ReloadID & { id: INPUT_TYPE }

export const deviceConfiguration: InputConfiguration = {
    id: INPUT_TYPE.DEVICE,
    name: "device",
    iconClassName: "mdi mdi-link-variant",
}

export const tagConfiguration: InputConfiguration = {
    id: INPUT_TYPE.TAG,
    name: "tag",
    iconClassName: "mdi mdi-tag-outline",
}

export const inputConfiguration = {
    iconClassName: "mdi mdi-link-variant",
}

const inputTypeConfigurations = [deviceConfiguration, tagConfiguration]

export const getInputTypeInfo = (mutate?: Mutate) => {
    return mutate && mutate(inputTypeConfigurations) || inputTypeConfigurations
}
// endregion

// Logic Configuration
// region
export type LogicBlockConfiguration = ReloadID & { id: LOGIC_TYPE }

export const akenzaBlockConfiguration = {
    id: LOGIC_TYPE.AKENZA,
    name: "Comparison",
    type: "COMPARISON",
    iconClassName: "mdi mdi-link-variant",
}

const logicTypeConfigurations = [akenzaBlockConfiguration]

export const getLogicTypeInfo = (mutate?: Mutate) => {
    return mutate && mutate(logicTypeConfigurations) || logicTypeConfigurations
}
//endregion

// Action Configuration
// region
const customAkenzaDB = {
    id: DATA_SINK.CUSTOM_METAVUN_DB,
    group: ACTION_TYPE_GROUP.DATA_SINK,
    type: "CUSTOM_METAVUN_DB",
    name: "custom metavun DB",
    description: `configuration data structure and store your data in the metavun DB to
    have access to historical data at anytime`,
    img: `https://app.akenza.io/assets/img/output/custom_akenza_database.svg`,
}

const InfluxDB = {
    id: DATA_SINK.INFLUX_DB,
    group: ACTION_TYPE_GROUP.DATA_SINK,
    type: "INFLUXDB",
    name: "InfluxDB",
    description: `Select Influx DB as data entry point to build your application further with Influx services`,
    img: `https://app.akenza.io/assets/img/output/influx_db.svg`,
}

const Kafka = {
    id: DATA_SINK.KAFKA,
    group: ACTION_TYPE_GROUP.DATA_SINK,
    type: "KAFKA",
    name: "Kafka",
    description: `Select Apache Kafka as data sink to forward data to a compatible Apache Kafka cluster`,
    img: `https://app.akenza.io/assets/img/output/kafka.svg`,
}

const dataSinks = [customAkenzaDB, InfluxDB, Kafka]

export const getDataSinks = (mutate?: Mutate) => {
    return mutate && mutate(dataSinks) || dataSinks
}
// export const getNotificationServices = () => {}
// endregion
const Email = {
    id: NOTIFICATION_SERVICE.EMAIL,
    group: ACTION_TYPE_GROUP.NOTIFICATION_SERVICE,
    name: "E-Mail",
    type: "EMAIL",
    description: `Send notifications via E-Mail to one or multiple recipients`,
    img: `https://app.akenza.io/assets/img/output/mail.svg`,
}

const SMS = {
    id: NOTIFICATION_SERVICE.SMS,
    group: ACTION_TYPE_GROUP.NOTIFICATION_SERVICE,
    name: "SMS",
    type: "SMS",
    description: `Send notifications via SMS to one or multiple recipients`,
    img: `https://app.akenza.io/assets/img/output/sms.svg`,
}

const notificationServices = [Email]

export const getNotificationServices = (mutate?: Mutate) => {
    return mutate && mutate(notificationServices) || notificationServices
}