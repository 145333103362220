/**
 * interface for resources type
 */
 export enum ResourcesTypes {
    API_SUCCESS = '@@resources/API_SUCCESS',
    API_FAIL = '@@resources/API_FAIL',
    GET_EVENTS = '@@resources/GET_EVENTS',
    ADD_NEW_EVENT = '@@resources/ADD_NEW_EVENT',
    UPDATE_EVENT = '@@resources/UPDATE_EVENT',
    UPDATE_EVENT_SUCCESS = '@@resources/UPDATE_EVENT_SUCCESS',
    UPDATE_EVENT_FAIL = '@@resources/UPDATE_EVENT_FAIL',
    DELETE_EVENT = '@@resources/DELETE_EVENT',
    DELETE_EVENT_SUCCESS = '@@resources/DELETE_EVENT_SUCCESS',
    DELETE_EVENT_FAIL = '@@resources/DELETE_EVENT_FAIL',
    GET_CATEGORIES = '@@resources/GET_CATEGORIES',
    GET_CATEGORIES_SUCCESS = '@@resources/GET_CATEGORIES_SUCCESS',
    GET_CATEGORIES_FAIL = '@@resources/GET_CATEGORIES_FAIL',
    GET_RESOURCES = '@@resources/GET_RESOURCES',
    GET_RESOURCES_SUCCESS = '@@resources/GET_RESOURCES_SUCCESS',
    GET_RESOURCES_FAIL = '@@resources/GET_RESOURCES_FAIL',
 }

 export interface ResourcesState {
   events  : Array<any>;
   categories : Array<any>;
   error : Object;
}