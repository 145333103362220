import { StrictMode, useState, useEffect } from "react";
//i18n
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";

import Guide from "./Guide";
import RulesList from "./RulesList";

import { useUrlPrefix } from "./customHook";
import {
    getIotRules
} from "src/helpers/iot_backend_helper";

export default withTranslation()(withRouter((props:any) => {
    const { t, match: {params: {wsId, orgId}} } = props;

    const urlPrefix = useUrlPrefix()

    const [visible, setVisible] = useState(false)
    // 检查是否有data flow 数据,如果没有的话先到指引页面
    useEffect(() => {
        getIotRules({ workspace_id: wsId }, null).then((result: any) => {
            if (result.Code == 200 && result.Data && result.Data.length) {
                setVisible(true);
            }
        });
    }, []);

    const guideProps = {
        name: "rule",
        description: "with rules you can analyze and process data of one or multiple devices and trigger actions when needed. Actions include sending notifications, downlinks or forwarding data to third-party systems.",
        guidePath: `${urlPrefix}/rules`,
        buildPath: `${urlPrefix}/rules/new`
    }
    return (<StrictMode>
        {
            visible? <RulesList />:<Guide {...guideProps} hidden={!visible} />
        }
    </StrictMode>)
}))
