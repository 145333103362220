import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
//i18n
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, Col, Modal, Row } from 'reactstrap';
// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Loading from 'src/components/Loading';
import ConfirmModal from './components/ConfirmModal';

import {
  createIotWorkspaces,
  deleteIotWorkspace,
  getIotWorkspaces,
  updateIotWorkspace,
} from 'src/helpers/iot_backend_helper';
// actions
import { loginSuccess } from 'src/store/actions';
import _ from 'lodash';

const { SearchBar } = Search;

const Workspace = (props: any) => {
  const dispatch = useDispatch();
  const { t, match } = props;
  const { orgId } = match.params;
  const [loadding, setLoadding]: [boolean, Function] = useState(true);
  const [visiable, setVisiable] = useState(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentRow, setCurrentRow] = useState<any>(null);
  const [list, setList] = useState([]);

  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [total, setTotal] = useState(0)

  const { user } = useSelector((state: any) => ({
    user: state.login.user,
  }));
  const role =
    user.user_organizations.find((org: any) => org.organization.id == orgId)
      .role || 'viewer';
  useEffect(() => {
    fetchData(10,1);
  }, []);

  const fetchData = (newPageSize?: number, newPageNumber?: number, order?:string) => {
    getIotWorkspaces(
      {
        org_id: orgId,
        page_size: newPageSize || pageSize,
        page_number: newPageNumber || pageNumber,
        order,
      },
      null,
    )
      .then(result => {
        if (result && result.Code == 200) {
          let arr = result.Data.map(
            ({ id, name, description, created_at, updated_at }: any) => {
              return { id, name, description, created_at, updated_at };
            },
          );
          setList(arr);
          setTotal(result.Total)
        }
      })
      .finally(() => setLoadding(false));
  };

  const handleTableSort = (field:string, order:string) => {
    let orderStr = `${field}=${order}`
    fetchData(pageSize,pageNumber,orderStr)
  }

  const columns = [
    {
      dataField: 'name',
      text: props.t('Name'),
      sort: true,
      onSort:handleTableSort
    },
    {
      dataField: 'description',
      text: props.t('Description'),
      sort: true,
      onSort:handleTableSort
    },
    {
      dataField: 'created_at',
      text: props.t('CreationDate'),
      formatter: (value: any, row: any, index: any) => (
        <div>{dayjs(row.created_at).format('YYYY-MM-DD HH:mm:ss')}</div>
      ),
      sort: true,
      onSort:handleTableSort
    },
    {
      dataField: 'updated_at',
      text: props.t('ModificationDate'),
      formatter: (value: any, row: any, index: any) => (
        <div>{dayjs(row.created_at).format('YYYY-MM-DD HH:mm:ss')}</div>
      ),
      sort: true,
      onSort:handleTableSort
    },
    {
      dataField: 'Action',
      text: props.t('Action'),
      formatter: (value: any, row: any, index: any) => (
        <div className="flex-row">
          {role === 'admin' || role === 'editor' ? (
            <div
              onClick={() => handleUpdate(row)}
              className="text-primary first-letter-upper me-4">
              {t('edit')}
            </div>
          ) : (
            <div className="text-muted cursor-not-allowed first-letter-upper me-4">
              {t('edit')}
            </div>
          )}
          {role === 'admin' ? (
            <div
              onClick={() => handleremove(row)}
              className="text-danger first-letter-upper">
              {t('delete')}
            </div>
          ) : (
            <div className="text-muted cursor-not-allowed first-letter-upper me-4">
              {t('delete')}
            </div>
          )}
        </div>
      ),
    },
  ];
  const handleremove = (row: any) => {
    setIsOpen(true);
    setCurrentRow(row);
  };

  const handleUpdate = (row: any) => {
    setCurrentRow(row);
    setVisiable(true);
  };

  const defaultSorted: any = [
    {
      dataField: 'id',
      order: 'asc',
    },
  ];

  const pageOptions: any = {
    custom: true,
    page: pageNumber,
    sizePerPage: pageSize,
    totalSize: total, // replace later with size(customers),
  };

  const handleTableChange = async (type: string, { page, sizePerPage }: any) => {
    if (type == "sort") {
      return
    }
    fetchData(sizePerPage, page);
    setPageNumber(page);
    setPageSize(sizePerPage);
  }

  // Select All Button operation
  const selectRow: any = {
    mode: 'checkbox',
  };

  const togModal = () => {
    setVisiable(!visiable);
  };

  // 确认删除
  const confirmRemove = () => {
    let { id: wid } = currentRow;
    let authUser: any = localStorage.getItem('authUser');
    authUser = JSON.parse(authUser);
    // authUser.Data.workspaces = authUser.Data.workspaces.filter((item:any)=> {
    //   return  item.id != wid;
    // });
    const operatedWs = authUser.Data.workspaces.find((el: any) => el.id == wid);
    const wss = _.groupBy(authUser.Data.workspaces, 'org_id');
    const operatedOrg = wss[operatedWs.org_id];
    if (operatedOrg && operatedOrg.length == 1) {
      //当计划删除的组织只有一个工作区时，阻止他删除
      alert(t('Each organization needs at least one workspace!'));
      return;
    }
    deleteIotWorkspace(wid, null).then(result => {
      if (result && result.Code == 200) {
        let data = list.filter((item: any) => item.id != currentRow.id);
        setList(data);
        setIsOpen(false);
        authUser.Data.workspaces = authUser.Data.workspaces.filter(
          (item: any) => {
            return item.id != wid;
          },
        );

        if (authUser.Data.curr_workspace.id == wid) {
          // 如果当前工作区被删除，将当前组织的第一个工作区作为当前工作区
          authUser.Data.curr_workspace = wss[orgId.toString()][0];
        }

        dispatch(loginSuccess({ Data: authUser.Data }));
        localStorage.setItem('authUser', JSON.stringify(authUser));
      }
    });
  };

  // form提交
  const handleValidSubmit = (e: any, values: any) => {
    values.org_id = orgId;
    if (currentRow && currentRow.id) {
      updateWorkspace(currentRow.id, values);
    } else {
      createWorkspace(values);
    }
  };

  // 创建工作区
  const createWorkspace = (data: any) => {
    createIotWorkspaces(data, null).then(result => {
      if (result && result.Code == 200) {
        setVisiable(false);
        fetchData();
        // 更新redux跟本地存储数据
        let authUser: any = localStorage.getItem('authUser');
        authUser = JSON.parse(authUser);
        let { id, name, org_id, owner_id, description } = result.Data;
        let workspace = { id, name, org_id, owner_id, description };
        authUser.Data.curr_workspace = workspace;
        authUser.Data.workspaces.push(workspace);
        authUser.Data.user_workspaces.push({
          id,
          role: 'admin',
          workspace,
        });
        dispatch(loginSuccess({ Data: authUser.Data }));
        localStorage.setItem('authUser', JSON.stringify(authUser));
      }
    });
  };

  // 更新工作区
  const updateWorkspace = (id: string, data: any) => {
    updateIotWorkspace(id, data, null).then(result => {
      if (result && result.Code == 200) {
        setVisiable(false);
        fetchData();
        // 更新redux跟本地存储数据
        let authUser: any = localStorage.getItem('authUser');
        authUser = JSON.parse(authUser);
        let { id, org_id, owner_id } = currentRow;
        let workspace = { id, org_id, owner_id, ...data };
        authUser.Data.curr_workspace = workspace;
        let p = -1;
        authUser.Data.workspaces.forEach((row: any, i: number) => {
          if (row.id == id) {
            p = i;
          }
        });
        if (p == -1) return;
        authUser.Data.workspaces[p] = {
          ...authUser.Data.workspaces[p],
          ...data,
        };
        dispatch(loginSuccess({ Data: authUser.Data }));
        localStorage.setItem('authUser', JSON.stringify(authUser));
      }
    });
  };

  const confirmModalProps = {
    isOpen: isOpen,
    hideModal: () => setIsOpen(false),
    title: 'Do you really want to delete this Workspace?',
    description: 'This action cannot be undone',
    confirmText: 'delete',
    confirm: confirmRemove,
  };

  return (
    <React.Fragment>
      {loadding && <Loading loading={loadding} />}
      <div className="px-4" style={{ flexDirection: 'column' }}>
        <div
          className="flex-row justify-between mt-4 mb-4 px-2"
          style={{ alignItems: 'cente' }}>
          <div
            className="text-dark"
            style={{
              fontSize: '20px',
              fontWeight: 700,
            }}>
            {props.t('OrganizationWorkspaces')}
          </div>
          <button
            disabled={role != 'admin'}
            type="button"
            style={{ background: '#1890ff', fontSize: '16px' }}
            onClick={() => {
              setCurrentRow(null);
              togModal();
            }}
            className="btn btn-primary waves-effect waves-light">
            <span style={{ fontSize: '18px', marginRight: '10px' }}>+</span>
            {props.t('CreateWorkspace')}
          </button>
        </div>
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    // columns={columns}
                    // data={productData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={list}
                        search>
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                      placeholder={props.t(
                                        'SearchWorkspacelist',
                                      )}
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    // responsive
                                    bordered={false}
                                    remote
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    // selectRow={selectRow}
                                    classes={'table align-middle table-nowrap'}
                                    headerWrapperClasses={'thead-light'}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    onTableChange={handleTableChange}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            isOpen={visiable}
            toggle={() => {
              togModal();
            }}
            centered={true}>
            <AvForm
              onValidSubmit={(e: any, v: any) => {
                handleValidSubmit(e, v);
              }}>
              <div className="modal-header">
                <h5 className="modal-title mt-0">
                  {props.t(currentRow ? 'UpdateWorkspace' : 'CreateWorkspace')}
                </h5>
                <button
                  type="button"
                  onClick={() => {
                    setVisiable(false);
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <Row>
                  <div className="mb-3">
                    <AvField
                      name="name"
                      defaultValue={currentRow && currentRow.name}
                      label={props.t('Name')}
                      placeholder=""
                      type="text"
                      errorMessage={t('workspace name is required')}
                      validate={{ required: { value: true } }}
                    />
                  </div>
                  <div className="mb-3">
                    <AvField
                      name="description"
                      defaultValue={currentRow && currentRow.description}
                      label={
                        props.t('Description') + ' (' + t('optional') + ')'
                      }
                      placeholder=""
                      type="text"
                      errorMessage=""
                      validate={{ required: { value: false } }}
                    />
                  </div>
                </Row>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={() => {
                    setVisiable(false);
                  }}>
                  {props.t('Close')}
                </button>
                <button type="submit" className="btn btn-primary">
                  {props.t('Save')}
                </button>
              </div>
            </AvForm>
          </Modal>
        </div>
      </div>
      <ConfirmModal {...confirmModalProps} />
    </React.Fragment>
  );
};

Workspace.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withTranslation()(withRouter(Workspace));
