import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { isEmpty } from "lodash";

import {
    Button,
    Card,
    CardBody, CardHeader, CardSubtitle, CardTitle,
    Col,
    Container, Input, Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row, Table,
} from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";

/** Import Full Calendar  */
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";

//import images
// import calendar from "../../assets/images/undraw-calendar.svg";

import moment from 'moment'
import {getResources as onGetResources} from "../../../store/settings/Assets/actions";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

//i18n
import { useTranslation } from "react-i18next";

import {
    addNewEvent as onAddNewEvent,
    deleteEvent as onDeleteEvent,
    getCategories as onGetCategories,
    getEvents as onGetEvents,
    updateEvent as onUpdateEvent,
} from "../../../store/actions";

// import DeleteModal from "./DeleteModal";

//css
import "@fullcalendar/bootstrap/main.css";

//redux
import { useSelector, useDispatch } from "react-redux";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
    SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import {Link} from "react-router-dom";
import {useLocation} from "react-router";
import {getResources} from "../../../store/settings/Assets/actions";

interface AssetsProps {
    className: string;
}
// TODO: 函数头
const Assets = ({ className }: AssetsProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { state } = useLocation();

    const localState:any = state;

    const columns = [
        {
            dataField: "Server",
            text: t("Provider"),
            sort: true,
        },
        {
            dataField: "Apps",
            text: t("Apps"),
            sort: true,
        },
        {
            dataField: "User",
            text: t("User"),
            sort: true,
        },
        {
            dataField: "Belong",
            text: t("assets_Belong"),
            sort: false,
        },
        {
            dataField: "Name",
            text: t("th_Name"),
            sort: true,
        },
        {
            dataField: "CreateTime",
            text: t("th_createdTime"),
            sort: true,
        },{
            dataField: "Tag",
            text: t("Tag"),
            sort: true,
        },{
            dataField: "Type",
            text: t("th_Type"),
            sort: false,
        },{
            dataField: "Format",
            text: t("th_Scope"),
            sort: true,
        },
        {
            dataField: "Size",
            text: t("th_Size"),
            sort: false,
        },
        {
            dataField: "Preview",
            text: t("th_Preview"),
            sort: false,
        },
        {
            dataField: "URL",
            text: t("th_URL"),
            sort: false,
        },
        {
            dataField: "Operator",
            text: t("th_Operator"),
            sort: false,
        }
    ];

    useEffect(() => {
        dispatch(onGetResources());
    }, [dispatch]);

    let { result } = useSelector(function (state: any) {
        return {
            result: state.Resources.resources,
        }
    });

    if(!result){
        result = [];
    }

    let localId:string;

    let handleResult:any = ((result:any,index:number)=>{
        localId = result.owner+'/'+result.name
        result.name = (<Link to={{pathname:'/apps-provideradd', state:{id:localId,ifAdd:false}}}>{result.name}</Link>)
        result.createdTime = moment(result.createdTime).format('YYYY-MM-DD HH:mm:ss')
        result.logo = (<img src={result.logo} alt="" width='120'/>)
        result.Operator = returnButton(localId)
    })

    function returnButton(id:string): any{
        return (
            <div className="d-flex flex-wrap gap-2">
                {/*<button
                    type="button"
                    className="btn btn-outline-warning waves-effect waves-light"
                >
                    {t("OP_Edit")}
                </button>*/}
                <Link to={{pathname:'/apps-useradd', state:{id:id,ifAdd:false}}} className="btn btn-outline-warning waves-effect waves-light">
                    {t("OP_Edit")}
                </Link>
                <button
                    type="button"
                    className="btn btn-outline-danger waves-effect waves-light"
                >
                    {t("OP_Del")}
                </button>
            </div>);
    }

    function returnSwitch(ifChecked:boolean,index:number): any{
        return (
            <div className="d-flex flex-wrap gap-2">
                <Input type="checkbox" id={"switch"+index} switch="none" defaultChecked={ifChecked} />
                <Label className="me-1" htmlFor={"switch"+index} data-on-label="On" data-off-label="Off"></Label>
            </div>);
    }

    // TODO 需要处理返回值
    for(let i = 0;i<result.length;i++){
        handleResult(result[i],i)
    }

    // Table Data
    let productData:Array<any> = [];

    if(result.length > 0) {
        productData = result;
    }

    const pageOptions: any = {
        sizePerPage: 10,
        totalSize: productData.length, // replace later with size(customers),
        custom: true,
    };

    const { SearchBar } = Search;

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{t("Assets")}</title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumbs title={t("Authentication")} breadcrumbItem={t("Assets")} />
                    <Card>
                        <CardBody>
                            <PaginationProvider
                                pagination={paginationFactory(pageOptions)}
                                // columns={columns}
                                // data={productData}
                            >
                                {({ paginationProps, paginationTableProps }) => (
                                    <ToolkitProvider
                                        keyField="id"
                                        columns={columns}
                                        data={productData}
                                        search
                                    >
                                        {toolkitProps => (
                                            <React.Fragment>
                                                <Row className="mb-2">
                                                    <Col md="3">
                                                        <div className="search-box me-2 mb-2 d-inline-block">
                                                            <div className="position-relative">
                                                                <SearchBar {...toolkitProps.searchProps} />
                                                                <i className="bx bx-search-alt search-icon" />
                                                            </div>

                                                        </div>
                                                    </Col>
                                                </Row>
                                                <div className="d-flex flex-wrap gap-2">
                                                    <button
                                                        type="button"
                                                        className="btn btn-success"
                                                    >
                                                        {t("OP_Upload")}(.xlsx)
                                                    </button>
                                                </div>
                                                <Row>
                                                    <Col xl="12">
                                                        <div className="table-responsive">
                                                            <BootstrapTable
                                                                // responsive
                                                                bordered={false}
                                                                striped={false}
                                                                classes={"table align-middle table-nowrap"}
                                                                headerWrapperClasses={"thead-light"}
                                                                {...toolkitProps.baseProps}
                                                                {...paginationTableProps}
                                                            />

                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className="align-items-md-center mt-30">
                                                    <Col className="inner-custom-pagination d-flex">
                                                        <div className="d-inline">
                                                            <SizePerPageDropdownStandalone
                                                                {...paginationProps}
                                                            />
                                                        </div>
                                                        <div className="text-md-right ms-auto">
                                                            <PaginationListStandalone
                                                                {...paginationProps}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        )}
                                    </ToolkitProvider>
                                )}
                            </PaginationProvider>
                        </CardBody>
                    </Card>
                </Container>
            </div>

        </React.Fragment>
    );
};

export default Assets;
