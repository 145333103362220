import { CharacterTypes } from "./actionTypes";

export const getEvents = () => ({
  type: CharacterTypes.GET_EVENTS,
});

export const apiSuccess = (actionType : any, data : any) => ({
  type: CharacterTypes.API_SUCCESS,
  payload: { actionType, data },
});

export const apiFail = (actionType : any, error : any) => ({
  type: CharacterTypes.API_FAIL,
  payload: { actionType, error },
});

export const addNewEvent = (event : any) => ({
  type: CharacterTypes.ADD_NEW_EVENT,
  payload: event,
});

export const updateEvent = (event : any) => ({
  type: CharacterTypes.UPDATE_EVENT,
  payload: event,
});

export const updateEventSuccess = (event : any) => ({
  type: CharacterTypes.UPDATE_EVENT_SUCCESS,
  payload: event,
});

export const updateEventFail = (error : any) => ({
  type: CharacterTypes.UPDATE_EVENT_FAIL,
  payload: error,
});

export const deleteEvent = (event : any) => ({
  type: CharacterTypes.DELETE_EVENT,
  payload: event,
});

export const deleteEventSuccess = (event : any) => ({
  type: CharacterTypes.DELETE_EVENT_SUCCESS,
  payload: event,
});

export const deleteEventFail = (error : any) => ({
  type: CharacterTypes.DELETE_EVENT_FAIL,
  payload: error,
});

export const getCategories = () => ({
  type: CharacterTypes.GET_CATEGORIES,
});

export const getCategoriesSuccess = (categories : any) => ({
  type: CharacterTypes.GET_CATEGORIES_SUCCESS,
  payload: categories,
});

export const getCategoriesFail = (error : any) => ({
  type: CharacterTypes.GET_CATEGORIES_FAIL,
  payload: error,
});

export const getCharacter = () => ({
  type: CharacterTypes.GET_CHARACTER,
});

export const getCharacterSuccess = (characterinfo : any) => ({
  type: CharacterTypes.GET_CHARACTER_SUCCESS,
  payload: characterinfo,
});

export const getCharacterFail = (error : any) => ({
  type: CharacterTypes.GET_CHARACTER_FAIL,
  payload: error,
});

export const getRole = (id : string) => ({
  type: CharacterTypes.GET_ROLE,
  payload: id
});

export const getRoleSuccess = (roleinfo : any) => ({
  type: CharacterTypes.GET_ROLE_SUCCESS,
  payload: roleinfo,
});

export const getRoleFail = (error : any) => ({
  type: CharacterTypes.GET_ROLE_FAIL,
  payload: error,
});
