import { combineReducers } from "redux"

// Layout
import Layout from "./layout/reducer";

// Calendar
import Calendar from "./calendar/reducer";

//Chat
import Chat from "./chat/reducer";

//Invoices
import Invoices from "./invoices/reducer";

//Contact
import contacts from "./contacts/reducer";

//login
import login from "./auth/login/reducer";

//register
import register from "./auth/register/reducer";

// User Profile 
import profile from "./auth/profile/reducer";

// Forget Password
import forgetPassword from "./auth/forgetpwd/reducer";

// Organize
import Organize from "./apps/Organize/reducer";

// User
import User from "./apps/User/reducer";

// Character
import Role from "./apps/Character/reducer";

// Permissions
import Permission from "./apps/Authority/reducer";

// Settings
import Apps from "./settings/Apps/reducer";
import Providers from "./settings/Provider/reducer";
import Resources from "./settings/Assets/reducer";
import Tokens from "./settings/Tokens/reducer";
import Records from "./settings/Records/reducer";
import Webhooks from "./settings/Webhooks/reducer";
import Syncers from "./settings/Syncers/reducer";
import Certs from "./settings/Certs/reducer";

// akenza
import deviceType from "./akenza/deviceType/reducer";
import deviceConnector from "./akenza/deviceConnector/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Calendar,
  Chat,
  Invoices,
  contacts,
  login,
  register,
  profile,
  forgetPassword,
  Organize, User, Role, Permission,
  Apps, Providers, Resources, Tokens,
  Records, Webhooks,
  Syncers, Certs,
  deviceType, deviceConnector
})

export default rootReducer
