// i18n
import { useTranslation } from 'react-i18next';
// Component
import { Link } from 'react-router-dom';

export type LinkConfiguration = {
  path: string;
  description?: string;
  iconClassName?: string;
  selected?: boolean;
};

type Props = LinkConfiguration;

export default ({ path, description, iconClassName, selected }: Props) => {
  let { t } = useTranslation();

  return (
    <Link to={path}>
      <div
        className={`link-container px-12 py-2 rounded-2 
        text-secondary 
        ${selected && 'selected'}`}>
        <i className={`me-2 ${iconClassName}`}></i>
        <span className="fw-bold">{t(description || '')}</span>
      </div>
    </Link>
  );
};
