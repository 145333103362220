import { ActionTypes } from "./actionTypes";

export const addDeviceType = (data: any, history: any) => {
  return {
    type: ActionTypes.ADD_DEVICE_TYPE,
    payload: { data, history },
  };
};

export const updateDeviceType = (id: string, data: any, history: any) => {
  return {
    type: ActionTypes.UPDATE_DEVICE_TYPE,
    payload: { id, data, history },
  };
};

export const addDeviceTypeSuccess = (data: any) => {
  return {
    type: ActionTypes.ADD_DEVICE_TYPE_SUCCESS,
    payload: data,
  };
};

export const addDeviceTypeError = (error: any) => {
  return {
    type: ActionTypes.ADD_DEVICE_TYPE_ERROR,
    payload: "创建设备类型失败",
  };
};

export const getDeviceTypeList = (data: any, history: any) => {
  return {
    type: ActionTypes.GET_DEVICE_TYPE_LIST,
    payload: { data, history },
  };
};


export const getDeviceTypeListSuccess = (data: any) => {
  return {
    type: ActionTypes.GET_DEVICE_TYPE_LIST_SUCCESS,
    payload: {list: data.Data, Total: data.Total},
  };
};

export const getDeviceTypeListError = (error: any) => {
  return {
    type: ActionTypes.GET_DEVICE_TYPE_LIST_ERROR,
    payload: "获取设备类型列表失败",
  };
};

export const deleteDeviceTypeList = (data:any, history: any) => {
  return {
    type: ActionTypes.DELETE_DEVICE_TYPE,
    payload: { data, history },
  };
};

export const deleteDeviceTypeSuccess = (data: any) => {
  return {
    type: ActionTypes.DELETE_DEVICE_TYPE_SUCCESS,
    payload: { data, history },
  };
};
export const deleteDeviceTypeError = (data: any) => {
  return {
    type: ActionTypes.DELETE_DEVICE_TYPE_ERROR,
    payload: data,
  };
};

