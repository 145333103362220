import React, {ReactNode} from "react";

type Props = {
    className: string | false;
    style: React.CSSProperties;
    children: ReactNode;
}

export default ({className, style, children}: Partial<Props>) => {
    return (<React.StrictMode>
        <div className={`px-5 py-4 w-100 h-100 ${className}`} style={style}>
            {children}
        </div>
    </React.StrictMode>)
}
