/**
 *  interface for Device Type
*/

export enum ActionTypes {
  ADD_DEVICE_TYPE = '@@deviceType/ADD_DEVICE_TYPE',
  UPDATE_DEVICE_TYPE = '@@deviceType/UPDATE_DEVICE_TYPE',
  ADD_DEVICE_TYPE_SUCCESS = '@@deviceType/ADD_DEVICE_TYPE_SUCCESS',
  ADD_DEVICE_TYPE_ERROR = '@@deviceType/ADD_DEVICE_TYPE_ERROR',
  GET_DEVICE_TYPE_LIST = '@@deviceType/GET_DEVICE_TYPE_LIST',
  GET_DEVICE_TYPE_LIST_SUCCESS = '@@deviceType/GET_DEVICE_TYPE_LIST_SUCCESS',
  GET_DEVICE_TYPE_LIST_ERROR = '@@deviceType/GET_DEVICE_TYPE_LIST_ERROR',
  DELETE_DEVICE_TYPE = '@@deviceType/DELETE_DEVICE_TYPE',
  DELETE_DEVICE_TYPE_SUCCESS = '@@deviceType/DELETE_DEVICE_TYPE_SUCCESS',
  DELETE_DEVICE_TYPE_ERROR = '@@deviceType/DELETE_DEVICE_TYPE_ERROR',
}
