import React, { useContext } from "react";
//i18n
import { withTranslation } from "react-i18next";

import { withRouter } from "react-router-dom";

import { Input, InputGroup, InputGroupText } from "reactstrap";
import { insure } from "src/util";
import { ACTION_TYPE } from "../../contant";
import { DataFlowContext } from "../../reducer";

const OutputConnectorSelected = (props: any) => {
    const { t, onAddHandle, onEdit, onClear } = props;

    const { dispatch, state: { outputConnectors } } = useContext(DataFlowContext);

    const handleClear = (row: any) => {
        const action = {
            type: ACTION_TYPE.REMOVE_OUTPUT_CONNECTOR,
            payload: row.id,
        }
        dispatch(action)

        onClear && onClear()
    }
    const renderComponent = (data:any) => {
        const component: any = {
            "CUSTOM_METAVUN_DB": <main className="border-top border-bottom px-3 py-2" hidden={!open}>
                <div className="mt-narrow-pre text-secondary text-capitalize">
                    <span className="me-2  text-capitalize">{t("topic")}:</span>
                    <span>{data.attribute?.topic}</span>
                </div>
                {
                    data.attribute?.use_current_device ? <div className="mt-narrow-pretext-secondary">
                        <span className="me-2  text-capitalize">{t("use current device")}:</span>
                        <span>{"True"}</span>
                    </div> : <div className="mt-narrow-pretext-secondary">
                        <span className="me-2  text-capitalize">{t("device")}:</span>
                        <span>{data.attribute?.target_device_id}</span>
                    </div>
                }
            </main>,
            "INFLUXDB": <main className="border-top border-bottom px-3 py-2" hidden={!open}>
                <div className="mt-narrow-pre text-secondary text-capitalize">
                    <span className="me-2  text-capitalize">{t("InfluxDB Url")}:</span>
                    <span>{data.attribute?.uri}</span>
                </div>
                <div className="mt-narrow-pre text-secondary text-capitalize">
                    <span className="me-2  text-capitalize">{t("InfluxDB Org")}:</span>
                    <span>{data.attribute?.org}</span>
                </div>
                <div className="mt-narrow-pre text-secondary text-capitalize">
                    <span className="me-2  text-capitalize">{t("InfluxDB Bucket")}:</span>
                    <span>{data.attribute?.bucket}</span>
                </div>
                <div className="mt-narrow-pre text-secondary text-capitalize">
                    <span className="me-2  text-capitalize">{t("Measurement Name")}:</span>
                    <span>{data.attribute?.measurement_name}</span>
                </div>
            </main>,
            "KAFKA": <main className="border-top border-bottom px-3 py-2" hidden={!open}>
                <div className="mt-narrow-pre text-secondary text-capitalize">
                    <span className="me-2  text-capitalize">{t("Kafka Broker")}:</span>
                    <span>{data.attribute?.broker}</span>
                </div>
                <div className="mt-narrow-pre text-secondary text-capitalize">
                    <span className="me-2  text-capitalize">{t("Kafka Topic")}:</span>
                    <span>{data.attribute?.topic}</span>
                </div>
                <div className="mt-narrow-pre text-secondary text-capitalize">
                    <span className="me-2  text-capitalize">{t("Security Protocol")}:</span>
                    <span>{data.attribute?.security_protocol}</span>
                </div>
                <div className="mt-narrow-pre text-secondary text-capitalize">
                    <span className="me-2  text-capitalize">{t("SASL Mechanism")}:</span>
                    <span>{data.attribute?.sasl_mechanism}</span>
                </div>
            </main>,
            "EMAIL": <main className="border-top border-bottom" hidden={!open}>
                <div className="px-3 py-2">
                    <div className="mt-narrow-pre text-secondary text-capitalize">
                        <span className="me-2  text-capitalize">{t("Recipients")}:</span>
                        <span>{data.attribute?.recipients}</span>
                    </div>
                </div>
                <div className="bg-soft-light px-3 py-2 mt-narrow-pre text-secondary text-capitalize">
                    <div className="mt-1">{t("Subject")}</div>
                    <div className="me-2 mt-2 text-capitalize text-dark" style={{ fontWeight: 'bold' }}>{data.attribute?.subject}</div>
                    <div className="mt-3">{t("Message")}</div>
                    <div className="me-2 mt-2 text-capitalize text-dark">{data.attribute?.body}</div>
                </div>
            </main>
        } 
        return component[data.type]
    }
    return (
        <React.StrictMode>
            <div>
                <header className="flex-grow-0 flex-shrink-0 mb-4 font-size-16">
                    <div className="flex-row items-center">
                        <div className="first-letter-upper">{t("output connector")}</div>
                        <button
                            type="button"
                            onClick={onAddHandle}
                            className="btn btn-outline-light waves-effect font-size-16 ml-2"
                        >
                            + {" "}<span className="first-letter-upper">{props.t('add connector')}</span>
                        </button>
                    </div>
                </header>
                <main>
                    <div className="d-flex mb-3">
                        <div className="me-2 font-size-16 fw-bold text-capitalize">{t("topic")}</div>
                        <div>
                            <InputGroup className="border rounded-2 border-blue-hover" size="sm">
                                <Input className="border-0 font-size-14" placeholder={t("Please select a topic")} />
                                <InputGroupText className="border-0 bg-white">
                                    <i className="fas fa-angle-down" />
                                </InputGroupText>
                            </InputGroup>
                        </div>
                    </div>
                    {
                        outputConnectors.map((row: any) => <div key={row.id}
                            className={`d-flex flex-column gutter-t-normal py-2 border rounded-2 bg-white shadow cursor-pointer`}>
                            <header className="flex-grow-0 flex-shrink-0 border-bottom px-3 pb-2">
                                <i className="mdi mdi-link-variant me-2" />
                                <span className="me-2 font-size-16 fw-bold">{row.name}</span>
                                <span></span>
                            </header>
                            {renderComponent(row)}
                            <footer className="px-3 py-2 clearfix">
                                <div className="float-start text-primary text-capitalize cursor-pointer"
                                   onClick={() => handleClear(row)}>
                                    {t("clear")}
                                </div>
                                <div className="float-end text-primary text-capitalize cursor-pointer"
                                    onClick={insure(onEdit, row)}>
                                    {t("edit")}
                                </div>
                            </footer>
                        </div>)
                    }
                </main>
            </div>
        </React.StrictMode>
    )
}

export default withTranslation()(withRouter(OutputConnectorSelected));
