import { Fragment } from 'react';
// i18n
import { useTranslation } from 'react-i18next';
// Component
import { Input, InputGroup, InputGroupText } from 'reactstrap';
// Util
import { insure } from 'src/util';

type Props = {
  areaName: string;
  onClose: Function;
  onSearch: Function;
};

export default ({ areaName, onClose, onSearch }: Partial<Props>) => {
  const { t } = useTranslation();
  const searchBarPlaceholder = t(`Search ${areaName}`);

  return (
    <Fragment>
      <div className="px-4 pt-4 pb-3 font-size-14 clearfix">
        <span className="float-start text-capitalize">
          {t(`search to another ${areaName}`)}
        </span>
        <i
          className="fas fa-times float-end lh-lg cursor-pointer"
          onClick={insure(onClose)}
        />
      </div>
      <InputGroup className="border-0 px-4 pb-2">
        <InputGroupText className="border-0 ps-0 cus-bg-white">
          <i className="fas fa-search" />
        </InputGroupText>
        <Input
          className="border-0 px-1 cus-bg-white"
          placeholder={searchBarPlaceholder}
          onChange={e => onSearch && onSearch(e.target.value)}
        />
      </InputGroup>
    </Fragment>
  );
};
