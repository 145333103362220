import { takeEvery, put, call, all, fork } from "redux-saga/effects"

// Calender Redux States
import {RecordsState, RecordsTypes} from "./actionTypes"
import {
  getRecords, getRecordsSuccess, getRecordsFail
} from "./actions"

//Include Both Helper File with needed methods
import {
  api_getRecords,
} from "../../../API/api"

// 获取全部用户信息
function* onGetRecords() {
  try {
    const response : Promise<any> = yield call(api_getRecords)
    yield put(getRecordsSuccess(response))
  } catch (error) {
    yield put(getRecordsFail(error))
  }
}

export function* watchOnGetRecords() {
  yield takeEvery(RecordsTypes.GET_RECORDS, onGetRecords);
}

function* recordsSaga() {
  yield all([fork(watchOnGetRecords)]);
}

export default recordsSaga
