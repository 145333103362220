import { Key } from 'react';
// i18n
import { useTranslation } from 'react-i18next';

type Props = {
  id: Key;
  title: string;
  description: string;
  iconClassName: string;
  onSelect: Function;
};

export default ({
  id,
  title = 'title',
  description = '',
  iconClassName,
  onSelect,
}: Partial<Props>) => {
  const { t } = useTranslation();

  const handleClick = () => {
    onSelect && onSelect(id);
  };

  return (
    <div
      className="d-flex gutter-t-normal
      border border-blue-hover px-2 py-2 rounded-2
      cursor-pointer"
      onClick={handleClick}>
      <div
        className="flex-grow-0 flex-shrink-0
        me-2 rounded-circle px-1 py-1
        text-center lh-1">
        <i className={iconClassName} />
      </div>
      <div className="flex-grow-1">
        <header className="mb-2 font-size-16 fw-bold">{title}</header>
        <main className="text-secondary first-letter-upper">
          {t(description)}
        </main>
      </div>
    </div>
  );
};
