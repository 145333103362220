import { CREATE_MODE } from '../../contant';

export default [
    {
        id: CREATE_MODE.DEVICE_SIMULATOR,
        iconClassName: 'fas fa-flask text-danger',
        title: 'Device Simulator',
        description: `use this template if you have no physical device at hand, but still want to test out the Metavun platform.`,
    },
    {
        id: CREATE_MODE.HTTP,
        iconClassName: 'fas fa-passport text-danger',
        title: 'http',
        description: `use this template to receive data from an HTTP endpoint and store it in to the Metavun database.`,
    },
    {
        id: CREATE_MODE.MQTT,
        iconClassName: 'fas fa-wifi text-danger',
        title: 'mqtt',
        description: `use this template  to receive data from a MQTT-client and store it in to the Metavun database.`,
    },
];
