import {Key, StrictMode} from "react";

import {insure} from "src/util";

type Props = {
    value: Key;
    label: Key;
    onClick: Function;
    disable: boolean;
}

export default ({value, label, onClick, disable}: Partial<Props>) => {
    return (<StrictMode>
        <div className={`mt-narrow-pre border border-blue-hover rounded-2 px-2 py-2 font-size-16
        ${disable && "pointer-events-none bg-light text-secondary"}`}
             onClick={insure(onClick, value)}>
            <i className="me-2 mdi mdi-link-variant"/>
            <span className="fw-bold text-capitalize">{label || value}</span>
        </div>
    </StrictMode>)
}
