import React, { useState } from 'react';
//i18n
import { withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';

import DataFlowsTable from './DataFlowsTable';
import DeviceConnectorsTable from './DeviceConnectorsTable';
import OutputConnectorsTable from './OutputConnectorsTable';

import './dataFlowsList.scss';

export default withTranslation()(
  withRouter((props: any) => {
    let { t, history, match } = props;
    const { orgId, wsId } = match.params;
    const { user } = useSelector((state: any) => ({
      user: state.login.user,
    }));
    const role =
      user.user_workspaces.find((ws: any) => ws.workspace.id == wsId)?.role ||
      'viewer';

    const [activeTab, setActiveTab] = useState(1);

    const handleTableChange = (tab: number) => {
      setActiveTab(tab);
    };

    return (
      <React.StrictMode>
        <div className="px-5 py-4" {...props}>
          <header className="d-flex justify-content-between mb-4">
            <h4 className="lh-lg">{t('DataFlows')}</h4>
            {role == 'viewer' ? (
              <Button className="fw-bold" color="primary" disabled>
                {t('Create Data Flow')}
              </Button>
            ) : (
              <Link to={`/org/${orgId}/ws/${wsId}/data-flows/new`}>
                <Button className="fw-bold" color="primary">
                  {t('Create Data Flow')}
                </Button>
              </Link>
            )}
          </header>
          <nav
            className="flex-row mb-5 pb-2 border-bottom justify-between"
            style={{ width: '450px' }}>
            <span
              className={`me-3 pb-2 font-size-16 cursor-pointer ${
                activeTab == 1 && 'activeTab'
              }`}
              onClick={() => handleTableChange(1)}>
              {t('Overview')}
            </span>
            <span
              className={`me-3 pb-2 font-size-16 cursor-pointer ${
                activeTab == 2 && 'activeTab'
              }`}
              onClick={() => handleTableChange(2)}>
              {t('Device Connectors')}
            </span>
            <span
              className={`me-3 pb-2 font-size-16 cursor-pointer ${
                activeTab == 3 && 'activeTab'
              }`}
              onClick={() => handleTableChange(3)}>
              {t('Output Connectors')}
            </span>
          </nav>
          <main>
            {activeTab == 1 && <DataFlowsTable />}
            {activeTab == 2 && <DeviceConnectorsTable />}
            {activeTab == 3 && <OutputConnectorsTable />}
          </main>
        </div>
      </React.StrictMode>
    );
  }),
);
