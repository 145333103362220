/**
 * interface for userinfo type
 */
 export enum CharacterTypes {
    API_SUCCESS = '@@character/API_SUCCESS',
    API_FAIL = '@@character/API_FAIL',
    GET_EVENTS = '@@character/GET_EVENTS',
    ADD_NEW_EVENT = '@@character/ADD_NEW_EVENT',
    UPDATE_EVENT = '@@character/UPDATE_EVENT',
    UPDATE_EVENT_SUCCESS = '@@character/UPDATE_EVENT_SUCCESS',
    UPDATE_EVENT_FAIL = '@@character/UPDATE_EVENT_FAIL',
    DELETE_EVENT = '@@character/DELETE_EVENT',
    DELETE_EVENT_SUCCESS = '@@character/DELETE_EVENT_SUCCESS',
    DELETE_EVENT_FAIL = '@@character/DELETE_EVENT_FAIL',
    GET_CATEGORIES = '@@character/GET_CATEGORIES',
    GET_CATEGORIES_SUCCESS = '@@character/GET_CATEGORIES_SUCCESS',
    GET_CATEGORIES_FAIL = '@@character/GET_CATEGORIES_FAIL',
    GET_CHARACTER = '@@character/GET_USERINFO',
    GET_CHARACTER_SUCCESS = '@@character/GET_USERINFO_SUCCESS',
    GET_CHARACTER_FAIL = '@@character/GET_USERINFO_FAIL',
    GET_ROLE = '@@character/GET_ROLE',
    GET_ROLE_SUCCESS = '@@character/GET_ROLE_SUCCESS',
    GET_ROLE_FAIL = '@@character/GET_ROLE_FAIL',
 }

 export interface CharacterState {
   events  : Array<any>;
   categories : Array<any>;
   error : Object;
}