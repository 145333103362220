import {Key, StrictMode, useState} from "react";

import {useTranslation} from "react-i18next";

import {Input, ModalBody} from "reactstrap";

import {insure, validateDescription, validateName} from "src/util";

type Props = {
    name: Key;
    changeName: Function;
    description: string;
    changeDescription: Function;
}

export default ({name, changeName, description, changeDescription}: Partial<Props>) => {
    const {t} = useTranslation()

    const handleNameChange = (newName: string) => {
        if (!validateName(newName)) return;

        insure(changeName, newName)()
    }

    const [words, setWords] = useState(description?.length || 0)

    const handleDescriptionInputChange = (newDescription: string) => {
        if (!validateDescription(newDescription)) return;

        insure(changeDescription, newDescription)()
        setWords(newDescription.length)
    }

    return (<StrictMode>
        <ModalBody>
            <div className="mb-3">
                <div className="mb-2 ext-capitalize">{t("Name")}</div>
                <Input value={name} onChange={e => handleNameChange(e.target.value)}/>
            </div>
            <div>
                <div className="mb-2">
                    <span className="me-2 text-capitalize">{t("description")}</span>
                    <span className="text-secondary">({t("optional")})</span>
                </div>
                <Input value={description} disabled={words > 499}
                       onChange={e => handleDescriptionInputChange(e.target.value)}/>
                <div className="clearfix">
                    <span className="float-end">{words}/500</span>
                </div>
            </div>
        </ModalBody>
    </StrictMode>)
}
