import { Key, StrictMode, useEffect, useRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import { insure } from "src/util";
import { includes } from "lodash";
import { RuleContext } from "../../../reducer";
import Point, { PointProps } from "../../../components/point"
type Props = {
    iconClassName: string;
    onClear: Function;
    data: any;
    change: string;
}

export default ({ data, iconClassName, onClear, change }: Props) => {
    const { t } = useTranslation();
    const { state: { logicState }, dispatch } = useContext(RuleContext);
    const { device_id, id, name, description, availableSensors } = data;
    const logicSelectedPoint: any = logicState.selectedPoint;
    let dataSources: any = logicState.data_sources;
    const handleInputCheck = (x: number, y: number) :void=> {
        let point = { id, left: x, top: y };
        if (logicSelectedPoint && logicSelectedPoint.id) {
            let find = dataSources.find((o: any) => o.id == logicSelectedPoint.id);
           
            if (includes(find.input_ids, id)) {
                let ids = find.input_ids.filter((o: string) => o != id);
                find.input_ids = ids;
            } else {
                find.input_ids.push(id);
            }
            const action = {
                type: "UPDATE_DATA_SOURCE",
                payload: {...find}
            }
            dispatch(action);
        } else {
            const action = {
                type: "SELECT_INPUT",
                payload: point,
            }
            dispatch(action);
        }
    }

    const updatePointOffset = (x: number, y: number) :void => {
        data.offsetX = x;
        data.offsetY = y;
        const action = {
            type: "UPDATE_INPUT",
            payload: data
        }
        dispatch(action);
    }

    const pointProps: PointProps = {
        updateOffect: updatePointOffset,
        change: change,
        pointCheck: handleInputCheck,
        style: {right: -14}
    }

    return (<StrictMode>
        <div className="mt-sparse-pre border rounded-2 cus-bg-white shadow">
            <header className="border-bottom px-2 py-2" style={{ position: "relative" }}>
                <i className={`me-2 ${iconClassName}`}></i>
                <span className="me-2 fw-bold">{name}</span>
                <span>{device_id}</span>
                <Point {...pointProps} />
            </header>
            {(description || availableSensors) && <main className="border-bottom px-2 py-2">
                {description && <div className="mt-narrow-pre">
                    <div className="text-secondary text-capitalize">{t("description")}:</div>
                    <div>
                        {description}
                    </div>
                </div>}
                {availableSensors && availableSensors.map((sensor: string) =>
                    <div key={sensor} className="mt-narrow-pre">
                        <div className="text-secondary text-capitalize">{t("available sensors")}:</div>
                        <span className="me-2">
                            {sensor}
                        </span>
                    </div>)}
            </main>}
            <footer className="px-2 py-2">
                <span className="text-primary text-capitalize" onClick={insure(onClear, id)}>
                    {t("clear")}
                </span>
            </footer>
        </div>
    </StrictMode>)
}
