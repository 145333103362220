import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { RuleContext } from "../../../../reducer";
import { Input } from "reactstrap";
import { includes } from "lodash";
import Point from "../../../../components/point"
export default () => {
  const { t } = useTranslation();
  const { state: { actionSelectedPoint, logicState }, dispatch } = useContext(RuleContext);
  const custom_logic: any = logicState.custom_logic;

  const handlePropertieCheck = (x: number, y: number) => {
    let point = { id: custom_logic.custom_logic_id, left:x, top:y };
    if (actionSelectedPoint && actionSelectedPoint.id) {
      if (includes(custom_logic.action_ids, actionSelectedPoint.id)) {
        let ids = custom_logic.action_ids.filter((o: string) => o != actionSelectedPoint.id);
        custom_logic.action_ids = ids;
      } else {
        custom_logic.action_ids.push(actionSelectedPoint.id);
      }
      const action = {
        type: "UPDATE_CUSTOM_LOGIC",
        payload: custom_logic,
      }
      dispatch(action);
    } else {
      const action = {
        type: "SELECT_CUSTOM_LOGIC",
        payload: point,
      }
      dispatch(action);
    }
  }
  const handleChange = (value: string, index: number) => {
    custom_logic.properties[index].value = value;
    const action = {
      type: "UPDATE_CUSTOM_LOGIC",
      payload: custom_logic
    }
    dispatch(action);
  }
  const updatePointOffset = (x: number, y: number) => {
    custom_logic.offsetX = x;
    custom_logic.offsetY = y;
    const action = {
      type: "UPDATE_CUSTOM_LOGIC",
      payload: custom_logic,
    }
    dispatch(action);
  }
  return (
    <div className="py-2 mt-3">
      {
        custom_logic.properties?.map((p: any, index: number) =>
          <div key={index} className="mb-3">
            <div className="mb-2">{p.variable_name}</div>
            <Input onChange={(e) => { handleChange(e.target.value, index) }} className="form-control" placeholder={t("Enter topic")} style={{ height: "25px", width: "100%" }} type="text" defaultValue={p.value} id="example-text-input" />
          </div>
        )
      }
      <div className="mt-4" style={{ position: "relative" }}>
        <Point change={"change"} updateOffect={updatePointOffset} pointCheck={handlePropertieCheck} style={{ right: "-29px" }} />
        <div className="mb-2">{t('Description')}</div>
        <div>{custom_logic.description}</div>
      </div>
    </div>
  )
}
