import { useContext } from 'react';
// i18n
import { useTranslation } from 'react-i18next';
// Reduce
import DefaultContent from './DefaultContent';
import { DataFlowContext } from '../../../../reducer';

export type Type = 'text';

export default (props: any) => {
  const { t } = useTranslation();

  const { onClear, type } = props;

  const {
    state: { deviceType },
  }: { state: any } = useContext(DataFlowContext);

  const {
    id,
    name,
    description,
    deviceManufacturer,
    connectivity,
    firmware_version,
    pictureUrl,
  } = deviceType;

  const handleClear = () => {
    onClear && onClear();
  };

  const defaultContentProps = {
    connectivity,
    description,
    firmware_version,
    deviceManufacturer,
    pictureUrl,
  };

  return (
    <div className="border rounded-2 shadow-lg">
      <header className="flex-grow-0 flex-shrink-0 border-bottom px-3 pb-2">
        <i className="mdi mdi-link-variant me-2" />
        <span className="me-2 font-size-16 fw-bold">{t(name)}</span>
        <span className="font-size-14">{deviceManufacturer}</span>
      </header>
      <main>
        {id == '00000000000000000' ? (
          <div className="px-3 pt-3">{t(description)}</div>
        ) : (
          <DefaultContent {...defaultContentProps} />
        )}
      </main>
      <footer className="px-3 py-2">
        <div
          className="text-primary text-capitalize cursor-pointer"
          onClick={handleClear}>
          {t('clear')}
        </div>
      </footer>
    </div>
  );
};
