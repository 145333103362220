import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { isEmpty } from "lodash";

import {
    Badge,
    Button,
    Card,
    CardBody, CardHeader, CardSubtitle, CardTitle,
    Col,
    Container, Input, Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row, Table,
} from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";

/** Import Full Calendar  */
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";

//import images


//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import {
    getPermissions as onGetPermissions
} from "../../../store/apps/Authority/actions";

//i18n
import { useTranslation } from "react-i18next";

import {
    addNewEvent as onAddNewEvent,
    deleteEvent as onDeleteEvent,
    getCategories as onGetCategories,
    getEvents as onGetEvents,
    updateEvent as onUpdateEvent,
} from "../../../store/actions";

// import DeleteModal from "./DeleteModal";

//css
import "@fullcalendar/bootstrap/main.css";

//redux
import { useSelector, useDispatch } from "react-redux";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import paginationFactory, {
    PaginationListStandalone, PaginationProvider,
    SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import {Link} from "react-router-dom";
import {useLocation} from "react-router";

interface RegistItemsProps {
    className: string;
}
// TODO: 函数头
const RegistItems = ({ className }: RegistItemsProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { state } = useLocation();

    const localState:any = state;

    const columns = [
        {
            dataField: "name",
            text: t("th_Name"),
            sort: true,
        },
        {
            dataField: "Visiable",
            text: t("th_Visiable"),
            sort: true,
        },
        {
            dataField: "Necessary",
            text: t("th_Necessary"),
            sort: true,
        },
        {
            dataField: "Hint",
            text: t("th_Hint"),
            sort: true,
        },
        {
            dataField: "Rule",
            text: t("th_Rule"),
            sort: true,
        },
        {
            dataField: "Operator",
            text: t("th_Operator"),
            sort: true,
        },
    ];

    // Table Data
    let productData:Array<any> = [];

    return (
        <React.Fragment>
            <div>
                <ToolkitProvider
                    keyField="id"
                    columns={columns}
                    data={productData}
                    search
                >
                    {toolkitProps => (
                        <React.Fragment>
                            <Row>
                                <Col xl="12">
                                    <div className="table-responsive">
                                        <BootstrapTable
                                            // responsive
                                            bordered={false}
                                            striped={false}
                                            classes={"table align-middle table-nowrap"}
                                            headerWrapperClasses={"thead-light"}
                                            {...toolkitProps.baseProps}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </React.Fragment>
                    )}
                </ToolkitProvider>
            </div>
        </React.Fragment>
    );
};

export default RegistItems;
