export enum MODE {
    GUIDE,
    EDIT,
    SELECT_ACTION_TYPE,
    SELECT_CONNECTOR,
}

export enum ACTION_TYPE_GROUP {
    DATA_SINK,
    NOTIFICATION_SERVICE,
}

export enum DATA_SINK {
    CUSTOM_METAVUN_DB,
    INFLUX_DB,
    KAFKA
}

export enum NOTIFICATION_SERVICE {
    SMS,
    EMAIL
}