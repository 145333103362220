import { Key, StrictMode, useState } from "react";

import { useTranslation } from "react-i18next";
import { Button, ModalFooter } from "reactstrap";

import { insure } from "src/util";

import WorkspaceBody from "./WorkspaceBody";

type Props = {
    name: Key;
    description: string;
    onClose: Function;
    onCancel: Function;
    onCreate: Function;
}

export default ({ onClose, onCancel, onCreate }: Partial<Props>) => {
    const { t } = useTranslation()

    const [name, setName] = useState<string>("")

    const [description, setDescription] = useState("")

    return (<StrictMode>
        <div className="modal-header">
            <h5 className="modal-title text-capitalize">{t("CreateWorkspace")}</h5>
            <button type="button" className="btn-close" aria-label="Close"
                onClick={insure(onClose)} />
        </div>
        <WorkspaceBody name={name} changeName={setName}
            description={description} changeDescription={setDescription} />
        <ModalFooter className="justify-between">
            <Button className="text-capitalize" outline
                onClick={insure(onCancel)}>
                {t("cancel")}
            </Button>
            <Button className="text-capitalize" color="primary" outline
                disabled={!name} onClick={insure(onCreate, name, description)}>
                {t("CreateWorkspace")}
            </Button>
        </ModalFooter>
    </StrictMode>)
}
