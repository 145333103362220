import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { isEmpty } from "lodash";

import {
    Button,
    Form,
    Card,
    CardBody, CardHeader, CardSubtitle, CardTitle,
    Col,
    Container, Input, Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row, Table,
} from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";

/** Import Full Calendar  */
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";

//import images
// import calendar from "../../assets/images/undraw-calendar.svg";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

//i18n
import { useTranslation } from "react-i18next";

//Import Pages
import TestRegistPage from "./TestRegistPage";
import TestLoginPage from "./TestLoginPage";
import RegistItems from "./RegistItems";
import TestHintPage from "./TestHintPage";
import ProviderPage from "./ProviderPage";

//css
import "@fullcalendar/bootstrap/main.css";

//redux
import { useSelector, useDispatch } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
    SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";

import {Link} from "react-router-dom";
import Select from "react-select";
import Dropzone from "react-dropzone";
interface AppsAddProps {
    className: string;
}
// TODO: 函数头
const AppsAdd = ({ className }: AppsAddProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [selectedGroup, setselectedGroup] = useState<any>(null);

    let redirectColumns = [
        {
            dataField: "redirectURL",
            text: t("RedirectURL"),
            sort: true,
        },
        {
            dataField: "Operator",
            text: t("th_Operator"),
            sort: true,
        },
    ];

    const optionGroup = [
        {label:"JWT",value:"JWT"}
    ];

    const organizeSelect = [
        { label: "built-in", value: "built-in"},
    ]

    const certSelect = [
        { label: "cert-built-in", value: "cert-built-in"},
    ]

    function handleSelectGroup(selectedGroup: any) {
        setselectedGroup(selectedGroup);
    }

    // Table Data
    let redirectData:Array<any> = [];

    // ===============
    // 点击上传逻辑
    const [selectedFiles, setselectedFiles] = useState<any>([]);

    function handleAcceptedFiles(files: any) {
        files.map((file: any) =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            })
        );
        setselectedFiles(files);
    }

    /**
     * Formats the size
     */
    function formatBytes(bytes: any, decimals = 2) {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }
    // ===============
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{t("appsAdd")}</title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumbs title={t("Authentication")} breadcrumbItem={t("appsAdd")} />
                    <Card>
                        <CardBody>
                            <div className="mb-3 d-flex flex-wrap gap-2">
                                <button
                                    type="button"
                                    className="btn btn-outline-primary waves-effect waves-light"
                                >
                                    {t("OP_Save")}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-success"
                                >
                                    {t("OP_SaveAndExit")}

                                </button>
                                {/*<button
                                    type="button"
                                    className="btn btn-soft-warning waves-effect waves-light"
                                >
                                    {t("OP_Cancel")}
                                </button>*/}
                                <Link to="/page-apps" className="btn btn-secondary">
                                    {t("OP_Cancel")}
                                </Link>
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="name" className="form-Label">{t("th_Name")}</Label>
                                <i className="bx bx-help-circle" title={t("th_Name")} />
                                <Input className="name" type="text" defaultValue="" id="name" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="displayname" className="form-Label">{t("th_displayName")}</Label>
                                <i className="bx bx-help-circle" title={t("th_displayName")} />
                                <Input className="displayname" type="text" defaultValue="" id="displayname" />
                            </div>
                            <Row>
                                <Col xl={1}>
                                    <Label className="form-Label">{t("th_Logo")}</Label>
                                    <i className="bx bx-help-circle" title={t("th_Logo")} />
                                </Col>
                                <Col xl={10}>
                                    <Row>
                                        <Col xl={1}>
                                            <Label htmlFor="logourl" className="form-Label">{t("th_URL")}</Label>
                                            <i className="bx bx-help-circle" title={t("th_URL")} />
                                        </Col>
                                        <Col xl={11}>
                                            <Input className="form-control" type="text" defaultValue="" id="logourl" />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={1}>
                                            <Label htmlFor="logopreview" className="form-Label">{t("th_Preview")}</Label>
                                            <i className="bx bx-help-circle" title={t("th_Preview")} />
                                        </Col>
                                        <Col xl={3}>
                                            <img src="#" alt="" id="logopreview"/>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <div className="mb-3">
                                <Label htmlFor="homepage" className="form-Label">{t("HomePage")}</Label>
                                <i className="bx bx-help-circle" title={t("HomePage")} />
                                <Input className="form-control" type="text" defaultValue="" id="homepage" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="describe" className="form-Label">{t("Describe")}</Label>
                                <i className="bx bx-help-circle" title={t("Describe")} />
                                <Input className="form-control" type="text" defaultValue="" id="describe" />
                            </div>
                            {/*组织*/}
                            <div className="mb-3">
                                <Label className="form-Label">{t("th_Organize")}</Label>
                                <i className="bx bx-help-circle" title={t("th_Organize")} />
                                <Select
                                    value={organizeSelect}
                                    onChange={() => {
                                        handleSelectGroup(0);
                                    }}
                                    options={organizeSelect}
                                    classNamePrefix="select2-selection"
                                />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="clientID" className="form-Label">{t("th_ClientID")}</Label>
                                <i className="bx bx-help-circle" title={t("th_ClientID")} />
                                <Input className="form-control" type="text" defaultValue="" id="clientid" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="clientkey" className="form-Label">{t("th_ClientKey")}</Label>
                                <i className="bx bx-help-circle" title={t("th_ClientKey")} />
                                <Input className="form-control" type="password" defaultValue="" id="clientkey" />
                            </div>
                            {/*证书*/}
                            <div className="mb-3">
                                <Label className="form-Label">{t("Certs")}</Label>
                                <i className="bx bx-help-circle" title={t("Certs")} />
                                <Select
                                    value={certSelect}
                                    onChange={() => {
                                        handleSelectGroup(0);
                                    }}
                                    options={certSelect}
                                    classNamePrefix="select2-selection"
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-Label">{t("RedirectURL")}</Label>
                                <i className="bx bx-help-circle" title={t("RedirectURL")} />
                                <Link to="/#" className="btn btn-success btn-sm" style={{marginLeft:"10px"}}>
                                    {t("OP_AddData")}
                                </Link>
                                <ToolkitProvider
                                    keyField="id"
                                    columns={redirectColumns}
                                    data={redirectData}
                                    search
                                >
                                    {toolkitProps => (
                                        <React.Fragment>
                                            <Row>
                                                <Col xl="12">
                                                    <div className="table-responsive">
                                                        <BootstrapTable
                                                            // responsive
                                                            bordered={false}
                                                            striped={false}
                                                            classes={"table align-middle table-nowrap"}
                                                            headerWrapperClasses={"thead-light"}
                                                            {...toolkitProps.baseProps}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </React.Fragment>
                                    )}
                                </ToolkitProvider>
                            </div>
                            <div className="mb-3">
                                <Label className="form-Label">{t("AccessTokenForm")}</Label>
                                <i className="bx bx-help-circle" title={t("AccessTokenForm")} />
                                <Select
                                    value={selectedGroup}
                                    onChange={() => {
                                        handleSelectGroup(0);
                                    }}
                                    options={optionGroup}
                                    classNamePrefix="select2-selection"
                                />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="access-token-expire" className="form-Label">{t("AccessTokenExpire")}</Label>
                                <i className="bx bx-help-circle" title={t("AccessTokenExpire")} />
                                <Input className="form-control" type="text" defaultValue="" id="access-token-expire" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="refresh-token-expire" className="form-Label">{t("RefreshTokenExpire")}</Label>
                                <i className="bx bx-help-circle" title={t("RefreshTokenExpire")} />
                                <Input className="form-control" type="text" defaultValue="" id="refresh-token-expire" />
                            </div>

                            <div className="div-switch">
                                <Label className="col-sm-1 col-form-label">
                                    {t("PasswordNeed")}
                                    <i className="bx bx-help-circle col-form-label" title={t("PasswordNeed")} />
                                </Label>
                                <Input type="checkbox" id="passwordneed" switch="none" defaultChecked={false} />
                                <Label htmlFor="passwordneed" data-on-label="On" data-off-label="Off"></Label>
                            </div>

                            <div className="div-switch">
                                <Label className="col-sm-1 col-form-label">
                                    {t("RegisterAllowed")}
                                    <i className="bx bx-help-circle col-form-label" title={t("RegisterAllowed")} />
                                </Label>
                                <Input type="checkbox" id="registerallowed" switch="none" defaultChecked={false} />
                                <Label htmlFor="registerallowed" data-on-label="On" data-off-label="Off"></Label>
                            </div>

                            <div className="div-switch">
                                <Label className="col-sm-1 col-form-label">
                                    {t("KeepSession")}
                                    <i className="bx bx-help-circle col-form-label" title={t("KeepSession")} />
                                </Label>
                                <Input type="checkbox" id="keepsession" switch="none" defaultChecked={false} />
                                <Label htmlFor="keepsession" data-on-label="On" data-off-label="Off"></Label>
                            </div>

                            <div className="div-switch">
                                <Label className="col-sm-1 col-form-label">
                                    {t("CHAPTCHANeed")}
                                    <i className="bx bx-help-circle col-form-label" title={t("CHAPTCHANeed")} />
                                </Label>
                                <Input type="checkbox" id="chaptchaneed" switch="none" defaultChecked={false} />
                                <Label htmlFor="chaptchaneed" data-on-label="On" data-off-label="Off"></Label>
                            </div>

                            <div className="mb-3">
                                <Label htmlFor="registerurl" className="form-Label">{t("Register")}URL</Label>
                                <i className="bx bx-help-circle" title={t("Register")+'URL'} />
                                <Input className="form-control" type="text" defaultValue="" id="registerurl" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="loginurl" className="form-Label">{t("Login")}URL</Label>
                                <i className="bx bx-help-circle" title={t("Login")+'URL'} />
                                <Input className="form-control" type="text" defaultValue="" id="loginurl" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="passwordforgoturl" className="form-Label">{t("PasswordForgot")}URL</Label>
                                <i className="bx bx-help-circle" title={t("PasswordForgot")+'URL'} />
                                <Input className="form-control" type="text" defaultValue="" id="passwordforgoturl" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="incurl" className="form-Label">{t("Inc")}URL</Label>
                                <i className="bx bx-help-circle" title={t("Inc")+'URL'} />
                                <Input className="form-control" type="text" defaultValue="" id="incurl" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="termsofuse" className="form-Label">{t("TermsofUse")}</Label>
                                <i className="bx bx-help-circle" title={t("TermsofUse")} />
                                <Input className="form-control" type="text" defaultValue="" id="termsofuse" />
                                {/*点击上传*/}
                                <Dropzone
                                    onDrop={acceptedFiles => {
                                        handleAcceptedFiles(acceptedFiles);
                                    }}
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <div
                                            className="custom-btn"
                                            {...getRootProps()}
                                        >
                                            <input {...getInputProps()} />
                                            <p><i className="fas fa-upload" style={{marginRight:"10px"}} />Click to Upload</p>
                                        </div>
                                    )}
                                </Dropzone>
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="registerhtml" className="form-Label">{t("Register")}HTML</Label>
                                <i className="bx bx-help-circle" title={t("Register")+'HTML'} />
                                <Input className="form-control" type="text" defaultValue="" id="registerhtml" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="loginhtml" className="form-Label">{t("Login")}HTML</Label>
                                <i className="bx bx-help-circle" title={t("Login")+'HTML'} />
                                <Input className="form-control" type="text" defaultValue="" id="loginhtml" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="oauthtype" className="form-Label">{t("OAuthType")}</Label>
                                <i className="bx bx-help-circle" title={t("OAuthType")} />
                                <Input className="form-control" type="text" defaultValue="" id="oauthtype" />
                            </div>
                            <div className="mb-3">
                                <Label className="form-Label">{t("Provider")}</Label>
                                <i className="bx bx-help-circle" title={t("Provider")} />
                                <Link to="/#" className="btn btn-success btn-sm" style={{marginLeft:"10px"}}>
                                    {t("OP_AddData")}
                                </Link>
                                <ProviderPage className={t("Provider")} />
                            </div>
                            <Row>
                                <Col xl={1}>
                                    <Label htmlFor="example-text-input" className="form-Label">{t("th_Preview")}</Label>
                                    <i className="bx bx-help-circle" title={t("th_Preview")} />
                                </Col>
                                <Col xl={5}>
                                    <button
                                        type="button"
                                        className="btn btn-outline-primary waves-effect waves-light"
                                        style={{marginBottom:"10px"}}
                                    >
                                        {t("OP_TestRegistPage")}
                                    </button>
                                    <TestRegistPage className={t("OP_TestRegistPage")} />
                                </Col>
                                <Col xl={5}>
                                    <button
                                        type="button"
                                        className="btn btn-outline-primary waves-effect waves-light"
                                        style={{marginBottom:"10px"}}
                                    >
                                        {t("OP_TestRegistPage")}
                                    </button>
                                    <TestLoginPage className={t("OP_TestLoginPage")} />
                                </Col>
                            </Row>
                            <div className="mb-3">
                                <Label className="form-Label">{t("RegistList")}</Label>
                                <i className="bx bx-help-circle" title={t("RegistList")} />
                                <Link to="/#" className="btn btn-success btn-sm" style={{marginLeft:"10px"}}>
                                    {t("OP_AddData")}
                                </Link>
                                <RegistItems className={t("RegistList")}/>
                            </div>
                            <div className="mb-3">
                                <Label className="form-Label">{t("th_Preview")}</Label>
                                <i className="bx bx-help-circle" title={t("th_Preview")} />
                                <Link to="/#" className="btn btn-success btn-sm" style={{marginLeft:"10px"}}>
                                    {t("OP_TestHintPage")}
                                </Link>
                                <TestHintPage className={t("OP_TestHintPage")} />
                            </div>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AppsAdd;
