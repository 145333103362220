import {Key} from "react";
// Util
import {insure} from "src/util";

type props = {
  className?: string;
  value?: Key;
  label?: Key;
  bgColor?: string;
  selected?: boolean;
  toggle?: Function;
}

const generateStyle = (k: string, v: string, f = true) => {
  if (!f) {
    return {}
  }
  return {
    [k]: v
  }
}

export default function ({className, value, label, bgColor = "", selected, toggle}: props) {
  return (<span className={`me-3 mb-3 rounded-2 px-1 py-1 cursor-pointer user-select-none 
                ${className} ${selected && "text-white"}`}
                style={generateStyle("background-color", bgColor, selected)}
                onClick={insure(toggle, value)}>{label || value}</span>)
}
