import { StrictMode, useContext, useEffect, useState } from "react";
//i18n
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import ControlBar from "./ControlBar";
import DeviceOption from "./DeviceOption";
import { RuleContext } from "../../reducer";
import { insure } from "src/util";
import { deviceConfiguration } from "../../common/configuration";
import {
    getIotDevices
} from "src/helpers/iot_backend_helper";
import { includes } from "lodash";

export default withTranslation()(withRouter((props: any) => {
    const { onBack, onCancel, onSelect, t, match } = props;
    const { wsId } = match.params;
    const [list, setList] = useState([]);
    const { state, dispatch } = useContext(RuleContext);
    const inputs = state.input.inputs;
    const selectedDevices = inputs.map((row: any) => row.device_id);
    useEffect(() => {
        fetchData({})
    }, []);
    const fetchData = (params: any) => {
        getIotDevices({ ...params, workspace_id: wsId }, null).then(result => {
            if (result.Code == 200) {
                setList(result.Data);
            }
        }).catch(err => console.log(err))
    }
    const deviceOptionProps = {
        iconClassName: deviceConfiguration.iconClassName,
    }

    const handleSearch = (filterEntries: string) => {

    }

    const handleSelect = (data: any) => {
        const actions = {
            type: "ADD_DEVICE",
            payload: data
        }
        dispatch(actions)
        insure(onSelect)()
    }
    return (<StrictMode>
        <div className="d-flex flex-column h-100">
            <ControlBar className="flex-shrink-0 mb-4"
                onBack={onBack} onCancel={onCancel} onSearch={handleSearch} />
            <main className="flex-grow-1 overflow-auto">
                {list.length > 0 && <div className="mb-2 text-capitalize">{t("available devices")}</div>}
                {list.length > 0 && list.map((row: any) =>
                    <DeviceOption key={row.device_id} device={row}
                        disable={includes(selectedDevices, row.device_id)}
                        title={row.name} subtitle={row.device_id}
                        description={row.description}
                        {...deviceOptionProps}
                        onSelect={handleSelect} />)}
            </main>
        </div>
    </StrictMode>)
}))
