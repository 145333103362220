import { memo } from 'react';
// i18n
import { useTranslation } from 'react-i18next';
// Router
import { useLocation } from 'react-router';
// Redux
import { useSelector } from 'react-redux';
// Component
import LinkContainer from './LinkContainer';
// Util
import { loadRouteConfiguration } from './routeConfiguration';

export default memo(() => {
  const loc = useLocation();
  const { t } = useTranslation();

  const { selectedMenu, user } = useSelector((state: any) => ({
    selectedMenu: state.Layout.selectedMenu,
    user: state.login.user,
  }));
  let { curr_org, curr_workspace } = user;
  const orgId = curr_org.id;
  const wsId = curr_workspace.id;

  let { routes } = loadRouteConfiguration(selectedMenu);
  // 为预设路由中的缺省值赋值
  routes = routes.map(el => ({
    ...el,
    menus: el.menus.map(n => ({
      ...n,
      path: n.path.replace(':orgId', orgId).replace(':wsId', wsId),
    })),
  }));

  return (
    <ul className="list-unstyled">
      {routes.map((routeConfiguration: any) => (
        <div className="mb-5" key={routeConfiguration.groupName}>
          <div className="mt-2 mb-2 px-12 text-muted font-size-14">
            {t(routeConfiguration.groupName)}
          </div>
          {routeConfiguration.menus.map((menu: any) => {
            return (
              <li key={menu.path} className="mb-1">
                <LinkContainer
                  {...menu}
                  selected={loc.pathname.includes(menu.path)}
                />
              </li>
            );
          })}
        </div>
      ))}
    </ul>
  );
});
