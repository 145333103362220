import React, { Fragment, useState } from 'react';
// i18n
import { useTranslation } from 'react-i18next';
// Component
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
} from 'reactstrap';
// Conf
import { countries, phoneNumberPrefixes } from './compConf';
// Util
import { currying } from 'src/util';

type FormInputs = {
  name: string;
  email: string;
  nationality: string;
  phoneNumber?: string;
};

type props = {
  onComplete?: Function;
} & Partial<FormInputs>;

export default function ({
  name,
  email,
  nationality = 'zh_CN',
  phoneNumber,
  onComplete,
}: props) {
  const { t } = useTranslation();
  // Form
  const generateClauseOption = (declare = '', emphasis = '') => (
    <div className="first-letter-upper">
      <span>{t(declare)}</span>
      &nbsp;
      <span className="text-capitalize text-primary">{t(emphasis)}</span>
    </div>
  );
  // First Name
  // Last Name
  const FormSchema = {
    name: {
      required: 'nickname is required',
    },
    email: {
      required: "email is required",
      pattern: {
        value: /^\S+@\S+$/i,
        message: 'enter a valid email address',
      },
    },
    phoneNumber: {
      pattern: {
        value: /^\d+$/i,
        message: 'invalid phone number',
      },
      minLength: {
        value: 4,
        message: 'invalid phone number',
      },
      maxLength: {
        value: 11,
        message: 'invalid phone number',
      },
    },
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInputs>();
  // Country
  const [cntry, setCntry] = useState(nationality);
  const [cntrySelectOpened, setCntrySelectOpened] = useState(false);
  const reverseCntrySelectOpened = () => {
    setCntrySelectOpened(!cntrySelectOpened);
  };
  // Phone Number
  const [phoneNumberPrefix, setPhoneNumberPrefix] = useState('+86');
  const [phoneNumberPrefixSelectOpened, setPhoneNumberPrefixSelectOpened] =
    useState(false);
  const reversePhoneNumberPrefixSelectOpened = () => {
    setPhoneNumberPrefixSelectOpened(!phoneNumberPrefixSelectOpened);
  };
  // Clause
  const clauseOptions = [
    {
      value: 1 << 0,
      comp: generateClauseOption('I accept the', 'privacy policy'),
    },
    {
      value: 1 << 1,
      comp: generateClauseOption('I accept the', 'subscription terms'),
    },
    {
      value: 1 << 2,
      comp: generateClauseOption('sign up for Product Newsletter'),
    },
  ];
  const [clauseSelected, setClauseSelected] = useState(0);
  const handleClauseChange = (val: number) => {
    setClauseSelected(clauseSelected ^ val);
  };
  // Submit
  const onSubmit: SubmitHandler<FormInputs> = data => {
    // 检查协议接收情况
    // 未接受 privacy policy
    if (~clauseSelected & 1) {
      alert('请接受隐私政策！');
      return;
    }

    const addlInfo = {
      ...data,
      nationality: cntry,
      phoneNumber: data.phoneNumber
        ? phoneNumberPrefix + data.phoneNumber
        : undefined,
    };

    onComplete && onComplete(addlInfo);
  };

  return (
    <Fragment>
      <div className="mb-4 font-size-20 text-capitalize">
        {t('additional information')}
      </div>
      <div>
        {/* NickName */}
        <FormGroup className="mt-sparse-pre">
          <Label className="text-capitalize">{t('nickname')}</Label>
          <Input
            defaultValue={name}
            invalid={Boolean(errors.name)}
            placeholder={t('enter your nickname')}
            {...register('name', FormSchema.name)}
          />
          <FormFeedback className="first-letter-upper">
            {t(errors.name?.message || '')}
          </FormFeedback>
        </FormGroup>
        {/* Email */}
        <FormGroup className="mt-sparse-pre">
          <Label className="text-capitalize">{t('e-mail')}</Label>
          <Input
            className="mt-narrow-pre"
            autoComplete="email"
            defaultValue={email}
            invalid={Boolean(errors.email)}
            placeholder={t('enter e-mail to register with')}
            {...register('email', FormSchema.email)}
          />
          <FormFeedback className="mt-narrow-pre first-letter-upper">
            {t(errors.email?.message || '')}
          </FormFeedback>
        </FormGroup>
        {/* Country */}
        <FormGroup className="mt-sparse-pre">
          <Label className="text-capitalize">{t('country')}</Label>
          <Dropdown
            isOpen={cntrySelectOpened}
            toggle={reverseCntrySelectOpened}>
            <DropdownToggle tag="div">
              <div className="clearfix border rounded-2 px-2 py-2">
                <span className="float-start">
                  {t(countries?.find(c => c.abrv == cntry)?.name || '')}
                </span>
                <i
                  className={`float-end ms-2 lh-base
                  ${
                    cntrySelectOpened ? 'fas fa-angle-up' : 'fas fa-angle-down'
                  }`}
                />
              </div>
            </DropdownToggle>
            <DropdownMenu className="w-100">
              {countries.map(c => (
                <DropdownItem
                  key={c.abrv}
                  className="user-select-none"
                  active={c.abrv == cntry}
                  disabled={c.abrv == cntry}
                  onClick={currying(() => setCntry(c.abrv))}>
                  {t(c.name)}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </FormGroup>
        {/* Phone Number */}
        <FormGroup className="mt-sparse-pre">
          <Label>
            <span className="me-2 text-capitalize">{t('phone number')}</span>
            <span className="text-muted">({t('optional')})</span>
          </Label>
          <InputGroup>
            <InputGroupText className="border-0 p-0">
              <Dropdown
                isOpen={phoneNumberPrefixSelectOpened}
                toggle={reversePhoneNumberPrefixSelectOpened}>
                <DropdownToggle className="rounded-0 rounded-start cus-w-sm-6">
                  {phoneNumberPrefix}
                </DropdownToggle>
                <DropdownMenu>
                  {phoneNumberPrefixes.map(p => (
                    <DropdownItem
                      key={p}
                      className="user-select-none"
                      active={p == phoneNumberPrefix}
                      disabled={p == phoneNumberPrefix}
                      onClick={currying(() => setPhoneNumberPrefix(p))}>
                      {p}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </InputGroupText>
            <Input
              className="rounded-end mt-narrow-pre"
              defaultValue={phoneNumber}
              invalid={Boolean(errors.phoneNumber)}
              {...register('phoneNumber', FormSchema.phoneNumber)}
            />
            <FormFeedback className="mt-narrow-pre first-letter-upper">
              {t(errors.phoneNumber?.message || '')}
            </FormFeedback>
          </InputGroup>
        </FormGroup>
        {/* Clause */}
        <FormGroup className="mt-5">
          {clauseOptions.map(opt => (
            <div key={opt.value} className="mt-wide-pre">
              <Input
                className="me-2"
                type="checkbox"
                name="country"
                onChange={currying(handleClauseChange, opt.value)}
              />
              <Label>{opt.comp}</Label>
            </div>
          ))}
        </FormGroup>
        {/* Submit */}
        <FormGroup className="mt-5">
          <Button
            className="w-100 text-capitalize"
            color="primary"
            onClick={handleSubmit(onSubmit)}>
            {t('complete registration')}
          </Button>
        </FormGroup>
      </div>
    </Fragment>
  );
}
