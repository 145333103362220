import { PermissionsTypes } from "./actionTypes";

export const getEvents = () => ({
  type: PermissionsTypes.GET_EVENTS,
});

export const apiSuccess = (actionType : any, data : any) => ({
  type: PermissionsTypes.API_SUCCESS,
  payload: { actionType, data },
});

export const apiFail = (actionType : any, error : any) => ({
  type: PermissionsTypes.API_FAIL,
  payload: { actionType, error },
});

export const addNewEvent = (event : any) => ({
  type: PermissionsTypes.ADD_NEW_EVENT,
  payload: event,
});

export const updateEvent = (event : any) => ({
  type: PermissionsTypes.UPDATE_EVENT,
  payload: event,
});

export const updateEventSuccess = (event : any) => ({
  type: PermissionsTypes.UPDATE_EVENT_SUCCESS,
  payload: event,
});

export const updateEventFail = (error : any) => ({
  type: PermissionsTypes.UPDATE_EVENT_FAIL,
  payload: error,
});

export const deleteEvent = (event : any) => ({
  type: PermissionsTypes.DELETE_EVENT,
  payload: event,
});

export const deleteEventSuccess = (event : any) => ({
  type: PermissionsTypes.DELETE_EVENT_SUCCESS,
  payload: event,
});

export const deleteEventFail = (error : any) => ({
  type: PermissionsTypes.DELETE_EVENT_FAIL,
  payload: error,
});

export const getCategories = () => ({
  type: PermissionsTypes.GET_CATEGORIES,
});

export const getCategoriesSuccess = (categories : any) => ({
  type: PermissionsTypes.GET_CATEGORIES_SUCCESS,
  payload: categories,
});

export const getCategoriesFail = (error : any) => ({
  type: PermissionsTypes.GET_CATEGORIES_FAIL,
  payload: error,
});

export const getPermissions = () => ({
  type: PermissionsTypes.GET_PERMISSIONS,
});

export const getPermissionsSuccess = (permissions : any) => ({
  type: PermissionsTypes.GET_PERMISSIONS_SUCCESS,
  payload: permissions,
});

export const getPermissionsFail = (error : any) => ({
  type: PermissionsTypes.GET_PERMISSIONS_FAIL,
  payload: error,
});

export const getPermissionById = (id:string) => ({
  type: PermissionsTypes.GET_PERMISSIONBYID,
  payload: id
});

export const getPermissionByIdSuccess = (permissions : any) => ({
  type: PermissionsTypes.GET_PERMISSIONBYID_SUCCESS,
  payload: permissions,
});

export const getPermissionByIdFail = (error : any) => ({
  type: PermissionsTypes.GET_PERMISSIONBYID_FAIL,
  payload: error,
});
