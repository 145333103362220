import { Dispatch, StrictMode, useReducer, useState, useEffect } from "react";

import { Col, Row } from "reactstrap";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { TopBar, TopBarProps } from "./TopBar";
import AddTimer from "./AddTimer";
import RuleInput from "./RuleInput";
import RuleLogic from "./RuleLogic";
import RuleAction from "./RuleAction";
import AddRuleModal from './addRuleModal';
import SvgLine from './components/svgLine'
import { Action } from "./common/type";
import { RuleContext, init, reducer, State } from "./reducer";
import "./newRule.scss"
import {
    createIotRule, getIotRule, updateIotRule, getIotDevice
} from "src/helpers/iot_backend_helper";
import { MODE as INPUT_MODE } from './RuleInput/constant';
interface IQuery {
    device_id?: string;
}
export default withTranslation()(withRouter((props: any) => {
    const { t, history, match, location } = props;
    const [visiable, setVisiable] = useState(false);
    let query: IQuery = {};
    if (location.search) {
        let querys: string = location.search.slice(1);
        const queryArr: string[] = querys.split("=");
        query.device_id = queryArr[1]
    }
    const { orgId, wsId, id } = match.params;
    const [state, dispatch]: [State, Dispatch<Action>] = useReducer(reducer, {}, init);
    const { logicState: { data_sources, logic_blocks, custom_logic, type, name },
        input, actions, timer, status } = state;
    const customLogic: any = custom_logic;

    useEffect(() => {
        if (id) {
            getIotRule(id, null).then(result => {
                if (result && result.Code == 200) {
                    let data = {
                        status: result.Data.status,
                        name: result.Data.name,
                        description: result.Data.description,
                        logicState: {
                            ...result.Data.logic
                        },
                        input: {
                            inputs: result.Data.inputs
                        },
                        actions: result.Data.actions,
                        actionSelectedPoint: { id: '', top: 0, left: 0 },
                        timer: result.Data.timer
                    }
                    const action = {
                        type: "ADD_RULE",
                        payload: data
                    }
                    // setFlowData(data);
                    dispatch(action)
                }
            });
        }
        return () => {
            const action = {
                type: "INIT",
                payload: {}
            }
            dispatch(action);
        }
    }, []);

    useEffect(() => {
        if (query.device_id) {
            getIotDevice(query.device_id, null).then((result: any) => {
                if (result && result.Code == 200) {
                    const actions = {
                        type: "RESET_DEVICE",
                        payload: result.Data
                    }
                    dispatch(actions);
                }
            }).catch(err => {
                console.log(err)
            });
        }
    }, []);

    const onClose = (url: string) => {
        const action = {
            type: "CANCLE",
            payload: {},
        }
        dispatch(action)
        history.push(url);
    }

    const onSave = () => {
        setVisiable(true);
    }

    // 保存规则引擎
    const saveRule = (data: object) => {
        if (type == "CUSTOM_LOGIC") {
            delete customLogic.offsetX;
            delete customLogic.offsetY;
        }
        const postData = {
            workspace_id: wsId,
            ...data,
            status,
            inputs: input.inputs,
            actions,
            logic: {
                data_sources: data_sources.map((row: any) => { delete row.offsetX; delete row.offsetY; return row; }),
                logic_blocks: logic_blocks.map((row: any) => { delete row.offsetX; delete row.offsetY; return row; }),
                custom_logic: customLogic,
                type: type,
                name: name
            },
            timer: timer
        }
        // 根据是否有规则引擎ID判断更新还是新增
        if (id) {
            updateIotRule(id, postData, null).then(result => {
                if (result.Code == 200) {
                    history.push(`/org/${orgId}/ws/${wsId}/rules`)
                }
            }).catch(err => { });
        } else {
            createIotRule(postData, null).then(result => {
                if (result.Code == 200) {
                    history.push(`/org/${orgId}/ws/${wsId}/rules`)
                }
            }).catch(err => { });
        }
    }

    // 检查规则引擎是否填写完成 当填写完成提交按钮才可用
    const ruleComplete = (): boolean => {
        if (!input.inputs.length || !actions.length || !data_sources.length) {
            return true;
        }
        if (type == "COMPARISON" && !logic_blocks.length) {
            return true;
        }
        let disabled: boolean = false;
        data_sources.forEach((r: any) => {
            if (!r.input_ids.length || !r.topic || !r.key) {
                disabled = true;
            }
        });
        if (type == "COMPARISON") {
            logic_blocks.forEach((r: any) => {
                if (!r.action_ids.length) {
                    disabled = true;
                }
            });
        } else if (type == "CUSTOM_LOGIC") {
            if (!customLogic.action_ids.length) {
                return true;
            }
        }
        return disabled;
    }

    const modalProps = {
        visiable,
        data: {
            name: state.name,
            description: state.description,
        },
        id: id,
        togModal: () => setVisiable(!visiable),
        hideModal: () => setVisiable(false),
        save: saveRule
    }

    const changeStatus = (e: boolean) => {
        const action = {
            type: "CHANGE_STATUS",
            payload: e ? 1 : 2,
        }
        dispatch(action);
    }

    const topBarProps: TopBarProps = {
        disableSave: ruleComplete(),
        status: status === 1 ? true : false,
        changeStatus: changeStatus,
        id: id,
        onClose,
        onSave
    }

    const [timerVisible, setTimerVisible] = useState(false)
    const onInputConnectModeChange = (m: INPUT_MODE) => {
        if (m == INPUT_MODE.GUIDE) {
            setTimerVisible(false)
        } else {
            setTimerVisible(true)
        }
    }

    return (<StrictMode>
        <SvgLine {...state} />
        <RuleContext.Provider value={{ state, dispatch }}>
            <div className="h-100">
                <header className="h-header">
                    <TopBar {...topBarProps} />
                </header>
                <main className="position-relative fill-h-header bg-soft-light">
                    <Row className="mx-0 h-100">
                        <Col className="border-s-pre cus-border-gray-300 px-0 h-100 cus-scroll overflow-y-scroll">
                            <RuleInput onModeChange={onInputConnectModeChange} />
                        </Col>
                        <Col className="border-s-pre cus-border-gray-300 px-0 h-100 cus-scroll overflow-y-scroll">
                            <RuleLogic />
                        </Col>
                        <Col className="border-s-pre cus-border-gray-300 px-0 h-100 cus-scroll overflow-y-scroll">
                            <RuleAction />
                        </Col>
                    </Row>
                    <AddTimer location={true} timer={timer} hidden={timerVisible} />
                </main>
            </div>
            <AddRuleModal {...modalProps} />
        </RuleContext.Provider>
    </StrictMode>)
}));
