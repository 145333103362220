import React, {Key, useContext, useEffect, useRef} from "react";
//i18n
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
//redux
import { useSelector, useDispatch } from "react-redux";

import {ACTION_TYPE} from "../../../../contant";
import {PASS_DEVICE_TYPE} from "../contant";
import {DataFlowContext} from "../../../../reducer";

import HeadOption from "./HeadOption";
import ControlBar from "./ControlBar";
import OptionContainer from "./OptionContainer";

import { fetchHeadOptionInfo} from "./api";
// actions
import { getDeviceTypeList } from "src/store/actions";

const DeviceTypeOption = (props:any) => {
    let { match, history } = props;
    const { orgId } = match.params;
    const reduxDispatch = useDispatch();
    const headOptionInfo = fetchHeadOptionInfo()
    const { list } = useSelector((state: any) => (state.deviceType));
    const mainEl = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const onScroll = () => {
            console.log(mainEl?.current)
        }
        mainEl.current && mainEl.current.addEventListener("scroll", onScroll)
        return () => {
            mainEl.current && mainEl.current.removeEventListener("scroll", onScroll)
        }
    }, [mainEl]);

    useEffect(() => {
        fetchData({pageSize: 100, pageNumber: 1});
      }, []);
    
      // 获取设备类型列表数据
      const fetchData = (params:any) => {
        reduxDispatch(getDeviceTypeList({ 
          org_id: orgId, ...params 
        }, history));
      }

    const {dispatch} = useContext(DataFlowContext)

    const handleSelectHeadOption = () => {
        const action = {
            type: ACTION_TYPE.SELECT_DEVICE_TYPE,
            payload: {id: "00000000000000000",  name: "pass through",
            description: "the payload is forwarded without changes."}
        }

        dispatch(action)
    }

    const handleSelectOption = (deviceType: object) => {
        console.log(deviceType)
        const action = {
            type: ACTION_TYPE.SELECT_DEVICE_TYPE,
            payload: deviceType,
        }
        dispatch(action);
    }

    return (<React.StrictMode>
        <div>
            <header className="mb-4">
                <HeadOption
                    className="mb-4"
                    title={headOptionInfo.title}
                    description={headOptionInfo.description}
                    onClick={handleSelectHeadOption}/>
                <ControlBar/>
            </header>
            <main className="device-type-option-height overflow-auto" ref={mainEl}>
                {list.map((item:any) =>
                    <OptionContainer key={item.id} data={item} onClick={handleSelectOption}/>)}
            </main>
        </div>
    </React.StrictMode>)
}

export default withTranslation()(withRouter(DeviceTypeOption));
