'use strict';

import { Key } from 'react';
import { useSelector } from 'react-redux';

export default function (orgId: Key | null, wsId: Key | undefined): string {
  const { user } = useSelector((state: any) => ({
    user: state.login.user,
  }));

  if (!wsId) {
    return (
      user.user_organizations.find((org: any) => org.organization.id == orgId)
        ?.role || 'viewer'
    );
  }

  return (
    user.user_workspaces.find((ws: any) => ws.workspace.id == wsId)?.role ||
    'viewer'
  );
}
