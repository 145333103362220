import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CreatableSelect from 'react-select/creatable';
//i18n
import { withTranslation } from "react-i18next";
import { withRouter, Link } from "react-router-dom";
import { Col, Row, Input, Label, Button } from "reactstrap";
import DeviceModal from './DeviceModal'
import { AvForm, AvField } from "availity-reactstrap-validation";
import makeAnimated from "react-select/animated";
import {
  getIotTags, createIotTag
} from "src/helpers/iot_backend_helper";
type InputType =
  'text'
  | 'email'
  | 'select'
  | 'textarea'
  | 'number'
interface field {
  id: string;
  name: string;
  value: string;
  type: InputType;
}

const animatedComponents = makeAnimated();

const Basicinformation = (props: any) => {
  let { t, next, formData, match } = props;
  const { orgId, wsId } = match.params;
  const [tags, setTags] = useState<any>(null);
  const [name, setName] = useState<any>(formData.name);
  const [visiable, setVisiable] = useState<boolean>(false);
  const [customFields, setCustomFields] = useState<Array<field>>(formData.customFields || []);
  const [tagTips, setTagTips] = useState<boolean>(true);
  const [customFieldsTips, setCustomFieldsTips] = useState<boolean>(true);
  const [selectTags, setSelectTags] = useState<any>(formData.tags || []);
  console.log(formData)  
  useEffect(() => {
    getIotTags({workspace_id: wsId, page_size: 100, page_number: 1}, null).then(result => {
      if(result && result.Code == 200) {
        let data = result.Data.map((t:any) => ({ label: t.name, value: t.id }))
        setTags(data);
      }
    });
  }, [])

  // 显示隐藏自定义列模态层
  function togModal() {
    setVisiable(!visiable);
  }

  // 添加自定义列
  const addCustomerField = (data: any) => {
    data.id = String(Date.now());
    customFields.push(data);
    setVisiable(false);
    setCustomFields(customFields);
  }
  const modalProps = {
    visiable,
    hideModal: () => {
      setVisiable(false)
    },
    togModal: togModal,
    save: addCustomerField
  }
  // handleValidSubmit
  const handleValidSubmit = (event: any, values: any) => {
    values.customFields = customFields;
    values.tags = selectTags;
    next(values);
  };

  const handleRemoveCustomField = (id: string) => {
    let arr = customFields.filter(row => row.id != id);
    setCustomFields(arr);
  }

  const handleInputChange = (value: string, index: number) => {
    customFields[index].value = value;
    setCustomFields(customFields);
  }

  // 创建标签
  const handleCreateTag = (e:any) => {
    console.log(e)
    let data = {
      workspace_id: wsId,
      name: e,
      description: ''
    }

    createIotTag(data, null).then((result) => {
      tags.push({ label: result.Data.name, value: result.Data.id });
      setTags(tags);
    });
  }

  type Option = {
    lable: string;
    value: string;
  }
  // 标签选择
  const handleSelectChange = (e:[Option]) => {
    setSelectTags(e);
  }

  return (
    <React.Fragment>
      <h5 style={{ marginBottom: '30px' }}>{t('BasicInformation')}</h5>
      <AvForm
        onValidSubmit={(e: any, v: any) => {
          handleValidSubmit(e, v);
        }}
      >
        <Row>
          <div className="mb-3">
            <AvField
              name="name"
              defaultValue={formData.name}
              label={props.t('DeviceName')}
              onChange={(e: any) => setName(e.target.value)}
              placeholder=""
              type="text"
              errorMessage={t("Device name is required")}
              validate={{ required: { value: true } }}
            />
          </div>
          <div className="mb-3">
            <AvField
              name="description"
              defaultValue={formData.description}
              label={props.t('DeviceDescription') + " (" + t("optional")+")"}
              placeholder=""
              type="text"
              errorMessage=""
              validate={{ required: { value: false } }}
            />
          </div>
          <h5 className="mt-4 mb-4">{t('AssetTags')}</h5>
          {
            tagTips && <div>
              <div style={{ backgroundColor: '#fafafa', padding: '20px', marginBottom: '30px' }}>
                <div className="flex-row justify-between">
                  <div className="card-title me-2">{t('AssdtTipsTitle')}</div>
                  <i onClick={() => setTagTips(false)} style={{ color: '#8c8c8c' }} className="mdi mdi-close-thick cursor-pointer"></i>
                </div>
                <div>{t('AssdtTipsContent')}</div>
              </div>
            </div>
          }
          <div className="mb-3">
            <Label htmlFor="example-search-input" className="form-Label">{props.t('Tags')}</Label>
            <CreatableSelect
              isMulti
              isSearchable={true}
              classNamePrefix="select2-selection"
              placeholder={t('Select Asset Tag')}
              closeMenuOnSelect={false}
              onCreateOption={handleCreateTag}
              onChange={handleSelectChange}
              defaultValue={selectTags}
              options={tags}
              components={animatedComponents}
            />
          </div>
          <h5 className="mt-4 mb-4">{t('CustomFields')}</h5>
          {
            customFieldsTips && <div>
              <div style={{ backgroundColor: '#fafafa', padding: '20px', marginBottom: '30px' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <div className="card-title me-2">{t('CustomFiledTipsTitle')}</div>
                  <i onClick={() => setCustomFieldsTips(false)} style={{ color: '#8c8c8c' }} className="mdi mdi-close-thick cursor-pointer"></i>
                </div>
                <div>{t('CustomFiledTipsContent')}</div>
              </div>
            </div>
          }
          {
            customFields.map((field, index: number) => <div key={field.name} className="mb-3">
              <Label htmlFor="example-search-input" className="form-Label">{field.name}</Label>
              <Row style={{ display: 'flex', flexDirection: 'row' }}>
                <Col sm={10} xs={20}>
                  <Input defaultValue={field.value} onChange={(e) => {
                    handleInputChange(e.target.value, index)
                  }} className="form-control" type={field.type} id="example-search-input" />
                </Col>
                <Col sm={2} xs={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                  <div onClick={() => handleRemoveCustomField(field.id)} style={{ width: '35px', height: '35px', borderRadius: '35px', border: '1px solid #cacaca', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <i style={{ color: '#8c8c8c' }} className="far fa-trash-alt"></i>
                  </div>
                </Col>
              </Row>
            </div>)
          }

          <button
            type="button"
            style={{ fontSize: '16px', width: '200px' }}
            onClick={() => {
              togModal();
            }}
            className="btn btn-outline-light waves-effect"
          >
            <span> + </span>
            {props.t('AddCustomField')}
          </button>

          <div style={{ marginTop: '40px', marginBottom: '40px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Link to={`/org/${orgId}/ws/${wsId}/assets`}>
              <button
                type="button"
                style={{ fontSize: '16px' }}
                className="btn btn-outline-light waves-effect"
              >
                {props.t('Cancel')}
              </button>
            </Link>
            <Button type="submit" disabled={!name} color="primary">
              {props.t('Next')}
            </Button>
          </div>
        </Row>
      </AvForm>
      <DeviceModal {...modalProps} />
    </React.Fragment>
  );
}

Basicinformation.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  next: PropTypes.func
};
export default withTranslation()(withRouter(Basicinformation));