import { createContext, Dispatch, Key } from "react";

import { Action } from "../common/type";

import { LOGIC, INPUTS } from "./constant";

export const init = (initialState: Partial<State>): State => ({
    ...defaultState,
    ...initialState,
});

type DataSource = {
    id: string;
    name?: string;
}

export interface IPoint {
    id: string;
    top: number;
    left: number;
}

type LogicBlock = {
    id: string;
}

export type LogicState = {
    type?: String;
    name?: String;
    custom_logic?: Object;
    data_sources: DataSource[];
    logic_blocks: LogicBlock[];
    selectedPoint?: IPoint;
    conditionSelectedPoint?: IPoint;
    customLogicSelectedPoint?: IPoint;
}

export type InputDetail = {
    id: Key;
    name?: Key;
    description?: string;
    availableSensors?: string[];
}

export type InputState = {
    inputs: InputDetail[],
    selectedPoint: IPoint | null
}

export type Line = {
    lineStart: IPoint;
    lineEnd: IPoint;
}

type Timer = {
    enabled: boolean;
    schedule: { value: number, unit: string };
}
export type State = {
    showInputLine: boolean;
    showActionLine: boolean;
    status: number;
    name?: String;
    description?: String;
    logicState: LogicState;
    input: InputState;
    actions: any[];
    actionSelectedPoint: IPoint;
    timer: Timer
}

export const defaultState = {
    status: 1,
    showInputLine: false,
    showActionLine: false,
    name: "",
    description: "",
    logicState: {
        custom_logic: {},
        data_sources: [],
        logic_blocks: []
    },
    input: {
        inputs: [],
        selectedPoint: null
    },
    actions: [],
    actionSelectedPoint: { id: '', top: 0, left: 0 },
    timer: {
        enabled: false,
        schedule: { value: 0, unit: 'hours' }
    }
}



export const defaultInputState = {
    inputs: [],
    selectedPoint: { id: '', top: 0, left: 0 }
}
export const defaultLogicStateState = {
    custom_logic: {},
    data_sources: [],
    logic_blocks: []
}
export const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case "INIT":
            console.log(defaultState)
            return { ...defaultState };
        case "CANCLE":
            return { ...state, ...action.payload };
        case "ADD_RULE":
            return { ...state, ...action.payload };
        case "CHANGE_STATUS":
            return { ...state, status: action.payload };
        // case LOGIC.SELECT_TYPE:
        //     state.logicState.logic_type = action.payload;
        //     return {
        //         ...state,
        //     }
        case "ADD_LOGIC_BLOCK":
            state.logicState.logic_blocks?.push(action.payload);
            return { ...state };
        case "CLEAR_LOGIC_BLOCK":
            state.logicState.logic_blocks = state.logicState.logic_blocks.filter(logic_block => logic_block.id != action.payload);
            return { ...state };
        case "SELECT_LOGIC_BLOCK":
            return {
                ...state,
                logicState: action.payload
            }
        case "UPDATE_LOGIC_CONDITION":
            state.logicState.logic_blocks = state.logicState.logic_blocks.map((item, index) => {
                if (item.id == action.payload.id) {
                    item = { ...item, ...action.payload }
                }
                return item;
            });
            state.actionSelectedPoint = { id: '', top: 0, left: 0 };
            state.logicState.conditionSelectedPoint = { id: '', top: 0, left: 0 };
            return { ...state };
        case "UPDATE_CUSTOM_LOGIC":
            state.logicState.custom_logic = action.payload;
            state.actionSelectedPoint = { id: '', top: 0, left: 0 };
            state.logicState.customLogicSelectedPoint = { id: '', top: 0, left: 0 };
            return { ...state }
        case "SELECT_CUSTOM_LOGIC":
            state.logicState.customLogicSelectedPoint = action.payload;
            return { ...state };
        case "SELECT_LOGIC_CONDITION":
            state.logicState.conditionSelectedPoint = action.payload;
            return { ...state };
        case "ADD_DATA_SOURCE":
            state.logicState.data_sources?.push(action.payload)
            return {
                ...state
            }
        case "CLEAR_DATA_SOURCE":
            state.logicState.data_sources = state.logicState.data_sources.filter(dataSource => dataSource.id != action.payload);
            return { ...state }
        case "UPDATE_DATA_SOURCE":
            state.logicState.data_sources = state.logicState.data_sources.map((item, index) => {
                if (item.id == action.payload.id) {
                    item = { ...item, ...action.payload }
                }
                return item;
            });
            state.input.selectedPoint = { id: '', top: 0, left: 0 };
            state.logicState.selectedPoint = { id: '', top: 0, left: 0 };
            return {
                ...state
            }
        case "SELECT_DATA_SOURCE":
            state.logicState.selectedPoint = action.payload;
            return {
                ...state,
            }
        case "CLEAR_LOGIC":
            state.logicState = defaultLogicStateState;
            return { ...state }

        case "ADD_DEVICE":
            state.input.inputs = [...state.input.inputs, action.payload];
            return {
                ...state,
            }
        case "RESET_DEVICE":
            state.input.inputs = [action.payload]
            return {
                ...state
            }
        case "CLEA_INPUT":
            state.input.inputs = state.input.inputs.filter(input => input.id != action.payload);
            state.logicState.data_sources.forEach((row: any) => {
                row.input_ids = row.input_ids.filter((o: string) => o != action.payload);
            });
            return {
                ...state,
            }
        case INPUTS.CLEAR:
            state.input = defaultInputState;
            return {
                ...state,
            }
        case "UPDATE_INPUT":
            let arr = state.input.inputs.map((item, index) => {
                if (item.id == action.payload.id) {
                    item = { ...item, ...action.payload }
                }
                return item;
            });
            state.input.inputs = arr;
            return { ...state }
        case "SELECT_INPUT":
            state.input.selectedPoint = action.payload;
            return {
                ...state
            }
        case "ADD_ACTION":
            state.actions.push(action.payload);
            return {
                ...state
            }
        case "CLEAR_ACTION":
            state.actions = state.actions.filter((row) => row.id != action.payload);
            state.logicState.logic_blocks.forEach((row: any) => {
                row.action_ids = row.action_ids.filter((o: string) => o != action.payload);
            });
            if (state.logicState.type == "CUSTOM_LOGIC") {
                let data: any = state.logicState.custom_logic;
                data.action_ids = data.action_ids.filter((o: string) => o != action.payload);
                state.logicState.custom_logic = data;
            }
            return { ...state }
        case "UPDATE_ACTION":
            state.actions = state.actions.map((item, index) => {
                if (item.id == action.payload.id) {
                    item = { ...item, ...action.payload }
                }
                return item;
            });
            return {
                ...state
            }
        case "SELECT_ACTION":
            state.actionSelectedPoint = action.payload;
            return {
                ...state
            }
        case "UPDATE_TIMER":
            state.timer = action.payload;
            return { ...state };
        case "TOGGLE_INPUT_LINE":
            state.showInputLine = action.payload;
            return { ...state };
        case "TOGGLE_ACTION_LINE":
            state.showActionLine = action.payload;
            return { ...state };
        default:
            return {
                ...state
            }
    }
}

type Context = {
    state: State,
    dispatch: Dispatch<Action>
}

export const RuleContext = createContext<Context>({
    state: defaultState,
    dispatch: (_v: Action): void => {
    }
})
