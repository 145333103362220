import React, { useState } from "react";
import { Link } from "react-router-dom";

//import drawer
import ReactDrawer from "react-drawer";
import "react-drawer/lib/react-drawer.css";

//Import Icons
import Icon from "@ailibs/feather-react-ts";

//import component
import RightSidebar from "../CommonForBoth/RightSidebar";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import LightDark from "../CommonForBoth/Menus/LightDark";

// Reactstrap
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";

//import images
import metavun from "src/assets/images/brands/metavun-black.png"
import logoSvg from "../../assets/images/logo-sm.svg";
import github from "../../assets/images/brands/github.png";
import bitbucket from "../../assets/images/brands/bitbucket.png";
import dribbble from "../../assets/images/brands/dribbble.png";
import dropbox from "../../assets/images/brands/dropbox.png";
import mail_chimp from "../../assets/images/brands/mail_chimp.png";
import slack from "../../assets/images/brands/slack.png";

//redux
import { useSelector } from "react-redux";

const Header = (props: any) => {
  const { layoutMode } = useSelector((state: any) => ({
    layoutMode: state.Layout.layoutMode,
  }));

  const [search, setsearch] = useState<boolean>(false);
  const [socialDrp, setsocialDrp] = useState<boolean>(false);
  const [isClick, setClick] = useState<boolean>(true);
  const [position, setPosition] = useState<string>();
  const [open, setOpen] = useState<boolean>(false);

  /**
   * Rightsidebar drawer
   */
  const toggleTopDrawer = () => {
    setPosition("right");
    setOpen(!open);
  };

  const onDrawerClose = () => {
    setOpen(false);
  };

  /*** Sidebar menu icon and default menu set */
  function tToggle() {
    var body = document.body;
    setClick(!isClick);
    if (isClick === true) {
      body.classList.add("sidebar-enable");
      document.body.setAttribute("data-sidebar-size", "sm");
    } else {
      body.classList.remove("sidebar-enable");
      document.body.setAttribute("data-sidebar-size", "lg");
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar" className="position-relative">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logoSvg} alt="" height="24" />
                </span>
                <span className="logo-lg">
                  <img src={logoSvg} alt="" height="24" />{" "}
                  <span className="logo-txt">Metavun</span>
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoSvg} alt="" height="24" />
                </span>
                <span className="logo-lg">
                  <img src={logoSvg} alt="" height="24" />{" "}
                  <span className="logo-txt">Metavun</span>
                </span>
              </Link>
            </div>

            <button
              onClick={() => {
                tToggle();
              }}
              type="button"
              className="btn btn-sm px-3 font-size-16 header-item"
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars"></i>
            </button>

            {/*<form className="app-search d-none d-lg-block">*/}
            {/*  <div className="position-relative">*/}
            {/*    <input*/}
            {/*      type="text"*/}
            {/*      className="form-control"*/}
            {/*      placeholder="Search..."*/}
            {/*    />*/}
            {/*    <button className="btn btn-primary" type="button">*/}
            {/*      <i className="bx bx-search-alt align-middle"></i>*/}
            {/*    </button>*/}
            {/*  </div>*/}
            {/*</form>*/}
          </div>
          <div className="d-flex">
            <div className="dropdown d-inline-block d-lg-none ms-2">
              <button
                onClick={() => {
                  setsearch(!search);
                }}
                type="button"
                className="btn header-item noti-icon "
                id="page-header-search-dropdown"
              >
                <Icon name="search" className="icon-lg" />
              </button>
              <div
                className={
                  search
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <LanguageDropdown />

            {/* light / dark mode */}
            <LightDark
              layoutMode={layoutMode}
              onChangeLayoutMode={props.onChangeLayoutMode}
            />

            <Dropdown
              className="d-none d-lg-inline-block ms-1"
              isOpen={socialDrp}
              toggle={() => {
                setsocialDrp(!socialDrp);
              }}
            >
              <DropdownToggle
                className="btn header-item noti-icon "
                tag="button"
              >
                <Icon name="grid" className="icon-lg" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-lg dropdown-menu-end">
                <div className="p-2">
                  <Row className="g-0">
                    <Col>
                      <a className="dropdown-icon-item" href="https://www.metavun.com" target="_blank">
                        <img src={metavun} alt="metavun" />
                        <span>metavun</span>
                      </a>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="/icons-materialdesign">
                        <img src={bitbucket} alt="bitbucket" />
                        <span>materialdesign</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="/icons-dripicons">
                        <img src={dribbble} alt="dribbble" />
                        <span>dripicons</span>
                      </Link>
                    </Col>
                  </Row>

                  <Row className="g-0">
                    <Col>
                      <Link className="dropdown-icon-item" to="/icons-fontawesome">
                        <img src={dropbox} alt="dropbox" />
                        <span>fontawesome</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="/ui-colors">
                        <img src={mail_chimp} alt="mail_chimp" />
                        <span>colors</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={slack} alt="slack" />
                        <span>Slack</span>
                      </Link>
                    </Col>
                  </Row>
                </div>
              </DropdownMenu>
            </Dropdown>

            {/*<NotificationDropdown />*/}
            {/*<div*/}
            {/*  onClick={() => {*/}
            {/*    toggleTopDrawer();*/}
            {/*  }}*/}
            {/*  className="dropdown d-inline-block"*/}
            {/*>*/}
            {/*  <button*/}
            {/*    type="button"*/}
            {/*    className="btn header-item noti-icon right-bar-toggle"*/}
            {/*  >*/}
            {/*    <Icon name="settings" className="icon-lg" />*/}
            {/*  </button>*/}
            {/*</div>*/}
            <ProfileMenu />
          </div>
        </div>
      </header>
      <ReactDrawer open={open} position={position}>
        <RightSidebar
          onClose={onDrawerClose}
          onChangeLayoutMode={props.onChangeLayoutMode}
        />
      </ReactDrawer>
    </React.Fragment>
  );
};

export default Header;
