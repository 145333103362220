import React from "react";
import {
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

const Locations = (props: any) => {
  const map = React.createRef<any>();
  return (
    <React.Fragment>
      <Col xl={4}>
        <Card>
          <CardBody>
            <div className="d-flex flex-wrap align-items-center mb-4">
              <h5 className="card-title me-2">Sales by Location</h5>
              <div className="ms-auto">
                <UncontrolledDropdown>
                  <DropdownToggle className="text-reset" to="#" tag="a">
                    <span className="text-muted font-size-12">Sort By:</span>{" "}
                    <span className="fw-medium">
                      World<i className="mdi mdi-chevron-down ms-1"></i>
                    </span>
                  </DropdownToggle>

                  <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem to="#">USA</DropdownItem>
                    <DropdownItem to="#">Russia</DropdownItem>
                    <DropdownItem to="#">Australia</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>

            <div
              id="sales-by-locations"
              data-colors='["#5156be"]'
              style={{ height: "250px" }}
            >
              <div style={{ width: props.width, height: 500 }}>
           
              </div>
            </div>

            <div className="px-2 py-2">
              <p className="mb-1">
                USA <span className="float-end">75%</span>
              </p>
              <div className="progress mt-2" style={{ height: "6px" }}>
                <div
                  className="progress-bar progress-bar-striped bg-primary"
                  role="progressbar"
                  style={{ width: "75%" }}
                ></div>
              </div>

              <p className="mt-3 mb-1">
                Russia <span className="float-end">55%</span>
              </p>
              <div className="progress mt-2" style={{ height: "6px" }}>
                <div
                  className="progress-bar progress-bar-striped bg-primary"
                  role="progressbar"
                  style={{ width: "55%" }}
                ></div>
              </div>

              <p className="mt-3 mb-1">
                Australia <span className="float-end">85%</span>
              </p>
              <div className="progress mt-2" style={{ height: "6px" }}>
                <div
                  className="progress-bar progress-bar-striped bg-primary"
                  role="progressbar"
                  style={{ width: "85%" }}
                ></div>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default Locations;
