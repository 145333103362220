import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { ActionTypes } from "./actionTypes";
import { 
  addDeviceTypeError, addDeviceTypeSuccess, getDeviceTypeListSuccess, getDeviceTypeListError,
  deleteDeviceTypeSuccess, deleteDeviceTypeError
} from "./actions";

import {
  createIotDeviceType, updateIotDeviceType, getIotDeviceTypeList, deleteIotDeviceType
} from "../../../helpers/iot_backend_helper";
// const fireBaseBackend = getFirebaseBackend();

function* addDeviceType({ payload: { data, history } }: any) {
  try {
    const response: Promise<any> = yield call(createIotDeviceType, data, null);
    history.push(`/org/${data.org_id}/device-types`);
    yield put(addDeviceTypeSuccess(response));
  } catch (error) {
    yield put(addDeviceTypeError(error));
  }
}

function* updateDeviceType({ payload: { id, data, history } }: any) {
  try {
    const response: Promise<any> = yield call(updateIotDeviceType, id, data, null);
    history.push(`/org/${data.org_id}/device-types`);
    yield put(addDeviceTypeSuccess(response));
  } catch (error) {
    yield put(addDeviceTypeError(error));
  }
}

function* getDeviceTypeList({ payload: { data, history } }: any) {
  try {
    const response: Promise<any> = yield call(getIotDeviceTypeList, data, null);
    yield put(getDeviceTypeListSuccess(response));
  } catch (error) {
    yield put(getDeviceTypeListError(error));
  }
}

function* deleteDeviceTypeList({ payload: { data, history } }: any) {
  try {
    const response: Promise<any> = yield call(deleteIotDeviceType, data, null);
    history.replace(`/org/${data.org_id}/device-types`);
    yield put(deleteDeviceTypeSuccess(response));
  } catch (error) {
    yield put(deleteDeviceTypeError(error));
  }
}

function* authSaga() {
  yield takeEvery(ActionTypes.ADD_DEVICE_TYPE, addDeviceType);
  yield takeEvery(ActionTypes.UPDATE_DEVICE_TYPE, updateDeviceType);
  yield takeEvery(ActionTypes.GET_DEVICE_TYPE_LIST, getDeviceTypeList);
  yield takeEvery(ActionTypes.DELETE_DEVICE_TYPE, deleteDeviceTypeList);
}

export default authSaga;
