import { Key } from 'react';
// interface
import {
  getIotAssetsDeviceDataOverviewCombine
} from 'src/helpers/iot_backend_helper';
// Util
import { awaitWrap, transField } from 'src/util';
import dayjs, { Dayjs, ManipulateType } from 'dayjs';

const formatStr = 'YYYY-MM-DD HH:mm:ss';

enum TimeFormat {
  Raw = "YYYY-MM-DD HH:mm:ss",
  Hour = "HH:mm",
  Day = "DD HH:mm",
  Week = "DD HH:mm",
  Month = "MM-DD HH:mm",
  Year = "YYYY-MM-DD HH"
}

// 获取设备数据 KPI
export const fetchKPIsData = async (
  id: string,
): Promise<| [null /* 响应结果 */, any /* 错误信息（满足 message 接口）*/]
  | [any, undefined]> => {
  const [start_at, end_at] = [
    dayjs().subtract(1, 'day').format(TimeFormat.Raw),
    dayjs().format(TimeFormat.Raw),
  ];
  const query = {
    start_at,
    end_at,
    device_id: id,
  };
  const [res, err1] = await awaitWrap(
    getIotAssetsDeviceDataOverviewCombine(query),
  );
  if (err1) {
    return [null, err1];
  }
  // 调整字段
  const response2chart = {
    dataKey: 'name',
    value: 'summary',
    values: 'data',
    chart: 'chart',
  };
  res.Data = res?.Data.map((n: object) => transField(n, response2chart));
  // 生成完整时间轴
  let xAxis: string[] = []
  for (const datum of res.Data) {
    if (datum?.chart) {
      xAxis = datum?.data.map((el: any) => el.date)
    }
  }
  res.supplement = {
    xAxis: {
      data: xAxis,
    },
  };

  return [res, undefined];
};

// ---------- * ---------- * ---------- * ---------- * ---------- * ---------- * ---------- * ---------- * ----------

/**
 * @1 表示开始时间和结束时间之间的时间间隔
 * @2 表示完整数据相邻两组数据间的时间间隔，数据补齐时以此为准
 * @3 x 轴显示时间的格式字符串
 * @4 控制多少组基础数据合并为 1 组最终展示数据
 */
type FmtConf = [
  [number, ManipulateType],
  [number, ManipulateType],
  string,
  number,
];
type FmtAppoint = { [k: string]: FmtConf };
const rel2fmt: FmtAppoint = {
  hour: [[1, 'hour'], [1, 'minute'], TimeFormat.Hour, 1],
  day: [[1, 'day'], [1, 'minute'], TimeFormat.Day, 15],
  week: [[1, 'week'], [1, 'hour'], TimeFormat.Week, 3],
  month: [[30, 'day'], [1, 'hour'], TimeFormat.Month, 6],
};

const abs2fmt: FmtAppoint = {
  free: [[1, 'day'], [1, 'minute'], 'HH:mm', 1],
  hour: [[1, 'hour'], [1, 'minute'], TimeFormat.Hour, 1],
  day: [[1, 'day'], [1, 'minute'], TimeFormat.Day, 15],
  week: [[1, 'week'], [1, 'hour'], TimeFormat.Week, 3],
  month: [[1, 'month'], [1, 'hour'], TimeFormat.Month, 6],
  year: [[1, 'year'], [1, 'week'], TimeFormat.Year, 1],
  custom: [[1, 'minute'], [1, 'minute'], TimeFormat.Raw, 1],
};

type HistoryDataOption = {
  type: 'r' | 'a' | Key;
  value: ManipulateType;
  st?: Dayjs | string;
  et?: Dayjs | string;
};

export const fetchHistoryData = async (
  id: Key,
  ol: Key[],
  opt: HistoryDataOption,
): Promise<[any, undefined] | [null | any]> => {
  const { type, value, st, et } = opt;
  let oft: [number, ManipulateType],
    sp: [number, ManipulateType],
    ftr: string,
    spt: number;
  switch (type) {
    case 'r':
      [oft, sp, ftr, spt] = rel2fmt[value];
      break;
    case 'a':
      [oft, sp, ftr, spt] = abs2fmt[value];
      break;
    default:
      [oft, sp, ftr, spt] = rel2fmt[value];
  }

  let [start_at, end_at] = [
    dayjs(st || et)
      .subtract(...oft)
      .format(formatStr),
    dayjs(et).subtract(1, sp[1]).format(formatStr),
  ];

  const f: any = value;
  if (f == 'custom') {
    start_at = dayjs(st)
      .subtract(...sp)
      .format(formatStr);
  }

  const p = {
    start_at,
    end_at,
    device_id: id,
  };
  const [res, err] = await awaitWrap(getIotAssetsDeviceDataOverviewCombine(p));
  if (err) {
    return [null, undefined];
  }

  let r: any[] = [];
  for (const k of ol) {
    const el = res.Data.find((n: any) => n.dataKey == k);
    if (el) {
      r.push({
        name: k,
        data: el.values.map((n: any) => [
          dayjs(n.date).format(ftr),
          n.value,
        ]),
      });
    }
  }

  return [r, undefined];
};
