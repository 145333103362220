import { ActionTypes } from "./actionTypes";

export const addDeviceConnector = (data: any, history: any) => {
  return {
    type: ActionTypes.ADD_DEVICE_CONNECTOR,
    payload: { data, history },
  };
};

export const addDeviceConnectorSuccess = (data: any) => {
  return {
    type: ActionTypes.ADD_DEVICE_CONNECTOR_SUCCESS,
    payload: data,
  };
};

export const addDeviceConnectorError = (error: any) => {
  return {
    type: ActionTypes.ADD_DEVICE_CONNECTOR_ERROR,
    payload: "创建设备连接器失败",
  };
};
export const getDeviceConnectorList = (data: any, history: any) => {
  return {
    type: ActionTypes.GET_DEVICE_CONNECTOR_LIST,
    payload: { data, history },
  };
};


export const getDeviceConnectorListSuccess = (data: any) => {
  return {
    type: ActionTypes.GET_DEVICE_CONNECTOR_LIST_SUCCESS,
    payload: {list: data.Data, Total: data.Total},
  };
};

export const getDeviceConnectorListError = (error: any) => {
  return {
    type: ActionTypes.GET_DEVICE_CONNECTOR_LIST_ERROR,
    payload: "获取设备连接器列表失败",
  };
};

export const deleteDeviceConnectorList = (data:any, history: any) => {
  return {
    type: ActionTypes.DELETE_DEVICE_CONNECTOR,
    payload: { data, history },
  };
};

export const deleteDeviceConnectorSuccess = (data: any) => {
  return {
    type: ActionTypes.DELETE_DEVICE_CONNECTOR_SUCCESS,
    payload: { data, history },
  };
};

export const deleteDeviceConnectorError = (data: any) => {
  return {
    type: ActionTypes.DELETE_DEVICE_CONNECTOR_ERROR,
    payload: data,
  };
};



