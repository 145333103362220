import {StrictMode} from "react";

import {insure} from "src/util";
import {useTranslation} from "react-i18next";

type Props = {
    className: string;
    onBack: Function;
    onCancel: Function;
}

export default ({className, onBack, onCancel}: Partial<Props>) => {
    const {t} = useTranslation()

    return (<StrictMode>
        <div className={className}>
            <div className="clearfix">
                <span onClick={insure(onBack)} className="float-start font-size-18 fw-bolder cursor-pointer">
                    <i className="fas fa-arrow-left me-2"/>
                    <span className="text-capitalize first-letter-upper">{t("add connector")}</span>
                </span>
                <span className="float-end lh-lg text-primary text-capitalize cursor-pointer"
                      onClick={insure(onCancel)}>
                    {t("cancel")}
                </span>
            </div>
        </div>
    </StrictMode>)
}
