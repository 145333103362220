import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { isEmpty } from "lodash";

import {
    Button,
    Form,
    Card,
    CardBody, CardHeader, CardSubtitle, CardTitle,
    Col,
    Container, Input, Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row, Table,
} from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";

/** Import Full Calendar  */
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";

//import images
// import calendar from "../../assets/images/undraw-calendar.svg";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

//i18n
import { useTranslation } from "react-i18next";

import {
    addNewEvent as onAddNewEvent,
    deleteEvent as onDeleteEvent,
    getCategories as onGetCategories,
    getEvents as onGetEvents,
    updateEvent as onUpdateEvent,
} from "../../../store/apps/Organize/actions";

// import DeleteModal from "./DeleteModal";

//css
import "@fullcalendar/bootstrap/main.css";

//redux
import { useSelector, useDispatch } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
    SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";

import {Link} from "react-router-dom";
import {useLocation} from "react-router";
import {getSyncerById as onGetSyncerById} from "../../../store/settings/Syncers/actions";
interface SyncersAddProps {
    className: string;
}
// TODO: 函数头
const SyncersAdd = ({ className }: SyncersAddProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { state } = useLocation();

    const localState:any = state;

    const columns = [
        {
            dataField: "name",
            text: t("th_Name"),
            sort: true,
        },
        {
            dataField: "value",
            text: t("Value"),
            sort: true,
        },
        {
            dataField: "Operator",
            text: t("th_Operator"),
            sort: true,
        }
    ];

    useEffect(() => {
        dispatch(onGetSyncerById(localState.id));
    }, [dispatch]);

    let { result } = useSelector(function (state: any) {
        return {
            result: state.Syncers.syncers,
        }
    });

    if(!result){
        result = [];
    }

    // Table Data
    let productData:Array<any> = [];

    if(result.length > 0) {
        productData = result;
    }

    const pageOptions: any = {
        sizePerPage: 10,
        totalSize: productData.length, // replace later with size(customers),
        custom: true,
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{t("syncersAdd")}</title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumbs title={t("Authentication")} breadcrumbItem={t("syncersAdd")} />
                    <Card>
                        <CardBody>
                            <div className="mb-3 d-flex flex-wrap gap-2">
                                <button
                                    type="button"
                                    className="btn btn-outline-primary waves-effect waves-light"
                                >
                                    {t("OP_Save")}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-success"
                                >
                                    {t("OP_SaveAndExit")}

                                </button>
                                {/*<button
                                    type="button"
                                    className="btn btn-soft-warning waves-effect waves-light"
                                >
                                    {t("OP_Cancel")}
                                </button>*/}
                                <Link to="/page-syncers" className="btn btn-secondary">
                                    {t("OP_Cancel")}
                                </Link>
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("th_Organize")}</Label>
                                <i className="bx bx-help-circle" title={t("th_Organize")} />
                                <Input className="form-control" type="text" defaultValue={result?result.organization:""} id="example-text-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("th_Name")}</Label>
                                <i className="bx bx-help-circle" title={t("th_Name")} />
                                <Input className="form-control" type="text" defaultValue={result?result.name:""} id="example-text-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("th_Type")}</Label>
                                <i className="bx bx-help-circle" title={t("th_Type")} />
                                <Input className="form-control" type="text" defaultValue={result?result.type:""} id="example-text-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("th_Host")}</Label>
                                <i className="bx bx-help-circle" title={t("th_Host")} />
                                <Input className="form-control" type="text" defaultValue={result?result.host:""} id="example-text-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("th_Port")}</Label>
                                <i className="bx bx-help-circle" title={t("th_Port")} />
                                <Input className="form-control" type="text" defaultValue={result?result.port:""} id="example-text-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("User")}</Label>
                                <i className="bx bx-help-circle" title={t("User")} />
                                <Input className="form-control" type="text" defaultValue={result?result.user:""} id="example-text-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("th_Password")}</Label>
                                <i className="bx bx-help-circle" title={t("th_Password")} />
                                <Input className="form-control" type="text" defaultValue={result?result.password:""} id="example-text-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("th_SQLType")}</Label>
                                <i className="bx bx-help-circle" title={t("th_SQLType")} />
                                <Input className="form-control" type="text" defaultValue={result?result.databaseType:""} id="example-text-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("th_DatabaseName")}</Label>
                                <i className="bx bx-help-circle" title={t("th_DatabaseName")} />
                                <Input className="form-control" type="text" defaultValue={result?result.database:""} id="example-text-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("th_DBTableName")}</Label>
                                <i className="bx bx-help-circle" title={t("th_DBTableName")} />
                                <Input className="form-control" type="text" defaultValue={result?result.table:""} id="example-text-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("PrimaryKey")}</Label>
                                <i className="bx bx-help-circle" title={t("PrimaryKey")} />
                                <Input className="form-control" type="text" defaultValue={result?result.tablePrimaryKey:""} id="example-text-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("Column")}</Label>
                                <i className="bx bx-help-circle" title={t("Column")} />
                                <Input className="form-control" type="text" defaultValue={result?result.tableColumns:""} id="example-text-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("IncList")}</Label>
                                <i className="bx bx-help-circle" title={t("IncList")} />
                                <Input className="form-control" type="text" defaultValue={result?result.incList:""} id="example-text-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("th_Portrait")}URL</Label>
                                <i className="bx bx-help-circle" title={t("th_Portrait")+'URL'} />
                                <Input className="form-control" type="text" defaultValue={result?result.portrait:""} id="example-text-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("th_SyncInterval")}</Label>
                                <i className="bx bx-help-circle" title={t("th_SyncInterval")} />
                                <Input className="form-control" type="text" defaultValue={result?result.syncInterval:""} id="example-text-input" />
                            </div>

                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("Errors")}</Label>
                                <i className="bx bx-help-circle" title={t("Errors")} />
                                <Card>
                                    <CardBody>
                                        <PaginationProvider
                                            pagination={paginationFactory(pageOptions)}
                                            // columns={columns}
                                            // data={productData}
                                        >
                                            {({ paginationProps, paginationTableProps }) => (
                                                <ToolkitProvider
                                                    keyField="id"
                                                    columns={columns}
                                                    data={productData}
                                                    search
                                                >
                                                    {toolkitProps => (
                                                        <React.Fragment>
                                                            <Row>
                                                                <Col xl="12">
                                                                    <div className="table-responsive">
                                                                        <BootstrapTable
                                                                            // responsive
                                                                            bordered={false}
                                                                            striped={false}
                                                                            classes={"table align-middle table-nowrap"}
                                                                            headerWrapperClasses={"thead-light"}
                                                                            {...toolkitProps.baseProps}
                                                                            {...paginationTableProps}
                                                                        />

                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row className="align-items-md-center mt-30">
                                                                <Col className="inner-custom-pagination d-flex">
                                                                    {/*<div className="d-inline">
                                                                        <SizePerPageDropdownStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>*/}
                                                                    <div className="text-md-right ms-auto">
                                                                        <PaginationListStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>
                                                    )}
                                                </ToolkitProvider>
                                            )}
                                        </PaginationProvider>
                                    </CardBody>
                                </Card>
                                <Input className="form-control" type="text" defaultValue={result?result.errorText:""} id="example-text-input" />
                            </div>

                            <div className="div-switch">
                                <Label htmlFor="isAdmin"
                                       className="col-sm-1 col-form-label">
                                    {t("th_Enabled")}
                                    <i className="bx bx-help-circle col-form-label" title={t("th_Enabled")} />
                                </Label>
                                <Input type="checkbox" id="isAdmin" switch="none" defaultChecked={result?result.isEnabled:false} />
                                <Label htmlFor="isAdmin" data-on-label="On" data-off-label="Off"></Label>
                            </div>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default SyncersAdd;
