import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./style.scss";
//i18n
import { withTranslation } from "react-i18next";
import { withRouter, Link } from "react-router-dom";
import { Button, Label, Row, Col } from "reactstrap";

//redux
import { useDispatch } from "react-redux";

import Steps, { StepProps } from "src/components/Steps";
import Basicinformation from "./Basicinformation";
import AdditionalInformation from "./AdditionalInformation";
import AceEditorPage from "src/components/AceEditorPage";
import {
  uploadFile
} from "src/helpers/iot_backend_helper";
// actions
import { addDeviceType, updateDeviceType } from "src/store/actions";
import {
  getIotDeviceType
} from "src/helpers/iot_backend_helper";
import { useRole } from '../../../../util';

type TDeviceType = {
  name: string;
  description: string;
  manufacturer: string;
  datasheet_url: string;
  script_author: string;
  firmware_version: string;
  uplink_script: string;
  downlink_script: string;
  org_id: number;
  payload_encoding: string;
  picture_url: string;
  connectivity: string
}

const AddDeviceType = (props: any) => {
  const [loadding, setLoadding] = useState<boolean>(true);
  const { t, match } = props;
  const { orgId, id } = match.params;
  const pageRole = useRole(orgId,undefined)

  const dispatch = useDispatch();
  const [inProgress, setInProgress] = useState<number>(1);
  const [formData, setFormData] = useState<any>({});
  const [aceEditorType, setAceEditorType] = useState<string>('');
  const [showAceEditor, setShowAceEditor] = useState<boolean>(false);

  const steps = [{
    step: 1,
    des: 'Basic Information',
  }, {
    step: 2,
    des: t("additional information")
  }, {
    step: 3,
    des: t("device type scripts")
  }];

  const stepProps: StepProps = {
    steps,
    inProgress
  }

  useEffect(() => {
    if (id) {
      getIotDeviceType(id, null).then(result => {
        if (result && result.Code == 200) {
          setFormData({
            ...result.Data,
            connectivity: { label: result.Data.connectivity, value: result.Data.connectivity },
            encoding: { label: result.Data.payload_encoding, value: result.Data.payload_encoding }
          });
        }
        setLoadding(false);
      }).catch(err => setLoadding(false));
    } else {
      setLoadding(false);
    }
  }, []);

  // 下一步
  const nextStep = (obj: object): void => {
    let data = { ...formData, ...obj };
    setFormData(data);
    setInProgress(inProgress + 1);
  }

  // 上一步
  const preStep = (): void => {
    setInProgress(inProgress - 1);
  }

  const basicinformationProps = {
    formData,
    next: nextStep,
    pre: preStep
  }

  // 显示隐藏脚本编辑器
  const toggleAceEditor = (type: string): void => {
    setAceEditorType(type);
    setShowAceEditor(!showAceEditor);
  }

  // 隐藏脚本编辑器
  const hideAceEditor = (): void => {
    setShowAceEditor(false);
  }

  // 脚本保存
  const saveScript = (data: string): void => {
    formData[aceEditorType] = data;
    setFormData(formData);
    setShowAceEditor(false);
  }

  const defaultScript = `function consume(event) {
  var encoded = ""; //TODO implement encoding
  var port = event.port ? event.port : 1;
  var confirmed = event.confirmed ? event.confirmed : false;
  var payload = event.payload;
  emit("downlink", { payloadHex: payload, port: port, confirmed: confirmed });
}`
  const aceEditorProps = {
    type: aceEditorType,
    script: formData[aceEditorType] || defaultScript,
    cancel: hideAceEditor,
    save: saveScript
  }

  // 创建设备类型
  const createDeviceType = async (): Promise<void> => {
    let postData: TDeviceType = {
      name: formData.name,
      description: formData.description,
      manufacturer: formData.manufacturer,
      datasheet_url: formData.datasheet_url,
      script_author: formData.script_author,
      firmware_version: formData.firmware_version,
      uplink_script: '',
      downlink_script: '',
      org_id: orgId,
      payload_encoding: '',
      picture_url: '',
      connectivity: ''
    };
    if (formData.selectedFiles && formData.selectedFiles.length) {
      const data = new FormData();
      data.append("file", formData.selectedFiles[0]);
      let result = await uploadFile(data, null);
      if (result && result.Code == 200) {
        postData.picture_url = `${result.Data.host}${result.Data.file}`
      }
    }
    if (formData.uplink_script) {
      postData.uplink_script = formData.uplink_script;
    }
    if (formData.downlink_script) {
      postData.downlink_script = formData.downlink_script;
    }
    if (formData.connectivity) {
      postData.connectivity = formData.connectivity.value
    }
    if (formData.encoding) {
      postData.payload_encoding = formData.encoding.value
    }
    if (id) {
      dispatch(updateDeviceType(id, postData, props.history));
    } else {
      dispatch(addDeviceType(postData, props.history));
    }
  }
  if (loadding) return (<></>)
  return (
    <React.Fragment>
      {
        showAceEditor ? <div className="page-content min-vh-100" >
          <AceEditorPage {...aceEditorProps} />
        </div> : <div className="min-vh-100" style={{ flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'cente', justifyContent: 'space-between', padding: '16px 30px', borderBottom: '1px solid #e8e8e8' }}>
            <div className="first-letter-upper" style={{ fontSize: '20px', color: 'rgba(0,0,0,.85)', fontWeight: 700 }}>
              {t(id ? 'update device type' : 'create new device type')}
            </div>
            <Link to={`/org/${orgId}/device-types`}>
              <button
                type="button"
                style={{ fontSize: '16px' }}
                className="btn btn-outline-light waves-effect text-capitalize"
              >
                {t('cancel')}
              </button>
            </Link>
          </div>
          <Row className="px-8 py-8">
            <Col sm={6} md={4} xs={12}>
              {
                inProgress == 1 && <Basicinformation {...basicinformationProps} />
              }
              {
                inProgress == 2 && <AdditionalInformation next={nextStep} pre={preStep} formData={formData} />
              }
              {
                inProgress == 3 &&
                <div>
                  <h5 style={{ marginBottom: '30px' }}>{t('device type scripts')}</h5>
                  <Row>
                    <div>
                      <div className="mb-4">
                        <Label htmlFor="example-text-input" className="form-Label">{t('uplink decoder')}</Label>
                        {
                          formData.uplink_script ? <div className="s-box">
                            <i className="fas fa-check-circle" style={{ marginRight: '10px', fontSize: '16px', color: '#52c41a' }}></i>
                            {t("script is present")}
                          </div> : <div className="s-box">
                            <i className="fas fa-info-circle" style={{ marginRight: '10px', fontSize: '16px', color: '#faad14' }}></i>
                            {t("script is required")}
                          </div>
                        }
                        <button
                          type="button"
                          style={{ fontSize: '16px' }}
                          onClick={() => toggleAceEditor('uplink_script')}
                          className="btn btn-outline-light waves-effect mt-3"
                        >
                          {`+ ${t(formData.uplink_script ?  pageRole=="viewer" ? "viewing uplink encoder" :
                            "edit uplink decoder" : "create uplink decoder")}`}
                        </button>
                      </div>

                      <div className="mb-4">
                        <Label htmlFor="example-text-input" className="form-Label">
                          {`${t('downlink encoder')} (${t("optional")})`}
                        </Label>
                        {
                          formData.downlink_script ? <div className="s-box">
                            <i className="fas fa-check-circle" style={{ marginRight: '10px', fontSize: '16px', color: '#52c41a' }}></i>
                            {t("script is present")}
                          </div> : <div className="d-box">
                            <i className="fas fa-info-circle" style={{ marginRight: '10px', fontSize: '16px', color: '#1890ff' }}></i>
                            {t("no encoder script for downlink")}
                          </div>
                        }
                        <button
                          type="button"
                          style={{ fontSize: '16px' }}
                          onClick={() => toggleAceEditor('downlink_script')}
                          className="btn btn-outline-light waves-effect mt-3"
                        >
                          {`+ ${t(formData.downlink_script ? pageRole=="viewer" ? "viewing downlink encoder" :
                          "edit downlink encoder" : "create downlink encoder")}`}
                        </button>
                      </div>

                      <div className="mt-4 mb-4 flex-row space-between">
                        <button
                          type="button"
                          onClick={preStep}
                          className="btn btn-outline-light waves-effect font-size-16 text-capitalize"
                        >
                          {t("back")}
                        </button>
                        <Button disabled={!formData.uplink_script || pageRole=="viewer"} type="button" color="primary" onClick={createDeviceType}>
                          {t(id ? 'update device type' : 'create device type')}
                        </Button>
                      </div>
                    </div>
                  </Row>
                </div>
              }
            </Col>
            <Col sm={6} md={4} xs={12} style={{ paddingLeft: '30px' }}>
              <Steps {...stepProps} />
            </Col>
          </Row>
        </div>
      }
    </React.Fragment>
  );
}

AddDeviceType.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withTranslation()(withRouter(AddDeviceType));