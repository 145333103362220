const deviceConnectorOptions = [
    {
        value: "http",
        label: "HTTP",
        description: "select HTTP as device connector to transfer data via Hypertext Transfer Protocol",
        iconClassName: "fas fa-align-right text-danger"
    },
    {
        value: "MQTT",
        label: "MQTT",
        description: "select MQTT as device connector to lightweight, publish-subscribe network protocol",
        iconClassName: "fas fa-align-left text-danger"
    }
]

export const fetchDeviceConnectorTypeOptions = (deviceConnector?: string | null) => {
    if (!deviceConnector) {
        return deviceConnectorOptions
    }

    const selectedConnector = deviceConnectorOptions.find((option) => option.value == deviceConnector)

    return selectedConnector && [selectedConnector]
}
