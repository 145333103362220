import {Key, StrictMode} from "react";

import {insure} from "src/util";

export default ({data, onClick}: any) => {
    return (<StrictMode>
        <div className="mt-narrow-pre border border-blue-hover rounded-2 px-2 py-2 font-size-16"
             onClick={insure(onClick, data)}>
            <i className={`me-2 mdi mdi-link-variant`}/>
            <span className="fw-bold text-capitalize">{data.name}</span>
        </div>
    </StrictMode>)
}
