import {Key, useState} from "react";
// Component
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import {DeviceItem} from "../Cell";
// Util
import {insure} from "src/util";

type props = {
  options?: DeviceItem[];
  select?: (uid: Key) => any;
}

export default function ({options, select}: props) {
  const [isOpen, setIsOpen] = useState(false)

  return <div className=" border rounded-2 py-3 h-100">
    <div className="position-relative w-100 cus-h-1" style={{paddingBottom: "56.25%"}}>
      <div className="position-absolute
            d-flex justify-content-center align-items-center
            w-100 h-100">
        <Dropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
          <DropdownToggle color="primary" disabled={!options?.length}>
            <i className="fas fa-plus"/>
          </DropdownToggle>
          <DropdownMenu>
            {options?.length ? options.map(n =>
              <DropdownItem key={n.id} onClick={insure(select, n.id)}>
                {n.name}
              </DropdownItem>) : <DropdownItem/>}
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
  </div>
}
