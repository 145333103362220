import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { isEmpty } from "lodash";

import {
    Button,
    Form,
    Card,
    CardBody, CardHeader, CardSubtitle, CardTitle,
    Col,
    Container, Input, Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row, Table,
} from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";

/** Import Full Calendar  */
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";

//import images
// import calendar from "../../assets/images/undraw-calendar.svg";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

//i18n
import { useTranslation } from "react-i18next";

import {
    addNewEvent as onAddNewEvent,
    deleteEvent as onDeleteEvent,
    getCategories as onGetCategories,
    getEvents as onGetEvents,
    updateEvent as onUpdateEvent,
} from "../../../store/apps/Organize/actions";

// import DeleteModal from "./DeleteModal";

//css
import "@fullcalendar/bootstrap/main.css";

//redux
import { useSelector, useDispatch } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
    SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";

import {Link} from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import {useLocation} from "react-router";
import {getWebhookById as onGebhookById} from "../../../store/settings/Webhooks/actions";
interface WebhooksAddProps {
    className: string;
}
// TODO: 函数头
const Webhooks = ({ className }: WebhooksAddProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { state } = useLocation();

    const localState:any = state;

    useEffect(() => {
        dispatch(onGebhookById(localState.id));
    }, [dispatch]);

    let { result } = useSelector(function (state: any) {
        return {
            result: state.Webhooks.webhooks,
        }
    });

    if(localState.ifAdd){
        result = null
    }

    const columns = [
        {
            dataField: "name",
            text: t("th_Name"),
            sort: true,
        },
        {
            dataField: "value",
            text: t("Value"),
            sort: true,
        },
        {
            dataField: "Operator",
            text: t("th_Operator"),
            sort: true,
        }
    ];

    /*let { result } = useSelector(function (state: any) {
        return {
            result: state.Webhooks.webhooks,
        }
    });*/

    // Table Data
    let productData:Array<any> = [];

    if(result && result.length > 0) {
        productData = result;
    }

    const pageOptions: any = {
        sizePerPage: 10,
        totalSize: productData.length, // replace later with size(customers),
        custom: true,
    };

    const animatedComponents = makeAnimated();

    let optionMulti = null;
    if(result && result.hasOwnProperty('events')){
        optionMulti = result? result.events.map((item:any,index:number)=>{
            return { label: item, value: item }
        }) :[];
    }

    if(localState.ifAdd){
        result = null
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{t("webhooksAdd")}</title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumbs title={t("Authentication")} breadcrumbItem={t("webhooksAdd")} />
                    <Card>
                        <CardBody>
                            <div className="mb-3 d-flex flex-wrap gap-2">
                                <button
                                    type="button"
                                    className="btn btn-outline-primary waves-effect waves-light"
                                >
                                    {t("OP_Save")}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-success"
                                >
                                    {t("OP_SaveAndExit")}

                                </button>
                                {/*<button
                                    type="button"
                                    className="btn btn-soft-warning waves-effect waves-light"
                                >
                                    {t("OP_Cancel")}
                                </button>*/}
                                <Link to="/page-webhooks" className="btn btn-secondary">
                                    {t("OP_Cancel")}
                                </Link>
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("th_Organize")}</Label>
                                <i className="bx bx-help-circle" title={t("th_Organize")} />
                                <Input className="form-control" type="text" defaultValue={result?result.organization:""} id="example-text-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("th_Name")}</Label>
                                <i className="bx bx-help-circle" title={t("th_Name")} />
                                <Input className="form-control" type="text" defaultValue={result?result.name:""} id="example-text-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("th_websiteUrl")}</Label>
                                <i className="bx bx-help-circle" title={t("th_websiteUrl")} />
                                <Input className="form-control" type="text" defaultValue={result?result.url:""} id="example-text-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("th_Method")}</Label>
                                <i className="bx bx-help-circle" title={t("th_Method")} />
                                <Input className="form-control" type="text" defaultValue={result?result.method:""} id="example-text-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("th_ContentType")}</Label>
                                <i className="bx bx-help-circle" title={t("th_ContentType")} />
                                <Input className="form-control" type="text" defaultValue={result?result.contentType:""} id="example-text-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("Headers")}</Label>
                                <i className="bx bx-help-circle" title={t("Headers")} />
                                <Card>
                                    <CardBody>
                                        <PaginationProvider
                                            pagination={paginationFactory(pageOptions)}
                                            // columns={columns}
                                            // data={productData}
                                        >
                                            {({ paginationProps, paginationTableProps }) => (
                                                <ToolkitProvider
                                                    keyField="id"
                                                    columns={columns}
                                                    data={productData}
                                                    search
                                                >
                                                    {toolkitProps => (
                                                        <React.Fragment>
                                                            <Row>
                                                                <Col xl="12">
                                                                    <div className="table-responsive">
                                                                        <BootstrapTable
                                                                            // responsive
                                                                            bordered={false}
                                                                            striped={false}
                                                                            classes={"table align-middle table-nowrap"}
                                                                            headerWrapperClasses={"thead-light"}
                                                                            {...toolkitProps.baseProps}
                                                                            {...paginationTableProps}
                                                                        />

                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row className="align-items-md-center mt-30">
                                                                <Col className="inner-custom-pagination d-flex">
                                                                    {/*<div className="d-inline">
                                                                        <SizePerPageDropdownStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>*/}
                                                                    <div className="text-md-right ms-auto">
                                                                        <PaginationListStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>
                                                    )}
                                                </ToolkitProvider>
                                            )}
                                        </PaginationProvider>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("th_Events")}</Label>
                                <i className="bx bx-help-circle" title={t("th_Events")} />
                                <Select
                                    defaultValue={optionMulti}
                                    isMulti={true}
                                    options={optionMulti}
                                    classNamePrefix="select2-selection"
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                />
                            </div>
                            <div className="div-switch">
                                <Label className="col-sm-1 col-form-label">
                                    {t("th_UserExtended")}
                                    <i className="bx bx-help-circle col-form-label" title={t("th_UserExtended")} />
                                </Label>
                                <Input type="checkbox" id="isUserExtended" switch="none" defaultChecked={result?result.isUserExtended:false} />
                                <Label htmlFor="isUserExtended" data-on-label="On" data-off-label="Off"></Label>
                            </div>

                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">{t("th_Preview")}</Label>
                                <i className="bx bx-help-circle" title={t("th_Preview")} />
                                <Input className="form-control" type="text" defaultValue="" id="example-text-input" />
                            </div>

                            <div className="div-switch">
                                <Label className="col-sm-1 col-form-label">
                                    {t("th_Enabled")}
                                    <i className="bx bx-help-circle col-form-label" title={t("th_Enabled")} />
                                </Label>
                                <Input type="checkbox" id="isEnabled" switch="none" defaultChecked={result?result.isEnabled:false} />
                                <Label htmlFor="isEnabled" data-on-label="On" data-off-label="Off"></Label>
                            </div>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Webhooks;
