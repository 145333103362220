/**
 * interface for Tokens type
 */
 export enum TokensTypes {
    API_SUCCESS = '@@tokens/API_SUCCESS',
    API_FAIL = '@@tokens/API_FAIL',
    GET_EVENTS = '@@tokens/GET_EVENTS',
    ADD_NEW_EVENT = '@@tokens/ADD_NEW_EVENT',
    UPDATE_EVENT = '@@tokens/UPDATE_EVENT',
    UPDATE_EVENT_SUCCESS = '@@tokens/UPDATE_EVENT_SUCCESS',
    UPDATE_EVENT_FAIL = '@@tokens/UPDATE_EVENT_FAIL',
    DELETE_EVENT = '@@tokens/DELETE_EVENT',
    DELETE_EVENT_SUCCESS = '@@tokens/DELETE_EVENT_SUCCESS',
    DELETE_EVENT_FAIL = '@@tokens/DELETE_EVENT_FAIL',
    GET_CATEGORIES = '@@tokens/GET_CATEGORIES',
    GET_CATEGORIES_SUCCESS = '@@tokens/GET_CATEGORIES_SUCCESS',
    GET_CATEGORIES_FAIL = '@@tokens/GET_CATEGORIES_FAIL',
    GET_TOKENS = '@@tokens/GET_TOKENS',
    GET_TOKENS_SUCCESS = '@@tokens/GET_TOKENS_SUCCESS',
    GET_TOKENS_FAIL = '@@tokens/GET_TOKENS_FAIL',
    GET_TOKENBYID = '@@tokens/GET_TOKENBYID',
    GET_TOKENBYID_SUCCESS = '@@tokens/GET_TOKENBYID_SUCCESS',
    GET_TOKENBYID_FAIL = '@@tokens/GET_TOKENBYID_FAIL',
 }

 export interface TokensState {
   events  : Array<any>;
   categories : Array<any>;
   error : Object;
}