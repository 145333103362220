export const countries = [
  {
    abrv: "zh_CN",
    name: "China",
  },
  {
    abrv: "U.S.A",
    name: "America",
  }
]

export const phoneNumberPrefixes = [
  "+86", "+41"
]
