// i18n
import { useTranslation } from 'react-i18next';
// Component
import { Badge } from 'reactstrap';
import deviceTypeLogo from 'src/assets/images/deviceType-noImage.svg';

type Props = {
  connectivity?: string;
  description?: string;
  firmware_version?: string;
  pictureUrl?: string;
};

export default ({
  connectivity,
  description,
  firmware_version,
  pictureUrl,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex px-3 pt-3">
      <div className="d-flex flex-1 me-2 rounded-circle text-center lh-1">
        <div>
          <img
            className="object-contain w-100"
            src={pictureUrl || deviceTypeLogo}
            alt="load..."
          />
        </div>
      </div>
      <div className="flex-2">
        <div className="gutter-t-normal">
          <span className="me-2 text-secondary text-capitalize">
            {t('technology')}:
          </span>
          <span>
            <Badge
              className="gutter-s-normal border border-secondary bg-light"
              color="black">
              {connectivity}
            </Badge>
          </span>
        </div>
        <div className="gutter-t-normal">
          <div className="text-secondary text-capitalize">
            {t('description')}:
          </div>
          <div>{description}</div>
        </div>
        <div className="gutter-t-normal">
          <span className="text-secondary me-2">{t('Firmware version')}:</span>
          <span>{firmware_version}</span>
        </div>
      </div>
    </div>
  );
};
