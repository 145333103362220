import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from "prop-types";
//i18n
import { withTranslation } from "react-i18next";
import { withRouter, Link } from "react-router-dom";
import Tags from 'src/components/Common/Tags';
import {
  Card,
  CardBody,
  Col,
  Row,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from 'reactstrap';
//redux
// datatable related plugins
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import { useSelector } from "react-redux";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BulkImportModal from "./BulkImportModal";
import ConfirmModal from "../components/ConfirmModal"
import Loading from "src/components/Loading"
import Table from 'src/components/Table'
import {
  getIotDevices, deleteIotDevice
} from "src/helpers/iot_backend_helper";

interface IParams {
  page_size?: number,
  page_number?: number,
  org_id?: string;
  workspace_id?: string;
  order?: string
}

const Assets = (props: any) => {
  let { t, history, match } = props;
  const { orgId, wsId } = match.params;
  const [visiable, setVisiable] = useState(false);
  const [dropdownOpen, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [loadding, setLoadding]: [boolean, Function] = useState(true);
  const [currentRow, setCurrentRow] = useState<any>(null);
  const [list, setList] = useState([]);

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);

  const { user } = useSelector((state: any) => ({
    user: state.login.user
  }));

  const role = (wsId ?
      user.user_workspaces.find((ws : any)=>ws.workspace.id == wsId)?.role :
      user.user_organizations.find((org : any)=>org.organization.id == orgId)?.role) ||
    "viewer"

  useEffect(() => {
    fetchData({ page_size: 10, page_number: 1 })
  },[orgId,wsId]);
  // 获取设备类型列表数据
  const fetchData = (params: IParams) => {
    params.org_id = orgId
    params.workspace_id = wsId
    getIotDevices(params, null).then((result: any) => {
      if (result && result.Code == 200) {
        let arr = result.Data.map((row: any) => {
          return {
            id: row.id,
            name: row.name,
            assetType: 'Device',
            connectivityStatus: row.connectivity,
            deviceId: row.device_id,
            workspaceId: row.workspace_id,
            dataFlowId: row.data_flow_id,
            tags: row.tags || [],
            sql: '',
            dataFlow: row.data_flow && row.data_flow.name
          }
        })
        setTotal(result.Total)
        setList(arr);
      }
    }).finally(() => setLoadding(false))
  }
  const toggle = () => setOpen(!dropdownOpen);

  const removeDevice = (row: any) => {
    setIsOpen(true);
    setCurrentRow(row);
  }

  const handleSort = (field:string, order:"asc"|"desc") => {
    switch (field) {
      case "connectivityStatus":
        field = "connectivity";
        break;
      case "deviceId":
        field = "device_id"
    }

    fetchData({page_size:pageSize, page_number:pageNumber, order:`${field}=${order}`})
  }

  const columns = [
    {
      dataField: "assetType",
      text: props.t('AssetType'),
      headerClasses: 'pb-3',
    },
    {
      dataField: "name",
      text: props.t('Name'),
      formatter: (value: any, row: any, index: any) => (
        <Link to={wsId ? `/org/${orgId}/ws/${wsId}/assets/device/${row.id}` : `/org/${orgId}/assets/device/${row.id}`} className="cursor-pointer">
          {value}
        </Link>
      ),
      sort: true,
      onSort: handleSort
    },
    {
      dataField: "connectivityStatus",
      text: props.t('ConnectivityStatus'),
      sort: true,
      onSort: handleSort
    },
    {
      dataField: "deviceId",
      text: props.t('AssetID'),
      sort: true,
      onSort: handleSort
    },
    {
      dataField: "tags",
      text: props.t('Tags'),
      formatter: (value: any, row: any, index: any) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
            {
              row.tags.map((tag: any) => <Tags title={tag.name} key={tag.id} icon="" close={false} />)
            }
          </div>
        )
      },
      sort: true,
      onSort: handleSort
    },
    {
      dataField: "sql",
      text: props.t('SQI'),
      sort: true,
      onSort: handleSort
    },
    {
      dataField: "dataFlow",
      text: props.t('DataFlow'),
      formatter: (value: any, row: any, index: any) => (
        <Link
          to={wsId ? `/org/${orgId}/ws/${wsId}/data-flows/${row.dataFlowId}` : `/org/${orgId}/data-flows/${row.dataFlowId}`}
          className="cursor-pointer"
        >
          {value}
        </Link>
      ),
      sort: true,
      onSort: handleSort
    },
    {
      dataField: "Action",
      text: props.t('Action'),
      formatter: (value: any, row: any, index: any) => (
        <div className="flex-row">
          {
            (role === "editor" || role === "admin") ? <Link
              to={`/org/${orgId}/ws/${row.workspaceId}/assets/${row.id}/edit`}
              className="cursor-pointer me-4"
            >
              {t('Edit')}
            </Link>: <div className="cursor-not-allowed me-4 text-muted">{t('Edit')}</div>
          }
          {
            (role === "editor" || role === "admin") ? <div onClick={() => removeDevice(row)} className="text-danger">{t('Delete')}</div>:
              <div className="cursor-not-allowed text-muted">{t('Delete')}</div>
          }
        </div>

      ),
    },
  ];

  const defaultSorted: any = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const pageOptions: any = {
    sizePerPage: pageSize,
    totalSize: total, // replace later with size(customers),
    custom: true,
    page: pageNumber,
  };

  // Select All Button operation
  const selectRow: any = {
    mode: "checkbox",
  };

  const { SearchBar } = Search;
  function togModal() {
    setVisiable(!visiable);
  }
  const bulkImportModalProps = {
    visiable: visiable,
    togModal: togModal,
    hideModal: () => {
      setVisiable(false)
    },
    save: () => {

    }
  }
  const confirmRemove = () => {
    let { id } = currentRow;
    deleteIotDevice(id, null).then(result => {
      if (result && result.Code == 200) {
        let data = list.filter((item: any) => item.id != currentRow.id);
        setList(data);
        setIsOpen(false);
      }
    });
  }

  const confirmModalProps = {
    isOpen: isOpen,
    hideModal: () => setIsOpen(false),
    title: 'Do you really want to delete this Asset?',
    description: 'This action cannot be undone',
    confirmText: 'delete',
    confirm: confirmRemove
  }
  const handleTableChange = async (type: string, { page, sizePerPage }: any) => {
    if (type == "sort") {
      return
    }
    fetchData({ page_size: sizePerPage, page_number: page });
    setPageNumber(page);
    setPageSize(sizePerPage);
  }
  return (
    <React.Fragment>
      {loadding && <Loading loading={loadding} />}
      <div className="px-4 py-4 flex-col">
        <div className="flex-row items-center justify-between px-3 mb-4">
          <div className="text-dark" style={{ fontSize: '20px', fontWeight: 700 }}>{props.t('OrganizationAssetInventory')}</div>
          {(wsId) && <div>
            <button
              type="button"
              disabled={role == "viewer"}
              style={{ background: '#1890ff', fontSize: '16px' }}
              onClick={() => {
                history.push(`/org/${orgId}/ws/${wsId}/assets/add`)
              }}
              className="btn btn-primary waves-effect waves-light"
            >
              <span style={{ fontSize: '18px', marginRight: '10px' }}>+ </span>
              {props.t('CreateDevice')}
            </button>
          </div>}
        </div>
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
               <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  // columns={columns}
                  // data={list}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={list}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} placeholder={props.t('SearchWorkspacelist')} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    // responsive
                                    bordered={false}
                                    remote
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    selectRow={selectRow}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    onTableChange={handleTableChange}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>

      </div>
      <BulkImportModal {...bulkImportModalProps} />
      <ConfirmModal {...confirmModalProps} />
    </React.Fragment>
  );
}

Assets.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withTranslation()(withRouter(Assets));