"use strict"

export class StateMachine {
  _s: number
  _c: number = 0

  constructor(s: number) {
    this._s = s
  }

  increase(): number {
    this._c++
    return this._c
  }

  decide(): boolean {
    return Boolean(this._c % this._s)
  }

  // 自动循环计数
  round(): boolean {
    this.increase()
    return this.decide()
  }
}
