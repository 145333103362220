import {WebhooksTypes,WebhooksState} from "./actionTypes"

export const INIT_STATE : WebhooksState = {
  events: [],
  categories: [],
  error: {},
}

const Webhooks = (state = INIT_STATE, action : any) => {
  switch (action.type) {
    case WebhooksTypes.API_SUCCESS:
      switch (action.payload.actionType) {
        case WebhooksTypes.GET_EVENTS:
          return {
            ...state,
            events: action.payload.data,
          };

        case WebhooksTypes.ADD_NEW_EVENT:
          return {
            ...state,
            events: [...state.events, action.payload.data],
          }

        default:
          return { ...state };
      }
    case WebhooksTypes.API_FAIL:
      switch (action.payload.actionType) {
        case WebhooksTypes.GET_EVENTS:
          return {
            ...state,
            events: action.payload.error,
          };

        case WebhooksTypes.ADD_NEW_EVENT:
          return {
            ...state,
            events: action.payload.error,
          };

        default:
          return { ...state };
      }

    case WebhooksTypes.UPDATE_EVENT_SUCCESS:
      return {
        ...state,
        events: state.events.map(event =>
          event.id.toString() === action.payload.id.toString()
            ? { event, ...action.payload }
            : event
        ),
      }

    case WebhooksTypes.UPDATE_EVENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case WebhooksTypes.DELETE_EVENT_SUCCESS:
      return {
        ...state,
        events: state.events.filter(
          event => event.id.toString() !== action.payload.id.toString()
        ),
      }

    case WebhooksTypes.DELETE_EVENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case WebhooksTypes.GET_WEBHOOKS:
      return {
        ...state,
        // events: action.payload.data,
        payload: state
      }

    case WebhooksTypes.GET_WEBHOOKS_SUCCESS:
      return {
        ...state,
        webhooks: action.payload,
      }

    case WebhooksTypes.GET_WEBHOOKS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case WebhooksTypes.GET_WEBHOOKBYID:
      return {
        ...state,
        // events: action.payload.data,
        payload: state
      }

    case WebhooksTypes.GET_WEBHOOKBYID_SUCCESS:
      return {
        ...state,
        webhooks: action.payload,
      }

    case WebhooksTypes.GET_WEBHOOKBYID_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default Webhooks
