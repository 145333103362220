import { takeEvery, put, call, all, fork } from "redux-saga/effects"

// Calender Redux States
import { ProvidersTypes } from "./actionTypes"
import {
  getProviders, getProvidersSuccess, getProvidersFail, getProviderByIdSuccess, getProviderByIdFail
} from "./actions"

//Include Both Helper File with needed methods
import {
  api_getProviderById,
  api_getProviders,
} from "../../../API/api"

// 获取全部用户信息
function* onGetProviders() {
  try {
    const response : Promise<any> = yield call(api_getProviders)
    yield put(getProvidersSuccess(response))
  } catch (error) {
    yield put(getProvidersFail(error))
  }
}

export function* watchOnGetProviders() {
  yield takeEvery(ProvidersTypes.GET_Providers, onGetProviders);
}

// 获取单个用户信息
function* onGetProviderById({payload:id}:any) {
  try {
    const response : Promise<any> = yield call(api_getProviderById,id)
    yield put(getProviderByIdSuccess(response))
  } catch (error) {
    yield put(getProviderByIdFail(error))
  }
}

export function* watchOnGetProviderById() {
  yield takeEvery(ProvidersTypes.GET_ProviderByID, onGetProviderById);
}

function* providersSaga() {
  yield all([fork(watchOnGetProviders)]);
  yield all([fork(watchOnGetProviderById)]);
}

export default providersSaga
