import {Key, StrictMode} from "react";

import {insure} from "src/util";

type Props = {
    id: Key;
    selected: boolean;
    simplify: boolean,
    name: Key;
    description: string;
    onSelect: Function;
}

export default ({selected, simplify, name, description, onSelect}: Partial<Props>) => {
    return (<StrictMode>
        <div className={`border-bottom px-4 py-4 ${selected && "bg-soft-light pointer-events-none"}`}
             onClick={insure(onSelect)}>
            <h5 className="mb-2">{name}</h5>
            <div hidden={simplify}>{description}</div>
        </div>
    </StrictMode>)
}
