import { Key, StrictMode, useContext, useState } from "react";

import RuleContainer from "../common/RuleContainer";
import Guide from "./Guide";
import LogicTypeSelector from "./LogicTypeSelector";
import { currying } from "src/util";
import { RuleContext } from "../reducer";
import { MODE } from "./constant";
const guideProps = {
    title: "logic",
    prompt: "click to add an logic block",
}
export default () => {
    const [mode, setMode] = useState(MODE.GUIDE)
    const changeMode = (destination: MODE, callback?: <T = MODE>(destination: T, origin: T) => T) => {
        setMode((origin) => {
            return callback && callback(destination, origin) || destination
        })
    }

    const { dispatch } = useContext(RuleContext)

    const handleLogicTypeSelect = (data: any) => {
        const { type, name, attribute, description, id } = data;
        const dataSourcesID = `dataSource::${String(Date.now())}`;
        if (type == "CUSTOM_LOGIC") {
            const action = {
                type: "SELECT_LOGIC_BLOCK",
                payload: {
                    type: type,
                    name: name,
                    custom_logic: {
                        action_ids: [],
                        custom_logic_id: id,
                        name: name,
                        description: description,
                        properties: attribute?.parameters.map((p: any) => ({
                            description: p.description,
                            name: p.display_name,
                            type: p.parameter_type,
                            value: p.dafault_value,
                            variable_name: p.variable_name
                        }))
                    },
                    data_sources: attribute?.inputs.map((d: any) => ({
                        id: `dataSource::${d.id}`,
                        topic: d.topic,
                        key: d.datakey,
                        input_ids: [],
                        description: d.description,
                        last_sample: true,
                        trigger_on_uplink: false,
                        value_type: "NUMERICAL",
                        variable_name: d.variable_name
                    })),
                    logic_blocks: []
                },
            }
            dispatch(action);
            setMode(MODE.GUIDE)
        } else {
            const action = {
                type: "SELECT_LOGIC_BLOCK",
                payload: {
                    type: type,
                    name: name,
                    customLogic: {},
                    data_sources: [{
                        id: dataSourcesID,
                        topic: '',
                        key: '',
                        input_ids: [],
                        last_sample: true,
                        trigger_on_uplink: false,
                        value_type: "NUMERICAL",
                        variable_name: null
                    }],
                    logic_blocks: [{
                        id: `logicBlock::${String(Date.now())}`,
                        data_source_id: dataSourcesID,
                        action_ids: [],
                        conditions: [{
                            chainOperator: null,
                            operator: "EQ",
                            type: "CONSTANT",
                            value: 0
                        }]
                    }]
                },
            }
            dispatch(action);
            setMode(MODE.GUIDE)
        }
    }

    const mode2component = {
        [MODE.GUIDE]: <Guide {...guideProps} onAdd={currying(changeMode, MODE.SELECT_LOGIC_BLOCK)} />,
        [MODE.SELECT_LOGIC_BLOCK]: <LogicTypeSelector onCancel={currying(changeMode, MODE.GUIDE)}
            onSelect={handleLogicTypeSelect} />,
    }
    return (<StrictMode>
        <RuleContainer className={mode != MODE.GUIDE && "cus-bg-white"}>
            {mode2component[mode]}
        </RuleContainer>
    </StrictMode>)
}
