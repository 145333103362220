import { takeEvery, put, call, all, fork } from "redux-saga/effects"

// Calender Redux States
import {ResourcesState, ResourcesTypes} from "./actionTypes"
import {
  getResources, getResourcesSuccess, getResourcesFail
} from "./actions"

//Include Both Helper File with needed methods
import {
  api_getResources,
} from "../../../API/api"

// 获取全部用户信息
function* onGetResources() {
  try {
    const response : Promise<any> = yield call(api_getResources)
    yield put(getResourcesSuccess(response))
  } catch (error) {
    yield put(getResourcesFail(error))
  }
}

export function* watchOnGetResources() {
  yield takeEvery(ResourcesTypes.GET_RESOURCES, onGetResources);
}

function* resourcesSaga() {
  yield all([fork(watchOnGetResources)]);
}

export default resourcesSaga
