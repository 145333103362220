// Component
import { Col } from 'reactstrap';
import DeviceConnector from './DeviceConnector';
import DeviceType from './DeviceType';
import OutputConnector from './OutputConnector';

export default () => {
  return (
    <div className="d-flex">
      <Col className="border-end px-0 cus-bg-white">
        <DeviceConnector />
      </Col>
      <Col className="border-end px-0 cus-bg-white">
        <DeviceType />
      </Col>
      <Col className="px-0">
        <OutputConnector />
      </Col>
    </div>
  );
};
