"use strict"

interface Recyclable {
  destroy: () => void
}

export class Recycler {
  _subs: Recyclable[] = []

  constructor(sub?: Recyclable) {
    if (!sub) return
    this._subs.push(sub)
  }

  register(sub: Recyclable) {
    this._subs.push(sub)
  }

  drop() {
    if (this._subs.length < 1) return
    for (const sub of this._subs) {
      sub?.destroy()
    }
    this._subs = []
  }
}
