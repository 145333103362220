import { useState, useEffect, useRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import { RuleContext } from "../../../../reducer";
import { Input, Popover, PopoverBody } from "reactstrap";
import { compose } from "src/util";
import { includes } from "lodash";
import Point from "../../../../components/point"
type Props = {
  onClear: Function;
  index: number;
  data: any;
  change: string;
}

export default ({ data, index, change, onClear }: Props) => {
  const { t } = useTranslation();
  const constant = [
    { label: t("Equal")+" (=)", value: "EQ" },
    { label: t("Smaller") + " (<)", value: "LT" },
    { label: t("Bigger") + " (>)", value: "GT" },
    { label: t("Bigger or equal") + " (>=)", value: "GTE" },
    { label: t("Smaller or equal") + " (<=)", value: "LTE" },
  ]
  const { state: { actionSelectedPoint, logicState }, dispatch } = useContext(RuleContext);
  const data_sources = logicState.data_sources;
  const [popoverbottom, setpopoverbottom] = useState(false);

  const handleConditionCheck = (x: number, y: number) => {
    let point = { id: data.id, left: x, top: y };
    if (actionSelectedPoint && actionSelectedPoint.id) {
      if (includes(data.action_ids, actionSelectedPoint.id)) {
        let ids = data.action_ids.filter((o: string) => o != actionSelectedPoint.id);
        data.action_ids = ids;
      } else {
        data.action_ids.push(actionSelectedPoint.id);
      }
      updateLogicConditionAction(data);
    } else {
      const action = {
        type: "SELECT_LOGIC_CONDITION",
        payload: point,
      }
      dispatch(action);
    }
  }

  const handleAddCondition = (op: string) => {
    data.conditions.push({
      chainOperator: op,
      operator: "EQ",
      type: "CONSTANT",
      value: 0
    });
    setpopoverbottom(false);
    return data;
  }

  // 移除条件
  const handleRemoveCondition = (idx: number) => {
    data.conditions = data.conditions.filter((o: any, _idx: number) => {
      return idx !== _idx;
    });
    return data;
  }

  // 切换数据源
  const changeDataSource = (id: string) => {
    data.data_source_id = id;
    data.conditions.forEach((o: any) => {
      if (o.value == id) {
        o.type = "CONSTANT";
        o.value = 0;
        o.operator = "EQ";
      }
    });
    return data;
  }

  const chageOperator = (v: string, i: number) => {
    data.conditions[i].operator = v;
    updateLogicConditionAction(data);
  }

  const chageType = (v: string, i: number) => {
    if (v == "CONSTANT") {
      data.conditions[i].type = v;
      data.conditions[i].value = 0;
    } else {
      data.conditions[i].type = "DATA_SOURCE";
      data.conditions[i].value = v;
    }
    updateLogicConditionAction(data);
  }

  const chageValue = (v: string, i: number) => {
    data.conditions[i].value = v;
    updateLogicConditionAction(data);
  }

  // 发起action
  const updateLogicConditionAction = (data: any) => {
    const action = {
      type: "UPDATE_LOGIC_CONDITION",
      payload: data
    }
    dispatch(action);
  }

  const updateCondition = (fn: Function, x: any) => {
    compose(updateLogicConditionAction, fn)(x);
  }

  const updatePointOffset = (x: number, y: number) => {
    data.offsetX = x;
    data.offsetY = y;
    updateLogicConditionAction(data);
  }

  return (
    <div style={{ position: 'relative' }}>
      <Point change={change} updateOffect={updatePointOffset} pointCheck={handleConditionCheck} style={{ right: "-29px" }} />
      <div className="first-letter-upper mt-3">{t("condition")} {index}</div>
      <div className="flex-row mt-1" style={{ alignItems: 'center', flexWrap: "wrap" }}>
        <span style={{ marginRight: '4px' }}>{t("if")}</span>
        <select
          onChange={(e) => { updateCondition(changeDataSource, e.target.value) }}
          className="form-select mt-1" style={{ height: "25px", width: "120px", padding: "0px 6px" }}>
          {
            data_sources.map((data_source, i: number) => <option key={data_source.id} value={data_source.id}>{t("data source")} {i + 1}</option>)
          }
        </select>
        <span className="text-primary" style={{ marginLeft: '4px', marginRight: '4px' }}>{t("is")}</span>
        <select onChange={(e) => { chageOperator(e.target.value, 0) }} className="form-select mt-1" defaultValue={data.conditions[0].operator} style={{ height: "25px", width: "120px", padding: "0px 6px" }}>
          {
            constant.map(o => <option key={o.value} value={o.value}>{o.label}</option>)
          }
        </select>
        <span style={{ marginLeft: '4px', marginRight: '4px' }}>{t("to")}</span>
        <select
          onChange={(e) => { chageType(e.target.value, 0) }}
          defaultValue={data.conditions[0].type}
          className="form-select mt-1" style={{ height: "25px", width: "120px", padding: "0px 6px" }}
        >
          <option value="CONSTANT">{t("Constant")}</option>
          {
            data_sources.map((data_source, i: number) => {
              if (data_source.id != data.data_source_id) {
                return <option key={data_source.id} value={data_source.id}>{t("data source")} {i + 1}</option>
              }
            })
          }
        </select>
        {data.conditions[0].type === "CONSTANT" && <Input onChange={(e) => { chageValue(e.target.value, 0) }} className="form-control mt-1" style={{ height: "25px", width: "80px" }} type="text" defaultValue={data.conditions[0].value} id="example-text-input" />}
        {
          index > 1 && <div
            onClick={() => onClear(data.id)}
            className="items-center mt-1"
            style={{ display: "flex", justifyContent: "center", width: "25px", height: "25px", borderRadius: "25px", marginLeft: "8px", border: "1px solid #e9e9ef" }}
          >
            <i className="far fa-trash-alt"></i>
          </div>
        }
        <div
          id={`conditionPopoverbottom${index}`}
          onClick={() => { setpopoverbottom(!popoverbottom) }}
          className="items-center  mt-1"
          style={{ display: "flex", justifyContent: "center", width: "25px", height: "25px", borderRadius: "25px", marginLeft: "8px", border: "1px solid #e9e9ef" }}
        >
          <i className="fas fa-plus"></i>
        </div>
        <Popover
          placement="bottom"
          isOpen={popoverbottom}
          target={`conditionPopoverbottom${index}`}
          toggle={() => {
            setpopoverbottom(!popoverbottom);
          }}
        >
          <PopoverBody>
            <div onClick={(e) => { updateCondition(handleAddCondition, "and") }} style={{ lineHeight: '25px' }}>and</div>
            <div onClick={(e) => { updateCondition(handleAddCondition, "or") }} style={{ lineHeight: '25px' }}>or</div>
          </PopoverBody>
        </Popover>
      </div>
      {
        data.conditions.map((condition: any, _index: number) => {
          return (_index ? <div key={_index.toString()} className="flex-row mt-1 items-center" style={{flexWrap: "wrap"}}>
            <span className="text-primary mt-1" style={{ marginLeft: '4px', marginRight: '4px' }}>{condition.chainOperator}</span>
            <span className="text-primary mt-1" style={{ marginLeft: '4px', marginRight: '4px' }}>is</span>
            <select
              onChange={(e) => { chageOperator(e.target.value, _index) }}
              className="form-select mt-1" defaultValue={condition.operator}
              style={{ height: "25px", width: "120px", padding: "0px 6px" }}
            >
              {
                constant.map(o => <option key={o.value} value={o.value}>{o.label}</option>)
              }
            </select>
            <span className="mt-1" style={{ marginLeft: '4px', marginRight: '4px' }}>to</span>
            <select
              className="form-select mt-1"
              onChange={(e) => { chageType(e.target.value, _index) }}
              defaultValue={condition.type}
              style={{ height: "25px", width: "120px", padding: "0px 6px" }}
            >
              <option value="CONSTANT">Constant</option>
              {
                data_sources.map((data_source, i: number) => {
                  if (data_source.id != data.data_source_id) {
                    return <option key={data_source.id} value={data_source.id}>{t("data source")}  {i + 1}</option>
                  }
                })
              }
            </select>
            {
              condition.type === "CONSTANT" && <Input onChange={(e) => { chageValue(e.target.value, _index) }} className="form-control mt-1" style={{ height: "25px", width: "80px" }} type="text" defaultValue={condition.value} id="example-text-input" />
            }
            <div
              onClick={() => { updateCondition(handleRemoveCondition, _index) }}
              className="items-center mt-1"
              style={{ display: "flex", justifyContent: "center", width: "25px", height: "25px", borderRadius: "25px", marginLeft: "8px", border: "1px solid #e9e9ef" }}
            >
              <i className="far fa-trash-alt"></i>
            </div>
          </div> : (null))
        })
      }
    </div>
  )
}
