/**
 * interface for apps type
 */
 export enum AppsTypes {
    API_SUCCESS = '@@apps/API_SUCCESS',
    API_FAIL = '@@apps/API_FAIL',
    GET_EVENTS = '@@apps/GET_EVENTS',
    ADD_NEW_EVENT = '@@apps/ADD_NEW_EVENT',
    UPDATE_EVENT = '@@apps/UPDATE_EVENT',
    UPDATE_EVENT_SUCCESS = '@@apps/UPDATE_EVENT_SUCCESS',
    UPDATE_EVENT_FAIL = '@@apps/UPDATE_EVENT_FAIL',
    DELETE_EVENT = '@@apps/DELETE_EVENT',
    DELETE_EVENT_SUCCESS = '@@apps/DELETE_EVENT_SUCCESS',
    DELETE_EVENT_FAIL = '@@apps/DELETE_EVENT_FAIL',
    GET_CATEGORIES = '@@apps/GET_CATEGORIES',
    GET_CATEGORIES_SUCCESS = '@@apps/GET_CATEGORIES_SUCCESS',
    GET_CATEGORIES_FAIL = '@@apps/GET_CATEGORIES_FAIL',
    GET_APPS = '@@apps/GET_APPS',
    GET_APPS_SUCCESS = '@@apps/GET_APPS_SUCCESS',
    GET_APPS_FAIL = '@@apps/GET_APPS_FAIL',
 }

 export interface AppsState {
   events  : Array<any>;
   categories : Array<any>;
   error : Object;
}