"use strict"

const nameValidateRegExp = /^[a-zA-Z_\u4e00-\u9fa5]+[\w\u4e00-\u9fa5]*$/
export const validateName = (name: string, maxLen: number = 49): boolean => {
  // if (name.length > maxLen) return false
  // return !name || nameValidateRegExp.test(name);
  return true
}

const descriptionValidateRegExp = /[^\/\-'"#]+$/
export const validateDescription = (description: string, maxLen: number = 499): boolean => {
  // if (description.length > maxLen) return false
  // return !description || descriptionValidateRegExp.test(description)
  return true
}
