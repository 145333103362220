"use strict"

// Echart
import * as echarts from "echarts";
// Util
import {CHART_COLOR, ChartDrawer} from "src/util"

const initialOptions: echarts.EChartOption = {
  color: CHART_COLOR,
  tooltip: {
    show: true,
    trigger: 'axis',
    axisPointer: {
      type: "cross",
    },
  },
  grid: {
    top: 40,
    bottom: 0,
    left: 0,
    containLabel: true
  },
  xAxis: {
    type: "category",
    boundaryGap: false,
    axisLabel: {
      width: 30,
      formatter(v: any, i: number) {
        if (i == 0) return ""
        return v
      }
    },
    axisLine: {
      show: false
    }
  },
  yAxis: {
    show: true,
    min(value) {
      const t = Math.floor(value.min - (value.max - value.min) / 10)
      if (value.min < 0) return t
      if (0 > t) return 0
      return t
    },
  },
  series: []
}

export class CellDrawer extends ChartDrawer {
  constructor(container: HTMLDivElement | HTMLCanvasElement | null, option: echarts.EChartOption, theme: string = "light") {
    super(initialOptions, container, option, theme);
  }
}
