import {Key} from "react";

export const fetchInputDevices = (params?: any) => {
    return [
        {
            id: "4FB5A5",
            name: "test1",
            description: "test1",
        },
        {
            id: "6075D92",
            name: "test2",
            description: "test2",
        }
    ]
}

export const fetchInputTags = (params?: any) => {
    return [
        {
            id: "test",
            name: "test",
        }
    ]
}

export const fetchInputDeviceDetail = (id: Key) => {
    if (id == "4FB5A5") {
        return {
            id: "4FB5A5",
            name: "test1",
            description: "test1",
            availableSensors: ["button"]
        }
    }
}

export const fetchInputTagDetail = (id: Key) => {
    if (id == "test") {
        return {
            id: "test",
            name: "test",
        }
    }
}
