/**
 * interface for Tokens type
 */
 export enum SyncersTypes {
    API_SUCCESS = '@@syncers/API_SUCCESS',
    API_FAIL = '@@syncers/API_FAIL',
    GET_EVENTS = '@@syncers/GET_EVENTS',
    ADD_NEW_EVENT = '@@syncers/ADD_NEW_EVENT',
    UPDATE_EVENT = '@@syncers/UPDATE_EVENT',
    UPDATE_EVENT_SUCCESS = '@@syncers/UPDATE_EVENT_SUCCESS',
    UPDATE_EVENT_FAIL = '@@syncers/UPDATE_EVENT_FAIL',
    DELETE_EVENT = '@@syncers/DELETE_EVENT',
    DELETE_EVENT_SUCCESS = '@@syncers/DELETE_EVENT_SUCCESS',
    DELETE_EVENT_FAIL = '@@syncers/DELETE_EVENT_FAIL',
    GET_CATEGORIES = '@@syncers/GET_CATEGORIES',
    GET_CATEGORIES_SUCCESS = '@@syncers/GET_CATEGORIES_SUCCESS',
    GET_CATEGORIES_FAIL = '@@syncers/GET_CATEGORIES_FAIL',
    GET_SYNCERS = '@@syncers/GET_SYNCERS',
    GET_SYNCERS_SUCCESS = '@@syncers/GET_SYNCERS_SUCCESS',
    GET_SYNCERS_FAIL = '@@syncers/GET_SYNCERS_FAIL',
    GET_SYNCERBYID = '@@syncers/GET_SYNCERBYID',
    GET_SYNCERBYID_SUCCESS = '@@syncers/GET_SYNCERBYID_SUCCESS',
    GET_SYNCERBYID_FAIL = '@@syncers/GET_SYNCERBYID_FAIL',
 }

 export interface SyncersState {
   events  : Array<any>;
   categories : Array<any>;
   error : Object;
}