/**
 * interface for permissions type
 */
 export enum PermissionsTypes {
    API_SUCCESS = '@@permission/API_SUCCESS',
    API_FAIL = '@@permission/API_FAIL',
    GET_EVENTS = '@@permission/GET_EVENTS',
    ADD_NEW_EVENT = '@@permission/ADD_NEW_EVENT',
    UPDATE_EVENT = '@@permission/UPDATE_EVENT',
    UPDATE_EVENT_SUCCESS = '@@permission/UPDATE_EVENT_SUCCESS',
    UPDATE_EVENT_FAIL = '@@permission/UPDATE_EVENT_FAIL',
    DELETE_EVENT = '@@permission/DELETE_EVENT',
    DELETE_EVENT_SUCCESS = '@@permission/DELETE_EVENT_SUCCESS',
    DELETE_EVENT_FAIL = '@@permission/DELETE_EVENT_FAIL',
    GET_CATEGORIES = '@@permission/GET_CATEGORIES',
    GET_CATEGORIES_SUCCESS = '@@permission/GET_CATEGORIES_SUCCESS',
    GET_CATEGORIES_FAIL = '@@permission/GET_CATEGORIES_FAIL',
    GET_PERMISSIONS = '@@permission/GET_PERMISSIONS',
    GET_PERMISSIONS_SUCCESS = '@@permission/GET_PERMISSIONS_SUCCESS',
    GET_PERMISSIONS_FAIL = '@@permission/GET_PERMISSIONS_FAIL',
    GET_PERMISSIONBYID = '@@permission/GET_PERMISSIONBYID',
    GET_PERMISSIONBYID_SUCCESS = '@@permission/GET_PERMISSIONBYID_SUCCESS',
    GET_PERMISSIONBYID_FAIL = '@@permission/GET_PERMISSIONBYID_FAIL',
 }

 export interface PermissionsState {
   events  : Array<any>;
   categories : Array<any>;
   error : Object;
}