import { ResourcesTypes } from "./actionTypes";

export const getEvents = () => ({
  type: ResourcesTypes.GET_EVENTS,
});

export const apiSuccess = (actionType : any, data : any) => ({
  type: ResourcesTypes.API_SUCCESS,
  payload: { actionType, data },
});

export const apiFail = (actionType : any, error : any) => ({
  type: ResourcesTypes.API_FAIL,
  payload: { actionType, error },
});

export const addNewEvent = (event : any) => ({
  type: ResourcesTypes.ADD_NEW_EVENT,
  payload: event,
});

export const updateEvent = (event : any) => ({
  type: ResourcesTypes.UPDATE_EVENT,
  payload: event,
});

export const updateEventSuccess = (event : any) => ({
  type: ResourcesTypes.UPDATE_EVENT_SUCCESS,
  payload: event,
});

export const updateEventFail = (error : any) => ({
  type: ResourcesTypes.UPDATE_EVENT_FAIL,
  payload: error,
});

export const deleteEvent = (event : any) => ({
  type: ResourcesTypes.DELETE_EVENT,
  payload: event,
});

export const deleteEventSuccess = (event : any) => ({
  type: ResourcesTypes.DELETE_EVENT_SUCCESS,
  payload: event,
});

export const deleteEventFail = (error : any) => ({
  type: ResourcesTypes.DELETE_EVENT_FAIL,
  payload: error,
});

export const getCategories = () => ({
  type: ResourcesTypes.GET_CATEGORIES,
});

export const getCategoriesSuccess = (categories : any) => ({
  type: ResourcesTypes.GET_CATEGORIES_SUCCESS,
  payload: categories,
});

export const getCategoriesFail = (error : any) => ({
  type: ResourcesTypes.GET_CATEGORIES_FAIL,
  payload: error,
});

export const getResources = () => ({
  type: ResourcesTypes.GET_RESOURCES,
});

export const getResourcesSuccess = (resources : any) => ({
  type: ResourcesTypes.GET_RESOURCES_SUCCESS,
  payload: resources,
});

export const getResourcesFail = (error : any) => ({
  type: ResourcesTypes.GET_RESOURCES_FAIL,
  payload: error,
});
