import { Fragment, Key, useState } from 'react';
// i18n
import { useTranslation } from 'react-i18next';
// Component
import {
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
} from 'reactstrap';
// Util
import dayjs, { Dayjs, ManipulateType } from 'dayjs';
import { currying } from 'src/util';

type timeRangeItem = {
  value: Key;
  label?: Key;
  header?: boolean;
};
type props = {
  timeRangeList?: timeRangeItem[];
  onChange?: Function;
};

export default function ({ timeRangeList = [], onChange }: props) {
  const { t } = useTranslation();

  let defaultTimeRange = '';
  for (const el of timeRangeList) {
    if (!el.header) {
      defaultTimeRange = (el.label || el.value).toString();
      break;
    }
  }

  const [timeRangeDropdownOpen, setTimeRangeDropdownOpen] = useState(false);
  const [timeRange, setTimeRange] = useState(defaultTimeRange);

  const timeFormatStr = 'YYYY-MM-DD HH:mm';
  const [startTime, setStartTime] = useState(
    dayjs().subtract(1, 'hour').format('YYYY-MM-DD HH:mm'),
  );
  const [endTime, setEndTime] = useState(dayjs().format('YYYY-MM-DD HH:mm'));
  const [off, setOff] = useState<ManipulateType>('hour');

  /**
   * RT: 时间范围类型
   * @R: 相对时间范围
   * @A: 绝对时间范围
   */
  enum RT {
    R,
    A,
  }

  const [rangeType, setRangeType] = useState(RT.R);

  const handleTimeRangeChange = (val: Key, lab: Key) => {
    setTimeRange((lab || val).toString());
    const [tp, v] = val.toString().split('-');
    let et: Dayjs = dayjs();
    if (tp == 'a') {
      setRangeType(RT.A);
    } else {
      setRangeType(RT.R);
      setEndTime(et.format(timeFormatStr));

      // 处理相对时间选择后的显示
      switch (v) {
        case 'hour':
          setOff(v);
          setStartTime(et.subtract(1, off).format(timeFormatStr));
          break;
        case 'day':
          setOff(v);
          setStartTime(et.subtract(24, 'hours').format(timeFormatStr));
          break;
        case 'week':
          setOff(v);
          setStartTime(et.subtract(7, 'days').format(timeFormatStr));
          break;
        case 'month':
          setOff(v);
          setStartTime(et.subtract(30, 'days').format(timeFormatStr));
          break;
        case 'year':
          setOff(v);
          setStartTime(et.subtract(1, 'year').format(timeFormatStr));
          break;
      }
    }
    onChange && onChange({ type: tp, value: v, st: startTime, et });
  };

  const changeStartTime = (v: string) => {
    if (dayjs(v).isAfter(dayjs(endTime))) {
      alert(t('Start time cannot be later than end time!'));
      return;
    }

    setStartTime(v);
    handleCustomTimeRange(v, undefined);
  };

  const changeEndTime = (v: string) => {
    if (dayjs(v).isBefore(dayjs(startTime))) {
      alert(t('End time cannot be earlier than start time!'));
      return;
    }

    if (dayjs(v).isAfter(dayjs())) {
      alert(t('End time cannot be later than current time!'));
      return;
    }

    setEndTime(v);
    handleCustomTimeRange(null, v);
  };

  const handleCustomTimeRange = (st: null | string, et: string | undefined) => {
    onChange &&
      onChange({
        type: 'a',
        value: 'custom',
        st: st || startTime,
        et: et || endTime,
      });
  };
  return (
    <Fragment>
      <Dropdown
        isOpen={timeRangeDropdownOpen}
        toggle={currying(setTimeRangeDropdownOpen, !timeRangeDropdownOpen)}>
        <DropdownToggle className="me-3 mb-3 border-0 px-2 py-1 shadow cus-bg-white cus-text-black text-nowrap">
          <div className="clearfix">
            <div className="float-start me-2 cus-w-1 truncate">
              {t(timeRange.toString())}
            </div>
            <i className="float-end lh-base text-secondary fas fa-angle-down" />
          </div>
        </DropdownToggle>
        <DropdownMenu className="cus-mh-3 overflow-auto" flip>
          {timeRangeList?.map(tr => (
            <DropdownItem
              key={tr.value}
              className="first-letter-upper"
              header={tr.header}
              onClick={currying(handleTimeRangeChange, tr.value, tr.label)}>
              {t((tr.label || tr.value).toString())}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
      <Row>
        <Col>
          <div className="first-letter-upper">{t('start time')}</div>
          <Input
            className="border-0 px-2 py-1 shadow"
            type="datetime-local"
            disabled={rangeType == RT.R}
            value={startTime}
            onChange={e => changeStartTime(e.target.value)}
          />
        </Col>
        <Col>
          <div className="first-letter-upper">{t('end time')}</div>
          <Input
            className="border-0 px-2 py-1 shadow"
            type="datetime-local"
            disabled={rangeType == RT.R}
            value={endTime}
            onChange={e => changeEndTime(e.target.value)}
          />
        </Col>
      </Row>
    </Fragment>
  );
}
