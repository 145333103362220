import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { isEmpty } from "lodash";
import "src/assets/scss/inproject/projstyle.scss";

import {
    Button,
    Form,
    Card,
    CardBody, CardHeader, CardSubtitle, CardTitle,
    Col,
    Container, Input, Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row, Table,
} from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";

/** Import Full Calendar  */
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";

//import images
// import calendar from "../../assets/images/undraw-calendar.svg";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import {
    getUserInfo as onGetUserInfo,
} from "../../../store/apps/User/actions";

//i18n
import { useTranslation } from "react-i18next";

// import DeleteModal from "./DeleteModal";

//css
import "@fullcalendar/bootstrap/main.css";

//redux
import { useSelector, useDispatch } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
    SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";

import {Link} from "react-router-dom";
import {useLocation} from "react-router";
interface UserAddProps {
    className: string;
}
// TODO: 函数头
const UserAdd = ({ className }: UserAddProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { state } = useLocation();

    const localState:any = state;

    useEffect(() => {
        // let external: any = document.getElementById("external-events");
        if(localState && localState.hasOwnProperty('id')){
            dispatch(onGetUserInfo(localState.id));
        }
        // dispatch(onGetEvents());
    }, [dispatch]);

    let { result } = useSelector(function(state:any){
        return {
            result: state.User.userinfo,
        }
    })
    if(localState.ifAdd){
        result = null
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{t("userAdd")}</title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumbs title={t("Dashboard")} breadcrumbItem={t("userAdd")} />
                    <Card>
                        <CardBody>
                            <div className="mb-3 d-flex flex-wrap gap-2">
                                <button
                                    type="button"
                                    className="btn btn-outline-primary waves-effect waves-light"
                                >
                                    {t("OP_Save")}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-success"
                                >
                                    {t("OP_SaveAndExit")}

                                </button>
                                {/*<button
                                    type="button"
                                    className="btn btn-soft-warning waves-effect waves-light"
                                >
                                    {t("OP_Cancel")}
                                </button>*/}
                                <Link to="/apps-user" className="btn btn-secondary">
                                    {t("OP_Cancel")}
                                </Link>
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="organize-input" className="form-Label">{t("th_Organize")}</Label>
                                <i className="bx bx-help-circle" title={t("th_Organize")} />
                                <Input className="form-control" type="text" value={result?result.owner:""} id="organize-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="id-input" className="form-Label">{t("th_ID")}</Label>
                                <i className="bx bx-help-circle" title={t("th_ID")} />
                                <Input className="form-control" type="text" disabled value={result?result.id:""} id="id-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="name-input" className="form-Label">{t("th_Name")}</Label>
                                <i className="bx bx-help-circle" title={t("th_Name")} />
                                <Input className="form-control" type="text" value={result?result.name:""} id="name-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="displayname-input" className="form-Label">{t("th_displayName")}</Label>
                                <i className="bx bx-help-circle" title={t("th_displayName")} />
                                <Input className="form-control" type="text" value={result?result.displayName:""} id="displayname-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="upload-avatar" className="form-Label">
                                    {t("th_Portrait")}
                                    <i className="bx bx-help-circle" title={t("th_Portrait")} />
                                </Label>
                                <Row className="mb-4">
                                    <Label
                                        htmlFor="horizontal-firstname-input"
                                        className="col-sm-1 col-form-label"
                                    >
                                        {t("th_URL")}
                                    </Label>
                                    <Col sm={11}>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            id="horizontal-firstname-input"
                                            value={result?result.avatar:""}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Label
                                        htmlFor="horizontal-firstname-input"
                                        className="col-sm-1 col-form-label"
                                    >
                                        {t("th_Preview")}
                                    </Label>
                                    <Col sm={4}>
                                        <img src={result?result.avatar:""} alt="" width='40'/>
                                    </Col>
                                </Row>
                                <Input className="form-control" type="file" id="upload-avatar" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="usertype-input" className="form-Label">{t("userType")}</Label>
                                <i className="bx bx-help-circle" title={t("userType")} />
                                <Input className="form-control" type="text" value={result?result.type:""} id="usertype-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="password-input" className="form-Label">{t("th_Password")}</Label>
                                <i className="bx bx-help-circle" title={t("th_Password")} />
                                <Input className="form-control" type="password" value={result?result.password:""} id="password-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="email-input" className="form-Label">{t("th_Mail")}</Label>
                                <i className="bx bx-help-circle" title={t("th_Mail")} />
                                <Input className="form-control" type="text" value={result?result.email:""} id="email-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="phone-input" className="form-Label">{t("th_Phone")}</Label>
                                <i className="bx bx-help-circle" title={t("th_Phone")} />
                                <div className="input-group">
                                    <div className="input-group-text">+86</div>
                                    <Input className="form-control" type="text" value={result?result.phone:""} id="phone-input" />
                                </div>
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="regin-input" className="form-Label">{t("CountryRegin")}</Label>
                                <i className="bx bx-help-circle" title={t("CountryRegin")} />
                                <Input className="form-control" type="text" value={result?result.region:""} id="regin-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="location-input" className="form-Label">{t("City")}</Label>
                                <i className="bx bx-help-circle" title={t("City")} />
                                <Input className="form-control" type="text" value={result?result.location:""} id="location-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="inc-input" className="form-Label">{t("Inc")}</Label>
                                <i className="bx bx-help-circle" title={t("Inc")} />
                                <Input className="form-control" type="text" value={result?result.affiliation:""} id="inc-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="post-input" className="form-Label">{t("Post")}</Label>
                                <i className="bx bx-help-circle" title={t("Post")} />
                                <Input className="form-control" type="text" value={result?result.post:""} id="post-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="homepage-input" className="form-Label">{t("Personal_Webpage")}</Label>
                                <i className="bx bx-help-circle" title={t("Personal_Webpage")} />
                                <Input className="form-control" type="text" value={result?result.homepage:""} id="homepage-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="introduce-input" className="form-Label">{t("Self_Introduction")}</Label>
                                <i className="bx bx-help-circle" title={t("Self_Introduction")} />
                                <Input className="form-control" type="text" value="" id="introduce-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="tag-input" className="form-Label">{t("Tag")}</Label>
                                <i className="bx bx-help-circle" title={t("Tag")} />
                                <Input className="form-control" type="text" value={result?result.tag:""} id="tag-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="registerapp-input" className="form-Label">{t("userTable_RegisterApp")}</Label>
                                <i className="bx bx-help-circle" title={t("userTable_RegisterApp")} />
                                <Input className="form-control" type="text" value={result?result.signupApplication:""} id="registerapp-input" />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="thirdlogin-input" className="form-Label">{t("ThirdLogin")}</Label>
                                <i className="bx bx-help-circle" title={t("ThirdLogin")} />
                                <Input className="form-control" type="text" value="" id="thirdlogin-input" />
                            </div>

                            {/* SWITCH */}

                            <div className="div-switch">
                                <Label htmlFor="isAdmin"
                                       className="col-sm-1 col-form-label">
                                    {t("userTable_Manager")}
                                    <i className="bx bx-help-circle col-form-label" title={t("userTable_Manager")} />
                                </Label>
                                <Input type="checkbox" id="isAdmin" switch="none" defaultChecked={result?result.isAdmin:""} />
                                <Label htmlFor="isAdmin" data-on-label="On" data-off-label="Off"></Label>
                            </div>
                            <div className="div-switch">
                                <Label htmlFor="isGlobalAdmin"
                                       className="col-sm-1 col-form-label">
                                    {t("userTable_GM")}
                                    <i className="bx bx-help-circle col-form-label" title={t("userTable_GM")} />
                                </Label>
                                <Input type="checkbox" id="isGlobalAdmin" switch="none" defaultChecked={result?result.isGlobalAdmin:""} />
                                <Label htmlFor="isGlobalAdmin" data-on-label="On" data-off-label="Off"></Label>
                            </div>
                            <div className="div-switch">
                                <Label htmlFor="isForbidden"
                                       className="col-sm-1 col-form-label"
                                >
                                    {t("userTable_Denied")}
                                    <i className="bx bx-help-circle col-form-label" title={t("userTable_Denied")} />
                                </Label>
                                <Input type="checkbox" id="isForbidden" switch="none" defaultChecked={result?result.isForbidden:""} />
                                <Label htmlFor="isForbidden" data-on-label="On" data-off-label="Off"></Label>
                            </div>
                            <div className="div-switch">
                                <Label
                                    htmlFor="isDeleted"
                                    className="col-sm-1 col-form-label"
                                >
                                    {t("userTable_Deleted")}
                                    <i className="col-sm-3 col-form-label bx bx-help-circle" title={t("userTable_Deleted")} />
                                </Label>
                                <Input type="checkbox" id="isDeleted" switch="none" defaultChecked={result?result.isDeleted:""} />
                                <Label htmlFor="isDeleted" data-on-label="On" data-off-label="Off"></Label>
                            </div>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default UserAdd;
