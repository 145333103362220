import { Fragment, useEffect, useRef, useState } from 'react';
// Router
import { useRouteMatch } from 'react-router-dom';
// i18n
import { useTranslation } from 'react-i18next';
// Component
import { Col, Row } from 'reactstrap';
import Loading from 'src/components/Loading';
// Chart
import { DeviceData2Option, DeviceOnlinePieDrawer } from './drawer';
// Api
import {
  getIotWorkspace,
  getIotWorkspaceDevices,
} from 'src/helpers/iot_backend_helper';
// Util
import dayjs from 'dayjs';
import { awaitWrap } from 'src/util';

const dateFormatStr = 'YYYY-MM-DD HH:mm:ss';

const initWorkspace = {
  name: '',
  status: 1 | 2,

  created_at: '',
  updated_at: '',
};

export default function () {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);

  const m = useRouteMatch<any>();
  const { wsId } = m.params;

  // 工作区基础信息
  const [workspace, setWorkspace] = useState(initWorkspace);
  // 工作区的设备相关信息
  const [tot, setTot] = useState();
  // 图表
  const pieDrawerRef = useRef<HTMLDivElement>(null);
  const pieDrawer = new DeviceOnlinePieDrawer(pieDrawerRef.current, {});
  // 设备健康检查总览
  const [summary, setSummary] = useState<any[]>([]);

  useEffect(() => {
    (async function () {
      const [r1, e1] = await awaitWrap(getIotWorkspace(wsId, null));
      setLoading(false);

      if (e1) {
        return;
      }
      if (r1.Code != 200) {
        return;
      }

      setWorkspace(r1.Data);

      const [r2, e2] = await awaitWrap(getIotWorkspaceDevices(wsId, null));
      if (e2) {
        return;
      }
      if (r2.Code != 200) {
        return;
      }

      setTot(r2.Data == null ? 0 : r2.Data.length);

      const data = r2.Data == null ? [] : r2.Data.reduce(
        (pre: any, cur: any) => {
          if (cur.on_line) {
            pre[0].value++;
          } else {
            pre[1].value++;
          }

          return pre;
        },
        [
          {
            name: t('Online'),
            value: 0,
          },
          {
            name: t('Offline'),
            value: 0,
          },
        ],
      );

      setSummary(data);
      pieDrawer.init(pieDrawerRef.current, DeviceData2Option(data));
    })();
  }, [wsId]);

  return (
    <Fragment>
      {loading && <Loading loading={loading} />}

      <div className="px-4 py-2">
        <h3 className="py-4">{workspace.name}</h3>

        <Row>
          <Col xl={6} xs={12}>
            <div className="mb-2">
              <span className="text-muted">
                {/*{t('Lifecycle notifications')}:*/}
                {t('Status')}:
              </span>
              <i
                className={`ms-2 mdi mdi-brightness-1
                ${workspace.status == 1 ? 'text-success' : 'text-danger'}`}
              />
              <a href="javascript:void(0);" className="ms-2">
                {workspace.name}
              </a>
            </div>
            <div>
              <span className="text-muted">{t('ID')}:</span>
              <span className="ms-2">{wsId}</span>
            </div>
          </Col>
          <Col xl={6} xs={12}>
            <div className="mb-2">
              <span className="text-muted">{t('Created')}:</span>
              <span className="ms-2">
                {dayjs(workspace.created_at).format(dateFormatStr)}
              </span>
            </div>
            <div>
              <span className="text-muted">{t('Modified')}:</span>
              <span className="ms-2">
                {dayjs(workspace.updated_at).format(dateFormatStr)}
              </span>
            </div>
          </Col>
        </Row>

        <div className="my-5 border-bottom" />

        <Row>
          <Col xl={4} xs={8}>
            <div className="mb-2 text-muted">{t('Device in workspace')}</div>
            <h3>
              {tot} {t('device')}
            </h3>
          </Col>
        </Row>

        <div className="my-5 border-bottom" />

        <h4 className="m-0">{t('Device health')}</h4>
        <div className="d-flex">
          <div className="cus-h-10 cus-w-2" ref={pieDrawerRef} />

          <div className="mt-4">
            <div className="font-size-16 fw-bold text-muted user-select-none">
              <i className="fas fa-circle me-2 text-success" />
              {t('Online')}: {summary[0]?.value}
            </div>
            <div className="mt-2 font-size-16 fw-bold text-muted user-select-none">
              <i className="fas fa-circle me-2 text-danger" />
              {t('Offline')}: {summary[1]?.value}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
