import React, {useEffect} from "react";
import MetaTags from "react-meta-tags";

import {Card, CardBody, Col, Container, Input, Label, Row,} from "reactstrap";

/** Import Full Calendar  */

//import images
// import calendar from "../../assets/images/undraw-calendar.svg";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

//i18n
import {useTranslation} from "react-i18next";

// import DeleteModal from "./DeleteModal";
//css
import "@fullcalendar/bootstrap/main.css";

//redux
import {useDispatch, useSelector} from "react-redux";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
    SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import {useLocation} from "react-router";
import {Link} from "react-router-dom";
import  CommonFilter from "../../../components/FuncProjCommon/CommonFilter";

interface SmartCheckInProps {
    className: string;
}
// TODO: 函数头
const SmartCheckIn = ({ className }: SmartCheckInProps) => {
    const { t } = useTranslation();

    const columns = [
        {
            dataField: "username",
            text: t("UserName"),
            sort: true,
        },
        {
            dataField: "department",
            text: t("Department"),
            sort: true,
        },
        {
            dataField: "jobNumber",
            text: t("JobNumber"),
            sort: true,
        },
        {
            dataField: "onJob",
            text: t("OnJob"),
            sort: true,
        },
        {
            dataField: "checkInAllow",
            text: t("CheckInAllow"),
            sort: true,
        },
        {
            dataField: "createdTime",
            text: t("th_createdTime"),
            sort: true,
        },
        {
            dataField: "remarks",
            text: t("Remarks"),
            sort: true,
        },
        {
            dataField: "operator",
            text: t("th_Operator"),
            sort: false,
        },
    ];

    let returnButton = ()=>{
        return (
            <div className="d-flex flex-wrap gap-2">
                {/*<button
                    type="button"
                    className="btn btn-outline-warning waves-effect waves-light"
                >
                    {t("OP_Edit")}
                </button>
                <Link to={{pathname:'/apps-organizeadd', state:{ifAdd:true}}} className="btn btn-outline-warning waves-effect waves-light">
                    {t("OP_Edit")}
                </Link>*/}
                <Link to={{pathname:'/#'}} className="btn btn-outline-warning waves-effect waves-light">
                    {t("OP_Edit")}
                </Link>
                <button
                    type="button"
                    className="btn btn-outline-danger waves-effect waves-light"
                >
                    {t("OP_Del")}
                </button>
            </div>);
    }

    // Table Data
    const productData:any = [
        {
            username:"黄雅海",
            department:"商务部",
            jobNumber:"2908498",
            onJob:"在职",
            checkInAllow:"",
            createdTime:"2024-04-23",
            remarks:"无",
            operator:returnButton(),
        }
    ];

    const pageOptions: any = {
        sizePerPage: 10,
        totalSize: productData.length, // replace later with size(customers),
        custom: true,
    };

    // 设置label变量
    const localArr:Array<string> = [
        "UserName","Department","OnJob"
    ]

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{t("SmartCheckIn")}</title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumbs title={t("SmartOA")} breadcrumbItem={t("SmartCheckIn")} />
                    <Card>
                        <CardBody>
                            <CommonFilter arr={localArr} />

                            <PaginationProvider
                                pagination={paginationFactory(pageOptions)}
                                // columns={columns}
                                // data={productData}
                            >
                                {({ paginationProps, paginationTableProps }) => (
                                    <ToolkitProvider
                                        keyField="id"
                                        columns={columns}
                                        data={productData}
                                        search
                                    >
                                        {toolkitProps => (
                                            <React.Fragment>
                                                {/*<Row className="mb-2">
                                                    <Col md="3">
                                                        <div className="search-box me-2 mb-2 d-inline-block">
                                                            <div className="position-relative">
                                                                <SearchBar {...toolkitProps.searchProps} />
                                                                <i className="bx bx-search-alt search-icon" />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>*/}
                                                {/*<button
                                                    type="button"
                                                    className="btn btn-success"
                                                >
                                                    {t("OP_AddData")}
                                                </button>*/}
                                                <Link to={{pathname:'/apps-organizeadd', state:{ifAdd:true}}} className="btn btn-success">
                                                    {t("OP_AddItem")}
                                                </Link>
                                                <Row>
                                                    <Col xl="12">
                                                        <div className="table-responsive">
                                                            <BootstrapTable
                                                                // responsive
                                                                bordered={false}
                                                                striped={false}
                                                                classes={"table align-middle table-nowrap"}
                                                                headerWrapperClasses={"thead-light"}
                                                                {...toolkitProps.baseProps}
                                                                {...paginationTableProps}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className="align-items-md-center mt-30">
                                                    <Col className="inner-custom-pagination d-flex">
                                                        <div className="d-inline">
                                                            <SizePerPageDropdownStandalone
                                                                {...paginationProps}
                                                            />
                                                        </div>
                                                        <div className="text-md-right ms-auto">
                                                            <PaginationListStandalone
                                                                {...paginationProps}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        )}
                                    </ToolkitProvider>
                                )}
                            </PaginationProvider>
                        </CardBody>
                    </Card>
                </Container>
            </div>

        </React.Fragment>
    );
};

export default SmartCheckIn;
