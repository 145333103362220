import * as Pages from '../pages';
//Dashboard
import Dashboard from "../pages/Dashboard";

//HomePage

//Calendar
import Calendar from "src/pages/Calendar";

//SmartOA
import AirControllDelay from "../pages/SmartOA/AirControllDelay";
import GroundBook from "../pages/SmartOA/GroundBook";
import ProjectSketch from "../pages/SmartOA/ProjSketch";
import Trainning from "../pages/SmartOA/Trainning";
import SmartParking from "../pages/SmartOA/SmartParking";
import SmartCheckIn from "../pages/SmartOA/SmartCheckIn";
import VisitorSys from "../pages/SmartOA/VisitorSys";
import SurplusService from "../pages/SmartOA/SurplusService";

//SmartProperty
import Notice from "../pages/SmartProperty/Notice";
import FeeList from "../pages/SmartProperty/FeeList";
import ItemCheck from "../pages/SmartProperty/ItemCheck";
import NoticeSys from "../pages/SmartProperty/NoticeSys";
import TroubleProcessing from "../pages/SmartProperty/TroubleProcessing";

// LifeService
import MallOL from "../pages/LifeService/MallOL";
import VIPAuthority from "../pages/LifeService/VIPAuthority";
import SmartCanteen from "../pages/LifeService/SmartCanteen";

//Organize
import Organize from "src/pages/Applications/Organize";
import OrganizeAdd from "src/pages/Applications/Organize/organizeAdd";

// User
import User from "src/pages/Applications/User";
import UserAdd from "src/pages/Applications/User/UserAdd";

// Character
import Character from "src/pages/Applications/Character";
import CharacterAdd from "../pages/Applications/Character/CharacterAdd";

// Authority
import Authority from "src/pages/Applications/Authority";
import AuthorityAdd from "../pages/Applications/Authority/AuthorityAdd";

// pages
import Provider from "src/pages/Settings/Provider";
import ProviderAdd from "../pages/Settings/Provider/ProviderAdd";
import PageApps from "src/pages/Settings/Apps";
import AppsAdd from "../pages/Settings/Apps/AppsAdd";
import Assets from "src/pages/Settings/Assets";
import Tokens from "src/pages/Settings/Tokens";
import TokensAdd from "../pages/Settings/Tokens/TokensAdd";
import Records from "src/pages/Settings/Records";
import Webhooks from "src/pages/Settings/Webhooks";
import WebhooksAdd from "../pages/Settings/Webhooks/WebhooksAdd";
import Syncers from "src/pages/Settings/Syncers";
import SyncersAdd from "../pages/Settings/Syncers/SyncersAdd";
import Certs from "src/pages/Settings/Certs";
import CertsAdd from "../pages/Settings/Certs/CertsAdd";
import Swagger from "src/pages/Settings/Swagger";

// Iot
import IotInsights from "src/pages/IotInsights";
import IotAssets from "src/pages/IotAssets";
import IotRules from "src/pages/IotRules";

//Chat
import Chat from "src/pages/Chat/Chat";

//Email Inbox
import Inbox from "src/pages/Email/Inbox";
import EmailRead from "src/pages/Email/email-read";

//Invoice
import InvoiceList from "src/pages/Invoices/invoice-list";
import InvoiceDetails from "src/pages/Invoices/invoice-details";

//Contacts
import ContactsGrid from "src/pages/Contacts/contactsGrid";
import ContactsList from "src/pages/Contacts/ContactList/contacts-list";
import ContactsProfile from "src/pages/Contacts/ContactsProfile/contacts-profile";

//Utility
import PageStarter from "src/pages/Utility/PageStarter";
import PageMaintenance from "src/pages/Utility/PageMaintenance";
import PageTimeline from "src/pages/Utility/PageTimeline";
import PageFaqs from "src/pages/Utility/PageFAQs";
import PagePricing from "src/pages/Utility/PagePricing";
import Error404 from "src/pages/Utility/Error404";
import Error500 from "src/pages/Utility/Error500";

//UI Components
import UiAlert from "src/pages/UiComponents/UiAlert";
import UiButton from "src/pages/UiComponents/UiButton";
import UiCard from "src/pages/UiComponents/UiCard";
import UiCarousel from "src/pages/UiComponents/UiCarousel";
import UiDropdowns from "src/pages/UiComponents/UiDropdowns";
import UiGrid from "src/pages/UiComponents/UiGird";
import UiModal from "src/pages/UiComponents/UiModals";
import UiImages from "src/pages/UiComponents/UiImages";
import UiDrawer from "src/pages/UiComponents/UiDrawer";
import UiProgressbar from "src/pages/UiComponents/UiProgressbar";
import UiTabsAccordions from "src/pages/Utility/UiTabsAccordions";
import UiTypography from "src/pages/Utility/UiTypography";
import UiVideo from "src/pages/Utility/UiVideo";
import UiGeneral from "src/pages/UiComponents/UiGeneral";
import UiColors from "src/pages/UiComponents/UiColors";

//Extended pages
import UiLightbox from "src/pages/Extended/Lightbox";
import RangeSlider from "src/pages/Extended/Rangeslider";
import UiSweetAlert from "src/pages/Extended/SweetAlert";
import SessionTimeout from "src/pages/Extended/SessionTimeout";
import UiRating from "src/pages/Extended/UiRating";
import Notifications from "src/pages/Extended/Notifications";

//Forms pages
import FormElements from "src/pages/Forms/FormElements";
import FormValidation from "src/pages/Forms/FormValidation";
import AdvancedPlugins from "src/pages/Forms/AdvancedPlugins";
import FormEditors from "src/pages/Forms/FormEditors";
import FormUpload from "src/pages/Forms/FormUpload";
import FormWizard from "src/pages/Forms/FormWizard";
import FormMask from "src/pages/Forms/FormMask";

//Tables
import BasicTable from "src/pages/Tables/BasicTables";
import DatatableTables from "src/pages/Tables/DatatableTables";
import ResponsiveTables from "src/pages/Tables/ResponsiveTables";
import EditableTables from "src/pages/Tables/EditableTables";

//Charts
import Apexchart from "src/pages/Charts/Apexcharts";
import EChart from "src/pages/Charts/EChart";
import ChartjsChart from "src/pages/Charts/ChartjsChart";
import SparklineChart from "src/pages/Charts/SparklineChart";

//Icons
import IconBoxicons from "../pages/Icons/IconBoxicons";
import IconMaterialdesign from "../pages/Icons/IconMaterialdesign";
import IconDripicons from "../pages/Icons/IconDripicons";
import IconFontawesome from "../pages/Icons/IconFontawesome";

//AuthenticationInner pages
import PageLogin from "src/pages/AuthenticationInner/PageLogin";
import PageRegister from "src/pages/AuthenticationInner/PageRegister";
import RecoverPassword from "src/pages/AuthenticationInner/RecoverPassword";
import LockScreen from "src/pages/AuthenticationInner/LockScreen";
import ConfirmMail from "src/pages/AuthenticationInner/ConfirmMail";
import EmailVerification from "src/pages/AuthenticationInner/EmailVerification";
import TwoStepVerfication from "src/pages/AuthenticationInner/TwoStepVerfication";

//Authentication pages
import Login from "src/pages/Authentication/Login";
import _Login from "src/pages/Authentication/_Login";
import Logout from "src/pages/Authentication/Logout";
import Register from "src/pages/Authentication/Register";
import Setup from "../pages/Authentication/Setup";
import UserProfile from "src/pages/Authentication/UserProfile";
import _userProfile from "../pages/Authentication/_user-profile";
import PagesComingsoon from "src/pages/Utility/PageComingsoon";
import UserActivation from "src/pages/Authentication/UserActivation";

//Maps
// import MapsGoogle from "src/pages/Maps/MapsGoogle";
import MapsVector from "src/pages/Maps/MapsVector";
import MapsLeaflet from "src/pages/Maps/MapsLeaflet";

//Xeogl
import GltfOffice from "src/pages/Xeogl/Office";
import GltfHospital from "src/pages/Xeogl/Hospital";
import { AkenzaWorkspaceOverview, Introduce } from '../pages';

interface RouteProps {
  path: string;
  component: any;
  exact?: boolean;
}

const userRoutes: Array<RouteProps> = [
  //User Profile
  {path: "/profile", component: UserProfile},
  {path: "/_profile", component: _userProfile},

  //dashboard
  {path: "/dashboard", component: Dashboard},

  //homepage
  {path: "/", component: Pages.AkenzaOverview},

  // SmartOA
  {path: "/soa-aircontroll", component: AirControllDelay},
  {path: "/soa-groundbook", component: GroundBook},
  {path: "/soa-projsketch", component: ProjectSketch},
  {path: "/soa-trainning", component: Trainning},
  {path: "/soa-smartparking", component: SmartParking},
  {path: "/soa-smartcheckin", component: SmartCheckIn},
  {path: "/soa-visitorsys", component: VisitorSys},
  {path: "/soa-surplusservice", component: SurplusService},

  // SmartProperty
  {path: "/stp-notice", component: Notice},
  {path: "/stp-feelist", component: FeeList},
  {path: "/stp-itemcheck", component: ItemCheck},
  {path: "/stp-noticesys", component: NoticeSys},
  {path: "/stp-troubleprocessing", component: TroubleProcessing},

  // LifeService
  {path: "/lsv-mallol", component: MallOL},
  {path: "/lsv-vipauthority", component: VIPAuthority},
  {path: "/lsv-smartcanteen", component: SmartCanteen},

  //Organize
  {path: "/apps-Organize", component: Organize},
  {path: "/apps-organizeadd", component: OrganizeAdd},

  //User
  {path: "/apps-user", component: User},
  {path: "/apps-useradd", component: UserAdd},

  //Character
  {path: "/apps-character", component: Character},
  {path: "/apps-characteradd", component: CharacterAdd},

  //Authority
  {path: "/apps-authority", component: Authority},
  {path: "/apps-authorityadd", component: AuthorityAdd},

  //Calendar
  {path: "/apps-calendar", component: Calendar},

  //Chat
  {path: "/apps-chat", component: Chat},

  //Provider
  {path: "/page-provider", component: Provider},
  {path: "/page-provideradd", component: ProviderAdd},

  //PageApps
  {path: "/page-apps", component: PageApps},
  {path: "/page-appsadd", component: AppsAdd},

  //Assets
  {path: "/page-assets", component: Assets},

  //Tokens
  {path: "/page-tokens", component: Tokens},
  {path: "/page-tokensadd", component: TokensAdd},

  //Records
  {path: "/page-records", component: Records},

  //Webhooks
  {path: "/page-webhooks", component: Webhooks},
  {path: "/page-webhooksadd", component: WebhooksAdd},

  //Syncers
  {path: "/page-syncers", component: Syncers},
  {path: "/page-syncersadd", component: SyncersAdd},

  //Certs
  {path: "/page-certs", component: Certs},
  {path: "/page-certsadd", component: CertsAdd},

  //Swagger
  {path: "/page-swagger", component: Swagger},

  //iot-insights
  {path: "/iot-insights", component: IotInsights},
  //iot-assets
  {path: "/iot-assets", component: IotAssets},
  //iot-rules
  {path: "/iot-rules", component: IotRules},

  //Email Inbox
  {path: "/email-inbox", component: Inbox},
  {path: "/email-read", component: EmailRead},

  //Invoice
  {path: "/invoices-list", component: InvoiceList},
  {path: "/invoices-detail", component: InvoiceDetails},

  //Contact
  {path: "/contacts-grid", component: ContactsGrid},
  {path: "/contacts-list", component: ContactsList},
  {path: "/contacts-profile", component: ContactsProfile},

  //Utility
  {path: "/pages-starter", component: PageStarter},
  {path: "/pages-timeline", component: PageTimeline},
  {path: "/pages-faqs", component: PageFaqs},
  {path: "/pages-pricing", component: PagePricing},

  //UI Components
  {path: "/ui-alerts", component: UiAlert},
  {path: "/ui-buttons", component: UiButton},
  {path: "/ui-cards", component: UiCard},
  {path: "/ui-carousel", component: UiCarousel},
  {path: "/ui-dropdowns", component: UiDropdowns},
  {path: "/ui-grid", component: UiGrid},
  {path: "/ui-modals", component: UiModal},
  {path: "/ui-images", component: UiImages},
  {path: "/ui-drawer", component: UiDrawer},
  {path: "/ui-progressbars", component: UiProgressbar},
  {path: "/ui-tabs-accordions", component: UiTabsAccordions},
  {path: "/ui-typography", component: UiTypography},
  {path: "/ui-video", component: UiVideo},
  {path: "/ui-general", component: UiGeneral},
  {path: "/ui-colors", component: UiColors},

  //Extended pages
  {path: "/extended-lightbox", component: UiLightbox},
  {path: "/extended-rangeslider", component: RangeSlider},
  {path: "/extended-sweet-alert", component: UiSweetAlert},
  {path: "/extended-session-timeout", component: SessionTimeout},
  {path: "/extended-rating", component: UiRating},
  {path: "/extended-notifications", component: Notifications},

  // Forms pages
  {path: "/form-elements", component: FormElements},
  {path: "/form-validation", component: FormValidation},
  {path: "/form-advanced", component: AdvancedPlugins},
  {path: "/form-editors", component: FormEditors},
  {path: "/form-uploads", component: FormUpload},
  {path: "/form-wizard", component: FormWizard},
  {path: "/form-mask", component: FormMask},

  //tables
  {path: "/tables-basic", component: BasicTable},
  {path: "/tables-datatable", component: DatatableTables},
  {path: "/tables-responsive", component: ResponsiveTables},
  {path: "/tables-editable", component: EditableTables},

  //Charts
  {path: "/charts-apex", component: Apexchart},
  {path: "/charts-echart", component: EChart},
  {path: "/charts-chartjs", component: ChartjsChart},
  {path: "/charts-sparkline", component: SparklineChart},

  //Icons
  {path: "/icons-boxicons", component: IconBoxicons},
  {path: "/icons-materialdesign", component: IconMaterialdesign},
  {path: "/icons-dripicons", component: IconDripicons},
  {path: "/icons-fontawesome", component: IconFontawesome},

  //Maps
  // { path: "/maps-google", component: MapsGoogle },
  {path: "/maps-vector", component: MapsVector},
  {path: "/maps-leaflet", component: MapsLeaflet},

  //Xeogl
  {path: "/gltf-office", component: GltfOffice},
  {path: "/gltf-hospital", component: GltfHospital},

  // akenza page
  {path: "/org/:orgId/ws/:wsId/overview", component: Pages.AkenzaWorkspaceOverview},
  {path: "/org/:orgId/overview", component: Pages.AkenzaOverview},
  {path: "/org/:orgId/analysis", component: Pages.AkenzaAnalysis},
  {path: "/org/:orgId/workspace", component: Pages.AkenzaWorkspace},
  {path: "/org/:orgId/ws/:wsId/assets", component: Pages.AkenzaAssets},
  {path: "/org/:orgId/assets", component: Pages.AkenzaAssets},
  {path: "/org/:orgId/ws/:wsId/rules", component: Pages.AkenzaRules},
  {path: "/org/:orgId/ws/:wsId/rules/new", component: Pages.AkenzaRuleNew},
  {path: "/org/:orgId/ws/:wsId/rules/:id", component: Pages.AkenzaRuleNew},
  {path: "/org/:orgId/logic-blocks", component: Pages.LogicBlocks},
  {path: "/org/:orgId/logic-blocks/add", component: Pages.AddLogicBlock},
  {path: "/org/:orgId/logic-blocks/:id", component: Pages.Introduce},
  {path: "/org/:orgId/logic-blocks/edit/:id", component: Pages.AddLogicBlock},
  {path: "/org/:orgId/ws/:wsId/logic-blocks/:id", component: Pages.AddLogicBlock},
  {path: "/org/:orgId/device-types", component: Pages.DeviceTypes},
  {path: "/org/:orgId/device-types/add", component: Pages.AddDeviceType},
  {path: "/org/:orgId/device-types/:id", component: Pages.AddDeviceType},
  {path: "/org/:orgId/device-types/:id/edit", component: Pages.AddDeviceType},
  {path: "/org/:orgId/ws/:wsId/general", component: Pages.GeneralWorkspace},
  {path: "/org/:orgId/general", component: Pages.GeneralOrganization},
  {path: "/org/:orgId/api-keys", component: Pages.ApiKeys},
  {path: "/org/:orgId/ws/:wsId/users", component: Pages.Users},
  {path: "/org/:orgId/users", component: Pages.Users},
  {path: "/org/:orgId/subscription", component: Pages.Subscription},

  {path: "/org/:orgId/ws/:wsId/data-flows", component: Pages.AkenzaDataFlows},
  {path: "/org/:orgId/ws/:wsId/data-flows/new", component: Pages.AkenzaDataFlowNew},
  {path: "/org/:orgId/ws/:wsId/data-flows/:id", component: Pages.AkenzaDataFlowNew},
  {path: "/org/:orgId/data-flows/:id", component: Pages.AkenzaDataFlowNew},
  {path: "/org/:orgId/ws/:wsId/akenza-logs", component: Pages.AkenzaLogs},
  {path: "/org/:orgId/ws/:wsId/integrations", component: Pages.AkenzaIntegrations},
  {path: "/org/:orgId/ws/:wsId/assets/add", component: Pages.AkenzaAddDevice},
  {path: "/org/:orgId/ws/:wsId/assets/:id/edit", component: Pages.AkenzaAddDevice},
  {path: "/org/:orgId/ws/:wsId/assets/device/:id", component: Pages.AkenzaDeviceInfo},
  {path: "/org/:orgId/assets/device/:id", component: Pages.AkenzaDeviceInfo},

  // this route should be at the end of all other routes
  // { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
  // { path: "/*", exact: true, component: () => <Redirect to="/pages-404" /> },
];

const authRoutes: Array<RouteProps> = [
  //Authentication pages
  {path: "/login", component: Login},
  {path: "/_login", component: _Login},
  {path: "/logout", component: Logout},
  {path: "/register", component: Register},
  {path: "/register/:id", component: Register},
  {path: "/user-activation/:id", component: UserActivation},
  {path: "/setup/:id", component: Setup},

  //AuthenticationInner pages
  {path: "/page-login", component: PageLogin},
  {path: "/page-register", component: PageRegister},
  {path: "/page-recoverpw", component: RecoverPassword},
  {path: "/page-lock-screen", component: LockScreen},
  {path: "/page-confirm-mail", component: ConfirmMail},
  {path: "/page-email-verification", component: EmailVerification},
  {path: "/page-two-step-verification", component: TwoStepVerfication},

  //utility page
  {path: "/pages-comingsoon", component: PagesComingsoon},
  {path: "/pages-maintenance", component: PageMaintenance},
  {path: "/pages-404", component: Error404},
  {path: "/pages-500", component: Error500},

  {path: "/trace-plot", component: Pages.TracePlot},
];

export {userRoutes, authRoutes};
