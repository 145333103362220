import React, { Key, useState, useContext } from "react";
//i18n
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import OutputConnectorList from './OutputConnectorList'
import AddOutputConnector from './AddOutputConnector'

import {
    getIotOutputConnectorList
} from "src/helpers/iot_backend_helper";
import ActionTypeOption from "../../../../Rules/NewRule/RuleAction/ActionTypeSelector/ActionTypeOption";
import { getDataSinks, getNotificationServices } from "../../../../Rules/NewRule/common/configuration";
import { ACTION_TYPE_GROUP } from "../../../../Rules/NewRule/RuleAction/constant";
const OutputConnectorType = (props: any) => {
    const { t, isAdd, cancelAdd, match, page, connector, onSave, onUpdate, cancelSelect } = props;
    const [mode, setMode] = useState<string>(page);
    const [type, setType] = useState<string>(connector?.type || '');
    const [typeName, setTypeName] = useState<string>();
    const [connectors, setConnectors] = useState<any>([])
    const { wsId } = match.params;
    const dataSinks = getDataSinks();
    const notificationServices = getNotificationServices();
    const getTypeName = (typeGroup: Key, typeId: Key) => {
        switch (typeGroup) {
            case ACTION_TYPE_GROUP.DATA_SINK:
                return getDataSinks(s => s.filter(n => n.id == typeId))[0]
            case ACTION_TYPE_GROUP.NOTIFICATION_SERVICE:
                return getNotificationServices(s => s.filter(n => n.id == typeId))[0]
        }
    }

    const onSelect = (id: any, group?: any) => {
        const { name: typeName, type } = getTypeName(group, id);
        let authUser: any = localStorage.getItem("authUser");
        authUser = JSON.parse(authUser);
        setType(type);
        setTypeName(typeName);
        cancelSelect();
        getIotOutputConnectorList({
            scope: "DATA_FLOW",
            type: type,
            workspace_id: wsId,
        }, null).then(result => {
            if (result && result.Code == 200) {
                // 如果有对应类型输出连接器则跳转到列表选择页面,如果没有的话就到新增页面
                if (result.Data.length) {
                    setConnectors(result.Data);
                    setMode('LIST');
                } else {
                    setMode('ADD');
                }
            }
        });
    }
  
    const editConnectorProps = {
        onBack: () => {
            setMode('TYPE');
            cancelSelect();
            if(connector) {
                cancelAdd();
            }
        },
        type,
        onUpdate,
        onSave: (data:any) => {
            data.type = type; 
            onSave(data);
        },
        typeName: connector?connector.name:typeName,
        onCancel: () => {
            setMode('TYPE');
            cancelSelect();
            cancelAdd();
        },
        connector: connector,
    }
    const handleAddConnector = () => {
        setMode('ADD');
        cancelSelect();
    }
    return (
        <React.StrictMode>
            {
                mode == 'TYPE' && <div>
                    {
                        isAdd ? <header onClick={cancelAdd} className="flex-grow-0 flex-shrink-0 mb-4 font-size-16 fw-bold">
                            <i className="mdi mdi-arrow-left me-2 cursor-pointer" />
                            {t("output connector")}
                        </header> :
                            <header className="flex-grow-0 flex-shrink-0 mb-4 font-size-16 fw-bold first-letter-upper">
                                {t("output connector")}
                            </header>
                    }
                    <main className="flex-grow-1 overflow-auto scrollbar-none">
                        <div className="mt-wide-pre">
                            <div className="mb-2 text-secondary first-letter-upper">{t("data sinks")}</div>
                            {dataSinks.map(sink =>
                                <ActionTypeOption key={sink.id} {...sink}
                                    onClick={onSelect} />)}
                            <div className="mb-2 mt-4 text-secondary first-letter-upper">{t("data sinks")}</div>
                            {notificationServices.map(sink =>
                                <ActionTypeOption key={sink.id} {...sink}
                                    onClick={onSelect} />)}
                        </div>
                        <div className="mt-wide-pre" hidden={true}>
                            <div className="mb-2 text-secondary first-letter-upper">Notification services</div>
                        </div>
                    </main>
                </div>
            }
            {
                mode == 'LIST' &&
                <OutputConnectorList back={() => setMode('TYPE')} type={typeName} handleAddConnector={handleAddConnector}
                    connectors={connectors} cancelAdd={cancelAdd} />
            }
            {
                mode == 'ADD' && <AddOutputConnector {...editConnectorProps} />
            }
        </React.StrictMode>
    )
}

export default withTranslation()(withRouter(OutputConnectorType));
