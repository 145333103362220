export enum ACTION_TYPE {
    SELECT_TEMPLATE = "SELECT_TEMPLATE",
    SELECT_DEVICE_CONNECTOR = "SELECT_DEVICE_CONNECTOR",
    CLEAR_DEVICE_CONNECTOR = "CLEAR_DEVICE_CONNECTOR",
    SELECT_DEVICE_TYPE = "SELECT_DEVICE_TYPE",
    CLEAR_DEVICE_TYPE = "CLEAR_DEVICE_TYPE",
    PUSH_OUTPUT_CONNECTOR = "PUSH_OUTPUT_CONNECTOR",
    REMOVE_OUTPUT_CONNECTOR = "REMOVE_OUTPUT_CONNECTOR",
    CLEAR_OUTPUT_CONNECTORS = "CLEAR_OUTPUT_CONNECTORS",
    UPDATE_OUTPUT_CONNECTOR = "UPDATE_OUTPUT_CONNECTOR",
    PUSH_DATA_FLOW = "PUSH_DATA_FLOW"
}

export enum CREATE_MODE {
    INIT = "@@INIT",
    CUSTOM = "CUSTOM",
    DEVICE_SIMULATOR = "DEVICE_SIMULATOR",
    HTTP = "HTTP",
    MQTT = "MQTT",
}
