import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
//i18n
import { withTranslation } from "react-i18next";
import { withRouter, Link } from "react-router-dom";
import { Table, Col, Row, Input, Label, Button } from "reactstrap";
import AceEditorPage from 'src/components/AceEditorPage'
import { AvForm, AvField } from "availity-reactstrap-validation";
import Steps from "src/components/Steps";
import AddInputModal from './AddInputModal';
import AddParameterModal from './AddParameterModal';

import {
  createIotLogicBlock, getIotLogicBlock, updateIotLogicBlock
} from "src/helpers/iot_backend_helper";

const AddLogicBlock = (props: any) => {
  const [loadding, setLoadding] = useState<boolean>(true);
  const [formData, setFormData] = useState<any>({})
  const [inProgress, setInProgress] = useState<number>(1);
  const [showAceEditor, setShowAceEditor] = useState<boolean>(false);
  const [inputModalVisiable, setInputModalVisiable] = useState<boolean>(false);
  const [parameterModalVisiable, setParameterModalVisiable] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>([]);
  const [currentInput, setCurrentInput] = useState<object>({});
  const [parameters, setParameters] = useState<any>([]);
  const [currentParameter, setCurrentParameter] = useState<object>({});
  const [scriptData, setScriptData] = useState<string>("");
  const { t, match, history } = props;
  const { orgId, wsId, id } = match.params;

  useEffect(() => {
    if(id) {
      getIotLogicBlock(id, null).then(result => {
        if(result && result.Code == 200) {
          let {name, description, attribute: {inputs, parameters, script}} = result.Data;
          setFormData({
            name, description
          });
          setInputs(inputs);
          setParameters(parameters);
          const p = getAceEditorParam(inputs, parameters)
          setAceEditorParam(p)

          setScriptData(script);
        }
        setLoadding(false);
      }).catch(err => setLoadding(false))
    }else {
      setLoadding(false);
    }
  }, []);

  const steps = [{
    step: 1,
    des: 'Basic Information'
  }, {
    step: 2,
    des: 'Custom logic'
  }];
  // 上一步
  const preStep = () => {
    setInProgress(inProgress - 1);
  }
  // 下一步
  const nextStep = () => {
    setInProgress(inProgress + 1);
  }
  // 显示隐藏脚本编辑器
  const toggleAceEditor = () => {
    setShowAceEditor(!showAceEditor);
  }

  // 隐藏脚本编辑器
  const hideAceEditor = () => {
    setShowAceEditor(false);
  }

  // 脚本保存
  const saveScript = (data: string) => {
    setScriptData(data);
    setShowAceEditor(false);
  }
  const defaultScript = `function consume(event) {
  var temperature = event.inputs["temperature"]
  var threshold = event.properties["threshold"]
  if(temperature < threshold) {
    emit("action", { message: "it is too cold" });
  }
}`
  const aceEditorProps = {
    script: scriptData ||defaultScript,
    cancel: hideAceEditor,
    save: saveScript
  }

  const [aceEditorParam,setAceEditorParam ] = useState<string>()
  const getZero = (data: any) => {
    switch (data.parameter_type) {
      case "number":
        return data.dafault_value || 0
      case  "string":
        return `'${data.dafault_value || ""}'`
      case "boolean":
        return data.dafault_value
      default:
        return 'undefined'
    }
  }
  const getAceEditorParam = (inputs: any[], parameter: any[]) => {
    return `{
  "inputs": {${inputs.reduce((pre, cur)=> 
      pre + `\n\t\t${cur.variable_name}: ${cur.dafault_value || 'undefined'}, // ${cur.name}`,"")}
  },\n
  "properties": {${parameter.reduce((pre, cur)=>
      pre + `\n\t\t${cur.variable_name}: ${getZero(cur)}, // ${cur.display_name}`,"")}
  }
}`
  }

  // 显示隐藏inputModal
  const togInputModal = () => {
    setInputModalVisiable(!inputModalVisiable)
  }
  // inputModal数据保存
  const saveInput = (data: object) => {
    const newInputs = [...inputs, data]
    setInputs(newInputs);
    setInputModalVisiable(false)
    const p = getAceEditorParam(newInputs, parameters)
    setAceEditorParam(p)
  }
  // inputModal数据更新
  const updateInput = (data: any) => {
    let arr = [...inputs];
    arr.forEach((row, index) => {
      if (row.id == data.id) {
        arr[index] = data
      }
    });
    setInputs(arr);
    setInputModalVisiable(false)
    const p = getAceEditorParam(arr, parameters)
    setAceEditorParam(p)
  }

  const inputModalProps = {
    visiable: inputModalVisiable,
    inputData: currentInput,
    togModal: togInputModal,
    hideModal: () => {
      setInputModalVisiable(false)
    },
    save: saveInput,
    update: updateInput
  }
  // 显示隐藏parameterModal
  const togParameterModal = () => {
    setParameterModalVisiable(!parameterModalVisiable)
  }
  // parameterModal数据保存
  const saveParameter = (data: object) => {
    parameters.push(data);
    setParameters(parameters);
    setParameterModalVisiable(false)
    console.log(data);
    const p = getAceEditorParam(inputs, parameters)
    setAceEditorParam(p)
  }

  // parameterModal数据更新
  const updateParameter = (data: any) => {
    let arr = [...parameters];
    arr.forEach((row, index) => {
      if (row.id == data.id) {
        arr[index] = data
      }
    });
    setParameters(arr);
    setParameterModalVisiable(false)
    const p = getAceEditorParam(inputs, arr)
    setAceEditorParam(p)
  }

  const parameterModalProps = {
    visiable: parameterModalVisiable,
    parameterData: currentParameter,
    togModal: () => setShowAceEditor(!parameterModalVisiable),
    hideModal: () => {
      setParameterModalVisiable(false)
    },
    update: updateParameter,
    save: saveParameter
  }

  // 编辑block input
  const handleEditInput = (row: any) => {
    setInputModalVisiable(true);
    setCurrentInput(row);
  }

  // 删除block input
  const handleDeleteInput = (row: any) => {
    let arr = inputs.filter((input: any) => input.id != row.id);
    setInputs(arr)
    const p = getAceEditorParam(arr, [])
    setAceEditorParam(p)
  }

  // 编辑block parameter
  const handleEditParameter = (row: any) => {
    setParameterModalVisiable(true);
    setCurrentParameter(row);
  }

  // 删除block parameter
  const handleDeleteParameter = (row: any) => {
    let arr = parameters.filter((parameter: any) => parameter.id != row.id);
    setParameters(arr)
  }

  // 提交创建逻辑块
  const handCreateLogicBlock = () => {
    if (!(inputs.length)) {
      alert(t("At least 1 input is required"))
      return
    }
    if (!scriptData) {
      alert(t("Custom logic script is required"))
      return;
    }
    let postData = {
      ...formData,
      org_id: orgId,
      attribute: {
        inputs, parameters, script: scriptData
      }
    }
    if(id) {
      updateIotLogicBlock(id, postData, null).then(result => {
        if(result.Code == 200) {
          history.push(`/org/${orgId}/logic-blocks`);
        }
      })
    }else {
      createIotLogicBlock(postData, null).then(result => {
        if(result.Code == 200) {
          history.push(`/org/${orgId}/logic-blocks`);
        }
      })
    }

  }

  if(loadding) return (<></>)
  return (
    <React.Fragment>
      <div className="min-vh-100" style={{ display: 'flex', flexDirection: 'column' }}>
        {
          showAceEditor ? <AceEditorPage {...aceEditorProps} parameter = {aceEditorParam} /> :
            <div>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'cente', justifyContent: 'space-between', padding: '16px 30px', borderBottom: '1px solid #e8e8e8' }}>
                <div className="first-letter-upper" style={{ fontSize: '20px', color: 'rgba(0,0,0,.85)', fontWeight: 700 }}>
                  {t(id?"update logic block":"create new logic block")}
                </div>
                <Link to={`/org/${orgId}/logic-blocks`}>
                  <button
                    type="button"
                    style={{ fontSize: '16px' }}
                    className="btn btn-outline-light waves-effect"
                  >
                    {t('cancel')}
                  </button>
                </Link>
              </div>
              <Row style={{ padding: '50px 0 0 50px' }}>
                <Col sm={4}>
                  {
                    inProgress == 1 &&
                    <AvForm
                      onValidSubmit={(e: any, v: any) => {
                        setFormData({ ...formData, ...v });
                        nextStep();
                      }}
                    >
                      <h5 className="mb-4">{t('BasicInformation')}</h5>
                      <div className="mb-4">
                        <AvField
                          name="name"
                          defaultValue={formData.name || ''}
                          label={t('Name')}
                          placeholder=""
                          type="text"
                          errorMessage={t("Name is required")}
                          validate={{ required: { value: true } }}
                        />
                      </div>
                      <div className="mb-4">
                        <AvField
                          name="description"
                          defaultValue={formData.description || ''}
                          label={`${t('description')} (${t("optional")})`}
                          placeholder=""
                          type="text"
                          errorMessage=""
                          validate={{ required: { value: false } }}
                        />
                      </div>
                      <div style={{ marginTop: '40px', marginBottom: '40px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Link to={`/org/${orgId}/logic-blocks`}>
                          <button
                            type="button"
                            style={{ fontSize: '16px' }}
                            className="btn btn-outline-light waves-effect text-capitalize"
                          >
                            {t('cancel')}
                          </button>
                        </Link>
                        <Button type="submit" color="primary">
                          {t('Next')}
                        </Button>
                      </div>
                    </AvForm>
                  }
                  {
                    inProgress == 2 &&
                    <div>
                      <h5 className="mb-4 first-letter-upper">{t('logic inputs')}</h5>
                      <div className="mb-5">
                        {
                          inputs.length ? <div className="table-responsive mt-4">
                            <Table className="table mb-0">
                              <thead className="table-light">
                                <tr>
                                  <th>#</th>
                                  <th style={{ width: '60%' }}>{t("Name")}</th>
                                  <th>{t("Action")}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  inputs.map((row: any, index: number) =>
                                    <tr key={row.id}>
                                      <th scope="row">{index + 1}</th>
                                      <td>{row.name}</td>
                                      <td>
                                        <div className="flex-row">
                                          <div className="text-info whitespace-nowrap" onClick={() => handleEditInput(row)} style={{ padding: "0 8px" }}>{t("Edit")}</div>
                                          <div className="text-danger whitespace-nowrap" onClick={() => handleDeleteInput(row)} style={{ padding: "0 8px" }}>{t("Delete")}</div>
                                        </div>
                                      </td>
                                    </tr>)
                                }
                              </tbody>
                            </Table>
                          </div> : <div className="s-box">
                            <i className="dripicons-information" style={{ marginRight: '10px', marginTop: '3px', fontSize: '16px', color: '#faad14' }} />
                            {t("At least 1 input is required")}
                          </div>
                        }
                        <button
                          type="button"
                          style={{ fontSize: '16px' }}
                          onClick={() => { togInputModal(); setCurrentInput({}) }}
                          className="btn btn-outline-light waves-effect mt-3"
                        >
                          {`+ ${t("add input")}`}
                        </button>
                      </div>
                      <h5 className="mb-2 mt-4 first-letter-upper">{t('logic parameters')}</h5>
                      <div className="mb-5">
                        {
                          parameters.length ? <div className="table-responsive mt-4">
                            <Table className="table mb-0">
                              <thead className="table-light">
                                <tr>
                                  <th>#</th>
                                  <th style={{ width: '60%' }}>{t("Parameter name")}</th>
                                  <th>{t("Action")}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  parameters.map((row: any, index: number) =>
                                    <tr key={row.id}>
                                      <th scope="row">{index + 1}</th>
                                      <td>{row.display_name}</td>
                                      <td>
                                        <div className="flex-row">
                                          <div className="text-info whitespace-nowrap" onClick={() => handleEditParameter(row)} style={{ padding: "0 8px" }}>{t("Edit")}</div>
                                          <div className="text-danger whitespace-nowrap" onClick={() => handleDeleteParameter(row)} style={{ padding: "0 8px" }}>{t("Delete")}</div>
                                        </div>
                                      </td>
                                    </tr>)
                                }
                              </tbody>
                            </Table>
                          </div> : null
                        }
                        <button
                          type="button"
                          style={{ fontSize: '16px' }}
                          onClick={() => { togParameterModal(); setCurrentParameter({}) }}
                          className="btn btn-outline-light waves-effect mt-3"
                        >
                          + {" "}{props.t('Add parameter')}
                        </button>
                      </div>

                      <h5 className="mb-4 first-letter-upper">{t('custom logic script')}</h5>
                      <div className="mb-5">
                        {
                          scriptData ? <div className="s-box">
                            <i className="fas fa-check-circle" style={{ marginRight: '10px', fontSize: '16px', color: '#52c41a' }}></i>
                                {t("Custom logic script is present")}
                          </div> : <div className="s-box">
                            <i className="fas fa-info-circle" style={{ marginRight: '10px', fontSize: '16px', color: '#faad14' }}></i>
                            {t("Custom logic script is required")}
                          </div>
                        }
                        <button
                          type="button"
                          style={{ fontSize: '16px' }}
                          onClick={toggleAceEditor}
                          className="btn btn-outline-light waves-effect mt-3"
                        >
                          {`+ ${t(scriptData?"update script":"add script")}`}
                        </button>
                      </div>

                      <div className="mt-4 mb-4 flex-row space-between">
                        <button
                          type="button"
                          style={{ fontSize: '16px' }}
                          onClick={preStep}
                          className="btn btn-outline-light waves-effect text-capitalize"
                        >
                          {t('back')}
                        </button>
                        <Button className="first-letter-upper" type="button" color="primary" onClick={handCreateLogicBlock}>
                          {t(id?'update custom logic block':'create custom logic block')}
                        </Button>
                      </div>
                    </div>
                  }
                </Col>
                <Col sm={4} style={{ paddingLeft: '30px' }}>
                  <Steps steps={steps} inProgress={inProgress} />
                </Col>
              </Row>
            </div>
        }
      </div>
      <AddInputModal {...inputModalProps} />
      { parameterModalVisiable && <AddParameterModal {...parameterModalProps} />}
    </React.Fragment>
  );
}

AddLogicBlock.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withTranslation()(withRouter(AddLogicBlock));