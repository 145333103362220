import { Key, StrictMode } from 'react';
// Util
import { insure } from 'src/util';

type Props = {
  iconClassName: string;
  disable: boolean;
  id: Key;
  title: Key;
  onSelect: Function;
};

export default ({
  iconClassName,
  disable,
  id,
  title,
  onSelect,
}: Partial<Props>) => {
  return (
    <StrictMode>
      <div
        className={`mt-narrow-pre border border-blue-hover rounded-2 px-2 py-2 ${
          disable && 'pointer-events-none bg-light text-secondary'
        }`}
        onClick={insure(onSelect, id)}>
        <i className={`me-2 ${iconClassName}`}></i>
        <span className="fw-bold">{title}</span>
      </div>
    </StrictMode>
  );
};
