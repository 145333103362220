import React, { useEffect, useState } from "react";
//i18n
import CreatableSelect from 'react-select/creatable';
import makeAnimated from "react-select/animated";
import { Col, Row, Modal, Label } from "reactstrap";
import {
  getIotTags, createIotTag, createIotDeviceTags
} from "src/helpers/iot_backend_helper";
import { useTranslation } from "react-i18next";
interface Itag {
  label: string;
  value: string;
}
const animatedComponents = makeAnimated();
const AddTagModal = (props: any) => {
  let {
    device, visiable, togModal, hideModal, handleOk
  } = props;
  const {workspace_id} = device;
  const [tags, setTags]: [Itag[], Function] = useState([]);
  const [selectTags, setSelectTags]: [Itag[], Function] = useState<any>([]);
  const { t } = useTranslation();

  useEffect(() => {
    getIotTags({workspace_id: workspace_id, page_size: 100, page_number: 1}, null).then(result => {
      if(result && result.Code == 200) {
        let data = result.Data.filter((t:{name: string, id: string}) => {
            let find = device?.tags?.find((o:{name: string, id: string}) => t.id === o.id);
            return !find;
        });
        data = data.map((t:{name: string, id: string}) => ({label: t.name, value: t.id}))
        console.log(data)
        setTags(data);
      }
    }).catch(err => console.log(err));
  }, [])

  // 创建标签
  const handleCreateTag = (e: string): void => {
    let data = {
      workspace_id: workspace_id,
      name: e,
      description: ''
    }
    createIotTag(data, null).then((result) => {
      tags.push({ label: result.Data.name, value: result.Data.id });
      setTags(tags);
    });
  }

  // 标签选择
  const handleSelectChange = (e: Itag[]): void => {
    setSelectTags(e);
  }

  const handleSaveDeviceTags = () :void => {
    let data = {
      device_id: device.id,
      tags: selectTags.map((o) => o.value)
    }
    createIotDeviceTags(data, null).then(result => {
      if(result && result.Code === 200) {
        handleOk(selectTags)
      }
    });
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={visiable}
        toggle={() => {
          togModal();
        }}
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">{t("Add tags to asset")}</h5>
          <button
            type="button"
            onClick={() => {
              hideModal();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Col sm={12}>
              <div className="mb-3">
                <Label htmlFor="example-search-input" className="form-Label">{t('Tags')}</Label>
                <CreatableSelect
                  isMulti
                  isSearchable={true}
                  classNamePrefix="select2-selection"
                  placeholder={t('Select Asset Tag')}
                  closeMenuOnSelect={false}
                  onCreateOption={handleCreateTag}
                  onChange={handleSelectChange}
                  defaultValue={selectTags}
                  options={tags}
                  components={animatedComponents}
                />
              </div>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
            onClick={hideModal}
          >
            {t('Close')}
          </button>
          <button onClick={handleSaveDeviceTags} className="btn btn-primary">
            {t('Save')}
          </button>
        </div>
      </Modal>
    </React.Fragment>
  );
}

// AddTagModal.propTypes = {
//   location: PropTypes.object,
//   t: PropTypes.any,
//   visiable: PropTypes.bool,
//   togModal: PropTypes.func,
//   hideModal: PropTypes.func,
//   save: PropTypes.func
// };
export default AddTagModal;