import {Key, StrictMode} from "react";

import {useTranslation} from "react-i18next";

import LogicConsole from "./LogicConsole";

import {insure} from "src/util";

type Props = {
    name: String;
    onClear: Function;
}

export default ({name, onClear}: Partial<Props>) => {
    const {t} = useTranslation()

    return (<StrictMode>
        <div className="shadow cus-bg-white">
            <header className="px-3 py-2">
                <i className="mdi mdi-link-variant me-2"/>
                <span className="fw-bold text-capitalize">{name}</span>
            </header>
            <main className="border-top border-bottom px-3 py-2">
                <LogicConsole/>
            </main>
            <footer className="px-3 py-2">
                <span className="text-primary text-capitalize cursor-pointer"
                      onClick={insure(onClear)}>
                    {t("clear")}
                </span>
            </footer>
        </div>
    </StrictMode>)
}
