import React, { Fragment, Key, useEffect, useState } from 'react';
// i18n
import { useTranslation } from 'react-i18next';
// Router
import { Link, useRouteMatch } from 'react-router-dom';
// Component
import { Alert, Button } from 'reactstrap';
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
// Api
import { getIotLogicBlock } from 'src/helpers/iot_backend_helper';
// Util
import { awaitWrap, useRole } from 'src/util';
import ReactJson from 'react-json-view';

type Input = {
  id: Key;
};
type Parameter = {};
type LogicBlock = {
  name: string;

  attribute: {
    inputs: Input[];
    parameters: Parameter[];
    script: string;
  };
};

type CellProp = {
  block?: LogicBlock;
};
const ScriptCell = ({ block }: CellProp) => {
  const { t } = useTranslation();

  if (!block) {
    return (
      <Alert className="d-inline-block mx-4 mt-3" color="danger">
        <i className="me-2 fas fa-exclamation" />
        <span>{t('No custom logic script exists')}</span>
      </Alert>
    );
  }

  return (
    <Fragment>
      <div className="d-inline-block mx-4 mt-2 p-3 rounded-2 shadow">
        <ReactJson
          collapsed
          displayObjectSize={false}
          name={false}
          src={{ script: block.attribute.script }}
        />
      </div>
      <div>
        <Alert className="d-inline-block mx-4 mt-3" color="success">
          <i className="me-2 fas fa-check" />
          <span>{t('Custom logic script is present')}</span>
        </Alert>
      </div>
    </Fragment>
  );
};

// ---------*---------*---------*---------*---------*---------*---------*---------*

export default function () {
  const { t } = useTranslation();
  const m = useRouteMatch<any>();

  const { orgId, id, wsId } = m.params;

  const pageRole = useRole(orgId, wsId);

  const [block, setBlock] = useState<LogicBlock>();

  // Table | Logic input
  const [inputData, setInputData] = useState([]);
  const inputColumns: ColumnDescription[] = [
    {
      dataField: 'name',
      text: t('Name'),
      headerClasses: 'ps-4',
      classes: 'ps-4',
    },
    {
      dataField: 'description',
      text: t('Description'),
    },
    {
      dataField: 'variable_name',
      text: t('Variable name'),
    },
    {
      dataField: 'topic',
      text: t('Topic'),
    },
    {
      dataField: 'datakey',
      text: t('Key'),
    },
  ];
  // Table | Logic parameters
  const [parametersData, setParametersData] = useState([]);
  const parametersColumns: ColumnDescription[] = [
    {
      dataField: 'display_name',
      text: t('Parameter name'),
      headerClasses: 'ps-4',
      classes: 'ps-4',
    },
    {
      dataField: 'description',
      text: t('Description'),
    },
    {
      dataField: 'variable_name',
      text: t('Variable name'),
    },
    {
      dataField: 'parameter_type',
      text: t('Type'),
    },
    {
      dataField: 'defaule_value',
      text: t('Default value'),
    },
  ];

  // initial page
  useEffect(() => {
    (async function () {
      const [res, err] = await awaitWrap(getIotLogicBlock(id, null));

      if (err) {
        return;
      }
      if (res && res.Code != 200) {
        return;
      }

      setBlock(res.Data);
      setInputData(res.Data.attribute.inputs);
      setParametersData(res.Data.attribute.parameters);
    })();
  }, [id]);

  return (
    <Fragment>
      <header className="clearfix p-4">
        <div className="float-start font-size-18">
          <Link to={`/org/${orgId}/logic-blocks`}>
            <i className="me-2 lh-lg mdi mdi-arrow-left" />
          </Link>
          <span className="fw-bolder">{block?.name}</span>
        </div>

        {pageRole == 'viewer' ? (
          <div className="float-end">
            <Button
              className="cus-border-h-blue-400 cus-text-h-blue-400 cus-bg-white"
              disabled
              outline>
              {t('Edit')}
            </Button>
          </div>
        ) : (
          <Link
            className="float-end"
            to={`/org/${orgId}/logic-blocks/edit/${id}`}>
            <Button
              className="cus-border-h-blue-400 cus-text-h-blue-400 cus-bg-white"
              outline>
              {t('Edit')}
            </Button>
          </Link>
        )}
      </header>

      <div className="border-bottom" />

      <main className="py-4">
        <Alert className="d-flex mx-4 w-50" color="warning">
          <i className="me-2 lh-lg fas fa-exclamation" />
          <div className="lh-lg">{t('__LBE')}</div>
        </Alert>

        {/* Logic inputs */}
        <div className="mx-4 mt-5 fw-bold">{t('Logic inputs')}</div>
        <BootstrapTable
          classes="mt-3"
          keyField="name"
          bordered={false}
          headerClasses="border-top cus-bg-gray-50"
          data={inputData}
          columns={inputColumns}
        />

        <div className="mx-4 mt-5 fw-bold">{t('Logic parameters')}</div>
        <BootstrapTable
          classes="mt-3"
          keyField="name"
          bordered={false}
          headerClasses="border-top cus-bg-gray-50"
          data={parametersData}
          columns={parametersColumns}
        />

        <div className="mx-4 mt-5 fw-bold">{t('Custom logic script')}</div>
        <ScriptCell block={block} />
      </main>
    </Fragment>
  );
}
