// i18n
import { useTranslation } from 'react-i18next';
// Component
import InputTypeOption from './InputTypeOption';
// Util
import { insure } from 'src/util';
import {
  getInputTypeInfo,
  InputConfiguration,
} from '../../common/configuration';

type Props = {
  onCancel: Function;
  onSelect: Function;
};

export default ({ onCancel, onSelect }: Partial<Props>) => {
  const { t } = useTranslation();

  const mutateInputTypeConfiguration = (
    inputTypeConfigurations: InputConfiguration[],
  ) =>
    inputTypeConfigurations.map(configuration => ({
      value: configuration.id,
      label: t(configuration.name.toString()),
      iconClassName: configuration.iconClassName,
    }));

  const inputTypeInfo = getInputTypeInfo(mutateInputTypeConfiguration);

  return (
    <div className="d-flex flex-column h-100">
      <header className="flex-shrink-0 mb-5 user-select-none clearfix">
        <span className="float-start font-size-18 fw-bolder text-capitalize">
          {t('select input type')}
        </span>
        <span
          className="float-end
          lh-lg text-primary text-capitalize cursor-pointer"
          onClick={insure(onCancel)}>
          {t('cancel')}
        </span>
      </header>
      <main className="flex-grow-1 overflow-auto">
        {inputTypeInfo.map(info => (
          <InputTypeOption key={info.value} {...info} onClick={onSelect} />
        ))}
      </main>
    </div>
  );
};
