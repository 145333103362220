import React, { StrictMode, useState } from "react";
//i18n
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "reactstrap";
import { menuList } from "../../../optional";
import OrganizationContent from "../ModalContent/OrganizationContent";
import WorkspaceContent from "../ModalContent/WorkspaceContent";
import DefaultContent from "../ModalContent/DefaultContent";

import { createIotWorkspaces, changeWorkspace } from "src/helpers/iot_backend_helper";
// actions
import { loginSuccess } from "src/store/actions";
export default withTranslation()(withRouter((props: any) => {
    const dispatch = useDispatch();
    let { t, match } = props;
    const { orgId } = match.params;

    const { selectedMenu } = useSelector((state: any) => ({
        selectedMenu: state.Layout.selectedMenu,
    }))

    const [modalVisible, setModalVisible] = useState(false)

    const handleClose = () => {
        setModalVisible(false)
    }

    // 创建工作区
    const handleWorkspaceCreate = (...args: any) => {
        let data = {
            name: args[0],
            description: args[1],
            org_id: orgId
        }
        createIotWorkspaces(data, null).then((result) => {
            if (result && result.Code == 200) {
                // 更新redux跟本地存储数据
                let authUser: any = localStorage.getItem("authUser");
                authUser = JSON.parse(authUser);
                let { id, name, org_id, owner_id, description } = result.Data;
                let workspace = { id, name, org_id, owner_id, description }
                authUser.Data.curr_org_id = org_id;
                authUser.Data.curr_wspace_id = id;
                authUser.Data.curr_workspace = workspace;
                authUser.Data.workspaces.push(workspace);
                changeWorkspace({ org_id: org_id, workspace_id: id }, null);
                dispatch(loginSuccess({ Data: authUser.Data }));
                localStorage.setItem("authUser", JSON.stringify(authUser));
                handleClose();
            }
        })
    }

    return (<StrictMode>
        <div className="px-4 py-4 text-primary cursor-pointer"
            onClick={() => setModalVisible(true)}>
            <i className="fas fa-plus me-2" />
            <span className="text-capitalize">{t(`new ${selectedMenu}`)}</span>
        </div>
        <Modal isOpen={modalVisible} centered>
            {selectedMenu == menuList[0] && <OrganizationContent onClose={handleClose} onCancel={handleClose} onCreate={handleClose} />}
            {selectedMenu == menuList[1] &&
                <WorkspaceContent onClose={handleClose} onCancel={handleClose} onCreate={handleWorkspaceCreate} />}
            {menuList.indexOf(selectedMenu) == -1 && <DefaultContent onClose={handleClose} onCancel={handleClose} />}
        </Modal>
    </StrictMode>)
}));
