import {StrictMode} from "react";

import {useTranslation} from "react-i18next";

import {insure} from "src/util";

type Props = {
    className: string;
    onBack: Function;
    onCancel: Function;
}

export default ({className, onBack, onCancel}: Partial<Props>) => {
    const {t} = useTranslation()

    return (<StrictMode>
        <div className={className}>
            <div className="clearfix">
                    <span className="float-start font-size-18 fw-bolder cursor-pointer">
                        <i className="fas fa-arrow-left me-2" onClick={insure(onBack)}/>
                        <span className="text-capitalize">{t("select tags")}</span>
                    </span>
                <span className="float-end lh-lg text-primary text-capitalize cursor-pointer"
                      onClick={insure(onCancel)}>
                    {t("cancel")}
                </span>
            </div>
        </div>
    </StrictMode>)
}
