import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
//i18n
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import { Col, Input, Row } from 'reactstrap';
import BootstrapTable from "react-bootstrap-table-next";
import ReactJson from 'react-json-view'
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import {
  getIotCollectDataList
} from "src/helpers/iot_backend_helper";
import dayjs from "dayjs";
import { currying } from '../../../../util';
const MessageLog = (props: any) => {
  const { t, match, device_id } = props;
  const [list, setList] = useState([]);
  const [expanded, setExpanded] = useState<any>([1]);

  const timeFormatStr = "YYYY-MM-DD HH:mm:ss"
  const [startTime, setStartTime] = useState(dayjs().startOf("day").format(timeFormatStr))
  const [endTime,setEndTime] = useState(dayjs().endOf("day").format(timeFormatStr))

  useEffect(() => {
    fetchData({
      page_size: 200,
      page_number: 1,
      start_at: startTime,
      end_at: endTime,
    })
  }, []);

  const fetchData = (params: any) => {
    getIotCollectDataList({ ...params, device_id }, null).then((result: any) => {
      if (result.Code == 200) {
        let arr: any = [];
        result.Data.forEach((row: any) => {
          arr.push({
            id: row.time,
            topic: row.topic,
            data: row.data,
            time_stamp: row.time * 1000
          })
        });
        setList(arr);
      }
    })
  }

  const columns = [{
    dataField: 'topic',
    text: t('topic')
  }, {
    dataField: 'data',
    text: t('data'),
    formatter: (dataObj: any, row: any, index: any) => {
      let dataArr: any = [];
      Object.keys(dataObj).forEach((key) => {
        if (typeof (dataObj[key]) == "object") {
          dataArr.push({
            key: key,
            value: '{...}'
          })
        } else {
          dataArr.push({
            key: key,
            value: dataObj[key]
          })
        }
      })
      return (
        <div className="flex-row" style={{ flexWrap: 'wrap' }}>
          {
            dataArr.map((item: any) =>
              <div className="flex-row mb-2" style={{ width: '220px' }}>
                <div className="text-black-50">{item.key}: </div>
                <div className="text-dark" style={{ marginLeft: '5px' }}> {item.value}</div>
              </div>)
          }
        </div>
      )
    },
  }, {
    dataField: 'time_stamp',
    text: t('timestamp'),
    formatter: (value: any, row: any, index: any) => {
      console.log(value);
      
      return <div>{dayjs(value).format("YYYY-MM-DD HH:mm:ss")}</div>
    }
  }];

  const handleOnExpand = (row: any, isExpand: boolean, rowIndex: number, e: any) => {
    if (isExpand) {
      setExpanded([...expanded, row.id])
    } else {
      setExpanded(expanded.filter((x: any) => x !== row.id))
    }
  }
  const expandRow = {
    renderer: (row: any) => (
      <div className="mt-2">
        <div style={{ color: '#8c8c8c' }}>Data </div>
        <div className="mt-1" style={{ border: '1px solid #e8e8e8', backgroundColor: '#fafafa', borderRadius: '4px', padding: '12px' }} >
          <ReactJson displayObjectSize={false} displayDataTypes={false} name={false} src={row.data} />
        </div>
      </div>
      //<div className="bg-soft-light">{row.name}</div>
    ),
    expanded: expanded,
    onExpand: handleOnExpand
  };
  const rowEvents = {
    onClick: (e: any, row: any, rowIndex: number) => {
      console.log(row)
    }
  }
  const defaultSorted: any = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const pageOptions: any = {
    sizePerPage: 10,
    totalSize: list.length, // replace later with size(customers),
    custom: true,
  };

  const changeStartTime = (v : string) => {
    if (dayjs(v).isAfter(dayjs(endTime))){
      alert("开始时间不应晚于结束时间！")
      return
    }
    setStartTime(v)
    fetchData({
      page_size: 100,
      page_number: 1,
      start_at: startTime,
      end_at: endTime,
    })
  }
  const changeEndTime = (v:string) =>{
    if (dayjs(v).isBefore(dayjs(startTime))){
      alert("结束时间不应晚于开始时间！")
      return
    }
    setEndTime(v)
    fetchData({
      page_size: 100,
      page_number: 1,
      start_at: startTime,
      end_at: endTime,
    })
  }

  // Select All Button operation
  const selectRow: any = {
    mode: "checkbox",
  };
  return (
    <React.Fragment>
      <div className="d-flex justify-content-end">
        <div className="me-4">
          <div className="fw-bold first-letter-upper">{t("start time")}</div>
          <Input
            type="datetime-local"
            value={startTime}
            onChange={e => changeStartTime(e.target.value)}
          />
        </div>
        <div>
          <div className="fw-bold first-letter-upper">{t("end time")}</div>
          <Input
            type="datetime-local"
            value={endTime}
            onChange={e => changeEndTime(e.target.value)}
          />
        </div>
      </div>
      <PaginationProvider pagination={paginationFactory(pageOptions)}>
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider keyField="id" columns={columns} data={list} search>
            {toolkitProps => (
              <Row>
                <BootstrapTable
                  expandRow={expandRow}
                  bordered={false}
                  striped={false}
                  defaultSorted={defaultSorted}
                  classes={'table align-middle table-nowrap'}
                  headerWrapperClasses={'thead-light'}
                  rowEvents={rowEvents}
                  {...toolkitProps.baseProps}
                  {...paginationTableProps}
                />
                <Row className="align-items-md-center mt-30">
                  <Col className="inner-custom-pagination d-flex">
                    <div className="d-inline">
                      <SizePerPageDropdownStandalone {...paginationProps} />
                    </div>
                    <div className="text-md-right ms-auto">
                      <PaginationListStandalone {...paginationProps} />
                    </div>
                  </Col>
                </Row>
              </Row>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>
    </React.Fragment>
  );
}

MessageLog.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  device_id: PropTypes.string,
};
export default withTranslation()(withRouter(MessageLog));