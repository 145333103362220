import React, { useEffect } from "react";
import MetaTags from "react-meta-tags";
import { Row, Col, Card, CardBody, Container, CardHeader } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import useWindowDimensions from '../../components/resize';

import "./style.css"

const GltfOffice = () => {
  
  let scripts: Array<HTMLScriptElement> = [];
  function addScripts(items:Array<string>) {
    items.map(filePath => {
      const script = document.createElement('script');
      script.src = filePath;
      script.async = false;
      document.body.appendChild(script);
      scripts.push(script);
    })
  }

  function removeScripts() {
    scripts.map(script => {
      document.body.removeChild(script);
    })
  }

  const { height, width } = useWindowDimensions();

  useEffect(() => {    
    addScripts([
      'xeogl/build/xeogl.js', 
      'xeogl/examples/js/models/glTFModel.js', 
      'xeogl/examples/js/skyboxes/skybox.js', 
      'xeogl/examples/js/libs/dat.gui.min.js',
      'xeogl/examples/test.js'
    ])
  return () => {
      removeScripts();
    }
  }, []);

  const mystyle = {
    height:  height - 160
  };

  return (    
    <React.Fragment>
      <div className="page-content" id="maincc">              
        <div id="dat-gui-container">        
        </div>
        <canvas id="xeogl_canvas" className="col-lg-12" style={mystyle}></canvas>
      </div>      
    </React.Fragment>
  );
};

export default GltfOffice;
