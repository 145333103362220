import { StrictMode } from 'react';

import { Link, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button } from 'reactstrap';

import { useUrlPrefix } from '../../customHook';
import { insure } from 'src/util';
import './topBar.scss';

export type TopBarProps = {
  disableSave: boolean;
  status: boolean;
  changeStatus: Function;
  onClose: Function;
  onSave: Function;
  id: string;
};

export const TopBar = ({
  disableSave = false,
  status,
  changeStatus,
  onClose,
  onSave,
  id,
}: TopBarProps) => {
  const { t } = useTranslation();

  const { wsId } = useRouteMatch<any>().params;
  const { user } = useSelector((state: any) => ({
    user: state.login.user,
  }));
  const role =
    user.user_workspaces.find((ws: any) => ws.workspace.id == wsId)?.role ||
    'viewer';

  const urlPrefix = useUrlPrefix();
  return (
    <StrictMode>
      <div className="top-bar-height border-bottom px-5 py-4 clearfix">
        <Link to={`${urlPrefix}/rules`}>
          <div className="float-start font-size-20 h-content lh-lg">
            <i className="mdi mdi-arrow-left me-2 cursor-pointer" />
            <span className="fw-bold text-capitalize">
              {t(id ? 'update rule' : 'new Rule')}
            </span>
          </div>
        </Link>
        <div className="float-end flex-row">
          {/* <Button className="me-5" color="primary" outline onClick={insure(reverseLocked)}>
                    <i className={`fas ${locked ? "fa-lock" : "fa-lock-open"}`} />
                </Button> */}
          <div className="form-check form-switch form-switch-lg" dir="ltr">
            <input
              disabled={role == 'viewer'}
              onChange={e => {
                changeStatus(e.target.checked);
              }}
              type="checkbox"
              checked={status}
              className="form-check-input"
              id="customSwitchsizelg"
            />
            <label className="form-check-label me-2 font-size-14 text-capitalize">
              {t('Rule is activated')}
            </label>
          </div>
          <Button
            className="me-2 font-size-16 text-capitalize"
            color="light"
            outline
            onClick={insure(onClose, `${urlPrefix}/rules`)}>
            {t('cancel')}
          </Button>
          <Button
            className="font-size-16"
            color="primary"
            disabled={disableSave}
            onClick={insure(onSave)}>
            {t(id ? 'update rule' : 'save rule')}
          </Button>
        </div>
      </div>
    </StrictMode>
  );
};
