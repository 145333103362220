import { OrganizeTypes, OrganizeState } from "./actionTypes"

export const INIT_STATE : OrganizeState = {
  events: [],
  categories: [],
  error: {},
  organize: [],
  organizes: [],
}

const Organize = (state = INIT_STATE, action : any) => {
  switch (action.type) {
    case OrganizeTypes.API_SUCCESS:
      switch (action.payload.actionType) {
        case OrganizeTypes.GET_EVENTS:
          return {
            ...state,
            events: action.payload.data,
          };

        case OrganizeTypes.ADD_NEW_EVENT:
          return {
            ...state,
            events: [...state.events, action.payload.data],
          }

        default:
          return { ...state };
      }
    case OrganizeTypes.API_FAIL:
      switch (action.payload.actionType) {
        case OrganizeTypes.GET_EVENTS:
          return {
            ...state,
            events: action.payload.error,
          };

        case OrganizeTypes.ADD_NEW_EVENT:
          return {
            ...state,
            events: action.payload.error,
          };

        default:
          return { ...state };
      }

    case OrganizeTypes.UPDATE_EVENT_SUCCESS:
      return {
        ...state,
        events: state.events.map(event =>
          event.id.toString() === action.payload.id.toString()
            ? { event, ...action.payload }
            : event
        ),
      }

    case OrganizeTypes.UPDATE_EVENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case OrganizeTypes.DELETE_EVENT_SUCCESS:
      return {
        ...state,
        events: state.events.filter(
          event => event.id.toString() !== action.payload.id.toString()
        ),
      }

    case OrganizeTypes.DELETE_EVENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case OrganizeTypes.GET_ORGANIZE:
      return {
        ...state,
        // events: action.payload.data,
        payload: state
      }

    case OrganizeTypes.GET_ORGANIZE_SUCCESS:
      return {
        ...state,
        organize: action.payload,
      }

    case OrganizeTypes.GET_ORGANIZE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case OrganizeTypes.GET_ORGANIZES:
      return {
        ...state,
        // events: action.payload.data,
        payload: state
      }

    case OrganizeTypes.GET_ORGANIZES_SUCCESS:
      return {
        ...state,
        organizes: action.payload,
      }

    case OrganizeTypes.GET_ORGANIZES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default Organize
