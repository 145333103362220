import { Fragment } from 'react';
// hook
import ReactDOM from 'react-dom';
// Component
import PrivateOverlay from './PrivateOverlay';
import PrivateDrawer from './PrivateDrawer';

type Props = {
  timer: {
    enabled: boolean;
    schedule: { value: number; unit: string };
  };
  overlay: boolean;
  container: Element;
  visible: boolean;

  onClose: Function;
  onSave: Function;
  onCancel: Function;
  onOverlayClick: Function;
};

export default ({
  container,
  visible = false,
  overlay = true,
  timer,
  onClose,
  onSave,
  onCancel,
  onOverlayClick,
}: Props) => {
  return (
    <Fragment>
      {ReactDOM.createPortal(
        <PrivateDrawer
          visible={visible}
          onClose={onClose}
          defaultVal={timer.schedule.value}
          defaultUnit={timer.schedule.unit}
          onCancel={onCancel}
          onSave={onSave}
        />,
        container,
      )}
      {visible &&
        overlay &&
        ReactDOM.createPortal(
          <PrivateOverlay onOverlayClick={onOverlayClick} />,
          container,
        )}
    </Fragment>
  );
};
