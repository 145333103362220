import React, { ChangeEvent, useEffect, useState } from "react";
import PropTypes from "prop-types";

//i18n
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
// redux
import { useDispatch } from "react-redux";
import { loginSuccess } from "src/store/auth/login/actions";
import { Col, Row, Input, Label, Modal } from "reactstrap";
import { getIotWorkspace, updateIotWorkspace } from "src/helpers/iot_backend_helper";
import { debounce } from "lodash";

interface IWorkspace {
  name: string;
  description: string;
}

interface IResult {
  Code: number;
  Data: IWorkspace;
}

const InitWorkspace = {
  name: "",
  description: ""
}
const GeneralWorkspace = (props: any) => {
  const [tab, setTab] = useState<string>('everyDay');
  const { match } = props;
  const { wsId, orgId } = match.params;
  const [workspace, setWorkspace] = useState<IWorkspace>(InitWorkspace);
  const [notifications, setNotifications] = useState<boolean>(true);
  const [showTip, setShowTip] = useState<boolean>(true);
  let { t } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    getIotWorkspace(wsId, null).then((result: IResult) => {
      if (result && result.Code === 200) {
        setWorkspace(result.Data);
      }
    }).catch(err => {
      console.log(err);
    });
  }, []);

  const notificationChange = (e: any) => {
    setNotifications(e.target.checked)
  }
  
  const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    updateIotWorkspace(wsId, { org_id: orgId, name: e.target.value }, null).then((result) => {
      if (result && result.Code === 200) {
        let authUser: any = localStorage.getItem("authUser")
        if (!authUser) return
        authUser = JSON.parse(authUser)
        authUser.Data.curr_workspace.name = e.target.value
        dispatch(loginSuccess({ Data: authUser.Data }))
        localStorage.setItem("authUser", JSON.stringify(authUser))
      }
    });
  }

  const onDescriptionChange = (e: ChangeEvent<HTMLInputElement>) => {
    updateIotWorkspace(wsId, { org_id: orgId, name: workspace.name, description: e.target.value }, null).then((result) => {
      if (result && result.Code === 200) {
       
      }
    });
  }

  const [
    namePlaceholder,
    descriptionPlaceholder,
    notificationRecipientsPlaceholder,
  ] = [t("enter an e-mail address or select existing user"), t("enter description"), t("enter an e-mail address or select existing user")]

  return (
    <React.Fragment>
      <div className="px-5 py-4 flex-col">
        <div className="flex-row items-center justify-between pb-3" style={{ borderBottom: "1px solid #efefef" }}>
          <div className="text-dark font-size-20" style={{ fontWeight: 700 }}>{t("Workspace Settings")}</div>
        </div>
        <Row className="mt-5">
          <Col xl={4} md={8} style={{ paddingRight: '50px' }}>
            <div className="mb-3">
              <Label htmlFor="choices-single-default" className="form-Label font-size-13 text-muted">{t('Name')}</Label>
              <Input onChange={debounce(onNameChange, 1000)} placeholder={namePlaceholder} className="form-control" type="text" defaultValue={workspace.name} id="example-text-input" />
            </div>
            <div className="mb-3">
              <Label htmlFor="choices-single-default" className="form-Label font-size-13 text-muted first-letter-upper">
                {`${t("description")} (${t("optional")})`}
              </Label>
              <Input onChange={debounce(onDescriptionChange, 1000)} placeholder={descriptionPlaceholder} className="form-control" type="text" defaultValue={workspace.description} id="example-text-input" />
            </div>
            {
              showTip && <div className="mt-5 mb-3">
                <h5 className="mb-3">{t("lifecycle notifications")}</h5>
                <div style={{ backgroundColor: '#e6f7ff', padding: '20px', border: '1px solid #91d5ff', borderRadius: '4px', display: 'flex', flexDirection: 'row' }}>
                  <i className="dripicons-information" style={{ marginRight: '10px', fontSize: '22px', color: '#1890ff' }} />
                  <div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                      <div className="card-title me-2">{t("what are lifecycle notifications for?")}</div>
                      <i onClick={() => setShowTip(false)} style={{ color: '#8c8c8c' }} className="mdi mdi-close-thick"></i>
                    </div>
                    <div>{t("Lifecycle notifications inform user-defined recipients about critical asset lifecycle events (device offline, weak signal & low battery). The notification mail is sent either daily or weekly.")}</div>
                  </div>
                </div>
              </div>
            }

            <div className="mb-3 mt-4">
              <label
                htmlFor="choices-single-default"
                className="form-label font-size-13 text-muted"
              >
                {t("activate lifecycle notifications")}
              </label>
              <div>
                <Input type="checkbox" onChange={notificationChange} id="switch1" switch="none" defaultChecked={notifications} />
                <Label className="me-1" htmlFor="switch1" data-on-label="" data-off-label=""></Label>
              </div>
            </div>
            {
              notifications && <div className="mb-3">
                <div>
                  <Label htmlFor="example-search-input" className="form-Label">{t('DataProcessing')}</Label>
                </div>
                <div
                  className="btn-group btn-group-lg"
                  role="group"
                  aria-label="Basic example"
                >
                  <button type="button" className={tab == "everyDay" ? "btn btn-primary" : "btn btn-outline-light"} onClick={() => setTab('everyDay')}>
                    {t("every day")}
                  </button>
                  <button type="button" className={tab == "everyWeek" ? "btn btn-primary" : "btn btn-outline-light"} onClick={() => setTab('everyWeek')}>
                    {t("every week")}
                  </button>
                </div>
              </div>
            }
            {
              notifications && <div className="mb-4">
                <Label htmlFor="choices-single-default" className="form-Label font-size-13 text-muted">{t('notification recipients')}</Label>
                <Input placeholder={notificationRecipientsPlaceholder} className="form-control" type="text" defaultValue="" id="example-text-input" />
              </div>
            }
            <div className="mt-5 mb-3">
              <h5 className="mb-4">{t('actions')}</h5>
              <button
                type="button"
                className="btn btn-outline-danger waves-effect waves-light"
              >
                {t("delete workspace")}
              </button>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}

GeneralWorkspace.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withTranslation()(withRouter(GeneralWorkspace));