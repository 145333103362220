import { includes } from "lodash";
import React, { useContext } from "react";
//i18n
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { ACTION_TYPE } from "../../contant";
import { DataFlowContext } from "../../reducer";

const OutputConnectorList = (props: any) => {
  const { t, type, back, connectors, handleAddConnector, cancelAdd } = props;
  const { dispatch, state: { outputConnectors }  } = useContext(DataFlowContext);
  let outputConnectorsIds = outputConnectors.map((row:any) => row.id);
  // 选择输出连接器
  const selectConnector = (item: any) => {
    const action = {
      type: ACTION_TYPE.PUSH_OUTPUT_CONNECTOR,
      payload: item
    }
    cancelAdd();
    dispatch(action);
  }
  // const handleOptionClick = (id: any) => {
  //     const action = {
  //         type: ACTION_TYPE.PUSH_OUTPUT_CONNECTOR,
  //         payload: id
  //     }

  //     dispatch(action)
  //     onSelect && onSelect()
  // }

  return (
    <React.StrictMode>
      <div>
        <header onClick={back} className="flex-grow-0 flex-shrink-0 mb-4 font-size-16 fw-bold">
          <i className="mdi mdi-arrow-left me-2 cursor-pointer" />
          {t("Add")} {type} {t("output connector")}
        </header>
        <main>
          <div>
            <div>{t("Saved Webhook output connectors")}</div>
            <div className="mt-2">
              {
                connectors.map((row: any) => {
                  let disabled = includes(outputConnectorsIds, row.id)
                  return (<div key={row.id} className={`mt-narrow-pre border border-blue-hover rounded-2 px-2 py-2 font-size-16
                 ${disabled && "pointer-events-none bg-light text-secondary"}`}
                    onClick={() => selectConnector(row)}>
                    <i className="me-2 mdi mdi-link-variant" />
                    <span className="fw-bold text-capitalize">{row.name}</span>
                  </div>)
                })
              }
            </div>
          </div>
          <div className="mt-4">
            <div>{t("Create new Webhook output connector")}</div>
            <button
              type="button"
              onClick={handleAddConnector}
              className="btn btn-outline-light waves-effect mt-2 font-size-16"
            >
              + {" "}{props.t('Create new connector')}
            </button>
          </div>
        </main>
      </div>
    </React.StrictMode>
  )
}

export default withTranslation()(withRouter(OutputConnectorList));
