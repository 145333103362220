import React, { Key } from "react";

interface props {
    data: any,
    onSelect: Function
}

export default (props: props) => {
    let { onSelect, data } = props;
    const handleSelect = () => {
        onSelect && onSelect(data)
    }
    return (<React.StrictMode>
        <div
            className="gutter-t-normal border border-secondary border-blue-hover rounded-2 px-2 py-2"
            onClick={handleSelect}>
            <i className="mdi mdi-link-variant me-2" />
            <span className="fw-bold">{data.name}</span>
        </div>
    </React.StrictMode>)
}