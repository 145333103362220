import React, {StrictMode} from "react";
// i18n
import {useTranslation} from "react-i18next";

type Props = {
    step: number;
}

export default ({step}: Partial<Props>) => {
    const {t} = useTranslation()

    return (<StrictMode>
        <div className="d-flex justify-between py-3 font-size-18">
            <div>
                <i className={`fas fa-home me-2 font-size-24 ${step && step > 0 && "text-info"}`}/>
                <span className="text-capitalize">{t("organization")}</span>
            </div>
            <div className="flex-grow-1 d-flex align-items-center px-3">
                <div className={`dropdown-divider w-100  ${step && step > 1 && "border-info"}`}/>
            </div>
            <div>
                <i className={`fas fa-folder-open me-2 font-size-24 ${step && step > 1 && "text-info"}`}/>
                <span className="text-capitalize">{t("workspace")}</span>
            </div>
        </div>
    </StrictMode>)
}
