import { Key, StrictMode, useEffect, useState } from "react";

import { Button } from "reactstrap";

import _ from "lodash";

import ControlBar from "./ControlBar";
import ConnectorOption from "./ConnectorOption";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { ACTION_TYPE_GROUP } from "../constant";
import { insure } from "src/util";
import { getDataSinks, getNotificationServices } from "../../common/configuration";
import {
    getIotOutputConnectorList
} from "src/helpers/iot_backend_helper";

const getTypeName = (typeGroup: Key, typeId: Key) => {
    switch (typeGroup) {
        case ACTION_TYPE_GROUP.DATA_SINK:
            return getDataSinks(s => s.filter(n => n.id == typeId))[0]
        case ACTION_TYPE_GROUP.NOTIFICATION_SERVICE:
            return getNotificationServices(s => s.filter(n => n.id == typeId))[0]
    }
}

export default withTranslation()(withRouter((props: any) => {
    const { typeId, typeGroup, selected, onBack, onCancel, onSelect, onNew, t, match } = props;
    const { wsId } = match.params;
    const { name: typeName, type } = getTypeName(typeGroup, typeId);
    const [list, setList] = useState([]);
    useEffect(() => {
        fetchData({})
    }, [])
    const fetchData = ((params: object) => {
        getIotOutputConnectorList({
            scope: "RULE_ACTION",
            type,
            workspace_id: wsId,
            ...params
        }, null).then(result => {
            if (result && result.Code == 200) {
                // 如果有对应类型输出连接器则跳转到列表选择页面,如果没有的话就到新增页面
                if (result.Data.length) {
                    setList(result.Data);
                }
            }
        });
    });

    return (<StrictMode>
        <div className="d-flex flex-column justify-between h-100">
            <ControlBar className="flex-shrink-0 mb-4" onBack={onBack} onCancel={onCancel} />
            <main className="flex-grow-1 mb-4 overflow-auto scrollbar-none">
                {(list && list.length) ? <div className="mb-2 first-letter-upper">{t("available")} {typeName} {t("connectors")}</div>:<></>}
                {list.map((item: any) =>
                    <ConnectorOption key={item.id}
                        value={item.id} label={item.name}
                        disable={_.includes(selected, item.id)}
                        onClick={() => onSelect(item)} />)}
                <div className="flex-shrink-0 mt-4">
                    <div className="mb-2 first-letter-upper">{t((list && list.length)?"or create new":"new")} {typeName} {t("connector")}</div>
                    <Button className="border-dashed border-blue-hover py-1 font-size-16"
                        color="primary" outline
                        onClick={insure(onNew, type, typeName)}>
                        <i className="fas fa-plus me-2" />
                        <span className="text-capitalize">{t("new")} {typeName} {t("connector")}</span>
                    </Button>
                </div>
            </main>
        </div>
    </StrictMode>)
}))

