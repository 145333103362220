'use strict';

export class AMalloc<T> {
  a: T[];
  len: number = 0;
  cap: number;

  constructor(cap: number) {
    this.a = new Array<T>(Math.floor(Number(cap)));
    this.cap = cap;
  }

  get() {
    return this.a;
  }

  push(el: T) {
    if (this.len < this.cap) {
      this.a[this.len] = el;
      this.len++;
    } else {
      this.a.push(el);
      this.len++;
      this.cap++;
    }
  }
}
