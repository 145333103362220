import { StrictMode, useEffect, useState } from "react";

import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import {
  Button,
  FormGroup,
  FormText,
  Label,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { insure } from "src/util";
import AceEditor from "react-ace";

export default withTranslation()(withRouter((props: any) => {
  const { t, onBack, onCancel, onSave, onUpdate, connector, type, typeName, match } = props;
  const { wsId } = match.params;
  const [useCustomPayload, setUseCustomPayload] = useState<boolean>(connector && connector.attribute.use_custom_payload);
  const [customPayload, setCustomPayload] = useState<string>(connector&& connector.attribute.use_custom_payload?connector.attribute.custom_payload:`{
    "message": {{data}} 
}`);

  const handleValidSubmit = (e: any, v: any) => {
    let data = {
      name: v.name,
      workspace_id: wsId,
      attribute: {
        broker: v.broker, topic: v.topic, security_protocol: v.security_protocol, sasl_mechanism: v.sasl_mechanism,
        jaas_user_name: v.jaas_user_name, jaas_password: v.jaas_password, use_custom_payload: useCustomPayload, custom_payload: {}
      }
    }
    if (useCustomPayload) {
      data.attribute.custom_payload = customPayload
    } 
    if(connector && connector.id) {
      onUpdate(connector.id, data);
    }else {
      onSave(data);
    }
  }

  const onCustomPayloadChange = (data: any) => {
    setCustomPayload(data);
  }

  return (<StrictMode>
    <AvForm
      onValidSubmit={(e: any, v: any) => {
        handleValidSubmit(e, v);
      }}
    >
      <header className="flex-shrink-0 mb-5 user-select-none clearfix">
        <span onClick={insure(onBack)} className="float-start font-size-18 fw-bolder cursor-pointer">
          <i className="fas fa-arrow-left me-2" />
          <span className="text-capitalize">{typeName}</span>
        </span>
        <span className="float-end lh-lg text-primary text-capitalize cursor-pointer"
          onClick={insure(onCancel)}>
          {t("cancel")}
        </span>
      </header>
      <main>
        <div className="mb-3">
          <AvField
            name="name"
            defaultValue={connector && connector.name}
            label={t("connector name")}
            placeholder=""
            type="text"
            errorMessage={t("Connector name is required")}
            validate={{ required: { value: true } }}
          />
        </div>
        <div className="mb-3">
          <AvField
            name="broker"
            defaultValue={connector && connector.attribute.broker}
            label={t("Kafka Broker")}
            placeholder="pkc-lzoyy.europe-west6.gcp.confluent.cloun:9..."
            type="text"
            errorMessage={t("Kafka Broker is required")}
            validate={{ required: { value: true } }}
          />
        </div>
        <div className="mb-3">
          <AvField
            name="topic"
            defaultValue={connector && connector.attribute.topic}
            label={t("Kafka Topic")}
            placeholder=""
            type="text"
            errorMessage={t("Kafka Topic is required")}
            validate={{ required: { value: true } }}
          />
        </div>
        <div className="mb-3">
          <AvField
            name="security_protocol"
            defaultValue={connector && connector.attribute.security_protocol}
            label={t("Security Protocol")}
            placeholder=""
            type="text"
            disabled={true}
            value="SASL_SSL"
            errorMessage={t("Security Protocol is required")}
            validate={{ required: { value: true } }}
          />
        </div>
        <div className="mb-3">
          <AvField
            name="sasl_mechanism"
            defaultValue={connector && connector.attribute.sasl_mechanism}
            label={t("SASL Mechanism")}
            placeholder=""
            type="text"
            disabled={true}
            value="PLAIN"
            errorMessage={t("SASL Mechanism is required")}
            validate={{ required: { value: true } }}
          />
        </div>
        <div className="mb-3">
          <AvField
            name="jaas_user_name"
            defaultValue={connector && connector.attribute.jaas_user_name}
            label={t("JAAS Username")}
            placeholder=""
            type="text"
            errorMessage={t("JAAS Username is required")}
            validate={{ required: { value: true } }}
          />
        </div>
        <div className="mb-3">
          <AvField
            name="jaas_password"
            defaultValue={connector && connector.attribute.jaas_password}
            label={t("JAAS Password")}
            placeholder=""
            type="text"
            errorMessage={t("JAAS Password is required")}
            validate={{ required: { value: true } }}
          />
        </div>
        <FormGroup className="mt-wide-pre mt-4">
          <Label className="first-letter-upper">{t("use custom payload")}</Label>
          <div className="form-check form-switch">
            <input className="form-check-input" type="checkbox" checked={useCustomPayload} onChange={(e) => { setUseCustomPayload(e.target.checked) }} />
          </div>
        </FormGroup>
        {
          useCustomPayload && <FormGroup className="mt-wide-pre mt-3">
            <Label className="first-letter-upper">{t("custom payload")}</Label>
            <div className="mt-1">
              <div className="mt-1" style={{ border: '1px solid #e8e8e8', backgroundColor: '#fafafa', borderRadius: '4px', height: "200px" }} >
                <AceEditor
                  placeholder={""}
                  mode="javascript"
                  theme="github"
                  name="blah2"
                  style={{ width: '100%', height: '100%' }}
                  onChange={onCustomPayloadChange}
                  fontSize={14}
                  showPrintMargin={true}
                  showGutter={true}
                  highlightActiveLine={true}
                  value={customPayload}
                  setOptions={{
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: true,
                    enableSnippets: true,
                    showLineNumbers: true,
                    tabSize: 2,
                  }} />
              </div>
            </div>
            <FormText className="font-size-14">
              {t("make use of template variables by using the following syntax {{data.dataKey}}")}
            </FormText>
          </FormGroup>
        }
      </main>
      <footer className="py-4 clearfix">
        <Button typt="submit" className="float-end first-letter-upper" color="primary" >
          <i className="me-2 fas fa-save" />
          {t("save connector")}
        </Button>
      </footer>
    </AvForm>
  </StrictMode>)
}))
