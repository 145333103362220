import { Fragment, useContext } from 'react';
// Reduce
import { ACTION_TYPE } from '../../../contant';
import { DataFlowContext } from '../../../reducer';
// Component
import DeviceTypeDisplayBar, { Type } from './DeviceTypeDisplayBar';
import DeviceTypeOption from './DeviceTypeOption';
// Util
import { PASS_DEVICE_TYPE } from './contant';
// Api
import { fetchDeviceTypeInfo } from './api';

export default () => {
  const {
    state: { deviceType },
    dispatch,
  } = useContext(DataFlowContext);

  const gotoOption = () => {
    const action = {
      type: ACTION_TYPE.CLEAR_DEVICE_TYPE,
    };

    dispatch(action);
  };

  const deviceTypeInfo = fetchDeviceTypeInfo(deviceType);

  const deviceTypeDisplayBarType: Type | undefined = (() => {
    switch (deviceType) {
      case PASS_DEVICE_TYPE:
        return 'text';
      default:
        return;
    }
  })();

  return (
    <Fragment>
      {deviceType && (
        <DeviceTypeDisplayBar
          type={deviceTypeDisplayBarType}
          {...deviceTypeInfo}
          onClear={gotoOption}
        />
      )}
      {!deviceType && <DeviceTypeOption />}
    </Fragment>
  );
};
