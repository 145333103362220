import { SyncersTypes } from "./actionTypes";

export const getEvents = () => ({
  type: SyncersTypes.GET_EVENTS,
});

export const apiSuccess = (actionType : any, data : any) => ({
  type: SyncersTypes.API_SUCCESS,
  payload: { actionType, data },
});

export const apiFail = (actionType : any, error : any) => ({
  type: SyncersTypes.API_FAIL,
  payload: { actionType, error },
});

export const addNewEvent = (event : any) => ({
  type: SyncersTypes.ADD_NEW_EVENT,
  payload: event,
});

export const updateEvent = (event : any) => ({
  type: SyncersTypes.UPDATE_EVENT,
  payload: event,
});

export const updateEventSuccess = (event : any) => ({
  type: SyncersTypes.UPDATE_EVENT_SUCCESS,
  payload: event,
});

export const updateEventFail = (error : any) => ({
  type: SyncersTypes.UPDATE_EVENT_FAIL,
  payload: error,
});

export const deleteEvent = (event : any) => ({
  type: SyncersTypes.DELETE_EVENT,
  payload: event,
});

export const deleteEventSuccess = (event : any) => ({
  type: SyncersTypes.DELETE_EVENT_SUCCESS,
  payload: event,
});

export const deleteEventFail = (error : any) => ({
  type: SyncersTypes.DELETE_EVENT_FAIL,
  payload: error,
});

export const getCategories = () => ({
  type: SyncersTypes.GET_CATEGORIES,
});

export const getCategoriesSuccess = (categories : any) => ({
  type: SyncersTypes.GET_CATEGORIES_SUCCESS,
  payload: categories,
});

export const getCategoriesFail = (error : any) => ({
  type: SyncersTypes.GET_CATEGORIES_FAIL,
  payload: error,
});

export const getSyncers = () => ({
  type: SyncersTypes.GET_SYNCERS,
});

export const getSyncersSuccess = (syncers : any) => ({
  type: SyncersTypes.GET_SYNCERS_SUCCESS,
  payload: syncers,
});

export const getSyncersFail = (error : any) => ({
  type: SyncersTypes.GET_SYNCERS_FAIL,
  payload: error,
});

export const getSyncerById = (id:string) => ({
  type: SyncersTypes.GET_SYNCERBYID,
  payload:id
});

export const getSyncerByIdSuccess = (syncers : any) => ({
  type: SyncersTypes.GET_SYNCERBYID_SUCCESS,
  payload: syncers,
});

export const getSyncerByIdFail = (error : any) => ({
  type: SyncersTypes.GET_SYNCERBYID_FAIL,
  payload: error,
});
