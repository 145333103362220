import React from "react";

import {insure} from "src/util";

export type PrivateOverlayProps = {
    onOverlayClick: Function
}

export default ({onOverlayClick}: Partial<PrivateOverlayProps>) => {
    const backgroundColor = "rgba(0,0,0,0.3)"

    return (
        <div
            className="position-absolute top-0 vh-100 vw-100 z-9"
            style={{backgroundColor}}
            onClick={insure(onOverlayClick)}/>
    )
}
