/**
 * interface for Tokens type
 */
 export enum WebhooksTypes {
    API_SUCCESS = '@@webhooks/API_SUCCESS',
    API_FAIL = '@@webhooks/API_FAIL',
    GET_EVENTS = '@@webhooks/GET_EVENTS',
    ADD_NEW_EVENT = '@@webhooks/ADD_NEW_EVENT',
    UPDATE_EVENT = '@@webhooks/UPDATE_EVENT',
    UPDATE_EVENT_SUCCESS = '@@webhooks/UPDATE_EVENT_SUCCESS',
    UPDATE_EVENT_FAIL = '@@webhooks/UPDATE_EVENT_FAIL',
    DELETE_EVENT = '@@webhooks/DELETE_EVENT',
    DELETE_EVENT_SUCCESS = '@@webhooks/DELETE_EVENT_SUCCESS',
    DELETE_EVENT_FAIL = '@@webhooks/DELETE_EVENT_FAIL',
    GET_CATEGORIES = '@@webhooks/GET_CATEGORIES',
    GET_CATEGORIES_SUCCESS = '@@webhooks/GET_CATEGORIES_SUCCESS',
    GET_CATEGORIES_FAIL = '@@webhooks/GET_CATEGORIES_FAIL',
    GET_WEBHOOKS = '@@webhooks/GET_WEBHOOKS',
    GET_WEBHOOKS_SUCCESS = '@@webhooks/GET_WEBHOOKS_SUCCESS',
    GET_WEBHOOKS_FAIL = '@@webhooks/GET_WEBHOOKS_FAIL',
    GET_WEBHOOKBYID = '@@webhooks/GET_WEBHOOKBYID',
    GET_WEBHOOKBYID_SUCCESS = '@@webhooks/GET_WEBHOOKBYID_SUCCESS',
    GET_WEBHOOKBYID_FAIL = '@@webhooks/GET_WEBHOOKBYID_FAIL',
 }

 export interface WebhooksState {
   events  : Array<any>;
   categories : Array<any>;
   error : Object;
}