import React, { useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
//redux
import { useDispatch } from "react-redux";
interface AuthLayoutProps {
  component: any;
  layout: any;
  isAuthProtected: any;
  path?: string;
  exact?: boolean;
  key?: number;
}
// actions
import { loginSuccess } from "src/store/actions";
import dayjs from "dayjs";
const Authmiddleware = ({
  component,
  layout,
  isAuthProtected,
  path,
  exact,
  key,
  ...rest
}: AuthLayoutProps) => {
  const Layout = layout;
  const Component = component;
  const dispatch = useDispatch();
  const [loading, setLoadding] = useState(true);
  useEffect(()=> {
    let authUser:any = localStorage.getItem("authUser");
    if(authUser) {
      authUser = JSON.parse(authUser);
      dispatch(loginSuccess(authUser));
    }
    setLoadding(false);
  }, []);
  if(loading) {
    return <></>
  }

  let auth = false;
  let authUser:any = localStorage.getItem("authUser");
  authUser = JSON.parse(authUser);
  if(authUser && authUser.Data.token && dayjs().isBefore(dayjs.unix(authUser.Data.token.expires_at))) {
    auth = true;
  }

  return (
    <Route
      {...rest}
      render={props => {
      
        if (isAuthProtected && !auth) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }
  
        if(props.location.pathname === "/") {
          if (!authUser?.Data) {
            return (
              <Redirect
                to={{ pathname: "/login", state: { from: props.location } }}
              />
            );
          }
          const pathname = `/org/${authUser.Data.curr_org_id}/overview`
          return (
            <Redirect
              to={{ pathname: pathname, state: { from: props.location } }}
            />
          );
        }
        
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};

export default Authmiddleware;
