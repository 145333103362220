import { Key } from 'react';
// Util
import { insure } from 'src/util';

type Props = {
  value: Key;
  label: Key;
  iconClassName: string;
  onClick: Function;
};

export default ({ value, label, iconClassName, onClick }: Partial<Props>) => {
  return (
    <div
      className="mt-narrow-pre border border-blue-hover rounded-2 px-2 py-2
      font-size-16"
      onClick={insure(onClick, value)}>
      <i className={`me-2 ${iconClassName}`} />
      <span className="fw-bold text-capitalize">{label || value}</span>
    </div>
  );
};
