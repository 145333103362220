import { Fragment, useEffect, useState } from 'react';
//i18n
import { useTranslation } from 'react-i18next';
// Router
import { useHistory, useRouteMatch } from 'react-router-dom';
// Component
import { Card, CardBody, Col, Row } from 'reactstrap';
import Loading from 'src/components/Loading';
// Api
import { getIotOrganization } from 'src/helpers/iot_backend_helper';
// Util
import dayjs from 'dayjs';
import { awaitWrap } from 'src/util';

const initOrganization = {
  name: '',

  user_count: 0,
  device_count: 0,
  workspace_count: 0,

  created_at: '',
  updated_at: '',

  owner: {
    id: '',
    name: '',
  },
};

export default () => {
  // 对登录用户进行检查
  const h = useHistory();
  (function () {
    let auth: any = localStorage.getItem('authUser');
    if (!auth) {
      h.replace('/login');
      return;
    }
    auth = JSON.parse(auth);
    if (!auth.Data.curr_org_id || !auth.Data.curr_wspace_id) {
      h.replace('/setup');
      return;
    }
  })();

  const { t } = useTranslation();

  const dateFormatStr = 'YYYY-MM-DD HH:mm:ss';

  const m = useRouteMatch<any>();
  const { orgId } = m.params;

  const [loading, setLoading] = useState(true);
  const [organization, setOrganization] = useState(initOrganization);

  useEffect(() => {
    (async function () {
      const [res, err] = await awaitWrap(getIotOrganization(orgId, null));

      setLoading(false);

      if (err) return;
      if (res.Code != 200) return;

      setOrganization(res?.Data);
    })();
  }, [orgId]);

  return (
    <Fragment>
      {loading && <Loading loading={loading} />}

      <div className="px-4 py-2">
        <h3 className="py-4 cus-bg-white">{organization.name}</h3>

        <Row>
          <Col xl={6} xs={12}>
            <div className="mb-2">
              <span className="text-muted">{t('OrganizationOwner')}:</span>
              <i className="ms-2 fas fa-user" />
              <a href="javascript:void(0);" className="ms-2">
                {organization.owner.name}
              </a>
            </div>
            <div>
              <span className="text-muted">{t('OrganizationId')}:</span>
              <span className="ms-2">{orgId}</span>
            </div>
          </Col>
          <Col xl={6} xs={12}>
            <div className="mb-2">
              <span className="text-muted">{t('OrganizationCreated')}:</span>
              <span className="ms-2">
                {dayjs(organization.created_at).format(dateFormatStr)}
              </span>
            </div>
            <div>
              <span className="text-muted">{t('OrganizationModified')}:</span>
              <span className="ms-2">
                {dayjs(organization.updated_at).format(dateFormatStr)}
              </span>
            </div>
          </Col>
        </Row>

        <div className="my-5 border-bottom" />

        <Row>
          <Col xl={4} xs={8}>
            <div className="mb-2 text-muted">
              {t('WorkspacesInOrganization')}
            </div>
            <h3>
              {organization.workspace_count} {t('workspace')}
            </h3>
          </Col>
          <Col xl={4} xs={8}>
            <div className="mb-2 text-muted">{t('DevicesInOrganization')}</div>
            <h3>
              {organization.device_count} {t('devices')}
            </h3>
          </Col>
          <Col xl={4} xs={8}>
            <div className="mb-2 text-muted">{t('UsersOrganization')}</div>
            <h3>
              {organization.user_count} {t('user')}
            </h3>
          </Col>
        </Row>

        <div className="my-5 border-bottom" />

        <Row>
          <Col xl={3} md={6}>
            <Card className="h-100">
              <CardBody>
                <div className="mb-2">
                  <i className="me-2 bx bx-book-content" />
                  <span className="font-size-16 fw-bold">
                    {t('Documentation')}
                  </span>
                </div>
                <div className="mb-2">{t('DocumentationDes')}</div>
                <a href="javascript:void(0);">{t('View')}</a>
              </CardBody>
            </Card>
          </Col>
          <Col xl={3} md={6}>
            <Card className="h-100">
              <CardBody>
                <div className="mb-2">
                  <i className="me-2 bx bx-book-content" />
                  <span className="font-size-16 fw-bold">
                    {t('APIDocumentation')}
                  </span>
                </div>
                <div className="mb-2">{t('APIDocumentationDes')}</div>
                <a href="javascript:void(0);">{t('View')}</a>
              </CardBody>
            </Card>
          </Col>
          <Col xl={3} md={6}>
            <Card className="h-100">
              <CardBody>
                <div className="mb-2">
                  <i className="me-2 bx bx-book-content" />
                  <span className="font-size-16 fw-bold">
                    {t('ServiceDesk')}
                  </span>
                </div>
                <div className="mb-2">{t('ServiceDeskDes')}</div>
                <a href="javascript:void(0);">{t('View')}</a>
              </CardBody>
            </Card>
          </Col>
          <Col xl={3} md={6}>
            <Card className="h-100">
              <CardBody>
                <div className="mb-2">
                  <i className="me-2 bx bx-book-content" />
                  <span className="font-size-16 fw-bold">
                    {t('RequestFeatures')}
                  </span>
                </div>
                <div className="mb-2">{t('RequestFeaturesDes')}</div>
                <a href="javascript:void(0);">{t('View')}</a>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};
