// i18n
import { useTranslation } from 'react-i18next';
// Component
import { Badge } from 'reactstrap';
import deviceTypeLogo from 'src/assets/images/deviceType-noImage.svg';

export default (props: any) => {
  const { t } = useTranslation();

  const { data, onClick } = props;

  console.log(data);

  const {
    name,
    firmware_version,
    deviceManufacturer,
    connectivity,
    pictureUrl,
    description,
  } = data;

  const handleClick = () => {
    onClick && onClick(data);
  };

  return (
    <div
      className="d-flex flex-column gutter-t-normal
      border border-blue-hover py-2 rounded-2
      cursor-pointer"
      onClick={handleClick}>
      <header className="flex-grow-0 flex-shrink-0 border-bottom px-3 pb-2">
        <i className="mdi mdi-link-variant me-2" />
        <span className="me-2 font-size-16 fw-bold">{name}</span>
        <span className="font-size-14">{deviceManufacturer}</span>
      </header>
      <main className="d-flex px-3 pt-3">
        <div className="d-flex flex-1 me-2 rounded-circle text-center lh-1">
          <div>
            <img
              className="object-contain w-100"
              src={pictureUrl || deviceTypeLogo}
              alt="load..."
            />
          </div>
        </div>
        <div className="flex-2">
          <div className="gutter-t-normal">
            <span className="text-secondary text-capitalize me-2">
              {t('technology')}:
            </span>
            <span>
              <Badge
                className="gutter-s-normal border border-secondary bg-light"
                color="black">
                {connectivity}
              </Badge>
            </span>
          </div>
          <div className="gutter-t-normal">
            <div className="text-secondary">{t('description')}:</div>
            <div>{description}</div>
          </div>
          <div className="gutter-t-normal">
            <span className="text-secondary me-2">
              {t('Firmware version')}:
            </span>
            <span>{firmware_version}</span>
          </div>
        </div>
      </main>
    </div>
  );
};
