import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from "prop-types";
import Select from "react-select";
//i18n
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Col, Row, Modal, Input, Label } from 'reactstrap';
import { AvForm, AvField } from "availity-reactstrap-validation";
// actions
import { loginSuccess } from "src/store/actions";
// Api
import {
  addIotOrganizationUser,
  addIotWorkspacesUser,
} from 'src/helpers/iot_backend_helper';
import { awaitWrap } from '../../../../util';

const AddUserModal = (props: any) => {
  const [fieldType, setFieldType] = useState<any>(null);
  let {
    t, user, visiable, togModal, hideModal, updateUser, createUser, match
  } = props;
  const {wsId, orgId} = match.params
  const dispatch = useDispatch();
  const { authUser } = useSelector((state: any) => ({
    authUser: state.login.user
  }));
  useEffect(() => {
    let dic: any = wsId ?{
      admin: { label: t("Workspace-Admin"), value: "admin" },
      editor: { label:t("Workspace-Editor"), value: "editor" },
      viewer: { label:t("Workspace-Viewer"), value: "viewer" },
    } :{
      admin: { label: t("Organization-Admin"), value: "admin" },
      editor: { label:t("Organization-Editor"), value: "editor" },
      viewer: { label:t("Organization-Viewer"), value: "viewer" },
    }
    if (user && user.id) {
      setFieldType(dic[user.role])
    } else {
      setFieldType(wsId ? { label: t("Workspace-Admin"), value: "admin" }:{ label: t("Organization-Admin"), value: "admin" })
    }
  }, []);

  const handleValidSubmit = async (e: any, v: any) => {
    let data = { ...v, role: fieldType.value }

    if (user && user.id) {
      updateUser(user.id, data);
      if (authUser.id === user.id) {
        let updateData = { ...authUser, role: data.role }
        dispatch(loginSuccess({ Data: updateData }));
      }
    } else {
      if (wsId) {
        await addWorkspaceUser(data)
      }else {
        await addOrganizationUser(data)
      }
    }
  }

  const addOrganizationUser = async (data: any) => {
    const [, err] = await awaitWrap(addIotOrganizationUser(orgId, data))
    if (err) {
      alert(err.response.data.message)
    }else {
      alert("添加用户成功")
      hideModal()
    }
  }

  const addWorkspaceUser = async (data: any) => {
    const [, err] = await awaitWrap(addIotWorkspacesUser(wsId, data, null))
    if (err) {
      alert(err.response.data.message)
    }else {
      alert("添加用户成功")
      hideModal()
    }
  }
  console.log(user?.user_name);

  const optionGroup = wsId? [
    { label: t("Workspace-Admin"), value: "admin" },
    { label: t("Workspace-Editor"), value: "editor" },
    { label: t("Workspace-Viewer"), value: "viewer" },
  ]:[
    { label: t("Organization-Admin"), value: "admin" },
    { label: t("Organization-Editor"), value: "editor" },
    { label: t("Organization-Viewer"), value: "viewer" },
  ];

  return (
    <React.Fragment>
      <Modal
        isOpen={visiable}
        toggle={() => {
          togModal();
        }}
        centered={true}>
        <AvForm
          onValidSubmit={(e: any, v: any) => {
            handleValidSubmit(e, v);
          }}>
          <div className="modal-header">
            <h5 className="modal-title mt-0">
              {t(user && user.id ? 'UpdateUser' : 'AddUser')}
            </h5>
            <button
              type="button"
              onClick={() => {
                hideModal();
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Row>
              <Col sm={12}>
                <div>
                  <div className="mb-3">
                    <AvField
                      disabled={user?.user_name}
                      name="user_name"
                      defaultValue={user && user.user_name}
                      // defaultValue={currentRow && currentRow.description}
                      label={props.t('username')}
                      placeholder=""
                      type="text"
                      errorMessage={t('username is required')}
                      validate={{ required: { value: true } }}
                    />
                  </div>
                  {user && user.id && (
                    <Fragment>
                      <div className="mb-3">
                        <AvField
                          name="name"
                          defaultValue={user && user.name}
                          label={props.t('Name')}
                          placeholder=""
                          type="text"
                          errorMessage={t('Name is required')}
                          validate={{ required: { value: true } }}
                        />
                      </div>
                      <div className="mb-3">
                        <AvField
                          name="email"
                          defaultValue={user && user.email}
                          label={props.t('Email')}
                          placeholder=""
                          type="email"
                          errorMessage={t('Please enter a valid email address')}
                          validate={{ required: { value: false } }}
                        />
                      </div>
                    </Fragment>
                  )}
                  <div className="mb-3">
                    <label
                      htmlFor="choices-single-default"
                      className="form-label font-size-13 text-muted">
                      {t('user role')}
                    </label>
                    <Select
                      value={fieldType}
                      onChange={setFieldType}
                      options={optionGroup}
                      placeholder={t('Select Role')}
                      classNamePrefix="select2-selection"
                    />
                    <div className="mt-2">
                      {t(
                        'the User will be created with the selected role, this can be changed later',
                      )}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={hideModal}>
              {props.t('Close')}
            </button>
            <button type="submit" className="btn btn-primary">
              {props.t('Save')}
            </button>
          </div>
        </AvForm>
      </Modal>
    </React.Fragment>
  );
}

AddUserModal.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  visiable: PropTypes.bool,
  togModal: PropTypes.func,
  hideModal: PropTypes.func,
  save: PropTypes.func
};
export default withTranslation()(withRouter(AddUserModal));
