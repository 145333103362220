/* eslint guard-for-in: 0 */
/* eslint no-restricted-syntax: 0 */
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory from 'react-bootstrap-table2-editor';
const cellEditProps = {
  mode: 'click'
};

const RemoteAll = ({ data, page, sizePerPage, columns, onTableChange, totalSize }:any) => (
  <div>
    <BootstrapTable
      bordered={false}
      remote
      keyField="id"
      data={ data }
      columns={ columns }
      pagination={ paginationFactory({ page, sizePerPage, totalSize }) }
      onTableChange={ onTableChange }
      // cellEdit={ cellEditFactory(cellEditProps) }
    />
  </div>
);

RemoteAll.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  totalSize: PropTypes.number.isRequired,
  sizePerPage: PropTypes.number.isRequired,
  onTableChange: PropTypes.func.isRequired
};

export default RemoteAll;