import React, {useState} from "react";

import {useTranslation} from "react-i18next";

import {Input, InputGroup, InputGroupText} from "reactstrap";

export default () => {
    const {t} = useTranslation()

    const [focus, setFocus] = useState(false)

    const handleFocus = () => {
        setFocus(true)
    }

    const handleBlur = () => {
        setFocus(false)
    }

    const searchbarPlaceHolder = t("Search device type")

    return (<React.StrictMode>
        <div className="d-flex">
            <InputGroup className={`me-3 border rounded-2 border-blue-hover ${focus && "shadow-blue"}`}>
                <InputGroupText className="border-0 cus-bg-white pe-1">
                    <i className="fas fa-search"/>
                </InputGroupText>
                <Input
                    className="border-0 ps-1 cus-bg-white"
                    placeholder={searchbarPlaceHolder}
                    onFocus={handleFocus}
                    onBlur={handleBlur}/>
            </InputGroup>
            <button
                className="btn btn-outline-primary font-size-16 text-capitalize text-nowrap"
            >
                {t("filter")}
            </button>
        </div>
    </React.StrictMode>)
}
