import { Key, StrictMode, useContext, useEffect } from "react";

import { useTranslation } from "react-i18next";

import { Button } from "reactstrap";

import BlankGuide from "../../common/BlankGuide";
import SelectedConnector from "./SelectedConnector";

import { insure } from "src/util";
import { RuleContext } from "../../reducer";

type Props = {
    title: string;
    prompt: string;
    onAdd: Function;
    onEdit: Function;
}

export default ({ title = "", prompt, onAdd, onEdit }: Partial<Props>) => {
    const { t } = useTranslation()
    const { state: { input,actions, logicState }, dispatch } = useContext(RuleContext)
    const {data_sources, logic_blocks} = logicState;
    const empty = !actions.length;

    // 控制是否显示连接线
    useEffect(()=> {
        const action = {
            type: "TOGGLE_ACTION_LINE",
            payload: true
        }
        dispatch(action);
        return () => {
            const action = {
                type: "TOGGLE_ACTION_LINE",
                payload: false
            }
            dispatch(action);
        }
    }, []);

    const handleClear = (id: Key) => {
        const action = {
            type: "CLEAR_ACTION",
            payload: id,
        }
        dispatch(action)
    }

    return (<StrictMode>
        <div className="position-relative d-flex flex-column h-100 user-select-none">
            <header className="flex-shrink-0">
                <span className="me-2 text-capitalize">{t(title)}</span>
                <Button className="border-dashed" color="primary" size="sm" outline hidden={empty}
                    onClick={insure(onAdd)}>
                    <span className="me-2">+</span>
                    <span className="text-capitalize">{t(`add ${title}`)}</span>
                </Button>
            </header>
            {empty && <BlankGuide prompt={prompt} onAdd={onAdd} />}
            <main className="d-flex mt-3 flex-column justify-content-center flex-grow-1">
                {!empty && actions.map(action =>
                    <SelectedConnector
                        data={action}
                        key={action.id}
                        id={action.id}
                        onClear={handleClear}
                        onEdit={onEdit}
                        change={`${input.inputs.length}_${actions.length}_${data_sources.length}_${logic_blocks.length}`} 
                    />)}
            </main>
        </div>
    </StrictMode>)
}
