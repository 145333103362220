import { Fragment, useContext } from 'react';
//i18n
import { useTranslation } from 'react-i18next';
// Router
import { useRouteMatch } from 'react-router-dom';
// Component
import { Button } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';
// Redux
import { DataFlowContext } from '../../../../../reducer';
import { ACTION_TYPE } from '../../../../../contant';
// Api
import { updateIotDeviceConnector } from 'src/helpers/iot_backend_helper';
// Util
import { awaitWrap } from 'src/util';

type props = {
  onBack: Function;
};

export default ({ onBack }: props) => {
  const { t } = useTranslation();
  const m = useRouteMatch<any>();
  const { wsId } = m.params;
  const {
    state: { deviceConnector },
  }: { state: any } = useContext(DataFlowContext);
  const { dispatch } = useContext(DataFlowContext);

  const handleValidSubmit = async (val: any) => {
    let data = {
      workspace_id: wsId,
      connectivity: deviceConnector.connectivity,
      workspaceId: deviceConnector.workspaceId,
      ...val,
    };

    const [res, err] = await awaitWrap(
      updateIotDeviceConnector(deviceConnector.id, data, null),
    );

    if (err) return;
    if (res.Code != 200) return;
    const action = {
      type: ACTION_TYPE.SELECT_DEVICE_CONNECTOR,
      payload: { ...deviceConnector, ...data },
    };

    dispatch(action);
    onBack();
  };

  return (
    <Fragment>
      <AvForm onValidSubmit={(_e: any, v: any) => handleValidSubmit(v)}>
        <div className="mb-4">
          <AvField
            name="name"
            defaultValue={deviceConnector.name}
            label={t('Connector name')}
            placeholder=""
            type="text"
            errorMessage={t('Enter Connector Name')}
            validate={{ required: { value: true } }}
          />
        </div>
        <div className="mb-4">
          <AvField
            name="description"
            defaultValue={deviceConnector.description}
            label={t('Connector description')}
            placeholder=""
            type="text"
            errorMessage="Enter Connector description"
            validate={{ required: { value: false } }}
          />
        </div>
        <div className="flex-row mb-4 justify-end">
          <Button color="primary" type="submit">
            <i className="fas fa-save me-2" />
            {t('Save connector')}
          </Button>
        </div>
      </AvForm>
    </Fragment>
  );
};
