import { Key, useRef, useState } from 'react';
// i18n
import { useTranslation } from 'react-i18next';
// Component
import { Col, Row, Tooltip } from 'reactstrap';
import Legend from './Legend';
import TimeRangePicker from './TimeRangePicker';
// Conf
import { timeRangeConf } from './conf';
// Util
import { Dayjs } from 'dayjs';
import { CHART_COLOR } from 'src/util';

type tagConf = {
  name?: Key;
  color?: string;
}[];
type props = {
  className?: string;
  selectedList?: Record<Key, boolean>;
  tags?: tagConf;
  clickTag?: Function;
  onClear?: Function;
  onTimeChange?: Function;
};

export default function ({
  className,
  selectedList = {},
  tags,
  clickTag,
  onClear,
  onTimeChange,
}: props) {
  const { t } = useTranslation();

  const tooltipTargetRef = useRef(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const ctl = t('clear all');

  const handleTimeRangeChange = (opt: {
    type: Key;
    value: Key;
    st?: Dayjs;
    et?: Dayjs;
  }) => {
    onTimeChange && onTimeChange(opt);
  };

  return (
    <Row className={className} xs={2}>
      <Col className="px-0">
        <div className="mb-2 first-letter-upper">{t('displayed data')}</div>
        <div className="d-flex flex-wrap w-100">
          {tags &&
            tags.map((tag, index) => (
              <Legend
                key={tag.name}
                value={tag.name}
                selected={selectedList[tag.name || '']}
                bgColor={CHART_COLOR[index]}
                toggle={clickTag}
              />
            ))}
          <Legend className="text-primary" label={ctl} toggle={onClear} />
        </div>
      </Col>
      <Col xs={6} className="clearfix px-0">
        <div className="float-end">
          <div className="mb-2 first-letter-upper">
            <span className="me-1">{t('time range')}</span>
            <i
              className="text-primary mdi mdi-alert-circle-outline"
              ref={tooltipTargetRef}
            />
            <Tooltip
              flip
              fade
              innerClassName="shadow mb-1 border bg-soft-light text-secondary"
              isOpen={tooltipOpen}
              target={tooltipTargetRef}
              toggle={() => setTooltipOpen(!tooltipOpen)}>
              <div className="position-relative">
                {t(
                  'Selected time range is less than 3 hours, so the segmentation of the chart is 15 seconds',
                )}
              </div>
            </Tooltip>
          </div>
          <div className="d-flex flex-wrap w-100">
            <TimeRangePicker
              timeRangeList={timeRangeConf}
              onChange={handleTimeRangeChange}
            />
          </div>
        </div>
      </Col>
    </Row>
  );
}
