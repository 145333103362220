import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LoginTypes } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";

//Include Both Helper File with needed methods
// import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import {
  postFakeLogin,
  postJwtLogin,
  postSocialLogin,
} from "../../../helpers/fakebackend_helper";

import {
  postSsoLogin,
} from "../../../helpers/sso_backend_helper";

import {
  postIotLogin,
} from "../../../helpers/iot_backend_helper";
import { awaitWrap } from '../../../util';
// const fireBaseBackend = getFirebaseBackend();

function* loginUser({ payload: { user, history } }: any) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response: Promise<any> = yield call(postJwtLogin, {
        user_name: user.user_name,
        password: user.password,
      });
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      const response: Promise<any> = yield call(postFakeLogin, {
        user_name: user.user_name,
        password: user.password,
      });
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    } else if (process.env.REACT_APP_DEFAULTAUTH === "sso") {
      const response: Promise<any> = yield call(postSsoLogin, {
        user_name: user.user_name,
        password: user.password,
      }, null);
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    } else if (process.env.REACT_APP_DEFAULTAUTH === "iot") {
      // 此处约束用户登录时的行为
      const response: Promise<any> = yield call(postIotLogin, {
        user_name: user.user_name,
        password: user.password,
      }, null);
      const auth: any = response
      auth.Data.organizations = auth.Data.user_organizations?.map((org:any)=>org.organization)
      auth.Data.workspaces = auth.Data.user_workspaces?.map((ws:any)=>ws.workspace)
      let userData = JSON.stringify(auth);
      localStorage.setItem("authUser", userData);
      const data: any = auth
      if (data.Data.curr_org_id && data.Data.curr_wspace_id) {
        history.push(`/org/${JSON.parse(userData).Data.curr_org_id}/overview`);
      }else {
        history.push(`/setup/${data.Data.id}`)
      }
      yield put(loginSuccess(auth));
    }
  } catch (error) {
    yield put(apiError(error));
    // 处理登录失败
    let e :any = error
    if (e.response.data.Message == "账号未激活") {
      let f = confirm("账号未激活，是否去激活账号？")
      if (f){
        history.push(`/register/${e.response.data.data.id}`)
      }
    }
  }
}

function* logoutUser({ payload: { history } }: any) {
  try {
    localStorage.removeItem("authUser");

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      // const response: Promise<any> = yield call(fireBaseBackend.logout);
      // yield put(logoutUserSuccess(response));
    }
    history.push("/login");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* socialLogin({ payload: { data, history, type } }: any) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      // const fireBaseBackend = getFirebaseBackend();
      // const response: Promise<any> = yield call(
      //   fireBaseBackend.socialLoginUser,
      //   data,
      //   type
      // );
      // localStorage.setItem("authUser", JSON.stringify(response));
      // yield put(loginSuccess(response));
    } else {
      const response: Promise<any> = yield call(postSocialLogin, data);
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    }
    // history.push("/dashboard");
    history.push("/");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LoginTypes.LOGIN_USER, loginUser);
  yield takeLatest(LoginTypes.SOCIAL_LOGIN, socialLogin);
  yield takeEvery(LoginTypes.LOGOUT_USER, logoutUser);
}

export default authSaga;
