// Echart
import echarts from 'echarts';
// Util
import { ChartDrawer } from 'src/util';

const COLOR = ['#63b181', '#fd625e'];
const baseOption: echarts.EChartOption = {
  color: COLOR,
  tooltip: {
    trigger: 'item',
    padding: 8,
    textStyle: {
      fontSize: 12,
    },
  },
  series: [
    {
      type: 'pie',
      radius: ['40%', '70%'],
      avoidLabelOverlap: false,
      label: {
        show: false,
      },
      data: [],
    },
  ],
};

export const DeviceData2Option = (data: any[]) => {
  const series = [
    {
      left: -50,
      type: 'pie',
      radius: ['40%', '70%'],
      avoidLabelOverlap: false,
      label: {
        show: false,
        position: 'center',
      },
      data,
    },
  ];

  return { ...baseOption, series };
};

export class DeviceOnlinePieDrawer extends ChartDrawer {
  constructor(
    container: HTMLDivElement | HTMLCanvasElement | null,
    option: echarts.EChartOption,
    theme: string = 'light',
  ) {
    super(baseOption, container, option, theme);
  }
}
