import { StrictMode, useState } from 'react';
// Component
import RuleContainer from '../common/RuleContainer';
import Guide from './Guide';
import InputTypeSelector from './InputTypeSelector';
import DeviceSelector from './DeviceSelector';
import TagSelector from './TagSelector';
// Util
import { INPUT_TYPE, MODE, TYPE2MODE } from './constant';
import { currying } from 'src/util';

const guideProps = {
  title: 'input',
  prompt: 'click to add an input connector',
};

type props = {
  onModeChange?: (m: MODE) => any;
};

export default ({ onModeChange }: props) => {
  const [mode, setMode] = useState(MODE.GUIDE);

  const changeMode = (
    destination: MODE,
    callback?: <T = MODE>(destination: T, origin: T) => T,
  ) => {
    setMode(origin => {
      return (callback && callback(destination, origin)) || destination;
    });

    onModeChange && onModeChange(destination);
  };
  const handleInputTypeSelect = (value: INPUT_TYPE) => {
    setMode(TYPE2MODE[value]);
  };

  const mode2component = {
    [MODE.GUIDE]: (
      <Guide
        {...guideProps}
        onAdd={currying(changeMode, MODE.SELECT_INPUT_TYPE)}
      />
    ),
    [MODE.SELECT_INPUT_TYPE]: (
      <InputTypeSelector
        onCancel={currying(changeMode, MODE.GUIDE)}
        onSelect={handleInputTypeSelect}
      />
    ),
    [MODE.SELECT_DEVICES]: (
      <DeviceSelector
        onSelect={currying(changeMode, MODE.GUIDE)}
        onBack={currying(changeMode, MODE.SELECT_INPUT_TYPE)}
        onCancel={currying(changeMode, MODE.GUIDE)}
      />
    ),
    [MODE.SELECT_TAGS]: (
      <TagSelector
        onSelect={currying(changeMode, MODE.GUIDE)}
        onBack={currying(changeMode, MODE.SELECT_INPUT_TYPE)}
        onCancel={currying(changeMode, MODE.GUIDE)}
      />
    ),
  };

  return (
    <StrictMode>
      <RuleContainer className={mode != MODE.GUIDE && 'cus-bg-white'}>
        {mode2component[mode]}
      </RuleContainer>
    </StrictMode>
  );
};
