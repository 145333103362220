import { Fragment, useContext, useState } from 'react';
// i18n
import { useTranslation } from 'react-i18next';
// Context
import { DataFlowContext } from '../../../reducer';
// Component
import DeviceConnectorTypeOption from './DeviceConnectorTypeOption';
import DeviceConnectorOption from './DeviceConnectorOption';
import DeviceConnectorEdit from './DeviceConnectorEdit';
// Util
import { fetchDeviceConnectorTypeOptions } from '../api';

export default () => {
  const { t } = useTranslation();

  const deviceConnectorTypeOptions = fetchDeviceConnectorTypeOptions();

  const {
    state: { deviceConnector },
  } = useContext(DataFlowContext);

  const [connectorType, setConnectorType] = useState('');

  const goBack = () => {
    setConnectorType('');
  };

  return (
    <Fragment>
      <header className="mb-4 font-size-16 fw-bold first-letter-upper">
        <i
          className="mdi mdi-arrow-left me-2 fw-bold cursor-pointer"
          hidden={Boolean(deviceConnector) || !connectorType}
          onClick={goBack}
        />
        <span>{t('device connector')}</span>
      </header>

      {!deviceConnector &&
        !connectorType &&
        deviceConnectorTypeOptions &&
        deviceConnectorTypeOptions.map(option => (
          <DeviceConnectorTypeOption
            key={option.value}
            id={option.value}
            title={option.label}
            description={option.description}
            iconClassName={option.iconClassName}
            onSelect={setConnectorType}
          />
        ))}
      {!deviceConnector && connectorType && (
        <DeviceConnectorOption connectivity={connectorType} />
      )}
      {deviceConnector && <DeviceConnectorEdit onBack={goBack} />}
    </Fragment>
  );
};
