import { Key, StrictMode, useContext, useState } from "react";

import { ACTION_TYPE_GROUP, MODE } from "./constant";

import RuleContainer from "../common/RuleContainer";
import Guide from "./Guide";
import ActionTypeSelector from "./ActionTypeSelector";
import ConnectorSelector from "./ConnectorSelector";
import EditConnector from "./EditConnector";

import { RuleContext } from "../reducer";
import { currying } from "src/util";
import { createIotOutputConnector, updateIotOutputConnector } from "src/helpers/iot_backend_helper";


export default () => {
    const [mode, setMode] = useState(MODE.GUIDE);
    const [type, setType] = useState("");
    const [name, setName] = useState("");
    const [connector, setConnector] = useState<any>(null);
    const changeMode = (destination: MODE, callback?: <T = MODE>(destination: T, origin: T) => T) => {
        setMode((origin) => {
            return callback && callback(destination, origin) || destination
        })
    }

    const [connectorTypeGroup, setConnectorTypeGroup] = useState(ACTION_TYPE_GROUP.DATA_SINK)
    const [connectorTypeId, setConnectorTypeId] = useState("")

    const handleTypeSelect = (id: any, group?: any) => {
        setConnectorTypeId(id)
        setConnectorTypeGroup(group)

        changeMode(MODE.SELECT_CONNECTOR)
    }

    const { state: { actions }, dispatch } = useContext(RuleContext)
    const selected = actions.map(item => item.id);
    const handleConnectorSelect = (row: any) => {
        const action = {
            type: "ADD_ACTION",
            payload: row
        }
        dispatch(action)
        setMode(MODE.GUIDE)
    }

    const handleEdit = (t: string, name: string) => {
        setMode(MODE.EDIT);
        setType(t);
        setName(name);
        setConnector(null);
    }
    const handleEditConnector = (data: any) => {
        setMode(MODE.EDIT);
        setType(data.type);
        setName(data.name);
        setConnector(data);
    }

    // 保存action
    const handleSaveAction = (data: any) => {
        data.scope = "RULE_ACTION";
        data.type = type;
        createIotOutputConnector(data, null).then(result => {
            if (result.Code == 200) {
                setMode(MODE.SELECT_CONNECTOR)
            }
        })
    }
    // 更新action
    const handleUpdateAction = (id: string, data: any) => {
        updateIotOutputConnector(id, data, null).then(result => {
            if (result.Code == 200) {
                setMode(MODE.GUIDE)
                const action = {
                    type: "UPDATE_ACTION",
                    payload: {id, ...data}
                }
                dispatch(action)
            }
        })
    }

    const guideProps = {
        title: "action",
        prompt: "click to add a rule action",
        onAdd: currying(changeMode, MODE.SELECT_ACTION_TYPE),
        onEdit: handleEditConnector
    }

    const actionTypeSelectorProps = {
        onCancel: currying(changeMode, MODE.GUIDE),
        onSelect: handleTypeSelect
    }

    const connectorSelectorProps = {
        typeId: connectorTypeId,
        typeGroup: connectorTypeGroup,
        selected: selected,
        onSelect: handleConnectorSelect,
        onBack: currying(changeMode, MODE.SELECT_ACTION_TYPE),
        onCancel: currying(changeMode, MODE.GUIDE),
        onNew: handleEdit
    }

    const editConnectorProps = {
        onBack: currying(changeMode, MODE.SELECT_CONNECTOR),
        connector, type, typeName: name,
        onSave: handleSaveAction,
        onCancel: currying(changeMode, MODE.GUIDE),
        onUpdate: handleUpdateAction
    }
    const mode2component = {
        [MODE.GUIDE]: <Guide {...guideProps} />,
        [MODE.SELECT_ACTION_TYPE]: <ActionTypeSelector {...actionTypeSelectorProps} />,
        [MODE.SELECT_CONNECTOR]: <ConnectorSelector {...connectorSelectorProps} />,
        [MODE.EDIT]: <EditConnector {...editConnectorProps} />
    }

    return (<StrictMode>
        <RuleContainer className={mode != MODE.GUIDE && "cus-bg-white"}>
            {mode2component[mode]}
        </RuleContainer>
    </StrictMode>)
}
