/**
 * interface for Organize type
 */
 export enum OrganizeTypes {
    API_SUCCESS = '@@organize/API_SUCCESS',
    API_FAIL = '@@organize/API_FAIL',
    GET_EVENTS = '@@organize/GET_EVENTS',
    ADD_NEW_EVENT = '@@organize/ADD_NEW_EVENT',
    UPDATE_EVENT = '@@organize/UPDATE_EVENT',
    UPDATE_EVENT_SUCCESS = '@@organize/UPDATE_EVENT_SUCCESS',
    UPDATE_EVENT_FAIL = '@@organize/UPDATE_EVENT_FAIL',
    DELETE_EVENT = '@@organize/DELETE_EVENT',
    DELETE_EVENT_SUCCESS = '@@organize/DELETE_EVENT_SUCCESS',
    DELETE_EVENT_FAIL = '@@organize/DELETE_EVENT_FAIL',
    GET_CATEGORIES = '@@organize/GET_CATEGORIES',
    GET_CATEGORIES_SUCCESS = '@@organize/GET_CATEGORIES_SUCCESS',
    GET_CATEGORIES_FAIL = '@@organize/GET_CATEGORIES_FAIL',
    GET_ORGANIZE = '@@organize/GET_ORGANIZE',
    GET_ORGANIZE_SUCCESS = '@@organize/GET_ORGANIZE_SUCCESS',
    GET_ORGANIZE_FAIL = '@@organize/GET_ORGANIZE_FAIL',
    GET_ORGANIZES = '@@organize/GET_ORGANIZES',
    GET_ORGANIZES_SUCCESS = '@@organize/GET_ORGANIZES_SUCCESS',
    GET_ORGANIZES_FAIL = '@@organize/GET_ORGANIZES_FAIL',
}

 export interface OrganizeState {
   events  : Array<any>;
   categories : Array<any>;
   error : Object;
   organize: Array<any>;
   organizes: Array<any>;
}