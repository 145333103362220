import { useEffect, useRef } from "react";
import "./index.css"

export type PointProps = {
  updateOffect: Function;
  change: string;
  pointCheck: Function;
  style: any;
}

type TPoint = {
  left: Number;
  top: Number;
}

const Point = ({ updateOffect, change, pointCheck, style }: PointProps) => {
  const ref = useRef<any>(null);
  useEffect(() => {
    let { left, top }: TPoint = ref.current.getBoundingClientRect();
    updateOffect(left, top);
  }, [change]);
  useEffect(() => {
    // 监听
    window.addEventListener('resize', resizeChange);
    // 销毁
    return () => window.removeEventListener('resize', resizeChange);
  }, []);

  //浏览器窗口大小改变
  const resizeChange = () => {
    let { left, top }: TPoint = ref.current.getBoundingClientRect();
    updateOffect(left, top);
  };

  const handleActionCheck = () => {
    let { left, top }: TPoint = ref.current.getBoundingClientRect();
    pointCheck(left, top);
  };

  return (
    <div
      className="items-center point-con"
      onClick={handleActionCheck}
      style={style}>
      <div
        className="point"
        ref={ref}

      >
      </div>
    </div>
  );
}

export default Point;