import React, { useState } from "react";
import PropTypes from "prop-types";
//i18n
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import { Card, CardBody, Col, Row } from "reactstrap";
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider from "react-bootstrap-table2-toolkit";
import AddApiKeyModal from "./components/AddApiKeyModal";

const ApiKeys = (props: any) => {
  const [visiable, setVisible] = useState(false);
  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);
  let {
    t
  } = props;
  const columns = [
    {
      dataField: "Name",
      text: t('Name'),
      sort: true,
    },
    {
      dataField: "Description",
      text: t('Description'),
    },
    {
      dataField: "Action",
      text: t('Action'),
      sort: true,
    },
  ];
  const returnButton = () => {
    return (
      <div className="d-flex flex-wrap gap-2">
        <button
          type="button"
          className="btn btn-outline-danger waves-effect waves-light"
        >
          {t("Delete")}
        </button>
      </div>
    );
  }

  const renderApiKey = (name:any) => {
    return (
      <div style={{color: '#1890ff'}}>{name}</div>
    )
  }

  // Table Data
  const productData = [
    {
      "Name": renderApiKey('apikey'),
      "Description": "first apikey",
      "Action": returnButton(),
    }
  ];

  const defaultSorted: any = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const pageOptions: any = {
    sizePerPage: 10,
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
  };


  // 显示隐藏新增用户Modal
  const togModal = () => {
    setVisible(!visiable);
  }
  // 隐藏新增用户Modal
  const hideModal = () => {
    setVisible(false);
  }
  // 保存用户信息
  const saveUser = () => {

  }
  const addApiKeyProps = {
    visiable,
    togModal,
    hideModal,
    save: saveUser
  }
  return (
    <React.Fragment>
      <div className="px-4 py-4 flex-col">
        <div className="flex-row items-center justify-between px-3 mb-3">
          <div className="text-dark" style={{ fontSize: '20px', fontWeight: 700 }}>{t('API Keys')}</div>
          <div>
            <button
              type="button"
              style={{ background: '#1890ff', fontSize: '16px' }}
              onClick={() => {
                togModal();
              }}
              className="btn btn-primary waves-effect waves-light"
            >
              <span style={{ fontSize: '18px', marginRight: '10px' }}>{`+ ${t("create API Key")}`}</span>
            </button>
          </div>
        </div>
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  // columns={columns}
                  // data={productData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={productData}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col md="8" style={{ display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center' }}>
                                <div>
                                  Showing 1 - 1 of 1 assets
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    // responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <AddApiKeyModal {...addApiKeyProps} />
    </React.Fragment>
  );
}

ApiKeys.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withTranslation()(withRouter(ApiKeys));