import { takeEvery, put, call, all, fork } from "redux-saga/effects"

// Calender Redux States
import {TokensState, TokensTypes} from "./actionTypes"
import {
  getTokens, getTokensSuccess, getTokensFail
} from "./actions"

//Include Both Helper File with needed methods
import {
  api_getTokenById,
  api_getTokens,
} from "../../../API/api"

// 获取全部token信息
function* onGetTokens() {
  try {
    const response : Promise<any> = yield call(api_getTokens)
    yield put(getTokensSuccess(response))
  } catch (error) {
    yield put(getTokensFail(error))
  }
}

export function* watchOnGetTokens() {
  yield takeEvery(TokensTypes.GET_TOKENS, onGetTokens);
}

// 获取单个token信息
function* onGetTokenById({payload:id}:any) {
  try {
    const response : Promise<any> = yield call(api_getTokenById,id)
    yield put(getTokensSuccess(response))
  } catch (error) {
    yield put(getTokensFail(error))
  }
}

export function* watchOnGetTokenById() {
  yield takeEvery(TokensTypes.GET_TOKENBYID, onGetTokenById);
}

function* tokensSaga() {
  yield all([fork(watchOnGetTokens)]);
  yield all([fork(watchOnGetTokenById)]);
}

export default tokensSaga
