import React, { StrictMode } from 'react';

import { useTranslation } from 'react-i18next';

import { Link, useRouteMatch } from 'react-router-dom';
import { Button } from 'reactstrap';
import { useSelector } from 'react-redux';

type Props = {
  hidden: boolean;
  name: string;
  description: string;
  guidePath: string;
  buildPath: string;
};

export default ({
  name,
  description = '',
  guidePath,
  buildPath,
  hidden = false,
}: Partial<Props>) => {
  const { t } = useTranslation();

  const { user } = useSelector((state: any) => ({
    user: state.login.user,
  }));
  const { wsId } = useRouteMatch<any>().params;
  const role =
    user.user_workspaces.find((ws: any) => ws.workspace.id == wsId)?.role ||
    'viewer';

  return (
    <StrictMode>
      <div
        className="position-relative d-flex justify-content-center align-items-center h-100 w-100"
        hidden={hidden}>
        <div className="w-normal">
          <h5 className="mb-5">
            <i className="me-2 fas fa-code-branch" />
            <span className="text-capitalize">
              {t(`create your first ${name}`)}
            </span>
          </h5>
          <div className="mb-3 text-secondary">{t(description)}</div>
          <div className="mb-5 text-primary first-letter-upper">
            <Link to={guidePath || '/'}>{t('learn about rules')}</Link>
          </div>
          <div>
            {role == 'viewer' ? (
              <Button
                className="fw-bold first-letter-upper"
                color="primary"
                disabled>
                {t('create a new rule')}
              </Button>
            ) : (
              <Link to={buildPath || '/'}>
                <Button className="fw-bold first-letter-upper" color="primary">
                  {t('create a new rule')}
                </Button>
              </Link>
            )}
          </div>
        </div>
      </div>
    </StrictMode>
  );
};
