import React, { Fragment, Key, useState } from 'react';
// Component
import MetaTags from 'react-meta-tags';
import { Col, Container, Row } from 'reactstrap';
import CarouselPage from 'src/pages/AuthenticationInner/CarouselPage';
import RegisterForm from './RegisterForm';
import AdditionalInformation from './AdditionalInformation';
import AlmostDone from './AlmostDone';
// Assets
import logo from 'src/assets/images/brands/metavun-text-black.png';
// API
import { registerUser } from './api';
import { useRouteMatch } from 'react-router-dom';

export default function () {
  // 先检查是否仅需要激活服务
  const { id } = useRouteMatch<any>().params;
  let defaultStep = 0;
  if (id) defaultStep = 2;

  const [step, setStep] = useState(defaultStep);
  // RegisterForm
  const defaultAccountInfo = {
    username: '',
    password: '',
  };
  const [accountInfo, setAccountInfo] = useState(defaultAccountInfo);

  const handleContinue = (data: typeof defaultAccountInfo) => {
    const newAccountInfo = {
      ...accountInfo,
      ...data,
    };
    setAccountInfo(newAccountInfo);
    setStep(1);
  };
  // AdditionalInformation
  type AdditionalInformationProps = {
    name: string;
    email: string;
    nationality: string;
    phoneNumber?: string;
  };
  const [additionalInformation, setAdditionalInformation] =
    useState<AdditionalInformationProps>();

  // AlmostDone
  const [uid, setUid] = useState<Key>(id);
  const handleComplete = async (data: AdditionalInformationProps) => {
    const newAdditionalInformation = {
      ...additionalInformation,
      ...data,
    };
    setAdditionalInformation(newAdditionalInformation);

    const t = {
      ...accountInfo,
      ...newAdditionalInformation,
    };

    const [res, err] = await registerUser(t);
    if (err) {
      alert(`Login failure: ${err}`);
      return;
    }
    if (res && res.Code != 200) {
      alert(res.Message);
      return;
    }

    setUid(res.Data.id);
    setStep(2);
  };
  // Component Map
  const step2component = [
    <RegisterForm {...accountInfo} onContinue={handleContinue} />,
    <AdditionalInformation
      {...additionalInformation}
      onComplete={handleComplete}
    />,
    <AlmostDone uid={uid} />,
  ];

  return (
    <Fragment>
      <MetaTags>
        <title>Register | Metavun</title>
      </MetaTags>
      <main>
        <Container className="p-0" fluid>
          <Row className="g-0">
            <Col className="col-xxl-3" lg={4} md={5}>
              <div className="p-sm-5 p-4 min-vh-100">
                <img className="mb-4 cus-h-2" src={logo} alt="..." />
                {step2component[step]}
              </div>
            </Col>
            <CarouselPage />
          </Row>
        </Container>
      </main>
    </Fragment>
  );
}
