// i18n
import {useTranslation} from "react-i18next";
// Component
import {Button} from "reactstrap";
// Util
import {insure} from "src/util";
// Image
import metavun from "src/assets/images/brands/metavun-black.png"

type props = {
  onStart?: Function;
}

export default function ({onStart}: props) {
  const {t} = useTranslation()

  return (<div className="d-flex flex-column
  justify-content-center align-items-center
  cus-w-4 text-center">
    <img className="mb-4 cus-h-2" src={metavun} alt="..."/>
    <div className="mb-4">
      <h4 className="first-letter-upper">{t("Welcome to metavun")}</h4>
      <h4 className="mb-0 first-letter-upper">
        {t("IotForSmartSolution")}
      </h4>
    </div>
    <div className="mb-4">
      {t("FewStepConnectDeviceSetup")}
    </div>
    <Button className="first-letter-upper" color="primary"
            onClick={insure(onStart)}>
      {t("start the setup")}
    </Button>
  </div>)
}
