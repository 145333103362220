/**
 * interface for providers type
 */
 export enum ProvidersTypes {
    API_SUCCESS = '@@providers/API_SUCCESS',
    API_FAIL = '@@providers/API_FAIL',
    GET_EVENTS = '@@providers/GET_EVENTS',
    ADD_NEW_EVENT = '@@providers/ADD_NEW_EVENT',
    UPDATE_EVENT = '@@providers/UPDATE_EVENT',
    UPDATE_EVENT_SUCCESS = '@@providers/UPDATE_EVENT_SUCCESS',
    UPDATE_EVENT_FAIL = '@@providers/UPDATE_EVENT_FAIL',
    DELETE_EVENT = '@@providers/DELETE_EVENT',
    DELETE_EVENT_SUCCESS = '@@providers/DELETE_EVENT_SUCCESS',
    DELETE_EVENT_FAIL = '@@providers/DELETE_EVENT_FAIL',
    GET_CATEGORIES = '@@providers/GET_CATEGORIES',
    GET_CATEGORIES_SUCCESS = '@@providers/GET_CATEGORIES_SUCCESS',
    GET_CATEGORIES_FAIL = '@@providers/GET_CATEGORIES_FAIL',
    GET_Providers = '@@providers/GET_Providers',
    GET_Providers_SUCCESS = '@@providers/GET_Providers_SUCCESS',
    GET_Providers_FAIL = '@@providers/GET_Providers_FAIL',
    GET_ProviderByID = '@@providers/GET_ProviderByID',
    GET_ProviderByID_SUCCESS = '@@providers/GET_ProviderByID_SUCCESS',
    GET_ProviderByID_FAIL = '@@providers/GET_ProviderByID_FAIL',
 }

 export interface ProvidersState {
   events  : Array<any>;
   categories : Array<any>;
   error : Object;
}