import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
//i18n
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Col, Row, Input, Label, Modal } from "reactstrap";

const AddParameterModal = (props: any) => {
  let {
    t, visiable, togModal, hideModal, save, update, parameterData = {}
  } = props;

  const [parameterType, setParameterType] = useState<any>({ label: t("Numerical"), value: "number" });
  const [parameterValue, setParameterValue] = useState<any>({ label: "True", value: true });

  const optionGroup = [
    { label: t("Numerical"), value: "number" },
    { label: t("String"), value: "string" },
    { label: t("Boolean"), value: "boolean" },
  ];

  useEffect(() => {
    let findType = optionGroup.find((option) => option.value == parameterData.parameter_type);
    if(findType) {
      setParameterType(findType);
      if(findType.value == "boolean") {
        if(parameterData.defaule_value) {
          setParameterValue({ label: "True", value: true })
        }else {
          setParameterValue({ label: "False", value: false })
        }
      }
    }
  }, []);

  const [
    displayPlaceholder,
    variableNamePlaceholder,
    parameterTypePlaceholder,
    defaultValuePlaceholder,
  ] = [t("choose display name"), t("variable name"), t("select parameter type"), t("enter default string value")]

  const handleValidSubmit = (e: any, v: any) => {
    let data = { ...v, id: String(Date.now()), parameter_type: parameterType && parameterType.value }
    if(parameterType && parameterType.value == 'boolean') {
      data.defaule_value = parameterValue.value
    }
    if (parameterData && parameterData.id) {
      data.id = parameterData.id
      update(data)
    } else {
      save(data);
    }
  }

  const typeDir: any = {
    "string": "text",
    "number": "number"
  }
  
  return (
    <React.Fragment>
      <Modal
        isOpen={visiable}
        toggle={() => {
          togModal();
        }}
        centered={true}
      >
        <AvForm
          onValidSubmit={(e: any, v: any) => {
            handleValidSubmit(e, v);
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0 first-letter-upper">{t(parameterData.id ? 'Update parameter' : 'Add parameter')}</h5>
            <button
              type="button"
              onClick={() => {
                hideModal();
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Row>
              <Col sm={12}>
                <div>
                  <div className="mb-3">
                    <AvField
                      name="display_name"
                      defaultValue={parameterData.display_name || ''}
                      label={t('parameter display name')}
                      placeholder={displayPlaceholder}
                      type="text"
                      errorMessage={t("Name is required")}
                      validate={{ required: { value: true } }}
                    />
                  </div>
                  <div className="mb-3">
                    <AvField
                      name="variable_name"
                      defaultValue={parameterData.variable_name || ''}
                      label={`${t('parameter variable name')}`}
                      placeholder={variableNamePlaceholder}
                      type="text"
                      errorMessage={t("VariableName is required")}
                      validate={{ required: { value: true } }}
                    />
                  </div>
                  <div className="mb-3">
                    <Label htmlFor="choices-single-default" className="form-Label font-size-13 text-capitalize">
                      {t('parameter type')}
                    </Label>
                    <Select
                      value={parameterType}
                      onChange={(e: any) => {
                        setParameterType(e);
                      }}
                      options={optionGroup}
                      placeholder={parameterTypePlaceholder}
                      classNamePrefix="select2-selection"
                    />
                  </div>
                  <div className="mb-3">
                    {
                      parameterType && parameterType.value == 'boolean' ? <Select
                        value={parameterValue}
                        onChange={(e: any) => {
                          setParameterValue(e);
                        }}
                        options={[
                          { label: "True", value: true },
                          { label: "False", value: false }
                        ]}
                        placeholder=""
                        classNamePrefix="select2-selection"
                      /> : <AvField
                        name="dafault_value"
                        defaultValue={parameterData.dafault_value || ''}
                        label={`${t('default value')} (${t("optional")})`}
                        placeholder={defaultValuePlaceholder}
                        type={parameterType ? typeDir[parameterType.value] : "text"}
                        errorMessage="Variable dafault_value is required"
                        validate={{ required: { value: false } }}
                      />
                    }
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="modal-footer" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <button
              type="button"
              style={{ fontSize: '16px' }}
              onClick={hideModal}
              className="btn btn-outline-light waves-effect text-capitalize"
            >
              {props.t('cancel')}
            </button>
            <button type="submit" className="btn btn-primary">
              {t(parameterData.id ? 'Update parameter' : 'Add parameter')}
            </button>
          </div>
        </AvForm>
      </Modal>
    </React.Fragment>
  );
}

AddParameterModal.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  visiable: PropTypes.bool,
  togModal: PropTypes.func,
  hideModal: PropTypes.func,
  save: PropTypes.func
};

export default withTranslation()(withRouter(AddParameterModal));