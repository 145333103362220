import React, {ReactNode, useState} from "react";

type Props = {
    label: string;
    content: string;
    shield: ReactNode;
}

const defaultShield = (<span>••••••</span>)

export default ({label, content, shield = defaultShield}: Partial<Props>) => {
    const [visible, setVisible] = useState(false)

    const reverseVisible = () => {
        setVisible((visible => !visible))
    }

    return (<React.StrictMode>
        <div className="d-flex gutter-t-normal">
            <div className="flex-shrink-0 me-2 text-secondary">{label}:</div>
            <div className="flex-grow-1 overflow-auto">
                <span className="me-2 select-all">
                    {visible ? content : shield}
                </span>
                <i className={`fas ${visible ? "fa-eye" : "fa-eye-slash"}`}
                   onClick={reverseVisible}/>
            </div>
        </div>
    </React.StrictMode>)
}
