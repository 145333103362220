import {StrictMode, useContext} from "react";
import {useTranslation} from "react-i18next";
import {RuleContext} from "../../reducer";

import BlankGuide from "../../common/BlankGuide";
import LogicPlatform from "./LogicPlatform";

type Props = {
    title: string;
    prompt: string;
    onAdd: Function;
}

export default ({title = "", prompt, onAdd}: Partial<Props>) => {
    const {t} = useTranslation()

    const {state: {logicState: {type, name}}, dispatch} = useContext(RuleContext)

    const empty = type == undefined

    const handleClear = () => {
        const action = {
            type: "CLEAR_LOGIC",
        }
        dispatch(action)
    }

    return (<StrictMode>
        <div className="position-relative d-flex flex-column h-100 user-select-none">
            <header className="flex-shrink-0">
                <span className="me-2 text-capitalize">{t(title)}</span>
            </header>
            {empty && <BlankGuide prompt={prompt} onAdd={onAdd}/>}
            <main className="d-flex flex-column justify-content-center flex-grow-1">
                {!empty && <LogicPlatform name={name} onClear={handleClear}/>}
            </main>
        </div>
    </StrictMode>)
}
