import { StrictMode } from "react";

import { useTranslation } from "react-i18next";

import ActionTypeOption from "./ActionTypeOption";

import { insure } from "src/util";

import { getDataSinks, getNotificationServices } from "../../common/configuration";

type Props = {
    onCancel: Function;
    onSelect: Function;
}

export default ({ onCancel, onSelect }: Partial<Props>) => {
    const { t } = useTranslation()
    const dataSinks = getDataSinks();
    const notificationServices = getNotificationServices();

    return <StrictMode>
        <div className="d-flex flex-column h-100">
            <header className="flex-shrink-0 mb-5 user-select-none clearfix">
                <span className="float-start font-size-18 fw-bolder text-capitalize">
                    {t("select action type")}
                </span>
                <span className="float-end lh-lg text-primary text-capitalize cursor-pointer"
                    onClick={insure(onCancel)}>
                    {t("cancel")}
                </span>
            </header>
            <main className="flex-grow-1 overflow-auto scrollbar-none">
                <div className="mt-wide-pre">
                    <div className="mb-2 text-secondary first-letter-upper">{t("data sinks")}</div>
                    {dataSinks.map(sink =>
                        <ActionTypeOption key={sink.id} {...sink}
                            onClick={onSelect} />)}
                    <div className="mb-2 mt-4 text-secondary first-letter-upper">{t("data sinks")}</div>
                    {notificationServices.map(sink =>
                        <ActionTypeOption key={sink.id} {...sink}
                            onClick={onSelect} />)}
                </div>
                <div className="mt-wide-pre" hidden={true}>
                    <div className="mb-2 text-secondary first-letter-upper">Notification services</div>
                </div>
            </main>
        </div>
    </StrictMode>
}
