import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
//i18n
import { withTranslation } from "react-i18next";
import { withRouter, Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import Steps from "src/components/Steps";
import Basicinformation from "./Basicinformation";
import AdditionalInformation from "./AdditionalInformation";
import ConnectivityParameters from "./ConnectivityParameters";
import {
  getIotDevice
} from "src/helpers/iot_backend_helper";
const AddDevice = (props: any) => {
  const { match } = props;
  const { orgId, wsId, id } = match.params;
  const [loading, setLoading] = useState<boolean>(true);
  const [inProgress, setInProgress] = useState<number>(1);
  const [formData, setFormData] = useState<object>({});

  useEffect(() => {
    if(id) {
      getIotDevice(id, null).then(result => {
          if(result && result.Code == 200) {
            let {name, description, tags, custom_fields, data_flow, device_id, online_timeout, lifecycle} = result.Data;
            setFormData({
              name, description, device_id,
              tags: tags?tags.map((tag:any) => ({label: tag.name, value: tag.id})):[], 
              dataFlow: {label: data_flow.name, value: data_flow.id},
              customFields: custom_fields? custom_fields.fields : [],
              online_timeout,
              lifecycle: lifecycle === 1? true: false
           });
          }
          setLoading(false);
      }).catch(err => {
        console.log(err);
        setLoading(false)
      });
    }else {
      setLoading(false);
    }
  }, []);
  const steps = [{
    step: 1,
    des: 'Basic Information'
  }, {
    step: 2,
    des: 'Additional device information'
  }, {
    step: 3,
    des: 'Connectivity Parameters'
  }];

  // 下一步
  const nextStep = (obj:object) => {
    let data = {...formData, ...obj};
    setFormData(data);
    setInProgress(inProgress + 1);
  }

  // 上一步
  const preStep = () => {
    setInProgress(inProgress - 1);
  }

  const commonProps = {
    formData,
    next: nextStep,
    pre: preStep
  }
  if(loading) return (<></>)
  return (
    <React.Fragment>
      <div style={{ flexDirection: 'column' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'cente', justifyContent: 'space-between', padding: '16px 30px', borderBottom: '1px solid #e8e8e8' }}>
          <div style={{ fontSize: '20px', color: 'rgba(0,0,0,.85)', fontWeight: 700 }}>{props.t(id?'Update device':'CreateNewDevice')}</div>
          <Link to={`/org/${orgId}/ws/${wsId}/assets`}>
            <button
              type="button"
              style={{ fontSize: '16px' }}
              className="btn btn-outline-light waves-effect"
            >
              {props.t('Cancel')}
            </button>
          </Link>
        </div>
        <Row style={{ padding: '50px 0 0 50px' }}>
          <Col sm={4}>
            {
              inProgress == 1 && <Basicinformation {...commonProps} />
            }
            {
              inProgress == 2 && <AdditionalInformation {...commonProps} />
            }
            {
              inProgress == 3 && <ConnectivityParameters {...commonProps} id={id} />
            }
          </Col>
          <Col sm={4} style={{ paddingLeft: '30px' }}>
            <Steps steps={steps} inProgress={inProgress} />
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}

AddDevice.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withTranslation()(withRouter(AddDevice));