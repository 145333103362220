import { Key, MouseEventHandler, useState } from 'react';
// Router
import { useHistory, useRouteMatch } from 'react-router-dom';
//i18n
import { useTranslation } from 'react-i18next';
// Api
import {
  createIotDevice,
  updateIotDevice,
} from 'src/helpers/iot_backend_helper';
// Component
import { Button, FormGroup, Input, Label, Row } from 'reactstrap';
// Util
import { awaitWrap } from 'src/util';

type props = {
  id: Key;
  formData: any;
  pre: MouseEventHandler<HTMLButtonElement>;
};

export default ({ id: uid, formData, pre }: props) => {
  const { t } = useTranslation();
  const h = useHistory();

  const m = useRouteMatch<any>();
  const { orgId, wsId } = m.params;

  const [deviceId, setDeviceId] = useState<string>(formData.device_id);

  let metaDate = Date.now();
  const generateId = () => {
    let currentDate = Date.now();
    // 防止出现一样的时间戳
    // 最大自旋次数为 10 次
    for (let i = 0; i < 10 && currentDate == metaDate; i++) {
      currentDate = Date.now();
    }
    metaDate = currentDate;

    const s1 = currentDate.toString(36);
    // 保证生成的 ID 长度为 15位
    const z = 15 - s1.length;
    const s2 = new Array<string>(z);
    for (let i = 0; i < z; i++) {
      const p = Math.random();
      if (p > 0.5) {
        // 在 'A' ~ 'Z' 中随机选择一个
        s2[i] = String.fromCharCode(Math.floor(Math.random() * 25) + 65);
      } else {
        // 在 '0' ~ '9' 中随机选择一个
        s2[i] = String.fromCharCode(Math.floor(Math.random() * 9) + 48);
      }
    }

    const s = (s2.join('') + s1).toUpperCase();
    setDeviceId(s);
  };

  const CreateDevice = async () => {
    let deviceData = {
      name: formData.name,
      description: formData.description,
      device_id: deviceId.trim(),
      data_flow_id: formData.dataFlow.value,
      org_id: orgId,
      lifecycle: formData.lifecycle ? 1 : 2,
      tags: formData.tags.map((tag: any) => ({
        id: tag.value,
        name: tag.label,
      })),
      online_timeout: formData.online_timeout,
      custom_fields: { fields: formData.customFields },
      workspace_id: wsId,
    };

    let res: any, err: any;
    if (uid) {
      [res, err] = await awaitWrap(
        updateIotDevice(uid.toString(), deviceData, null),
      );
    } else {
      [res, err] = await awaitWrap(createIotDevice(deviceData, null));
    }
    if (err) {
      alert(err.response?.data?.message);
      return;
    }
    if (res && res.Code != 200) {
      console.log(res);
      alert(res.Message);
      return;
    }

    h.push(`/org/${orgId}/ws/${wsId}/assets`);
  };

  return (
    <Row>
      <h5 className="mb-5">{t('ConnectivityParameters')}</h5>
      <FormGroup className="mb-5">
        <Label>{t('Device ID')}</Label>
        <div className="d-flex justify-content-between">
          <Input
            className="me-2 cus-mw-2"
            value={deviceId}
            onChange={e => setDeviceId(e.target.value)}
          />
          <Button className="whitespace-nowrap" outline onClick={generateId}>
            {t('Generate ID')}
          </Button>
        </div>
      </FormGroup>
      <div className="clearfix">
        <Button className="float-start" outline onClick={pre}>
          {t('Back')}
        </Button>
        <Button
          className="float-end"
          color="primary"
          disabled={!deviceId}
          onClick={CreateDevice}>
          {t(uid ? 'Update device' : 'Create device')}
        </Button>
      </div>
    </Row>
  );
};
