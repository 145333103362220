import React, { Fragment, useState } from 'react';
import PropTypes from "prop-types";
//i18n
import { withTranslation } from "react-i18next";
import { withRouter, Link } from "react-router-dom";
import { Col, Row, Label, Card, Form, Button, Input } from "reactstrap";
import Dropzone from "react-dropzone";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useRole } from 'src/util';

const Basicinformation = (props: any) => {
  let { t, next, formData,match } = props;
  const {orgId} = match.params;
  const [selectedFiles, setselectedFiles] = useState<any>(formData.selectedFiles || []);

  const pageRole = useRole(orgId,undefined)

  function handleAcceptedFiles(files: any) {
    files.map((file: any) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
  }
  /**
  * Formats the size
  */
  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  // handleValidSubmit
  const handleValidSubmit = (event: any, values: any) => {
    values.selectedFiles = selectedFiles;
    next(values);
  };

  const deviceTypeNameErrorMessage = t("Enter Device Type Description")
  
  return (
    <React.Fragment>
      <h5 style={{ marginBottom: '30px' }}>{t('BasicInformation')}</h5>
      <Row>
        <AvForm
          onValidSubmit={(e: any, v: any) => {
            handleValidSubmit(e, v);
          }}
        >
          <div className="mb-4">
            {pageRole=="viewer" ?
              <Fragment>
                <Label>{t('device type name')}</Label>
                <Input disabled value={formData.name} />
              </Fragment>
              :<AvField
              name="name"
              defaultValue={formData.name}
              label={t('device type name')}
              placeholder=""
              type="text"
              errorMessage={deviceTypeNameErrorMessage}
              validate={{ required: { value: true } }}
            />}
          </div>
          <div className="mb-4">
            {pageRole=="viewer" ?
              <Fragment>
                <Label>{t('DeviceDescription')}</Label>
                <Input disabled value={formData.description} />
              </Fragment>
              : <AvField
                name="description"
                defaultValue={formData.description}
                label={t('DeviceDescription')}
                placeholder=""
                type="text"
                errorMessage="Enter Device Type Description"
                validate={{ required: { value: false } }}
              />}
          </div>
          <div className="mb-4">
            <Label htmlFor="example-search-input" className="form-Label first-letter-upper">
              {t('device type image')}
              <span>({t("optional")})</span>
            </Label>
            <Form>
              <Dropzone
                disabled={pageRole=="viewer"}
                accept = {['.png', '.gif', '.jpeg', '.jpg']}
                onDrop={acceptedFiles => {
                  handleAcceptedFiles(acceptedFiles);
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <div className="dropzone">
                    <div
                      className="dz-message needsclick mt-2"
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} />
                      <div className="mb-3">
                        <i className="display-4 text-muted bx bxs-cloud-upload" />
                      </div>
                      <h4 className="first-letter-upper">{t("drop files here or click to upload.")}</h4>
                    </div>
                  </div>
                )}
              </Dropzone>
              <div className="dropzone-previews mt-3" id="file-previews">
                {selectedFiles.map((f: any, i: number) => {
                  return (
                    <Card
                      className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                      key={i + "-file"}
                    >
                      <div className="p-2">
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              data-dz-thumbnail=""
                              height="80"
                              className="avatar-sm rounded bg-light"
                              alt={f.name}
                              src={f.preview||formData.picture_url}
                            />
                          </Col>
                          <Col>
                            <Link
                              to="#"
                              className="text-muted font-weight-bold"
                            >
                              {f.name}
                            </Link>
                            <p className="mb-0">
                              <strong>{f.formattedSize}</strong>
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  );
                })}
                {!selectedFiles.length && <Card
                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <img
                          data-dz-thumbnail=""
                          height="80"
                          className="avatar-sm rounded bg-light"
                          alt={formData.name}
                          src={formData.picture_url}
                        />
                      </Col>
                      <Col>
                        {formData.name}
                      </Col>
                    </Row>
                  </div>
                </Card>}
              </div>
            </Form>
          </div>

          <div style={{ marginTop: '40px', marginBottom: '40px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Link to={`/org/${orgId}/device-types`}>
              <button
                type="button"
                style={{ fontSize: '16px' }}
                className="btn btn-outline-light waves-effect"
              >
                {t('Cancel')}
              </button>
            </Link>
            <Button color="primary" type="submit">
              {t('Next')}
            </Button>
          </div>
        </AvForm>
      </Row>
    </React.Fragment>
  );
}

Basicinformation.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  next: PropTypes.func
};

export default withTranslation()(withRouter(Basicinformation));