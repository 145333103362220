import { memo } from 'react';
// Router
import { Link } from 'react-router-dom';
// Custom hook
import useSelectedMenu from './customHook/useSelectedMenu';
// Component
import IconContainer from './IconContainer';
import SidebarDetail from './SidebarDetail';
// CSS
import 'src/assets/scss/inproject/summarySidebar.scss';
// Util
import { options } from './optional';

export default memo(() => {
  const [selectedMenu] = useSelectedMenu();

  return (
    <div className="d-flex h-100">
      <div className="border-right cus-border-gray-200 px-12 py-3">
        <div
          className="summary-sidebar-content
          d-flex flex-column justify-content-between
          h-100">
          <header>
            <Link to="/">
              <div
                className="logo-container mb-5 rounded-circle
                text-center text-white">
                <i className="fas fa-cube font-size-16" />
              </div>
            </Link>
            {options.map(option => (
              <IconContainer
                key={option.name}
                name={option.name}
                selected={selectedMenu == option.name}>
                {option.trigger}
              </IconContainer>
            ))}
          </header>
        </div>
      </div>
      <SidebarDetail />
    </div>
  );
});
