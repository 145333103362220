import { useCallback, useState } from 'react';

type UseMouseOverVisible = [boolean, () => void, () => void];

const useMouseOverVisible: () => UseMouseOverVisible = () => {
  const [visible, setVisible] = useState(false);

  const handleMouseOver = useCallback(() => {
    setVisible(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setVisible(false);
  }, []);

  return [visible, handleMouseOver, handleMouseLeave];
};

export default useMouseOverVisible;
