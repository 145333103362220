export enum MODE {
    GUIDE,
    SELECT_INPUT_TYPE,
    SELECT_DEVICES,
    SELECT_TAGS,
}

export enum INPUT_TYPE {
    DEVICE,
    TAG,
}

export const TYPE2MODE = {
    [INPUT_TYPE.DEVICE]: MODE.SELECT_DEVICES,
    [INPUT_TYPE.TAG]: MODE.SELECT_TAGS,
}
