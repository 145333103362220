import { ProvidersTypes } from "./actionTypes";

export const getEvents = () => ({
  type: ProvidersTypes.GET_EVENTS,
});

export const apiSuccess = (actionType : any, data : any) => ({
  type: ProvidersTypes.API_SUCCESS,
  payload: { actionType, data },
});

export const apiFail = (actionType : any, error : any) => ({
  type: ProvidersTypes.API_FAIL,
  payload: { actionType, error },
});

export const addNewEvent = (event : any) => ({
  type: ProvidersTypes.ADD_NEW_EVENT,
  payload: event,
});

export const updateEvent = (event : any) => ({
  type: ProvidersTypes.UPDATE_EVENT,
  payload: event,
});

export const updateEventSuccess = (event : any) => ({
  type: ProvidersTypes.UPDATE_EVENT_SUCCESS,
  payload: event,
});

export const updateEventFail = (error : any) => ({
  type: ProvidersTypes.UPDATE_EVENT_FAIL,
  payload: error,
});

export const deleteEvent = (event : any) => ({
  type: ProvidersTypes.DELETE_EVENT,
  payload: event,
});

export const deleteEventSuccess = (event : any) => ({
  type: ProvidersTypes.DELETE_EVENT_SUCCESS,
  payload: event,
});

export const deleteEventFail = (error : any) => ({
  type: ProvidersTypes.DELETE_EVENT_FAIL,
  payload: error,
});

export const getCategories = () => ({
  type: ProvidersTypes.GET_CATEGORIES,
});

export const getCategoriesSuccess = (categories : any) => ({
  type: ProvidersTypes.GET_CATEGORIES_SUCCESS,
  payload: categories,
});

export const getCategoriesFail = (error : any) => ({
  type: ProvidersTypes.GET_CATEGORIES_FAIL,
  payload: error,
});

export const getProviders = () => ({
  type: ProvidersTypes.GET_Providers,
});

export const getProvidersSuccess = (providers : any) => ({
  type: ProvidersTypes.GET_Providers_SUCCESS,
  payload: providers,
});

export const getProvidersFail = (error : any) => ({
  type: ProvidersTypes.GET_Providers_FAIL,
  payload: error,
});

export const getProviderById = (id:string) => ({
  type: ProvidersTypes.GET_ProviderByID,
  payload: id
});

export const getProviderByIdSuccess = (providers : any) => ({
  type: ProvidersTypes.GET_ProviderByID_SUCCESS,
  payload: providers,
});

export const getProviderByIdFail = (error : any) => ({
  type: ProvidersTypes.GET_ProviderByID_FAIL,
  payload: error,
});
