import { useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { RuleContext } from "../../../../reducer";
import DataSource from './dataSource';
import CustomDataSource from './customDataSource';
import Condition from './condition';
import CustomLogic from './customLogic';

export default () => {
    const { t } = useTranslation();
    const { state: { logicState }, dispatch } = useContext(RuleContext)
    let { logic_blocks, data_sources, type } = logicState;
    const dataSourcesID = `dataSource::${String(Date.now())}`;
    const handAddDataSource = () => {
        let dataSource = {
            id: dataSourcesID,
            key: "",
            topic: "",
            input_ids: [],
            last_sample: true,
            trigger_on_uplink: false,
            value_type: "NUMERICAL",
            variable_name: null
        };
        const action = {
            type: "ADD_DATA_SOURCE",
            payload: dataSource,
        }
        dispatch(action)
    }

    const handAddLogicBlock = () => {
        let logic_block = {
            id: `logicBlock::${String(Date.now())}`,
            data_source_id: data_sources[0].id,
            action_ids: [],
            conditions: [{
                chainOperator: null,
                operator: "EQ",
                type: "CONSTANT",
                value: 0
            }]
        };
        const action = {
            type: "ADD_LOGIC_BLOCK",
            payload: logic_block,
        }
        dispatch(action)
    }
    const handRemoveDataSource = (id: string) => {
        const action = {
            type: "CLEAR_DATA_SOURCE",
            payload: id
        }
        dispatch(action)
    }
    const handlerRemoveLogicBlock = (id: string) => {
        const action = {
            type: "CLEAR_LOGIC_BLOCK",
            payload: id
        }
        dispatch(action)
    }

    return (<>
        {
            type === "CUSTOM_LOGIC" ? <div>
                <div className="py-2">
                    {
                        data_sources?.map((data_source: any, index: number) => {
                            let inputsLength = 0;
                            data_sources.forEach((o: any) => {
                                inputsLength += o.input_ids.length;
                            });
                            return <CustomDataSource
                                key={data_source.id}
                                change={`${data_sources.length}_${logic_blocks.length}_${inputsLength}`}
                                index={index + 1}
                                onClear={handRemoveDataSource}
                                data={data_source}
                            />
                        }
                        )
                    }
                </div>
                <CustomLogic />
            </div> : <div>
                <div className="py-2">
                    {
                        data_sources?.map((data_source: any, index: number) => {
                            let conditionLength = 0;
                            logic_blocks.forEach((o: any) => {
                                conditionLength += o.conditions.length;
                            });
                            let inputsLength = 0;
                            data_sources.forEach((o: any) => {
                                inputsLength += o.input_ids.length;
                            });
                            let change = `${data_sources.length}_${logic_blocks.length}_${conditionLength}_${inputsLength}`;
                            return <DataSource
                                key={data_source.id}
                                change={change}
                                index={index + 1}
                                onClear={handRemoveDataSource}
                                data={data_source}
                            />
                        })
                    }
                    <Button className="border-dashed py-0 mt-3" color="primary" size="sm" outline onClick={handAddDataSource}>
                        {t("add input")}
                    </Button>
                </div>
                <div className="py-2">
                    {
                        logic_blocks?.map((logic_block: any, index: number) => {
                            let conditionLength = 0;
                            logic_blocks.forEach((o: any) => {
                                conditionLength += o.conditions.length;
                            });
                            let inputsLength = 0;
                            data_sources.forEach((o: any) => {
                                inputsLength += o.input_ids.length;
                            });
                            let change = `${data_sources.length}_${logic_blocks.length}_${conditionLength}_${inputsLength}`;
                            return <Condition
                                key={index.toString()}
                                onClear={handlerRemoveLogicBlock}
                                change={change}
                                index={index + 1}
                                data={logic_block}
                            />
                        })
                    }
                    <Button className="border-dashed py-0 mt-3" color="primary" size="sm" outline onClick={handAddLogicBlock}>
                        {t("add condition")}
                    </Button>
                </div>
            </div>
        }
    </>)
}
