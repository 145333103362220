import { State } from "../reducer";
interface ILineItem {
  id: string;
  x: number;
  y: number;
  v: number;
  h: number;
  c: number;
  d: number;
  e: number;
}
interface IPoint {
  left: number,
  top: number,
}
interface ILine {
  id?: string; // ID is optional (use of ? operator)
  lineStart: IPoint; // Name is Required
  lineEnd: IPoint;
}
const SvgLine: React.FC<State> = (state) => {
  const { logicState: { data_sources, logic_blocks, custom_logic, type, name },
    input, actions,  showInputLine, showActionLine } = state;


  const arr: Array<ILineItem> = [];

  const lines: Array<ILine> = [];
  const customLogic: any = custom_logic;
  // 计算input 跟 datasource 连线
  showInputLine && data_sources?.forEach((dataSource: any) => {
    if (dataSource.input_ids.length) {
      dataSource.input_ids.forEach((inputId: string) => {
        let find: any = input.inputs.find((o) => o.id == inputId);
        if (find) {
          lines.push({
            id: dataSource.id + find.id,
            lineStart: { left: find.offsetX, top: find.offsetY },
            lineEnd: { left: dataSource.offsetX, top: dataSource.offsetY },
          });
        }
      });
    }
  });

  // 计算condition 跟 action 连线
  showActionLine && logic_blocks?.forEach((logic_block: any) => {
    if (logic_block.action_ids.length) {
      logic_block.action_ids.forEach((action_id: string) => {
        let find: any = actions.find((o) => o.id == action_id);
        if (find) {
          lines.push({
            id: logic_block.id + find.id,
            lineStart: { left: logic_block.offsetX, top: logic_block.offsetY },
            lineEnd: { left: find.offsetX, top: find.offsetY },
          });
        }
      });
    }
  });

  // 计算自定义逻辑块 跟 action 连线
  showActionLine && customLogic?.action_ids?.forEach((action_id: string) => {
    let find: any = actions.find((o) => o.id == action_id);
    if (find) {
      lines.push({
        id: customLogic.id + find.id,
        lineStart: { left: customLogic.offsetX, top: customLogic.offsetY },
        lineEnd: { left: find.offsetX, top: find.offsetY },
      });
    }
  });

  // 计算连线参数
  lines.forEach((line: any) => {
    let { id, lineStart, lineEnd } = line;
    let x = lineStart.left - 298;
    let y = lineStart.top + 4;
    let v = lineEnd.top - lineStart.top;
    let h = (lineEnd.left - lineStart.left - 10) / 2;
    let c = 5;
    let d = 0;
    let e = 1;
    if (v > 0) {
      v = v - 10;
      d = 1;
      e = 0;
      c = 5;
    } else {
      v = v + 10;
      d = 0;
      e = 1;
      c = -5;
    }
    arr.push({ id, x, y, v, h, c, d, e })
  });
  return (
    <>
      {
        arr.map(({ id, x, y, v, h, c, d, e }: any) => <svg key={id} _ngcontent-djw-c457="" akenza-rule-connection-lines="" _nghost-djw-c408="" style={{ opacity: 1, width: "100%", height: "100%", position: "fixed", top: 0, bottom: 0, zIndex: 999, pointerEvents: "none" }}>
          <path d={`M${x} ${y} h${h} a5,5 0 0 ${d} 5,${c} v${v} a5,5 0 0 ${e} 5,${c} h${h}`} fill="none" id="point::L4I5IMXQQXLPNFCP2GQ@point::L4I5J4G8JZNQAAQPYQ" opacity="1" stroke="#1890ff"></path>
        </svg>)
      }
    </>
  );
}

export default SvgLine;