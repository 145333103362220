export const CHART_COLOR = [
  "#ee716e",
  "#ffac80",
  "#FDE68A",
  "#49aa42",
  "#A7F3D0",
  "#aed2e6",
  "#A5B4FC",
  "#EC4899",
  "#7F1D1D",
  "#8d5d76",
  "#047857",
  "#2563EB",
  "#7C3AED",
]
