import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { isEmpty } from "lodash";

import {
    Button,
    Card,
    CardBody, CardHeader, CardSubtitle, CardTitle,
    Col,
    Container, Input, Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row, Table,
} from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";

/** Import Full Calendar  */
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";

//import images
// import calendar from "../../assets/images/undraw-calendar.svg";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { useTranslation } from "react-i18next";

import {
    addNewEvent as onAddNewEvent,
    deleteEvent as onDeleteEvent,
    getCategories as onGetCategories,
    getEvents as onGetEvents,
    updateEvent as onUpdateEvent,
} from "../../store/actions";

// import DeleteModal from "./DeleteModal";

//css
import "@fullcalendar/bootstrap/main.css";

//redux
import { useSelector, useDispatch } from "react-redux";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
    SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";

interface OrganizeProps {
    className: string;
}
// TODO: 函数头
const IotAssets = ({ className }: OrganizeProps) => {
    const { t } = useTranslation();

    const columns = [
        {
            dataField: "Organize",
            text: t("userTable_Organize"),
            sort: true,
        },
        {
            dataField: "App",
            text: t("userTable_App"),
            sort: true,
        },
        {
            dataField: "Name",
            text: t("userTable_Name"),
            sort: true,
        },
        {
            dataField: "CreateTime",
            text: t("userTable_CreateTime"),
            sort: false,
        },
        {
            dataField: "displayName",
            text: t("userTable_displayName"),
            sort: true,
        },
        {
            dataField: "Portrait",
            text: t("userTable_Portrait"),
            sort: true,
        },{
            dataField: "Mail",
            text: t("userTable_Mail"),
            sort: true,
        },{
            dataField: "Phone",
            text: t("userTable_Phone"),
            sort: false,
        },{
            dataField: "Inc",
            text: t("Inc"),
            sort: true,
        },
        {
            dataField: "CountryRegion",
            text: t("CountryRegin"),
            sort: false,
        },
        {
            dataField: "Tag",
            text: t("userTable_Tag"),
            sort: false,
        },
        {
            dataField: "Manager",
            text: t("userTable_Manager"),
            sort: false,
        },
        {
            dataField: "GM",
            text: t("userTable_GM"),
            sort: false,
        },
        {
            dataField: "Denied",
            text: t("userTable_Denied"),
            sort: false,
        },
        {
            dataField: "Deleted",
            text: t("userTable_Deleted"),
            sort: false,
        },
        {
            dataField: "Operator",
            text: t("userTable_Operator"),
            sort: false,
        },
    ];

    function returnButton(): any{
        return (
            <div className="d-flex flex-wrap gap-2">
                <button
                    type="button"
                    className="btn btn-outline-primary waves-effect waves-light"
                >
                    {t("OP_Edit")}
                </button>
                <button
                    type="button"
                    className="btn btn-outline-danger waves-effect waves-light"
                >
                    {t("OP_Del")}
                </button>
            </div>);
    }

    function returnSwitch(ifChecked:boolean): any{
        return (
            <div className="d-flex flex-wrap gap-2">
                <Input type="checkbox" id="switch1" switch="none" checked={ifChecked} />
                <Label className="me-1" htmlFor="switch1" data-on-label="On" data-off-label="Off"></Label>
            </div>);
    }

    // Table Data
    const productData = [
        {
            Name: "Airi Satou",
            CreateTime: "Accountant",
            displayName: "Tokyo",
            Icon: "",
            WebAddr: "2008/11/28",
            Deleted: returnSwitch(true),
            Operator: returnButton()
        },
        {
            Name: "Biri Satou",
            CreateTime: "Bccountant",
            displayName: "Cokyo",
            Icon: "",
            WebAddr: "2009/11/28",
            Deleted: returnSwitch(false),
            Operator: returnButton()
        }
    ];

    const pageOptions: any = {
        sizePerPage: 10,
        totalSize: productData.length, // replace later with size(customers),
        custom: true,
    };

    const { SearchBar } = Search;

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{t("Assets")}</title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumbs title={t("Dashboard")} breadcrumbItem={t("Assets")} />
                    <Card>
                        <CardBody>
                            <PaginationProvider
                                pagination={paginationFactory(pageOptions)}
                                // columns={columns}
                                // data={productData}
                            >
                                {({ paginationProps, paginationTableProps }) => (
                                    <ToolkitProvider
                                        keyField="id"
                                        columns={columns}
                                        data={productData}
                                        search
                                    >
                                        {toolkitProps => (
                                            <React.Fragment>
                                                <Row className="mb-2">
                                                    <Col md="3">
                                                        <div className="search-box me-2 mb-2 d-inline-block">
                                                            <div className="position-relative">
                                                                <SearchBar {...toolkitProps.searchProps} />
                                                                <i className="bx bx-search-alt search-icon" />
                                                            </div>

                                                        </div>
                                                    </Col>
                                                </Row>
                                                <div className="d-flex flex-wrap gap-2">
                                                    <button
                                                        type="button"
                                                        className="btn btn-success"
                                                    >
                                                        {t("OP_AddData")}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-success"
                                                    >
                                                        {t("OP_Upload")}(.xlsx)
                                                    </button>
                                                </div>
                                                <Row>
                                                    <Col xl="12">
                                                        <div className="table-responsive">
                                                            <BootstrapTable
                                                                // responsive
                                                                bordered={false}
                                                                striped={false}
                                                                classes={"table align-middle table-nowrap"}
                                                                headerWrapperClasses={"thead-light"}
                                                                {...toolkitProps.baseProps}
                                                                {...paginationTableProps}
                                                            />

                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className="align-items-md-center mt-30">
                                                    <Col className="inner-custom-pagination d-flex">
                                                        <div className="d-inline">
                                                            <SizePerPageDropdownStandalone
                                                                {...paginationProps}
                                                            />
                                                        </div>
                                                        <div className="text-md-right ms-auto">
                                                            <PaginationListStandalone
                                                                {...paginationProps}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        )}
                                    </ToolkitProvider>
                                )}
                            </PaginationProvider>
                        </CardBody>
                    </Card>
                </Container>
            </div>

        </React.Fragment>
    );
};

export default IotAssets;
