import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
//i18n
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import ConfirmModal from "../../components/ConfirmModal";
import { Card, CardBody, Col, Row } from "reactstrap";
import { useSelector } from "react-redux";
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider from "react-bootstrap-table2-toolkit";
import Loading from "src/components/Loading";
import {
  getIotDeviceConnectorList, deleteIotDeviceConnector
} from "src/helpers/iot_backend_helper";

const DeviceConnectorsTable = (props: any) => {
  let { t, match } = props;
  let { wsId } = match.params;
  const [list, setList] = useState([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentRow, setCurrentRow] = useState<any>(null);
  const [loading, setLoading]: [boolean, Function] = useState(true);

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);

  const { user } = useSelector((state: any) => ({
    user: state.login.user
  }));
  const role = user.user_workspaces.find((ws : any)=>ws.workspace.id == wsId)?.role || "viewer"

  const fetchData = (newPageSize?:number, newPageNumber?:number, order?:string) => {
    getIotDeviceConnectorList({
      workspace_id: wsId,
      page_number: newPageNumber||pageNumber,
      page_size: newPageSize||pageSize,
      order,
    }, null).then((result: any) => {
      if (result.Code == 200 && result.Data && result.Data.length) {
        let data = result.Data.map((row: any) => ({
          id: row.id,
          name: row.name,
          description: row.description,
          connectivity: row.connectivity,
        }))
        setTotal(result.Total)
        setList(data);
      }
    }).finally(() => setLoading(false));
  }
  useEffect(() => {
    fetchData(10,1)
  }, []);

  // 删除指定设备连接器
  const confirmRemove = () => {
    deleteIotDeviceConnector(currentRow.id, null).then(result => {
      if (result && result.Code == 200) {

        let data = list.filter((item: any) => item.id != currentRow.id);
        setList(data);
        setIsOpen(false);
      }
    }).catch(e => {
      alert(e.response?.data?.message)
      return
    });
  }

  const handleTableSort = (field:string, order:string) => {
    fetchData(pageSize,pageNumber,`${field}=${order}`)
  }

  const columns = [
    {
      dataField: "name",
      text: props.t('Name'),
      sort: true,
      onSort:handleTableSort,
    },
    {
      dataField: "description",
      text: props.t('Description'),
      sort: true,
      onSort:handleTableSort,
    },
    {
      dataField: "connectivity",
      text: props.t('Connectivity'),
      sort: true,
      onSort:handleTableSort,
    },
    {
      dataField: "Action",
      text: props.t('Action'),
      formatter: (value: any, row: any, index: any) => (
        <div className="d-flex flex-wrap gap-2">
          <button
            disabled={role != "admin"}
            onClick={() => {
              setCurrentRow(row);
              setIsOpen(true)
            }}
            type="button"
            className="btn btn-outline-danger waves-effect waves-light"
          >
            {t("Delete")}
          </button>
        </div>
      )
    },
  ];

  const defaultSorted: any = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const pageOptions: any = {
    page: pageNumber,
    sizePerPage: pageSize,
    totalSize: total, // replace later with size(customers),
    custom: true,
  };

  const handleTableChange = async (type: string, { page, sizePerPage }: any) => {
    if (type == "sort") {
      return
    }

    fetchData(sizePerPage, page);
    setPageNumber(page);
    setPageSize(sizePerPage);
  }

  const confirmModalProps = {
    isOpen: isOpen,
    hideModal: () => setIsOpen(false),
    title: 'Do you really want to delete this Output Connector?',
    description: 'This action cannot be undone',
    confirmText: 'Delete',
    confirm: confirmRemove
  }

  return (
    <React.Fragment>
      {loading && <Loading loading={loading} />}
      <div className="container-fluid">
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                // columns={columns}
                // data={productData}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      columns={columns}
                      data={list}
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2">
                            <Col md="8" style={{ display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center' }}>
                              <div>
                                {t("_Showing")} 1 - 1 {t("_of")} 1 {t("_assets")}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  // responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  classes={"table align-middle table-nowrap"}
                                  headerWrapperClasses={"thead-light"}
                                  remote
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                  onTableChange={handleTableChange}
                                />
                              </div>
                            </Col>
                          </Row>

                          <Row className="align-items-md-center mt-30">
                            <Col className="inner-custom-pagination d-flex">
                              <div className="d-inline">
                                <SizePerPageDropdownStandalone
                                  {...paginationProps}
                                />
                              </div>
                              <div className="text-md-right ms-auto">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <ConfirmModal {...confirmModalProps} />
    </React.Fragment>
  );
}

DeviceConnectorsTable.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withTranslation()(withRouter(DeviceConnectorsTable));