export const timeRangeConf = [
  {
    value: 'relative time range',
    label: 'relative time range',
    header: true,
  },
  {
    value: 'r-hour',
    label: 'last 1h',
  },
  {
    value: 'r-day',
    label: 'last 24h',
  },
  {
    value: 'r-week',
    label: 'last 7 days',
  },
  {
    value: 'r-month',
    label: 'last 30 days',
  },
  {
    value: 'absolute time range',
    label: 'absolute time range',
    header: true,
  },
  {
    value: 'a-custom',
    label: 'custom',
  },
  // {
  //   value: "a-free",
  //   label: "free",
  // },
  // {
  //   value: "a-day",
  //   label: "day",
  // },
  // {
  //   value: "a-week",
  //   label: "week",
  // },
  // {
  //   value: "a-month",
  //   label: "month",
  // },
  // {
  //   value: "a-year",
  //   label: "year",
  // }
];
