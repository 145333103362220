/**
 * interface for Tokens type
 */
 export enum RecordsTypes {
    API_SUCCESS = '@@records/API_SUCCESS',
    API_FAIL = '@@records/API_FAIL',
    GET_EVENTS = '@@records/GET_EVENTS',
    ADD_NEW_EVENT = '@@records/ADD_NEW_EVENT',
    UPDATE_EVENT = '@@records/UPDATE_EVENT',
    UPDATE_EVENT_SUCCESS = '@@records/UPDATE_EVENT_SUCCESS',
    UPDATE_EVENT_FAIL = '@@records/UPDATE_EVENT_FAIL',
    DELETE_EVENT = '@@records/DELETE_EVENT',
    DELETE_EVENT_SUCCESS = '@@records/DELETE_EVENT_SUCCESS',
    DELETE_EVENT_FAIL = '@@records/DELETE_EVENT_FAIL',
    GET_CATEGORIES = '@@records/GET_CATEGORIES',
    GET_CATEGORIES_SUCCESS = '@@records/GET_CATEGORIES_SUCCESS',
    GET_CATEGORIES_FAIL = '@@records/GET_CATEGORIES_FAIL',
    GET_RECORDS = '@@records/GET_RECORDS',
    GET_RECORDS_SUCCESS = '@@records/GET_RECORDS_SUCCESS',
    GET_RECORDS_FAIL = '@@records/GET_RECORDS_FAIL',
 }

 export interface RecordsState {
   events  : Array<any>;
   categories : Array<any>;
   error : Object;
}