import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
//i18n
import { withTranslation } from "react-i18next";
import { withRouter, Link } from "react-router-dom";
import {
  CardText, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane, Card, CardHeader, CardTitle,
  ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from "reactstrap";
import FileSaver from 'file-saver'
import classnames from "classnames";
import Tags from 'src/components/Common/Tags';
import ApiConfiguration from '../components/ApiConfiguration';
import MessageLog from './MessageLog';
import AddTagModal from "./AddTagModal";
import ConfirmModal from "../components/ConfirmModal"

import {
  getIotDevice, deleteIotDevice, deleteIotDeviceTag, exportIotCollectData, deleteIotDeviceData
} from "src/helpers/iot_backend_helper";
import DataOverview from "./DataOverview";
import dayjs from "dayjs";
import { useSelector } from 'react-redux';
type Field = {
  name: string;
  value: string;
}
const DeviceInfo = (props: any) => {
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const [dropdownOpen, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [deviceData, setDeviceData] = useState<any>({});
  const [loadding, setLoadding] = useState<boolean>(true);
  const [visiable, setVisiable] = useState<boolean>(false);
  const [target, setTarget] = useState<string>("");
  const [tags, setTags] = useState<any>([]);
  const [rules, setRules] = useState<any>([]);
  const [online, setOnline] = useState(false)
  const toggle = () => setOpen(!dropdownOpen);
  let { t, match, history } = props;
  const { orgId, wsId, id } = match.params;
  const { user:pageUser } = useSelector((state: any) => ({
    user: state.login.user
  }));
  const pageRole = (wsId ? pageUser.user_workspaces.find((ws : any)=>ws.workspace.id == wsId)?.role : pageUser.user_organizations.find((org : any)=>org.organization.id == orgId)?.role) || "viewer"
  const toggleCustom = (tab: any) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
  useEffect(() => {
    getIotDevice(id, null).then((result: any) => {
      if (result && result.Code == 200) {
        setDeviceData(result.Data);
        setTags(result.Data.tags || []);
        setRules(result.Data.rules || []);
        setLoadding(false);
        setOnline(result.Data.online == 1)
      } else {
        setLoadding(false);
      }
    }).catch(err => {
      setLoadding(false);
    });
  }, []);
  if (loadding) {
    return <div></div>
  }
  const togModal = () => {
    setVisiable(!visiable);
  }

  const hideModal = () => {
    setVisiable(false);
  }
  interface Itag {
    label: string;
    value: string;
  }

  const handleOk = (data: Itag[]) => {
    data.forEach((o) => {
      tags.push({ id: o.value, name: o.label })
    });
    setTags(tags);
    setVisiable(false);
  }

  const handleRemove = (id: string) => {
    deleteIotDeviceTag({ device_id: deviceData.id, tag_id: id }, null).then(result => {
      if (result && result.Code === 200) {
        let arr = tags.filter((tag: any) => {
          return tag.id != id
        });
        setTags(arr);
      }
    });
  }
  const addTagModalProps = {
    device: deviceData, visiable, togModal, hideModal, handleOk
  }

  const handlerExport = () => {
    // window.location.href = `/api/v1/iot-collect-data/export?device_id=${id}`
    exportIotCollectData({ device_id: id, page_size: 10000, page_number: 1 }, null).then(result => {
      let blob = new Blob([result], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      })
      FileSaver.saveAs(blob, deviceData.name)
    });
  }

  const confirmRemove = () => {
    deleteIotDevice(id, null).then(result => {
      if (result && result.Code == 200) {
        history.goBack()
      }
    });
  }

  const confirmRemoveData = () => {
    deleteIotDeviceData(id, null).then((result) => {
      if (result && result.Code == 200) {
        setIsOpen(false)
      }
    }).catch(err => {});
  }

  const confirmModalProps = {
    isOpen: isOpen,
    hideModal: () => setIsOpen(false),
    title: 'Do you really want to delete this Asset?',
    description: 'This action cannot be undone',
    confirmText: 'delete',
    confirm: target == "device"? confirmRemove : confirmRemoveData
  }

  return (
    <React.Fragment>
      <div className="px-4 py-4 flex-col">
        <div className="flex-row items-center justify-between px-3 mb-3">
          <Link to={wsId ? `/org/${orgId}/ws/${wsId}/assets` : `/org/${orgId}/assets`} className="flex-row items-center">
            <i className="dripicons-arrow-thin-left lh-1" style={{ fontSize: '20px', marginRight: '15px' }}/>
            <h5 className="m-0">{deviceData.name}</h5>
            <div className={`mx-2 fas fa-circle lh-1 ${online ? "text-success" : "text-danger"}`} />
            <div>{t("device")}</div>
          </Link>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Link className="card-link" style={{ marginRight: '20px', fontSize: '16px' }} to={{ pathname: `/org/${orgId}/ws/${wsId}/akenza-logs` }}>{t("Show logs")}</Link>
            <ButtonDropdown style={{ display: 'flex', fontSize: '16px', marginRight: '10px', alignItems: 'center', height: '38px' }} isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle caret style={{ background: '#fff', color: 'rgba(0,0,0,.85)', borderColor: '#d9d9d9' }} >
                <i className="bx bx-dots-vertical-rounded" style={{ marginRight: '5px' }} />
                {props.t('More')}
                <i className="bx bx-chevron-down" style={{ marginLeft: '5px' }} />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem disabled={pageRole=="viewer"} onClick={() => history.push(`/org/${orgId}/ws/${deviceData.workspace_id}/assets/${id}/edit`)}>{t('Edit')}</DropdownItem>
                <DropdownItem disabled={pageRole=="viewer"} onClick={handlerExport}>{t('Data Export')}</DropdownItem>
                <DropdownItem disabled={pageRole=="viewer"} onClick={() => {setIsOpen(true); setTarget("device")}}>{t('Delete')}</DropdownItem>
                <DropdownItem disabled={pageRole=="viewer"} onClick={() => {setIsOpen(true); setTarget("deviceData")}}>{t('Delete Data')}</DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
          </div>
        </div>
        <div className="row" style={{ padding: '10px 20px' }}>
          <div className="text-dark mb-4">{deviceData.description}</div>
          <Col sm={4} xs={8}>
            <div className="mb-1" style={{ display: 'flex', flexDirection: 'row' }} >
              <div style={{ color: '#8c8c8c' }}>{t("Last seen")}: </div>
              <div className="text-dark" style={{ marginLeft: '6px' }}>{deviceData.last_seen ? dayjs(deviceData.last_seen).format("YYYY-MM-DD HH:mm:ss") : t("no message received yet")}</div>
            </div>
          </Col>
          <Col sm={4} xs={8}>
            <div className="mb-1" style={{ display: 'flex', flexDirection: 'row' }} >
              <div style={{ color: '#8c8c8c' }}>Metavun ID: </div>
              <div className="text-dark" style={{ marginLeft: '6px' }}>{deviceData.id}</div>
            </div>
            <div className="mb-1" style={{ display: 'flex', flexDirection: 'row' }} >
              <div style={{ color: '#8c8c8c' }}>{t('Device ID')}: </div>
              <div className="text-dark" style={{ marginLeft: '6px' }}>{deviceData.device_id}</div>
            </div>
            <div className="mb-1" style={{ display: 'flex', flexDirection: 'row' }} >
              <div style={{ color: '#8c8c8c' }}>{t('Data Flow')}: </div>
              <Link to={wsId ? `/org/${orgId}/ws/${wsId}/data-flows/${deviceData.data_flow && deviceData.data_flow.id}` : `/org/${orgId}/data-flows/${deviceData.data_flow && deviceData.data_flow.id}`}
                style={{ marginLeft: '6px' }}>{deviceData.data_flow && deviceData.data_flow.name}</Link>
            </div>
          </Col>
          <Col sm={4} xs={8}>
            <div className="mb-1" style={{ display: 'flex', flexDirection: 'row' }} >
              <div style={{ color: '#8c8c8c' }}>{t('tags')}: </div>
              <div style={{ marginLeft: '10px', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                {
                  tags.map((tag: any) => <Tags title={tag.name} key={tag.id} id={tag.id} icon="bx bx-purchase-tag-alt" close={true} handleRemove={handleRemove} />)
                }
                <div className="cursor-pointer" onClick={(): void => setVisiable(true)}><Tags title={t("Add Tag")} icon="bx bx-plus" /></div>
              </div>
            </div>
          </Col>
        </div>
        <div style={{ padding: '30px' }}>
          <div style={{ width: '600px' }}>
            <Nav tabs className="nav-tabs-custom nav-justified">
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: customActiveTab === "1",
                  })}
                  onClick={() => {
                    toggleCustom("1");
                  }}
                >
                  <span className="d-block d-sm-none">
                    <i className="fas fa-home"></i>
                  </span>
                  <span className="d-none d-sm-block">{t('DataOverview')}</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: customActiveTab === "2",
                  })}
                  onClick={() => {
                    toggleCustom("2");
                  }}
                >
                  <span className="d-block d-sm-none">
                    <i className="far fa-user"></i>
                  </span>
                  <span className="d-none d-sm-block">{t('DeviceDetails')}</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: customActiveTab === "3",
                  })}
                  onClick={() => {
                    toggleCustom("3");
                  }}
                >
                  <span className="d-block d-sm-none">
                    <i className="far fa-envelope"></i>
                  </span>
                  <span className="d-none d-sm-block">{t('MessageLogs')}</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: customActiveTab === "4",
                  })}
                  onClick={() => {
                    toggleCustom("4");
                  }}
                >
                  <span className="d-block d-sm-none">
                    <i className="fas fa-cog"></i>
                  </span>
                  <span className="d-none d-sm-block">{t('APIConfiguration')}</span>
                </NavLink>
              </NavItem>
            </Nav>
          </div>
          <TabContent
            activeTab={customActiveTab}
            className="p-3 text-muted"
          >
            <TabPane tabId="1">
              <Row>
                <Col sm="12">
                  <CardText className="mb-0">
                    <DataOverview />
                  </CardText>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row>
                <Col sm="12">
                  <div>
                    <CardTitle className="h4 mt-4 mb-4">{t("General")}</CardTitle>
                    <Col sm={4} xs={8}>
                      <div className="mb-1" style={{ display: 'flex', flexDirection: 'row' }} >
                        <div style={{ color: '#8c8c8c' }}>{t("Device Connector")}: </div>
                        <div className="text-dark" style={{ marginLeft: '6px' }}>{deviceData.data_flow?.device_connector?.connectivity} {t("Device Connector")}</div>
                      </div>
                      <div className="mb-1" style={{ display: 'flex', flexDirection: 'row' }} >
                        <div style={{ color: '#8c8c8c' }}>{t("Lifecycle notifications")}: </div>
                        <div className="text-dark" style={{ marginLeft: '6px' }}>{deviceData.lifecycle === 1 ? "开启" : "关闭"}</div>
                      </div>
                      <div className="mb-1" style={{ display: 'flex', flexDirection: 'row' }} >
                        <div style={{ color: '#8c8c8c' }}>{t("Offline Timeout")}: </div>
                        <div className="text-dark" style={{ marginLeft: '6px' }}>{deviceData.online_timeout}</div>
                      </div>
                    </Col>
                  </div>
                  {
                    deviceData.custom_fields?.fields.length && <div>
                      <CardTitle className="h4 mt-4 mb-4">{t("Custom Fields")}</CardTitle>
                      <Col sm={4} xs={8}>
                        {
                          deviceData.custom_fields.fields.map((field: Field) => <div className="mb-1" style={{ display: 'flex', flexDirection: 'row' }} >
                            <div style={{ color: '#8c8c8c' }}>{field.name}: </div>
                            <div className="text-dark" style={{ marginLeft: '6px' }}>{field.value}</div>
                          </div>)
                        }
                      </Col>
                    </div>
                  }

                  <CardTitle className="h4 mt-4 mb-4">{t("Rules")}</CardTitle>
                  <Col xl={4}>
                    <Card>
                      <CardHeader>
                        <span className="text-dark">
                          {t((rules && rules.length) ? "This device is used in the following rules" : "This device is not being used in any rules")}:
                        </span>
                      </CardHeader>
                      <div>
                        {
                          rules.map((rule: any) => <div style={{ display: 'flex', alignItems: 'center', borderBottom: "1px solid #f0f0f0", padding: "12px 16px" }}>
                            <i className="bx bx-link" style={{ color: "#7a7fdc", margin: "0 5px" }}></i> <Link to={`/org/${orgId}/ws/${rule.workspace_id}/rules/${rule.id}`} style={{ fontSize: '16px' }}>{rule.name}</Link>
                          </div>)
                        }
                        <div style={{ display: 'flex', alignItems: 'center', padding: "12px 16px" }}>
                          + <Link to={`/org/${orgId}/ws/${deviceData.workspace_id}/rules/new?device_id=${id}`} style={{ fontSize: '16px' }}>{t("Create rule with this device")}</Link>
                        </div>
                      </div>
                    </Card>
                  </Col>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="3">
              <MessageLog device_id={deviceData.id} />
            </TabPane>
            <TabPane tabId="4">
              <Row>
                <Col sm="4">
                  <ApiConfiguration data={deviceData} />
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </div>
      </div>
      {visiable && <AddTagModal {...addTagModalProps} />}
      {isOpen && <ConfirmModal {...confirmModalProps} />}
    </React.Fragment>
  );
}

DeviceInfo.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withTranslation()(withRouter(DeviceInfo));