import { useContext, useState } from 'react';
// Api
import {
    createIotOutputConnector,
    updateIotOutputConnector,
} from 'src/helpers/iot_backend_helper';
// Redux
import { DataFlowContext } from '../../reducer';
import { ACTION_TYPE } from '../../contant';
// Component
import OutputConnectorType from './OutputConnectorType';
import OutputConnectorSelected from './OutputConnectorSelected';

const OutputConnector = () => {
    const [add, setAdd] = useState<boolean>(false);
    const [connector, setConnector] = useState<any>(null);
    const {
        state: { outputConnectors },
        dispatch
    } = useContext(DataFlowContext);
    const [mode, setMode] = useState<string>('TYPE');

    const onAddHandle = () => {
        setAdd(true);
        setMode('TYPE');
    }

    const cancelAdd = () => {
        setAdd(false)
    }

    const onEdit = (data: any) => {
        setAdd(true);
        setConnector(data)
        setMode('ADD')
    }

    const onClear = () => {
        setAdd(false);
        setMode('TYPE')
    }

    // 更新action
    const handleUpdateAction = (id: string, data: any) => {
        updateIotOutputConnector(id, data, null).then(result => {
            if (result.Code == 200) {
                const action = {
                    type: ACTION_TYPE.UPDATE_OUTPUT_CONNECTOR,
                    payload: { id, ...data }
                }
                dispatch(action);
                setAdd(false);
            }
        });
    }
    // 保存action
    const handleSaveAction = (data: any) => {
        data.scope = "DATA_FLOW";
        createIotOutputConnector(data, null).then((result: any) => {
            if (result && result.Code == 200) {
                const action = {
                    type: ACTION_TYPE.PUSH_OUTPUT_CONNECTOR,
                    payload: result.Data
                }
                dispatch(action);
                setAdd(false);
            }
        });
    }
    const cancelSelect = () => {
        setConnector(null);
    }
    const connectorTypeProps = {
        isAdd: add,
        connector: connector,
        page: mode,
        onSave: handleSaveAction,
        onUpdate: handleUpdateAction,
        cancelAdd: cancelAdd,
        cancelSelect: cancelSelect
    }
    return (
      <div className="d-flex flex-column px-4 py-4 h-100">
          <main className="flex-grow-1 overflow-auto">
              {
                  outputConnectors && outputConnectors.length && !add ?
                    <OutputConnectorSelected onClear={onClear} onEdit={onEdit}
                                             onAddHandle={onAddHandle} /> :
                    <OutputConnectorType {...connectorTypeProps} />
              }
          </main>
      </div>
    )
}

export default OutputConnector
