import React, { useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
//i18n
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import { Col, Row, Input, Label, Modal } from "reactstrap";

const DeviceModal = (props: any) => {
  const [fieldName, setFieldName] = useState<any>(null);
  const [fieldType, setFieldType] = useState<any>(null);
  let {
    t, visiable, togModal, hideModal, save
  } = props;
  const submit = () => {
    let data = {
      name: fieldName,
      type: fieldType.value,
      value: ""
    }
    save(data);
  }

  const onChange = (e: any) => {
    let value = e.target.value;
    setFieldName(value)
  }
  const optionGroup = [
    { label: "Number", value: "number" },
    { label: "Text", value: "text" },
    { label: "Date", value: "date" }
  ];
  return (
    <React.Fragment>
      <Modal
        isOpen={visiable}
        toggle={() => {
          togModal();
        }}
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">{t('AddCustomField')}</h5>
          <button
            type="button"
            onClick={() => {
              hideModal();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Col sm={12}>
              <div>
                <div className="mb-3">
                  <Label htmlFor="choices-single-default" className="form-Label font-size-13 text-muted">{t('FieldName')}</Label>
                  <Input className="form-control" onChange={onChange} type="text" defaultValue="" id="example-text-input" />
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="choices-single-default"
                    className="form-label font-size-13 text-muted"
                  >
                    {t('FieldType')}
                  </label>
                  <Select
                    value={fieldType}
                    onChange={setFieldType}
                    options={optionGroup}
                    placeholder={t('select field type')}
                    classNamePrefix="select2-selection"
                  />
                  {/* <Label htmlFor="example-search-input" className="form-Label">{t('FieldType')}</Label>
                  <select className="form-select" onChange={onSelectChange}>
                    <option value='Number'>Number</option>
                    <option value='Text'>Text</option>
                    <option value='Date'>Date</option>
                    <option value='JSON'>JSON</option>
                    <option value='GPS'>GPS coordinates</option>
                  </select> */}
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-primary" onClick={submit}>
            {t('Save')}
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
            onClick={() => {
              hideModal();
            }}
          >
            {t('Close')}
          </button>
        </div>
      </Modal>
    </React.Fragment>
  );
}

DeviceModal.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  visiable: PropTypes.bool,
  togModal: PropTypes.func,
  hideModal: PropTypes.func,
  save: PropTypes.func
};
export default withTranslation()(withRouter(DeviceModal));