import { Key, useState } from 'react';
// Component
import Dots from './Dots';
import NewOrganization, {
  FormInputs as OrganizationData,
} from './NewOrganization';
import NewWorkspace, { FormInputs as WorkspaceData } from './NewWorkspace';

type props = {
  initialStep: number;
  onNew?: Function;
};

export default function ({ initialStep, onNew }: props) {
  const [step, setStep] = useState(initialStep);

  const [organizationData, setOrganizationData] = useState<
    OrganizationData & { size: Key; industry: Key }
  >();
  const [f, setF] = useState<File>();
  const onCreateOrganization = (data: any, f: File) => {
    setF(f);
    setOrganizationData(data);
    setStep(1);
  };

  const [workspaceData, setWorkspaceData] = useState<WorkspaceData>();
  const onCreateWorkspace = (data: any) => {
    setWorkspaceData(data);
    const t1 = {
      ...organizationData,
    };
    const t2 = {
      ...data,
    };
    onNew && onNew(t1, t2, f);
  };

  const step2component = [
    <NewOrganization {...organizationData} onCreate={onCreateOrganization} />,
    <NewWorkspace {...workspaceData} onCreate={onCreateWorkspace} />,
  ];

  return (
    <div className="cus-w-4">
      <Dots className="mb-4" tgt={step} opts={[0, 1]} />
      {step2component[step]}
    </div>
  );
}
