import React from "react";

import {useTranslation} from "react-i18next";

type Props = {
    children: React.ReactNode;
    onClick: any;
    defaultValue: string;
}

export default ({children, onClick, defaultValue = ""}: Partial<Props>) => {
    const {t} = useTranslation()

    return (<React.StrictMode>
        <div className="d-flex justify-content-center align-items-center h-100">
            <div>
                <header className="d-flex justify-content-center mb-5">
                    <button
                        className="btn btn-outline-light font-size-16"
                        onClick={() => onClick && onClick(defaultValue)}
                    >
                        <i className="fas fa-plus me-2 font-size-14 text-secondary"/>
                        <span className="text-capitalize">{t("create new Data Flow")}</span>
                    </button>
                </header>
                <div className="mb-5 text-center">{t("or use a template")}</div>
                <main>
                    {children}
                </main>
            </div>
        </div>
    </React.StrictMode>)
}
