export default {
  routes: [
    {
      groupName: '',
      menus: [
        {
          path: '/org/:orgId/overview',
          description: 'Overview',
          iconClassName: 'bx bx-customize',
        },
        {
          path: '/org/:orgId/analysis',
          description: 'Analysis',
          iconClassName: 'bx bx-bar-chart-square',
        },
        {
          path: '/org/:orgId/workspace',
          description: 'workspace',
          iconClassName: 'bx bx-conversation',
        },
        {
          path: '/org/:orgId/assets',
          description: 'Assets',
          iconClassName: 'bx bx-list-ol',
        },
      ],
    },
    {
      groupName: 'Custom components',
      menus: [
        {
          path: '/org/:orgId/logic-blocks',
          description: 'Logic Blocks',
          iconClassName: 'bx bx-hive',
        },
        {
          path: '/org/:orgId/device-types',
          description: 'Device Types',
          iconClassName: 'bx bx-magnet',
        },
      ],
    },
    {
      groupName: 'Settings',
      menus: [
        {
          path: '/org/:orgId/general',
          description: 'General',
          iconClassName: 'bx bx-cog',
        },
        // {
        //     path: "/org/:orgId/api-keys",
        //     description: "API Keys",
        //     iconClassName: "bx bx-key"
        // },
        {
          path: '/org/:orgId/users',
          description: 'Users',
          iconClassName: 'bx bx-user',
        },
        // {
        //     path: "/org/:orgId/subscription",
        //     description: "Subscription",
        //     iconClassName: "bx bx-chalkboard"
        // }
      ],
      // }, {
      //     groupName: 'Others',
      //     menus: [{
      //         path: "/icons-boxicons",
      //         description: "boxicons",
      //         iconClassName: "fas fa-icons"
      //     },
      //     {
      //         path: "/icons-materialdesign",
      //         description: "materialdesign",
      //         iconClassName: "fas fa-icons"
      //     },
      //     {
      //         path: "/icons-dripicons",
      //         description: "dripicons",
      //         iconClassName: "fas fa-icons"
      //     },
      //     {
      //         path: "/icons-fontawesome",
      //         description: "fontawesome",
      //         iconClassName: "fas fa-icons"
      //     },
      //     {
      //         path: "/form-elements",
      //         description: "FormElements",
      //         iconClassName: "fas fa-icons"
      //     },
      //     {
      //         path: "/form-validation",
      //         description: "FormValidation",
      //         iconClassName: "fas fa-icons"
      //     },
      //     {
      //         path: "/form-advanced",
      //         description: "AdvancedPlugins",
      //         iconClassName: "fas fa-icons"
      //     },
      //     {
      //         path: "/form-editors",
      //         description: "FormEditors",
      //         iconClassName: "fas fa-icons"
      //     },
      //     {
      //         path: "/form-uploads",
      //         description: "FormUpload",
      //         iconClassName: "fas fa-icons"
      //     },
      //     {
      //         path: "/form-wizard",
      //         description: "FormWizard",
      //         iconClassName: "fas fa-icons"
      //     },
      //     {
      //         path: "/form-mask",
      //         description: "FormMask",
      //         iconClassName: "fas fa-icons"
      //     },
      //     {
      //         path: "/tables-basic",
      //         description: "BasicTable",
      //         iconClassName: "fas fa-icons"
      //     },
      //     {
      //         path: "/tables-datatable",
      //         description: "DatatableTables",
      //         iconClassName: "fas fa-icons"
      //     },
      //     {
      //         path: "/tables-responsive",
      //         description: "ResponsiveTables",
      //         iconClassName: "fas fa-icons"
      //     },
      //     {
      //         path: "/tables-editable",
      //         description: "EditableTables",
      //         iconClassName: "fas fa-icons"
      //     },        {
      //         path: "/iot-insights",
      //         description: "iot-insights",
      //         iconClassName: "fas fa-chart-bar"
      //     },
      //     {
      //         path: "/iot-assets",
      //         description: "iot-assets",
      //         iconClassName: "fas fa-atom"
      //     },
      //     {
      //         path: "/iot-rules",
      //         description: "iot-rules",
      //         iconClassName: "fas fa-clipboard-list"
      //     },
      //     {
      //         path: "/ui-alerts",
      //         description: "ui-alerts",
      //         iconClassName: "fas fa-address-book"
      //     },
      //     {
      //         path: "/ui-buttons",
      //         description: "ui-buttons",
      //         iconClassName: "fas fa-address-card"
      //     },
      //     {
      //         path: "/ui-cards",
      //         description: "ui-cards",
      //         iconClassName: "fas fa-adjust"
      //     },
      //     {
      //         path: "/ui-colors",
      //         description: "ui-colors",
      //         iconClassName: "fas fa-adjust"
      //     },
      //     {
      //         path: "/ui-carousel",
      //         description: "ui-carousel",
      //         iconClassName: "fas fa-air-freshener"
      //     },
      //     {
      //         path: "/ui-dropdowns",
      //         description: "ui-dropdowns",
      //         iconClassName: "fas fa-align-center"
      //     },
      //     {
      //         path: "/ui-grid",
      //         description: "ui-grid",
      //         iconClassName: "fas fa-allergies"
      //     },
      //     {
      //         path: "/ui-images",
      //         description: "ui-images",
      //         iconClassName: "fas fa-ambulance"
      //     },
      //     {
      //         path: "/ui-modals",
      //         description: "ui-modals",
      //         iconClassName: "fas fa-anchor"
      //     },
      //     {
      //         path: "/ui-drawer",
      //         description: "ui-drawer",
      //         iconClassName: "fas fa-angry"
      //     }]
    },
  ],
};
