import React, { useState } from 'react';
//i18n
import { withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';

import RulesTable from './RulesTable';
import ActionsTable from './ActionsTable';

export default withTranslation()(
  withRouter((props: any) => {
    let { t, match } = props;
    const { orgId, wsId } = match.params;
    const [activeTab, setActiveTab] = useState(1);
    const { user } = useSelector((state: any) => ({
      user: state.login.user,
    }));

    const role =
      (wsId
        ? user.user_workspaces.find((ws: any) => ws.workspace.id == wsId)?.role
        : user.user_organizations.find(
            (org: any) => org.organization.id == orgId,
          )?.role) || 'viewer';

    const handleTableChange = (tab: number) => {
      setActiveTab(tab);
    };
    return (
      <React.StrictMode>
        <div className="px-5 py-4">
          <header className="d-flex justify-content-between mb-4">
            <h4 className="lh-lg">{t('Rules')}</h4>
            {role != 'viewer' ? (
              <Link to={`/org/${orgId}/ws/${wsId}/rules/new`}>
                <Button className="fw-bold" color="primary">
                  {t('Create Data Rule')}
                </Button>
              </Link>
            ) : (
              <Button disabled className="fw-bold" color="primary">
                {t('Create Data Rule')}
              </Button>
            )}
          </header>
          <nav
            className="flex-row mb-5 pb-2 border-bottom justify-between"
            style={{ width: '200px' }}>
            <span
              className={`me-3 pb-2 font-size-16 cursor-pointer ${
                activeTab == 1 && 'activeTab'
              }`}
              onClick={() => handleTableChange(1)}>
              {t('Overview')}
            </span>
            <span
              className={`me-3 pb-2 font-size-16 cursor-pointer ${
                activeTab == 2 && 'activeTab'
              }`}
              onClick={() => handleTableChange(2)}>
              {t('Actions')}
            </span>
          </nav>
          <main>
            {activeTab == 1 && <RulesTable />}
            {activeTab == 2 && <ActionsTable />}
          </main>
        </div>
      </React.StrictMode>
    );
  }),
);
