import { ACTION_TYPE } from "./contant";
import React, { Dispatch } from "react";

export type State = {
    deviceConnector: string;
    deviceType: string;
    outputConnectors: string[]
}

export type Action = {
    type: string;
    payload: any;
}

const defaultState = {
    deviceConnector: "",
    deviceType: "",
    outputConnectors: [],
}

export const init = (initialState: Partial<State>): State => ({
    ...defaultState,
    ...initialState,
})

export const reducer = (state: State, action: Partial<Action>) => {
    switch (action.type) {
        case ACTION_TYPE.SELECT_TEMPLATE:
            return {
                ...state,
                ...action.payload,
            }
        case ACTION_TYPE.SELECT_DEVICE_CONNECTOR:
            return {
                ...state,
                deviceConnector: action.payload,
            }
        case ACTION_TYPE.CLEAR_DEVICE_CONNECTOR:
            return {
                ...state,
                deviceConnector: defaultState.deviceConnector,
            }
        case ACTION_TYPE.SELECT_DEVICE_TYPE:
            return {
                ...state,
                deviceType: action.payload,
            }
        case ACTION_TYPE.CLEAR_DEVICE_TYPE:
            return {
                ...state,
                deviceType: defaultState.deviceType,
            }
        case ACTION_TYPE.PUSH_OUTPUT_CONNECTOR:
            return {
                ...state,
                outputConnectors: [...state.outputConnectors, action.payload],
            }
        case ACTION_TYPE.REMOVE_OUTPUT_CONNECTOR:
            return {
                ...state,
                outputConnectors: state.outputConnectors.filter((connector: any) => connector.id != action.payload),
            }
        case ACTION_TYPE.CLEAR_OUTPUT_CONNECTORS:
            return {
                ...state,
                outputConnectors: defaultState.outputConnectors,
            }
        case ACTION_TYPE.PUSH_DATA_FLOW:
            return {
                ...state,
                deviceConnector: action.payload.deviceConnector,
                deviceType: action.payload.deviceType,
                outputConnectors: action.payload.outputConnectors,
            }
        case ACTION_TYPE.UPDATE_OUTPUT_CONNECTOR:
            console.log(action.payload);
            console.log(state.outputConnectors)
            state.outputConnectors = state.outputConnectors.map((item:any, index) => {
                if (item.id == action.payload.id) {
                    item = { ...item, ...action.payload }
                }
                return item;
            });
            return {
                ...state
            }
        default:
            return state
    }
}

type Context = {
    state: State,
    dispatch: Dispatch<Partial<Action>>
}

export const DataFlowContext = React.createContext<Context>({
    state: defaultState,
    dispatch(value: Partial<Action>): void {
    },
})
