import React, { useState } from "react";
import PropTypes from "prop-types";
//i18n
import { withTranslation } from "react-i18next";
import { withRouter, Link } from "react-router-dom";
import Select from "react-select";
import { Col, Row, Input, Label, Modal, Form, Card } from "reactstrap";
import Dropzone from "react-dropzone";

const BulkImportModal = (props: any) => {
  let {
    t, visiable, togModal, hideModal, save
  } = props;
  const [formData, setFormData] = useState<any>({});
  const [dataFlow, setDataFlow] = useState<string>('String');
  const [tab, setTab] = useState<any>('tabOne');
  const [selectedFiles, setselectedFiles] = useState<any>([]);

  const submit = () => {
    save(formData);
  }

  const onChange = (key: string, e: any) => {
    let value = e.target.value;
    formData[key] = value;
    setFormData(formData);
  }

  const optionGroup = [
    { label: "Flow1", value: "Flow1" },
    { label: "Flow2", value: "Flow2" },
    { label: "Flow3", value: "Flow3" }
  ];
  function handleAcceptedFiles(files: any) {
    files.map((file: any) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
  }
  /**
  * Formats the size
  */
  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  
  return (
    <React.Fragment>
      <Modal
        isOpen={visiable}
        toggle={() => {
          togModal();
        }}
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">{t('Bulk import')}</h5>
          <button
            type="button"
            onClick={() => {
              hideModal();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Col sm={12}>
              <div>
                <div className="mb-3">
                  <Label htmlFor="choices-single-default" className="form-Label font-size-13 text-muted flex-row">
                    {t('Data processing')}
                    <div className="mt-1">
                      <i className="dripicons-information" style={{ marginLeft: '6px', fontSize: '15px', color: '#1890ff' }}></i>
                    </div>
                  </Label>
                  <div
                    className="btn-group btn-group-lg"
                    role="group"
                    aria-label="Basic example"
                  >
                    <button type="button" className={tab == "tabOne" ? "btn btn-primary" : "btn btn-outline-light"} onClick={() => setTab('tabOne')}>
                      Data Flow
                    </button>
                    <button type="button" className={tab == "tabTwo" ? "btn btn-primary" : "btn btn-outline-light"} onClick={() => setTab('tabTwo')}>
                      Connectivity mgmt only
                    </button>
                  </div>
                </div>
                <div className="mb-3">
                  <Label htmlFor="choices-single-default" className="form-Label font-size-13 text-muted">{t('Data Flows ')}</Label>
                  <Select
                    value={dataFlow}
                    onChange={setDataFlow}
                    options={optionGroup}
                    placeholder='Select Parameter Type'
                    classNamePrefix="select2-selection"
                  />
                </div>
                <div className="mb-3">
                  <Label htmlFor="example-search-input" className="form-Label">{props.t('Upload File')}</Label>
                  <div className="mb-3">Unsure about the format? Check out our documentation page.</div>
                  <Form>
                    <Dropzone
                      onDrop={acceptedFiles => {
                        handleAcceptedFiles(acceptedFiles);
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                          <div
                            className="dz-message needsclick mt-2"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="mb-3">
                              <i className="display-4 text-muted bx bxs-cloud-upload" />
                            </div>
                            <h4>Drop files here or click to upload.</h4>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div className="dropzone-previews mt-3" id="file-previews">
                      {selectedFiles.map((f: any, i: number) => {
                        return (
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt={f.name}
                                    src={f.preview}
                                  />
                                </Col>
                                <Col>
                                  <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                  >
                                    {f.name}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        );
                      })}
                    </div>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="modal-footer" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <button
            type="button"
            style={{ fontSize: '16px' }}
            onClick={hideModal}
            className="btn btn-outline-light waves-effect"
          >
            {props.t('Cancel')}
          </button>
          <button type="button" className="btn btn-primary" onClick={submit}>
            {t('Add Input')}
          </button>
        </div>
      </Modal>
    </React.Fragment>
  );
}

BulkImportModal.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  visiable: PropTypes.bool,
  togModal: PropTypes.func,
  hideModal: PropTypes.func,
  save: PropTypes.func
};
export default withTranslation()(withRouter(BulkImportModal));