import React, {useEffect, useState} from "react";

import Guide from "./Guide";
import DataFlowsList from "./DataFlowsList";

//i18n
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";

import {
    getIotDataFlowList
} from "src/helpers/iot_backend_helper";

export default withTranslation()(withRouter((props: any) => {
    let {t, history, match} = props;

    const {orgId, wsId} = match.params;
    const [visible, setVisible] = useState(false);
    const guideProps = {
        name: "data flow",
        description: "with rules you can analyze and process data of one or multiple devices and trigger actions when needed. Actions include sending notifications, downlinks or forwarding data to third-party systems.",
        guidePath: `/org/${orgId}/ws/${wsId}/data-flows`,
        buildPath: `/org/${orgId}/ws/${wsId}/data-flows/new`
    }
    // 检查是否有data flow 数据,如果没有的话先到指引页面
    useEffect(() => {
        getIotDataFlowList({workspace_id: wsId}, null).then((result: any) => {
            if (result.Code == 200 && result.Data && result.Data.length) {
                setVisible(true);
            }
        })
    }, []);
    return (<React.StrictMode>
        <div className="w-100 h-100">
            <Guide {...guideProps} hidden={visible}/>
            <DataFlowsList hidden={!visible}/>
        </div>
    </React.StrictMode>);
}))
