import { CSSProperties, FC, Key, useEffect, useState } from 'react';
// Component
import { DndContext } from '@dnd-kit/core';
import { arrayMove, SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

interface Identifiable {
  id: Key;

  [k: string]: any;
}

// 拖拽项组件
type ItemProps = {
  uid: Key;
  item?: Identifiable;
  container?: FC<any>;
  transmission?: object;
};

const Item: FC<ItemProps> = ({
  uid,
  item,
  container: Comp,
  transmission,
}: ItemProps) => {
  const { setNodeRef, listeners, transform } = useSortable({ id: uid });
  const styles: CSSProperties = {
    transform: CSS.Transform.toString(transform),
  };

  return (
    <div className="p-0 pointer-event" ref={setNodeRef} style={styles}>
      {Comp && <Comp item={item} {...transmission} listeners={listeners} />}
    </div>
  );
};

type props = {
  items: Identifiable[];
  container?: FC<any>;
  transmission?: object;
  onDragEnd?: (identifications: Key[]) => void;
};

// 容器组件
export default function ({ items, onDragEnd, container, transmission }: props) {
  const [identifications, setIdentification] = useState<Key[]>([]);
  useEffect(() => {
    const t = items.map(n => n.id);
    setIdentification(t);
  }, [items.length]);

  function dragEndEvent(props: any) {
    const { active, over } = props;
    if (!active || !over) return;
    const activeIndex = identifications.indexOf(active.id);
    const overIndex = identifications.indexOf(over.id);
    const orders = arrayMove(identifications, activeIndex, overIndex);
    setIdentification(orders);
    onDragEnd && onDragEnd(orders);
  }

  return (
    <DndContext onDragEnd={dragEndEvent}>
      <SortableContext items={identifications}>
        {identifications.map(n => (
          <Item
            key={n}
            uid={n}
            container={container}
            item={items.find(el => el.id == n)}
            transmission={transmission}
          />
        ))}
      </SortableContext>
    </DndContext>
  );
}
