import React, { useState } from "react";
import PropTypes from "prop-types";
//i18n
import { withTranslation } from "react-i18next";
import { withRouter, Link } from "react-router-dom";

import { Card, CardBody, Col, Row } from "reactstrap";
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
const Integrations = (props: any) => {
  const [visiable, setVisiable] = useState(false);
  const columns = [
    {
      dataField: "Name",
      text: props.t('Name'),
      sort: true,
    },
    {
      dataField: "Description",
      text: props.t('Description'),
      sort: true,
    },
    {
      dataField: "CreationDate",
      text: props.t('CreationDate'),
      sort: true,
    },
    {
      dataField: "ModificationDate",
      text: props.t('ModificationDate'),
      sort: true,
    },
    {
      dataField: "Action",
      text: props.t('Action'),
      sort: true,
    },
  ];
  let returnButton = () => {
    return (
      <div className="d-flex flex-wrap gap-2">
        <Link to={{ pathname: '/#' }} className="btn btn-outline-warning waves-effect waves-light">
          {props.t("Edit")}
        </Link>
        <button
          type="button"
          className="btn btn-outline-danger waves-effect waves-light"
        >
          {props.t("Delete")}
        </button>
      </div>);
  }
  // Table Data
  const productData = [
    {
      Name: "Moyun",
      Description: "Moyun",
      CreationDate: "2008/11/28",
      ModificationDate: "2008/11/28",
      Action: returnButton(),
    }
  ];

  const defaultSorted: any = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const pageOptions: any = {
    sizePerPage: 10,
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
  };

  // Select All Button operation
  const selectRow: any = {
    mode: "checkbox",
  };

  const { SearchBar } = Search;
  function togModal() {
    setVisiable(!visiable);
  }
  return (
    <React.Fragment>
      <div className="px-4 py-4 flex-col">
        <div className="flex-row items-center justify-between px-2 mb-4">
          <div className="text-dark font-size-20" style={{ fontWeight: 700 }}>{props.t('OrganizationAssetInventory')}</div>
        </div>
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  // columns={columns}
                  // data={productData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={productData}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} placeholder={props.t('SearchWorkspacelist')} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    // responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    // selectRow={selectRow}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

Integrations.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withTranslation()(withRouter(Integrations));