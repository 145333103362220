import React, { Fragment, Key, ReactNode } from 'react';
// Router
import { useHistory } from 'react-router-dom';
// i18n
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector } from 'react-redux';
// Custom hook
import useMouseOverVisible from '../customHook/useMouseOverVisible';
import useSelectedMenu from '../customHook/useSelectedMenu';

type props = {
  children: ReactNode;
  name: Key;
  selected: boolean;
};

export default ({ children, name, selected }: props) => {
  name = name.toString();

  const { t } = useTranslation();
  const h = useHistory();

  const [, setSelectedMenu] = useSelectedMenu(name);
  const [visible, handleMouseOver, handleMouseLeave] = useMouseOverVisible();

  const { user } = useSelector((state: any) => ({ user: state.login.user }));

  const { curr_org, curr_workspace } = user;

  const onClick = () => {
    switch (name) {
      case 'organization':
        h.push(`/org/${curr_org.id}/overview`);
        break;
      case 'workspace':
        h.push(`/org/${curr_org.id}/ws/${curr_workspace.id}/overview`);
        break;
    }

    setSelectedMenu();
  };
  return (
    <Fragment>
      <div
        className={`icon-container position-relative
          mb-1 rounded-3  bg-gray-light-hover
          text-center ${selected && 'selected'}`}
        onClick={onClick}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}>
        {children}
        <span
          hidden={!visible}
          className="triangle-lc-4 position-absolute top-0 start-100
            ms-1 px-1 rounded-2
            text-center font-size-14 fw-bold whitespace-nowrap
            bg-white z-9 shadow-lg">
          {t(name)}
        </span>
      </div>
    </Fragment>
  );
};
