/**
 * interface for userinfo type
 */
 export enum UserTypes {
    API_SUCCESS = '@@userinfo/API_SUCCESS',
    API_FAIL = '@@userinfo/API_FAIL',
    GET_EVENTS = '@@userinfo/GET_EVENTS',
    ADD_NEW_EVENT = '@@userinfo/ADD_NEW_EVENT',
    UPDATE_EVENT = '@@userinfo/UPDATE_EVENT',
    UPDATE_EVENT_SUCCESS = '@@userinfo/UPDATE_EVENT_SUCCESS',
    UPDATE_EVENT_FAIL = '@@userinfo/UPDATE_EVENT_FAIL',
    DELETE_EVENT = '@@userinfo/DELETE_EVENT',
    DELETE_EVENT_SUCCESS = '@@userinfo/DELETE_EVENT_SUCCESS',
    DELETE_EVENT_FAIL = '@@userinfo/DELETE_EVENT_FAIL',
    GET_CATEGORIES = '@@userinfo/GET_CATEGORIES',
    GET_CATEGORIES_SUCCESS = '@@userinfo/GET_CATEGORIES_SUCCESS',
    GET_CATEGORIES_FAIL = '@@userinfo/GET_CATEGORIES_FAIL',
    GET_USERINFO = '@@userinfo/GET_USERINFO',
    GET_USERINFO_SUCCESS = '@@userinfo/GET_USERINFO_SUCCESS',
    GET_USERINFO_FAIL = '@@userinfo/GET_USERINFO_FAIL',
    GET_GLOBALUSERS = '@@userinfo/GET_GLOBALUSERS',
    GET_GLOBALUSERS_SUCCESS = '@@userinfo/GET_GLOBALUSERS_SUCCESS',
    GET_GLOBALUSERS_FAIL = '@@userinfo/GET_GLOBALUSERS_FAIL',
 }

 export interface UserState {
   events  : Array<any>;
   categories : Array<any>;
   error : Object;
}