import { useEffect, useRef } from 'react';
// Map
import { TracePlotL7 } from 'src/util/genL7';
import { LineLayer } from '@antv/l7';
import { GaodeMap } from '@antv/l7-maps';
// Util
import { Recycler } from 'src/util';

export default function () {
  const recycler = new Recycler();

  const mapBox = useRef(null);
  const mapScene = useRef<TracePlotL7>();

  useEffect(() => {
    if (!mapBox.current) {
      return;
    }

    mapScene.current = new TracePlotL7(mapBox.current, {
      logoVisible: false,
      map: new GaodeMap({
        style: 'dark',
        center: [103.83735, 1.3602538],
        pitch: 2,
      }),
    });

    if (!mapScene.current) {
      return;
    }

    recycler.register(mapScene.current);

    fetch(
      'https://gw.alipayobjects.com/os/basement_prod/ee07641d-5490-4768-9826-25862e8019e1.json',
    )
      .then(res => res.json())
      .then(data => {
        const layer = new LineLayer({})
          .source(data, {
            parser: {
              type: 'json',
              coordinates: 'path',
            },
          })
          .size('level', level => {
            return [1, level * 0.1];
          })
          .shape('line')
          .color('level', [
            '#312B60',
            '#4A457E',
            '#615C99',
            '#816CAD',
            '#A67FB5',
            '#C997C7',
            '#DEB8D4',
            '#F5D4E6',
            '#FAE4F1',
            '#FFF3FC',
          ]);

        mapScene.current?.on('loaded', () => {
          mapScene.current?.addLayer(layer);
        });
      });

    return function () {
      recycler.drop();
    };
  }, []);

  return <div className="position-relative vw-100 vh-100" ref={mapBox} />;
}
