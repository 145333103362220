import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { isEmpty } from "lodash";

import {
    Badge,
    Button,
    Card,
    CardBody, CardHeader, CardSubtitle, CardTitle,
    Col,
    Container, Form, Input, Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row, Table,
} from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";

/** Import Full Calendar  */
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";

//import images


//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import {
    getPermissions as onGetPermissions
} from "../../../store/apps/Authority/actions";

//i18n
import { useTranslation } from "react-i18next";

import {
    addNewEvent as onAddNewEvent,
    deleteEvent as onDeleteEvent,
    getCategories as onGetCategories,
    getEvents as onGetEvents,
    updateEvent as onUpdateEvent,
} from "../../../store/actions";

// import DeleteModal from "./DeleteModal";

//css
import "@fullcalendar/bootstrap/main.css";

//redux
import { useSelector, useDispatch } from "react-redux";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import paginationFactory, {
    PaginationListStandalone, PaginationProvider,
    SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import {Link} from "react-router-dom";
import {useLocation} from "react-router";

interface TestRegistPageProps {
    className: string;
}
// TODO: 函数头
const TestRegistPage = ({ className }: TestRegistPageProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { state } = useLocation();

    const localState:any = state;

    return (
        <React.Fragment>
            <div>
                <Card>
                    <CardBody>
                        <Form>
                            <Row className="mb-4">
                                <Label
                                    htmlFor="trp-username"
                                    className="col-sm-2 col-form-label"
                                >
                                    {t("UserName")}
                                </Label>
                                <Col sm={9}>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        id="trp-username"
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Label
                                    htmlFor="trp-displayname"
                                    className="col-sm-2 col-form-label"
                                >
                                    {t("th_displayName")}
                                </Label>
                                <Col sm={9}>
                                    <Input
                                        type="email"
                                        className="form-control"
                                        id="trp-displayname"
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Label
                                    htmlFor="trp-password"
                                    className="col-sm-2 col-form-label"
                                >
                                    {t("th_Password")}
                                </Label>
                                <Col sm={9}>
                                    <Input
                                        type="password"
                                        className="form-control"
                                        id="trp-password"
                                    />
                                </Col>
                            </Row>

                            <Row className="mb-4">
                                <Label
                                    htmlFor="passwordconfirm"
                                    className="col-sm-2 col-form-label"
                                >
                                    {t("PasswordConfirm")}
                                </Label>
                                <Col sm={9}>
                                    <Input
                                        type="password"
                                        className="form-control"
                                        id="passwordconfirm"
                                    />
                                </Col>
                            </Row>

                            <Row className="mb-4">
                                <Label
                                    htmlFor="email"
                                    className="col-sm-2 col-form-label"
                                >
                                    {t("Email")}
                                </Label>
                                <Col sm={9}>
                                    <Input
                                        type="password"
                                        className="form-control"
                                        id="email"
                                    />
                                </Col>
                            </Row>

                            <Row className="mb-4">
                                <Label
                                    htmlFor="emailchaptcha"
                                    className="col-sm-2 col-form-label"
                                >
                                    {t("EmailCHAPTCHA")}
                                </Label>
                                <Col sm={9}>
                                    <Input
                                        type="password"
                                        className="form-control"
                                        id="emailchaptcha"
                                    />
                                </Col>
                            </Row>

                            <Row className="mb-4">
                                <Label
                                    htmlFor="phone"
                                    className="col-sm-2 col-form-label"
                                >
                                    {t("th_Phone")}
                                </Label>
                                <Col sm={9}>
                                    <Input
                                        type="password"
                                        className="form-control"
                                        id="phone"
                                    />
                                </Col>
                            </Row>

                            <Row className="mb-4">
                                <Label
                                    htmlFor="phonechaptcha"
                                    className="col-sm-2 col-form-label"
                                >
                                    {t("PhoneCHAPTCHA")}
                                </Label>
                                <Col sm={9}>
                                    <Input
                                        type="password"
                                        className="form-control"
                                        id="phonechaptcha"
                                    />
                                </Col>
                            </Row>

                            <Row className="justify-content-end">
                                <Col sm={9}>
                                    <div className="form-check mb-4">
                                        <Input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="readandaccept"
                                        />
                                        <Label
                                            className="form-check-label"
                                            htmlFor="readandaccept"
                                        >
                                            {t("ReadAndAccept")}
                                            <Link to="/#">
                                                《{t("UserAgreement")}》
                                            </Link>
                                        </Label>
                                    </div>

                                    <div>
                                        <button type="submit" className="btn btn-primary w-md">
                                            {t("Register")}
                                        </button>
                                        <span style={{marginLeft:"10px"}}>
                                            {t("HasAccount")}
                                            <Link to="/#">
                                                 {t("LoginImidiatly")}
                                            </Link>
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </CardBody>
                </Card>
            </div>
        </React.Fragment>
    );
};

export default TestRegistPage;
