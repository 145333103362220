import React, { Fragment, Key, useEffect, useState } from 'react';
// Router
import { useRouteMatch } from 'react-router-dom';
// i18n
import { useTranslation } from 'react-i18next';
// Component
import { props as KPIProps } from './Charts/DeviceDataKPIs/Cage';
import DeviceDataKPIs from './Charts/DeviceDataKPIs';
import DeviceDataHistory from './Charts/DeviceDataHistory';
import Loading from 'src/components/Loading';
// Chart
import echarts from 'echarts';
import { CHART_COLOR } from 'src/util';
// Api
import { fetchKPIsData } from './api';
// CSS
import privateClass from './DataOverview.module.scss';
// 会修改原对象
const generateColor = (list?: KPIProps[]) => {
  if (!list) return;
  let n = 0;
  for (const el of list) {
    if (el.data?.length && el.data.length > 0) {
      el.color = CHART_COLOR[n];
      n++;
    }
  }
};

/**
 * 收集字段
 * @r: 原数组
 * @f: 筛选字段
 * @ff: 保留字段
 */
const collectField = (r: any[], f: string, ff: string) => {
  const t = r.filter(n => n[f]);
  return t.map(n => n[ff]);
};

export default function () {
  const { t } = useTranslation();

  const { id }: any = useRouteMatch().params;
  const [KPIsData, setKPIsData] = useState<any[]>();
  const [supplement, setSupplement] = useState<echarts.EChartOption>();
  const [loading, setLoading] = useState<boolean>(true);
  const [fields, setFields] = useState<Key[]>();

  useEffect(() => {
    (async function () {
      const [res, err] = await fetchKPIsData(id);
      setLoading(false);
      if (err) {
        return;
      }
      generateColor(res?.Data);
      setKPIsData(res?.Data);
      setSupplement(res?.supplement);

      setFields(collectField(res.Data, 'chart', 'name'));
    })();
  }, []);

  return (
    <Fragment>
      {loading && <Loading loading={loading} />}
      <header>
        <div className="my-3 font-size-16 fw-bold cus-text-black text-capitalize">
          {t('device data KPIs')}
        </div>
        <div>
          <DeviceDataKPIs
            chartClassName={privateClass[`device-data-kpi-chart`]}
            KPIs={KPIsData}
            supplement={supplement}
          />
          <div className="mt-sparse-pre">
            <div className="mb-3 font-size-16 fw-bold cus-text-black text-capitalize">
              {t('device data history')}
            </div>
            <DeviceDataHistory
              className={privateClass[`device-data-history-chart`]}
              idt={id}
              fields={fields}
            />
          </div>
        </div>
      </header>
    </Fragment>
  );
}
