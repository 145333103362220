import { CertsTypes } from "./actionTypes";

export const getEvents = () => ({
  type: CertsTypes.GET_EVENTS,
});

export const apiSuccess = (actionType : any, data : any) => ({
  type: CertsTypes.API_SUCCESS,
  payload: { actionType, data },
});

export const apiFail = (actionType : any, error : any) => ({
  type: CertsTypes.API_FAIL,
  payload: { actionType, error },
});

export const addNewEvent = (event : any) => ({
  type: CertsTypes.ADD_NEW_EVENT,
  payload: event,
});

export const updateEvent = (event : any) => ({
  type: CertsTypes.UPDATE_EVENT,
  payload: event,
});

export const updateEventSuccess = (event : any) => ({
  type: CertsTypes.UPDATE_EVENT_SUCCESS,
  payload: event,
});

export const updateEventFail = (error : any) => ({
  type: CertsTypes.UPDATE_EVENT_FAIL,
  payload: error,
});

export const deleteEvent = (event : any) => ({
  type: CertsTypes.DELETE_EVENT,
  payload: event,
});

export const deleteEventSuccess = (event : any) => ({
  type: CertsTypes.DELETE_EVENT_SUCCESS,
  payload: event,
});

export const deleteEventFail = (error : any) => ({
  type: CertsTypes.DELETE_EVENT_FAIL,
  payload: error,
});

export const getCategories = () => ({
  type: CertsTypes.GET_CATEGORIES,
});

export const getCategoriesSuccess = (categories : any) => ({
  type: CertsTypes.GET_CATEGORIES_SUCCESS,
  payload: categories,
});

export const getCategoriesFail = (error : any) => ({
  type: CertsTypes.GET_CATEGORIES_FAIL,
  payload: error,
});

export const getCerts = () => ({
  type: CertsTypes.GET_CERTS,
});

export const getCertsSuccess = (certs : any) => ({
  type: CertsTypes.GET_CERTS_SUCCESS,
  payload: certs,
});

export const getCertsFail = (error : any) => ({
  type: CertsTypes.GET_CERTS_FAIL,
  payload: error,
});

export const getCertById = (id:string) => ({
  type: CertsTypes.GET_CERTBYID,
  payload: id,
});

export const getCertByIdSuccess = (certs : any) => ({
  type: CertsTypes.GET_CERTBYID_SUCCESS,
  payload: certs,
});

export const getCertByIdFail = (error : any) => ({
  type: CertsTypes.GET_CERTBYID_FAIL,
  payload: error,
});
