import { LitElement, html, customElement } from 'lit-element';

@customElement('title-element')
class TitleElement extends LitElement {
    render() {
        return html`
      <div>Hello from LitElement!</div>
    `;
    }
}

export default TitleElement;
