import {Key, StrictMode, useState} from "react";
// i18n
import {useTranslation} from "react-i18next";
// Component
import {Button, Input, ModalBody, ModalFooter} from "reactstrap";
// util
import {insure, validateDescription, validateName} from "src/util";
import {useDropzone} from "react-dropzone";

type Props = {
  name: Key;
  changeName: Function;
  description: string;
  changeDescription: Function;
  logoFile: File;
  uploadLogoFile: Function;
  onCancel: Function;
  onNext: Function;
}

export default ({
                  name,
                  changeName,
                  description,
                  changeDescription,
                  uploadLogoFile,
                  onCancel,
                  onNext,
                }: Partial<Props>) => {
  const {t} = useTranslation()

  function handleNameChange(newName: string) {
    if (!validateName(newName)) return
    insure(changeName, newName)()
  }

  const [descriptionWords, setDescriptionWords] = useState(description?.length || 0)

  function handleDescriptionChange(newDescription: string) {
    if (!validateDescription(newDescription)) return

    setDescriptionWords(newDescription.length)
    insure(changeDescription, newDescription)()
  }

  const [files, setFiles] = useState<(File & { preview?: string })[]>([]);
  const {getRootProps, getInputProps} = useDropzone({
    accept: "image/*",
    maxSize: 2 << 20,
    onDrop: acceptedFiles => {
      const t = acceptedFiles.map(f => Object.assign(f, {
        formattedSize: `${f.size >> 10} KB`,
        preview: URL.createObjectURL(f)
      }))
      setFiles(t);
      uploadLogoFile && uploadLogoFile(t[0])
    }
  })

  return (<StrictMode>
    <ModalBody>
      <header className="mb-3 font-size-16 fw-bold text-capitalize">
        {t("new organization")}
      </header>
      <div className="mb-3">
        <div className="text-capitalize">{t("Name")}</div>
        <Input value={name} onChange={e => handleNameChange(e.target.value)}/>
      </div>
      <div className="mb-3">
        <span className="me-2 text-capitalize">{t("description")}</span>
        <span className="text-secondary">({t("optional")})</span>
        <Input value={description} onChange={e => handleDescriptionChange(e.target.value)}/>
        <div className="clearfix">
          <span className="float-end">{descriptionWords}/500</span>
        </div>
      </div>
      <div>
        <div className="mb-2">
          <span className="me-2 text-capitalize">{t("upload logo file")}</span>
          <span className="text-secondary">({t("optional")})</span>
        </div>
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <Button className="new-organization-upload-input
                d-flex justify-content-center align-items-center
                 mb-2 border-dashed border-blue-hover
                 w-100 bg-soft-light" outline>
            <div>
              <div hidden={!files?.length}>
                <img className="border rounded-1 cus-h-3" src={files?.[0]?.preview} alt="..."/>
              </div>
              <div hidden={Boolean(files.length)} className="font-size-20">
                <i className="fas text-info fa-plus"/>
              </div>
              <div className="text-secondary text-capitalize">
                {files?.[0]?.name || t("upload logo file")}
              </div>
            </div>
          </Button>
        </div>
      </div>
    </ModalBody>
    <ModalFooter className="justify-between">
      <Button className="text-capitalize" outline
              onClick={insure(onCancel)}>
        {t("cancel")}
      </Button>
      <Button disabled={!name} className="text-capitalize" color="primary" outline
              onClick={insure(onNext)}>
        {t("next")}
      </Button>
    </ModalFooter>
  </StrictMode>)
}
