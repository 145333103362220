import { RecordsTypes } from "./actionTypes";

export const getEvents = () => ({
  type: RecordsTypes.GET_EVENTS,
});

export const apiSuccess = (actionType : any, data : any) => ({
  type: RecordsTypes.API_SUCCESS,
  payload: { actionType, data },
});

export const apiFail = (actionType : any, error : any) => ({
  type: RecordsTypes.API_FAIL,
  payload: { actionType, error },
});

export const addNewEvent = (event : any) => ({
  type: RecordsTypes.ADD_NEW_EVENT,
  payload: event,
});

export const updateEvent = (event : any) => ({
  type: RecordsTypes.UPDATE_EVENT,
  payload: event,
});

export const updateEventSuccess = (event : any) => ({
  type: RecordsTypes.UPDATE_EVENT_SUCCESS,
  payload: event,
});

export const updateEventFail = (error : any) => ({
  type: RecordsTypes.UPDATE_EVENT_FAIL,
  payload: error,
});

export const deleteEvent = (event : any) => ({
  type: RecordsTypes.DELETE_EVENT,
  payload: event,
});

export const deleteEventSuccess = (event : any) => ({
  type: RecordsTypes.DELETE_EVENT_SUCCESS,
  payload: event,
});

export const deleteEventFail = (error : any) => ({
  type: RecordsTypes.DELETE_EVENT_FAIL,
  payload: error,
});

export const getCategories = () => ({
  type: RecordsTypes.GET_CATEGORIES,
});

export const getCategoriesSuccess = (categories : any) => ({
  type: RecordsTypes.GET_CATEGORIES_SUCCESS,
  payload: categories,
});

export const getCategoriesFail = (error : any) => ({
  type: RecordsTypes.GET_CATEGORIES_FAIL,
  payload: error,
});

export const getRecords = () => ({
  type: RecordsTypes.GET_RECORDS,
});

export const getRecordsSuccess = (records : any) => ({
  type: RecordsTypes.GET_RECORDS_SUCCESS,
  payload: records,
});

export const getRecordsFail = (error : any) => ({
  type: RecordsTypes.GET_RECORDS_FAIL,
  payload: error,
});
