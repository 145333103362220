import React, { useState, useContext } from "react";

import { useTranslation } from "react-i18next";

import { Button } from "reactstrap";

import TimeDrawer from "./TimeDrawer";
import { RuleContext } from "../reducer";
type Direction = "top" | "right" | "bottom" | "left"

const position = {
    bottom: 60, left: 60,
}

type Props = {
    location: boolean;
    timer: {
        enabled: boolean;
        schedule: { value: number, unit: string };
    };
    hidden: boolean;
}

export default ({ location = true, timer,hidden }: Props) => {
    const { t } = useTranslation()
    const { dispatch } = useContext(RuleContext)
    const [drawerVisible, setDrawerVisible] = useState(false)

    const showDrawer = () => {
        setDrawerVisible(true)
    }

    const hideDrawer = () => {
        setDrawerVisible(false);
    }
    const saveTimer = (value: number, unit: string) => {
        const action = {
            type: "UPDATE_TIMER",
            payload: {
                enabled: true,
                schedule: {
                    unit, value
                }
            }
        }
        dispatch(action);
        setDrawerVisible(false);
    }

    const removeTimer = () => {
        const action = {
            type: "UPDATE_TIMER",
            payload: {
                enabled: false,
                schedule: {
                    value: 0, unit: 'hours'
                }
            }
        }
        dispatch(action);
    }

    const editTimer = () => {
        showDrawer()
    }

    return (< div className="cus-bg-light" hidden={hidden}>
        {
            timer.enabled && !drawerVisible ? <div style={{ width: "100%", height: "57px", borderTop: "1px solid #e8e8e8", position: 'absolute', bottom: 0, backgroundColor: '#fff', display: "flex", alignItems: "center", paddingLeft: "15px" }}>
                <i className="far fa-clock px-2"></i>
                <span>Run rule every {timer.schedule.value} {timer.schedule.unit}</span>
                <div onClick={editTimer} className="text-primary px-4 cursor-pointer"> Edit timer</div>
                <div onClick={removeTimer} className="text-danger cursor-pointer"> Remove timer</div>
            </div> : <div>
                <Button style={position}
                    className={`font-size-16 z-1 shadow ${location && "position-absolute"}`}
                    color="primary" outline
                    onClick={showDrawer}
                    disabled={drawerVisible}>
                    <i className="fas fa-clock me-2" />
                    <span className="text-capitalize">{t("add timer")}</span>
                </Button>
                <TimeDrawer overlay={true} timer={timer} container={document.body} visible={drawerVisible}
                    onClose={hideDrawer} onCancel={hideDrawer}
                    onSave={saveTimer} onOverlayClick={hideDrawer} />
            </div>
        }
    </div>)
}
