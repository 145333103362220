import {useCallback} from "react";

import {useDispatch, useSelector} from "react-redux";
import {selectMenu} from "src/store/layout/actions";

type UseSelectedMenu = [any, () => void]
const useSelectedMenu: (selected?: string) => UseSelectedMenu = (selected) => {
    
    const {selectedMenu} = useSelector((state: any) => ({
        selectedMenu: state.Layout.selectedMenu,
    }))

    const dispatch = useDispatch()

    const setSelectedMenu = useCallback(() => {
        selected && dispatch(selectMenu(selected))
    }, [])

    return [selectedMenu, setSelectedMenu]
}

export default useSelectedMenu
