import React, {StrictMode} from "react";

import {Button, ModalBody, ModalFooter} from "reactstrap";

import {insure} from "src/util";

type Props = {
    onClose: Function;
    onCancel: Function;
}

export default ({onClose, onCancel}: Partial<Props>) => {
    return (<StrictMode>
        <div className="modal-header">
            <h5 className="modal-title text-capitalize">404</h5>
            <button type="button" className="btn-close" aria-label="Close"
                    onClick={insure(onClose)}/>
        </div>
        <ModalBody>
            <h1>cannot found related component, please check your code</h1>
        </ModalBody>
        <ModalFooter className="justify-between">
            <Button className="text-capitalize" outline
                    onClick={insure(onCancel)}>cancel</Button>
        </ModalFooter>
    </StrictMode>)
}
