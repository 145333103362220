import React, {Fragment, StrictMode, useState} from "react";
// i18n
import {useTranslation} from "react-i18next";
// redux
import {useDispatch} from "react-redux";
import {loginSuccess} from "src/store/auth/login/actions";
// Component
import {Button, ModalBody, ModalFooter} from "reactstrap";
import OrganizationContentProgress from "./OrganizationContentProgress";
import OrganizationCreatePanel from "./OrganizationCreatePanel";
import WorkspaceBody from "../WorkspaceContent/WorkspaceBody";
// util
import {awaitWrap, insure} from "src/util";
// API
import {changeWorkspace, createIotOrganization, createIotWorkspaces, uploadFile} from "src/helpers/iot_backend_helper";

type Props = {
  onClose: Function;
  onCancel: Function;
  onCreate: Function;
}

export default ({onClose, onCancel, onCreate}: Partial<Props>) => {
  const {t} = useTranslation()
  const dispatch = useDispatch();

  const [step, setStep] = useState(1)

  const handleNext = () => {
    setStep(2)
  }

  const [organizationName, setOrganizationName] = useState("")
  const [organizationDescription, setOrganizationDescription] = useState("")

  const [workspaceName, setWorkspaceName] = useState("")
  const [workspaceDescription, setWorkspaceDescription] = useState("")

  const [logoFile, setLogoFile] = useState<File>()

  const handleLocalStorage = (organizationData: any, workspaceData: any) => {
    let authUser: any = localStorage.getItem("authUser")
    if (!authUser) return
    authUser = JSON.parse(authUser)
    authUser.Data.curr_org_id = organizationData.id
    authUser.Data.curr_org = organizationData
    authUser.Data.curr_wspace_id = workspaceData.id
    authUser.Data.curr_workspace = workspaceData
    authUser.Data.workspaces.push(workspaceData)
    authUser.Data.organizations.push(organizationData)
    authUser.Data.user_organizations.push({
      id: organizationData.id,
      role: 'admin',
      organization: organizationData,
    });
    authUser.Data.user_workspaces.push({
      id: workspaceData.id,
      role: 'admin',
      workspace: workspaceData,
    });
    changeWorkspace({org_id: organizationData.id, workspace_id: workspaceData.id}, null);
    dispatch(loginSuccess({Data: authUser.Data}))
    localStorage.setItem("authUser", JSON.stringify(authUser))
  }

  const handleCreate = async () => {
    let logo: string | undefined = undefined
    if (logoFile) {
      const t = new FormData()
      t.append("file", logoFile)
      const [rf, ef] = await awaitWrap(uploadFile(t, null))
      if (ef) {
        alert(`上传组织徽标失败：${ef}`)
        return
      }
      logo = rf?.Data ? rf.Data?.host + rf.Data?.file : undefined
    }

    const newOrganizationData = {
      name: organizationName,
      description: organizationDescription,
      logo,
    }
    let [orgRes, orgErr] = await awaitWrap(createIotOrganization(newOrganizationData))
    if (orgErr) {
      return
    }
    if (orgRes.Code != 200) {
      return
    }

    const newWorkspaceData = {
      org_id: orgRes?.Data.id,
      name: workspaceName,
      description: workspaceDescription,
    };
    const [wsRes, wsErr] = await awaitWrap(createIotWorkspaces(newWorkspaceData, null))
    if (wsErr) {
      return
    }
    if (wsRes.Code != 200) {
      return
    }
    handleLocalStorage(orgRes.Data, wsRes.Data)
    onCreate?.()
  }

  return (<StrictMode>
    <div className="modal-header">
      <h5 className="modal-title text-capitalize">{t("create new organization")}</h5>
      <button type="button" className="btn-close" aria-label="Close"
              onClick={insure(onClose)}/>
    </div>
    <ModalBody className="border-bottom px-5">
      <OrganizationContentProgress step={step}/>
    </ModalBody>
    {step == 1 &&
        <OrganizationCreatePanel name={organizationName} changeName={setOrganizationName}
                                 description={organizationDescription}
                                 changeDescription={setOrganizationDescription}
                                 logoFile={logoFile} uploadLogoFile={setLogoFile}
                                 onNext={handleNext} onCancel={onCancel}/>}
    {step == 2 &&
        <Fragment>
            <WorkspaceBody name={workspaceName} changeName={setWorkspaceName}
                           description={workspaceDescription} changeDescription={setWorkspaceDescription}/>
            <ModalFooter className="justify-between">
                <Button className="text-capitalize" outline
                        onClick={insure(onCancel)}>
                  {t("cancel")}
                </Button>
                <Button className="text-capitalize" color="primary" outline
                        disabled={!workspaceName} onClick={handleCreate}>
                  {t("finish setup")}
                </Button>
            </ModalFooter>
        </Fragment>
    }
  </StrictMode>)
}
