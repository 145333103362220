import { useState, useEffect } from "react";

import { withTranslation } from "react-i18next";
import { withRouter, Link } from "react-router-dom";
import AkenzaBlockOption from "./AkenzaBlockOption";

import {
    getIotLogicBlocks
} from "src/helpers/iot_backend_helper";
export default withTranslation()(withRouter((props: any) => {
    const { t, onSelect, match } = props;
    const { orgId } = match.params;
    const [list, setList] = useState([]);
    useEffect(() => {
        fetchData({});
    }, []);

    // 获取设备类型列表数据
    const fetchData = (params: any) => {
        getIotLogicBlocks({ org_id: orgId, ...params }, null).then((result: any) => {
            if (result && result.Code == 200) {
                setList(result.Data);
            }
        })
    }
    const COMPARISON = {
        id: "Comparison",
        name: t("Comparison"),
        type: "COMPARISON",
        iconClassName: "mdi mdi-link-variant",
    }

    return (
        <div>
            <div className="mb-2 text-capitalize">{t("logic blocks")}</div>
            {
                list.map((o: any, index:number) => <AkenzaBlockOption key={index} data={{...o, type: "CUSTOM_LOGIC"}} onClick={onSelect} />)
            }
            <div className="mb-2 text-capitalize mt-4">metavun {t("logic blocks")}</div>
            <AkenzaBlockOption data={COMPARISON} onClick={onSelect} />
        </div>
    )
}))
