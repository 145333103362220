import {updateIotUser} from "src/helpers/iot_backend_helper";
import {Key} from "react";
import {awaitWrap} from "src/util";

export const mutateUser = async <T, U = any>(uid: Key, p: any):
  Promise<[null, any] | [any, undefined]> => {
  const [res, err] = await awaitWrap(updateIotUser(uid.toString(), p, null))
  if (err) {
    return [null, err]
  }

  return [res, undefined]
}
