import { ActionTypes } from "./actionTypes";

const initialState = {
  list: [],
  deviceTypes: [],
  error: "",
  loading: false,
};

const deviceType = (state = initialState, action: any) => {
  switch (action.type) {

    case ActionTypes.ADD_DEVICE_CONNECTOR:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ActionTypes.ADD_DEVICE_CONNECTOR_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
      
    case ActionTypes.ADD_DEVICE_CONNECTOR_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;

    case ActionTypes.GET_DEVICE_CONNECTOR_LIST_SUCCESS:
      state = { ...state, ...action.payload, loading: false };
      break;

    case ActionTypes.GET_DEVICE_CONNECTOR_LIST_ERROR:
      state = { ...state, list: [], loading: false };
      break;

    case ActionTypes.DELETE_DEVICE_CONNECTOR_SUCCESS:
      state = { ...state, loading: false };
      break;
    case ActionTypes.DELETE_DEVICE_CONNECTOR_ERROR:
      state = { ...state, loading: false };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default deviceType;
