import { TokensTypes } from "./actionTypes";

export const getEvents = () => ({
  type: TokensTypes.GET_EVENTS,
});

export const apiSuccess = (actionType : any, data : any) => ({
  type: TokensTypes.API_SUCCESS,
  payload: { actionType, data },
});

export const apiFail = (actionType : any, error : any) => ({
  type: TokensTypes.API_FAIL,
  payload: { actionType, error },
});

export const addNewEvent = (event : any) => ({
  type: TokensTypes.ADD_NEW_EVENT,
  payload: event,
});

export const updateEvent = (event : any) => ({
  type: TokensTypes.UPDATE_EVENT,
  payload: event,
});

export const updateEventSuccess = (event : any) => ({
  type: TokensTypes.UPDATE_EVENT_SUCCESS,
  payload: event,
});

export const updateEventFail = (error : any) => ({
  type: TokensTypes.UPDATE_EVENT_FAIL,
  payload: error,
});

export const deleteEvent = (event : any) => ({
  type: TokensTypes.DELETE_EVENT,
  payload: event,
});

export const deleteEventSuccess = (event : any) => ({
  type: TokensTypes.DELETE_EVENT_SUCCESS,
  payload: event,
});

export const deleteEventFail = (error : any) => ({
  type: TokensTypes.DELETE_EVENT_FAIL,
  payload: error,
});

export const getCategories = () => ({
  type: TokensTypes.GET_CATEGORIES,
});

export const getCategoriesSuccess = (categories : any) => ({
  type: TokensTypes.GET_CATEGORIES_SUCCESS,
  payload: categories,
});

export const getCategoriesFail = (error : any) => ({
  type: TokensTypes.GET_CATEGORIES_FAIL,
  payload: error,
});

export const getTokens = () => ({
  type: TokensTypes.GET_TOKENS,
});

export const getTokensSuccess = (tokens : any) => ({
  type: TokensTypes.GET_TOKENS_SUCCESS,
  payload: tokens,
});

export const getTokensFail = (error : any) => ({
  type: TokensTypes.GET_TOKENS_FAIL,
  payload: error,
});

export const getTokenById = (id:string) => ({
  type: TokensTypes.GET_TOKENBYID,
  payload:id
});

export const getTokenByIdSuccess = (tokens : any) => ({
  type: TokensTypes.GET_TOKENBYID_SUCCESS,
  payload: tokens,
});

export const getTokenByIdFail = (error : any) => ({
  type: TokensTypes.GET_TOKENBYID_FAIL,
  payload: error,
});
