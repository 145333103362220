import React, { useState } from "react";

import PropTypes from "prop-types";

//i18n
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Alert, Card, CardBody, Col, Row } from 'reactstrap';
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";
import ReactJson from 'react-json-view'

import "./style.scss";
import _ from 'lodash';
import { useRole } from '../../util';

const defaultParam = `{
    "data": {
        "port": 1,
        "payload": {
            "example": "PLACE PAYLOAD PROPERTIES HERE"
        }
    },
    "time": "2020-09-07T18:20:05.199Z"
}`

const emitStr = `let res = {}
function emit(s, obj) {
  res = obj;
}`

const AceEditorPage = (props: any) => {
    const { t, cancel, save, type, script, parameter, match } = props;
    const {orgId, wsId} = match.params;
    const pageRole = useRole(orgId,wsId)

    const [scriptString, setScriptString] = useState<string>(script)
    const handleFuncChange = (val: string) => {
        setScriptString(val)
    }
    const saveScript = () => {
        save(scriptString);
    }

    const [param, setParam] = useState<string>(parameter || defaultParam)
    const handleParamChange = (val: string) => {
        setParam(val);
    }

    const placeholderText = t("Placeholder Text")

    const [scriptOutput,setScriptOutput] = useState<object>()
    const [runScriptErr,setRunScriptErr] = useState<string>()
    const handleRunScript = () => {
        // 使用严格模式，防止污染全局作用域
        'use strict'
        try {
            const code = `${emitStr}; let event = ${param};
            (${scriptString})(event); (function(){return res})();`
            const res = eval(code)
            setRunScriptErr("")
            setScriptOutput(res)
        } catch (e) {
            let err:any = e
            setScriptOutput(undefined)
            setRunScriptErr(`${err.name}: ${err.message}`)
        }
    }

    return (
        <React.Fragment>
            <div className="flex-1 column">
                <div className="flex-row space-between mb-4 mt-4" style={{ padding: '0 30px' }}>
                    <h4>{t(type ? `${type} Script` : "Script")}</h4>
                    <div>
                        <button disabled={pageRole=="viewer"} type="button" className="btn btn-outline-primary waves-effect waves-light mr-5" onClick={handleRunScript}>
                            <i className="bx bx-right-arrow font-size-16 me-2 align-middle lh-1" />
                            <span className="text-capitalize">{t("run script")}</span>
                        </button>
                        <button
                            type="button"
                            style={{ fontSize: '16px', marginRight: '10px' }}
                            onClick={cancel}
                            className="btn btn-outline-light waves-effect text-capitalize">
                            {t("cancel")}
                        </button>
                        <button
                            disabled={pageRole=="viewer"}
                            type="button"
                            style={{ background: '#1890ff', fontSize: '16px' }}
                            onClick={saveScript}
                            className="btn btn-primary waves-effect waves-light first-letter-upper">
                            {t("save script")}
                        </button>
                    </div>
                </div>
                <div className="column flex-1">
                    <div style={{ display: 'flex', flex: 2 }}>
                        <Col sm="6" xs="12" style={{ overflow: 'scroll' }}>
                            <div className="top bdr">
                                <h5 className="text-capitalize">{t("script")}</h5>
                            </div>
                            {/* func */}
                            <AceEditor
                                placeholder={placeholderText}
                                mode="javascript"
                                theme="github"
                                name={_.uniqueId('ace_editor')}
                                style={{ width: '100%', marginTop: '50px', height: 'calc(100% - 100px)' }}
                                onChange={handleFuncChange}
                                fontSize={14}
                                showPrintMargin={true}
                                showGutter={true}
                                highlightActiveLine={true}
                                value={scriptString}
                                setOptions={{
                                    enableBasicAutocompletion: true,
                                    enableLiveAutocompletion: true,
                                    enableSnippets: true,
                                    showLineNumbers: true,
                                    tabSize: 2,
                                }} />
                        </Col>
                        <Col sm="6" xs="12" style={{ overflow: 'scroll' }}>
                            <div className="top">
                                <h5 className="first-letter-upper first-letter-upper">
                                    {t("script arguments")}
                                </h5>
                            </div>
                            {/* parameter */}
                            <AceEditor
                                placeholder={placeholderText}
                                mode="javascript"
                                theme="github"
                                name={_.uniqueId('ace_editor')}
                                style={{ width: '100%', marginTop: '50px', height: 'calc(100% - 100px)' }}
                                onChange={handleParamChange}
                                fontSize={14}
                                showPrintMargin={true}
                                showGutter={true}
                                highlightActiveLine={true}
                                value={param}
                                setOptions={{
                                    enableBasicAutocompletion: true,
                                    enableLiveAutocompletion: true,
                                    enableSnippets: true,
                                    showLineNumbers: true,
                                    tabSize: 2,
                                }} />
                        </Col>
                    </div>
                    <div className="column flex-1">
                        <div className="top">
                            <h5 className="text-capitalize">{t("script output")}</h5>
                        </div>
                        <Alert color="danger" hidden={!runScriptErr}>{runScriptErr}</Alert>
                        {scriptOutput && <ReactJson displayObjectSize={false} displayDataTypes={false} name={false} src={scriptOutput}/>}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

AceEditorPage.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    cancel: PropTypes.func,
    save: PropTypes.func
};

export default withTranslation()(withRouter(AceEditorPage));