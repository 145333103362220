import PropTypes from "prop-types";
import React from "react";


const Vectormap = (props: any) => {
  return (
    <div style={{ width: props.width, height: 500 }}>
  
    </div>
  );
};

Vectormap.propTypes = {
  color: PropTypes.string,
  value: PropTypes.any,
  width: PropTypes.any,
};

export default Vectormap;
