import React, {useState} from "react";

import {
    Button,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
    InputGroup,
    InputGroupText
} from "reactstrap";

import {useTranslation} from "react-i18next";

import {currying, insure} from "src/util";

type Direction = "top" | "right" | "bottom" | "left"
const location = (position: Direction) => ({[position]: 0})

export type PrivateDrawerProps = {
    visible: boolean;
    position: Direction;
    defaultVal: number;
    defaultUnit: string;
    onCancel: Function;
    onClose: Function;
    onSave: Function;
    timer: {enabled: boolean, schedule: {value: number, unit: string}};
}

const timeUnits = ["hours", "minutes", "days"]

export default (
    {
        visible = false,
        position = "bottom",
        defaultVal = 1,
        defaultUnit = "hours",
        onClose,
        onCancel,
        onSave,
    }: Partial<PrivateDrawerProps>) => {
    const {t} = useTranslation()

    const [val, setVal] = useState(defaultVal)
    const handleInputChange = (newVal: string) => {
        if (Number(newVal) < 0) return
        setVal(Number(newVal))
    }

    const [dropdownOpened, setDropdownOpened] = useState(false)
    const reverseDropdownOpened = () => {
        setDropdownOpened((opened) => !opened)
    }

    const [timeUnit, setTimeUnit] = useState(defaultUnit)

    const handleTimeUnitChange = (unit: string) => {
        setTimeUnit(unit)
    }

    return (<React.StrictMode>
        <div className="position-absolute w-100 bg-white z-10" style={location(position)} hidden={!visible}>
            <header className="border-bottom px-4 py-3 font-size-16 clearfix">
                <div className="float-start fw-bold first-letter-upper">{t("rule timer")}</div>
                <i className="fas fa-times float-end cursor-pointer" onClick={insure(onClose)}/>
            </header>
            <main className="px-4 pb-2 font-size-14">
                <div className="d-flex border-bottom py-4">
                    <span className="flex-shrink-0 me-2 lh-lg">{t("Run rules every")}:</span>
                    <InputGroup className="uw-20">
                        <Input className="border-blue-hover ps-2 pe-0 py-1"
                               type="number" value={val}
                               onChange={e => handleInputChange(e.target.value)}/>
                        <InputGroupText className="px-0 py-0">
                            <Dropdown className="d-inline-block border-start-0" isOpen={dropdownOpened}
                                      toggle={reverseDropdownOpened}>
                                <DropdownToggle className="border-0 px-2 py-1 bg-light" caret outline>
                                    <span className="me-2">{t(timeUnit)}</span>
                                    <i className="fas fa-angle-down"/>
                                </DropdownToggle>
                                <DropdownMenu>
                                    {timeUnits.map(unit =>
                                        <DropdownItem key={unit} onClick={currying(handleTimeUnitChange, unit)}>
                                            {t(unit)}
                                        </DropdownItem>)}
                                </DropdownMenu>
                            </Dropdown>
                        </InputGroupText>
                    </InputGroup>
                </div>
                <div className="py-4">
                    <Button className="me-2 text-capitalize" color="secondary" outline onClick={insure(onCancel)}>
                        {t("cancel")}
                    </Button>
                    <Button className="text-capitalize" color="primary" onClick={insure(onSave, val, timeUnit)}>
                        {t("save timer")}
                    </Button>
                </div>
            </main>
        </div>
    </React.StrictMode>)
}
