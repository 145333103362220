// Router
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
// i18n
import { useTranslation } from 'react-i18next';
// Component
import { Button } from 'reactstrap';
// Api
import { activateUser } from 'src/helpers/iot_backend_helper';
import { awaitWrap } from '../../../util';

export default function () {
  const { t } = useTranslation();
  const h = useHistory();

  const { id } = useRouteMatch<any>().params;

  const handleActivate = async () => {
    const [, err] = await awaitWrap(activateUser(id, null));
    if (err) {
      alert(err.response.data.message);
    }

    h.push('/login');
  };

  return (
    <div className="position-relative vh-100 vw-100">
      <div className="position-absolute top-50 start-50 translate-middle">
        <header className="font-size-20 fw-bold first-letter-upper">
          {t('activate account')}
        </header>

        <main className="mt-5">
          <div className="mb-2 first-letter-upper">
            {t('after activation, you will jump to the login page')}
          </div>
          <Button
            className="text-capitalize"
            color="success"
            onClick={handleActivate}>
            {t('activate now')}
          </Button>
        </main>

        <Link to="/login">
          <Button className="mt-5 text-capitalize w-100" color="info">
            {t('login here')}
          </Button>
        </Link>
      </div>
    </div>
  );
}
