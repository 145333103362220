import { all, fork } from "redux-saga/effects"

//Layout
import LayoutSaga from "./layout/saga";

//Calendar
import calendarSaga from "./calendar/saga";

//Chat
import chatSaga from "./chat/saga";

//Invoices
import invoiceSaga from "./invoices/saga";

//Contact
import contactsSaga from "./contacts/saga";

//Login
import authSaga from "./auth/login/saga";

//Register
import registerSaga from "./auth/register/saga";

//User Profile
import ProfileSaga from "./auth/profile/saga";

// Forget Password
import forgetPasswordSaga from "./auth/forgetpwd/saga"

// Application
import organizeSaga from "./apps/Organize/saga"
import userSaga from "./apps/User/saga"
import roleSaga from "./apps/Character/saga";
import permissionSaga from "./apps/Authority/saga";

// Settings
import appsSaga from "./settings/Apps/saga";
import providersSaga from "./settings/Provider/saga";
import resourcesSaga from "./settings/Assets/saga";
import tokensSaga from "./settings/Tokens/saga";
import recordsSaga from "./settings/Records/saga";
import webhooksSaga from "./settings/Webhooks/saga";
import syncersSaga from "./settings/Syncers/saga";
import certsSaga from "./settings/Certs/saga";

// akenza
import deviceTypeSage from "./akenza/deviceType/saga";
import deviceConnectorSage from "./akenza/deviceConnector/saga";

export default function* rootSaga() {
  yield all([
    //public
    fork(LayoutSaga),
    fork(calendarSaga),
    fork(chatSaga),
    fork(invoiceSaga),
    fork(contactsSaga),
    fork(authSaga),
    fork(registerSaga),
    fork(ProfileSaga),
    fork(forgetPasswordSaga),
    fork(organizeSaga), fork(userSaga),
    fork(roleSaga), fork(permissionSaga),
    fork(appsSaga), fork(providersSaga),
    fork(resourcesSaga), fork(tokensSaga),
    fork(recordsSaga), fork(webhooksSaga),
    fork(syncersSaga), fork(certsSaga),
    fork(deviceTypeSage),
    fork(deviceConnectorSage)
  ])
}
