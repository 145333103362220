import { Key, StrictMode } from "react";

import { useTranslation } from "react-i18next";

import { insure } from "src/util";

type Props = {
    iconClassName: string;
    disable: boolean;
    id: Key;
    title: Key;
    subtitle: Key;
    description: string;
    onSelect: Function;
    device: object;
}

export default ({ iconClassName, disable, device, title, subtitle, description, onSelect }: Partial<Props>) => {
    const { t } = useTranslation()

    return (<StrictMode>
        <div className={`mt-narrow-pre border border-blue-hover rounded-2 ${disable &&
            "pointer-events-none bg-light text-secondary"}`}
            onClick={insure(onSelect, device)}>
            <header className="border-bottom px-2 py-2" style={{position: 'relative'}}>
                <i className={`me-2 ${iconClassName}`}></i>
                <span className="me-2 fw-bold">{title}</span>
                <span>{subtitle}</span>
            </header>
            <main className="px-2 py-2">
                <div className="text-secondary text-capitalize">{t("description")}:</div>
                <div>
                    {description}
                </div>
            </main>
        </div>
    </StrictMode>)
}
