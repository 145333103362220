import React, { MouseEventHandler } from "react";
//i18n
import { useTranslation } from "react-i18next";
import { Modal } from 'reactstrap';

export type ModalProps = {
  confirm: MouseEventHandler;
  hideModal: Function;
  title: string;
  description: string;
  isOpen: boolean;
  cancelText?: string;
  confirmText?: string;
}

const ConfirmModal = (props: ModalProps) :React.ReactElement => {
  let { hideModal, confirm, title, description, isOpen, cancelText, confirmText } = props;
  const { t } = useTranslation();
  return (
    <React.StrictMode>
      <Modal
        isOpen={isOpen}
        toggle={() => {
          hideModal();
        }}
        backdrop={"static"}
        id="staticBackdrop"
      >
        <div className="modal-header" style={{ display: "flex", flex: 1, justifyContent: 'space-between'}}>
          <div className="flex-row" style={{ display: "flex", flex: 1 }}>
            <i className="bx bx-info-circle font-size-24 mt-1 px-2" style={{ color: '#faad14', marginRight: '5px' }}></i>
            <h5 className="modal-title mt-1" id="staticBackdropLabel">
              {/* {title} */}
              {t("Do you really want to delete this data?")}
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                hideModal(false);
              }}
              aria-label="Close"
            ></button>
          </div>
        </div>
        <div className="modal-body px-5">
          <p>{t(description)}</p>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              hideModal(false);
            }}
          >
            {t(cancelText || 'cancel')}
          </button>
          <button onClick={confirm} type="button" className="btn btn-danger">
            {t(confirmText || 'OK')}
          </button>
        </div>
      </Modal>
    </React.StrictMode>
  );
}

export default ConfirmModal;