import React from "react";

import DeviceTypeSelector from "./DeviceTypeSelector";
import {useTranslation} from "react-i18next";

export default () => {
    const {t} = useTranslation()

    return (<React.StrictMode>
        <div className="d-flex flex-column px-4 py-4 h-100">
            <header className="flex-grow-0 flex-shrink-0 mb-4 font-size-16 fw-bold first-letter-upper">
                {t("device type")}
            </header>
            <main className="flex-grow-1">
                <DeviceTypeSelector/>
            </main>
        </div>
    </React.StrictMode>)
}
