import React, { useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
//i18n
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import { Card, CardBody, Col, Row, Input, Label, Modal } from "reactstrap";
const AddApiKeyModal = (props: any) => {
  const [workspace, setWorkspace] = useState<any>(null);
  let {
    t, visiable, togModal, hideModal, save
  } = props;
  const submit = () => {
    save();
  }

  const onChange = (e: any) => {
    let value = e.target.value;
  }
  const optionGroup = [
    { label: "Workspace1", value: "Workspace1" },
    { label: "Workspace2", value: "Workspace2" },
  ];

  const data = [{
    title: "Organization Member",
    checked: false,
    children: [{
      title: "read",
      checked: false,
    }, {
      title: "manage",
      checked: false,
    }]
  }, {
    title: "Billing",
    checked: false,
    children: []
  }, {
    title: "Workspace",
    checked: false,
    children: [{
      title: "create",
      checked: false,
    }, {
      title: "read",
      checked: true,
    }, {
      title: "update",
      checked: false,
    }, {
      title: "delete",
      checked: false,
    }, {
      title: "manage",
      checked: false,
    }]
  }, {
    title: "Workspace Member",
    checked: false,
    children: [{
      title: "read",
      checked: false,
    }, {
      title: "manage",
      checked: false,
    }]
  }, {
    title: "Asset",
    checked: false,
    children: [{
      title: "create",
      checked: false,
    }, {
      title: "read",
      checked: true,
    }, {
      title: "update",
      checked: false,
    }, {
      title: "delete",
      checked: false,
    }, {
      title: "manage",
      checked: false,
    }]
  }, {
    title: "Device Type",
    checked: false,
    children: [{
      title: "create",
      checked: false,
    }, {
      title: "read",
      checked: true,
    }, {
      title: "update",
      checked: false,
    }, {
      title: "delete",
      checked: false,
    }, {
      title: "manage",
      checked: false,
    }]
  }, {
    title: "Device Flow",
    checked: false,
    children: [{
      title: "create",
      checked: false,
    }, {
      title: "read",
      checked: true,
    }, {
      title: "update",
      checked: false,
    }, {
      title: "delete",
      checked: false,
    }, {
      title: "manage",
      checked: false,
    }]
  }, {
    title: "Integration",
    checked: false,
    children: [{
      title: "create",
      checked: false,
    }, {
      title: "read",
      checked: true,
    }, {
      title: "update",
      checked: false,
    }, {
      title: "delete",
      checked: false,
    }, {
      title: "manage",
      checked: false,
    }]
  }, {
    title: "Rule",
    checked: false,
    children: [{
      title: "create",
      checked: false,
    }, {
      title: "read",
      checked: true,
    }, {
      title: "update",
      checked: false,
    }, {
      title: "delete",
      checked: false,
    }, {
      title: "manage",
      checked: false,
    }]
  }];

  const [
      namePlaceholder,
      descriptionPlaceholder,
      keyScopePlaceholder,
  ]=[t("enter key name"),t("enter description"),t("Select Role")]

  return (
    <React.Fragment>
      <Modal
        size="lg"
        isOpen={visiable}
        toggle={() => {
          togModal();
        }}
        centered={true}
        scrollable={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">{t('AddCustomField')}</h5>
          <button
            type="button"
            onClick={() => {
              hideModal();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body" style={{ padding: '20px' }}>
          <Row>
            <Col sm={12}>
              <div>
                <div className="mb-3">
                  <Label htmlFor="choices-single-default" className="form-Label font-size-13 text-muted">{t('Name')}</Label>
                  <Input placeholder={namePlaceholder} className="form-control" onChange={onChange} type="text" defaultValue="" id="example-text-input" />
                </div>
                <div className="mb-3">
                  <Label htmlFor="choices-single-default" className="form-Label font-size-13 text-muted">{t('Description')}</Label>
                  <Input placeholder={descriptionPlaceholder} className="form-control" onChange={onChange} type="text" defaultValue="" id="example-text-input" />
                </div>
                <div className="mt-5 mb-5">
                  <h5 className="mb-4">{t('API key scope')}</h5>
                  <div className="form-check form-switch form-switch-md mb-3" dir="ltr">
                    <input type="checkbox" className="form-check-input" id="customSwitchsizemd" />
                    <label className="form-check-label" htmlFor="customSwitchsizemd">
                      {t("restrict permissions to specific workspaces")}
                    </label>
                  </div>
                  <div className="mb-5 mt-2">
                    <label
                      htmlFor="choices-single-default"
                      className="form-label font-size-13 text-muted"
                    >
                      {t('API key scope')}
                    </label>
                    <Select
                      value={workspace}
                      onChange={setWorkspace}
                      options={optionGroup}
                      placeholder={keyScopePlaceholder}
                      classNamePrefix="select2-selection"
                    />
                  </div>
                  <div className="form-check form-switch form-switch-md" dir="ltr">
                    <input type="checkbox" className="form-check-input" id="customSwitchsizemd" />
                    <label className="form-check-label" htmlFor="customSwitchsizemd">{t("select all permissions")}</label>
                  </div>
                </div>
                <div>
                  <Card>
                    <ul className="list-group list-group-flush">
                      {
                        data.map((row, index) => {
                          return (
                            <li key={index.toString()} className="list-group-item" style={{ padding: 0 }}>
                              <div className="card-header bg-transparent border-bottom text-uppercase">
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" id="formCheck2" defaultChecked={row.checked} />
                                  <label className="form-check-label" htmlFor="formCheck2">
                                    {t(row.title)}
                                  </label>
                                </div>
                              </div>
                              {
                                (row.children && row.children.length) ? <CardBody>
                                  {
                                    row.children.map((item, index) => <div key={index.toString()} className="form-check mb-3">
                                      <input className="form-check-input" type="checkbox" id="formCheck2" defaultChecked={item.checked} />
                                      <label className="form-check-label" htmlFor="formCheck2" style={{ fontWeight: 400 }}>
                                        {t(item.title)}
                                      </label>
                                    </div>)
                                  }
                                </CardBody> : null
                              }
                            </li>
                          )
                        })
                      }
                    </ul>
                  </Card>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="modal-footer" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <button
            type="button"
            style={{ fontSize: '16px', marginRight: '10px' }}
            onClick={hideModal}
            className="btn btn-outline-light waves-effect"
          >
            {t('Cancel')}
          </button>
          <button type="button" className="btn btn-primary" onClick={submit}>
            {t('create API Key')}
          </button>
        </div>
      </Modal>
    </React.Fragment>
  );
}

AddApiKeyModal.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  visiable: PropTypes.bool,
  togModal: PropTypes.func,
  hideModal: PropTypes.func,
  save: PropTypes.func
};

export default withTranslation()(withRouter(AddApiKeyModal));