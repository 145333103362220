import { Fragment, Key, useEffect, useState } from 'react';
// i18n
import { useTranslation } from 'react-i18next';
// Component
import { Button } from 'reactstrap';
import { sendActiveEmail } from './api';

const SECOND = 1000;

type props = {
  uid?: Key;
};

export default function ({ uid }: props) {
  const { t } = useTranslation();

  // Timer
  let verificationEmailInterval: NodeJS.Timer;
  let verificationEmailTimeout: NodeJS.Timeout;
  const [disableSendButton, setDisableSendButton] = useState(false);
  // 定时器关闭时间
  // 秒
  const timeInterval = 60;
  let ti = timeInterval;
  // 毫秒
  const sendInterval = timeInterval * SECOND;
  const [timeLeft, setTimeLeft] = useState(timeInterval);
  // 发送验证邮件
  const sendVerificationEmail = () => {
    if (disableSendButton) return;
    setDisableSendButton(true);

    (async function () {
      if (!uid) return;

      const [, err] = await sendActiveEmail(uid);
      if (err) {
        alert(err.response.data.message);
        return;
      }
    })();

    verificationEmailInterval = setInterval(() => {
      // 借助外部变量避免因 react 优化而导致的显示错误
      ti--;
      setTimeLeft(ti);
    }, SECOND);
    verificationEmailTimeout = setTimeout(() => {
      clearInterval(verificationEmailInterval);
      setTimeLeft(timeInterval);
      setDisableSendButton(false);
    }, sendInterval);
  };
  // 清除计时器
  useEffect(() => {
    return function () {
      clearInterval(verificationEmailInterval);
      clearInterval(verificationEmailTimeout);
    };
  }, []);

  return (
    <Fragment>
      <div className="mb-4 font-size-20 text-capitalize">
        {t('almost done')}!
      </div>
      <div className="mb-5 cus-text-gray-400 first-letter-upper">
        {t(
          disableSendButton
            ? 'a verification E-Mail has been sent'
            : 'please verify your email address',
        )}
      </div>
      <div className="mb-5 font-size-16 first-letter-upper">
        {t(
          'check your inbox and click the link to verify your email address and complete your registration on metavun.',
        )}
      </div>
      <div className="first-letter-upper">
        {t(
          'check your spam folder too. You can always request another verification E-Mail.',
        )}
      </div>
      <Button
        className="mt-4 py-1 font-size-16 first-letter-upper"
        color={disableSendButton ? 'secondary' : 'primary'}
        outline={!disableSendButton}
        disabled={disableSendButton}
        onClick={sendVerificationEmail}>
        {disableSendButton
          ? `${t('resend in')} ${timeLeft} ${t('__seconds')}`
          : t('send verification email')}
      </Button>
    </Fragment>
  );
}
