import  {  StrictMode } from "react";

import CustomMetavunDb from './custom_metavun_db';
import InfluxDb from './influx_db';
import Kafka from './kafka';
import Email from './email';
export default (props: any) => {
    const component:any = {
        "CUSTOM_METAVUN_DB": <CustomMetavunDb {...props}/>,
        "INFLUXDB": <InfluxDb {...props}/>,
        "KAFKA": <Kafka {...props}/>,
        "EMAIL": <Email {...props}/>
    }
    return (<StrictMode>{component[props.type]}</StrictMode>)
}
