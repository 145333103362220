import {Fragment} from "react";
// i18n
import {useTranslation} from "react-i18next";
// Component
import {SubmitHandler, useForm} from "react-hook-form";
import {Button, FormFeedback, FormGroup, Input, Label} from "reactstrap";
// Util

export type FormInputs = {
  name: string;
  description: string;
}

type props = Partial<FormInputs> & {
  onCreate?: Function;
}

export default function ({name, description, onCreate}: props) {
  const {t} = useTranslation()
  // 表单校验规则
  const formSchema = {
    name: {
      required: "workspace name is required",
      maxLength: {
        value: 16,
        message: "the maximum length of the workspace name is 16"
      }
    },
    description: {
      maxLength: {
        value: 500,
        message: "the maximum length of the workspace description is 500"
      }
    },
  }
  const {register, handleSubmit, formState: {errors}} = useForm<FormInputs>()

  const handleCreate: SubmitHandler<FormInputs> = (data) => {
    const information = {
      ...data,
    }
    onCreate && onCreate(information)
  }

  return (<Fragment>
    <h5 className="mb-2 text-center first-letter-upper">
      {t("your first workspace")}
    </h5>
    <div className="mb-4 text-center text-secondary first-letter-upper">
      {t("WorkspaceSetupForProjectDescription")}
    </div>
    <div className="rounded-3 px-3 py-4 cus-bg-white">
      <FormGroup className="mt-sparse-pre">
        <Label className="text-capitalize">
          <span>{t("workspace name")}</span>
        </Label>
        <Input className="py-1" placeholder={t("Please enter the workspace name")}
               defaultValue={name}
               invalid={Boolean(errors.name)}
               {...register("name", formSchema.name)}/>
        <FormFeedback
          className="mt-narrow-pre first-letter-upper">
          {t(errors.name?.message || "")}
        </FormFeedback>
      </FormGroup>
      <FormGroup className="mt-sparse-pre">
        <Label>
          <span className="me-2 text-capitalize">
            {t("workspace description")}
          </span>
          <span>({t("optional")})</span>
        </Label>
        <Input className="py-1" placeholder={t("Please enter the workspace description")}
               defaultValue={description}
               invalid={Boolean(errors.description)}
               {...register("description", formSchema.description)}/>
        <FormFeedback
          className="mt-narrow-pre first-letter-upper">
          {t(errors.description?.message || "")}
        </FormFeedback>
      </FormGroup>
    </div>
    <div className="d-flex justify-content-center mt-4">
      <Button className="first-letter-upper" color="primary"
              onClick={handleSubmit(handleCreate)}>
        {t("create organization")}
      </Button>
    </div>
  </Fragment>)
}
