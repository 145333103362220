import { Fragment, Key, useEffect, useRef } from 'react';
// Chart
import echarts from 'echarts';
import { DeviceDataKPIDrawer } from '../drawer';
// Util
import { Recycler } from 'src/util';
import dayjs from 'dayjs';

export type props = {
  name?: Key;
  trend?: 'uptrend' | 'downtrend';
  summary?: string;
  chartClassName?: string;
  color?: echarts.EChartOption.Color;
  data?: any[];
} & {
  supplement?: echarts.EChartOption;
};

const trendIcon = {
  uptrend: <i className="me-2 fas fa-arrow-up" />,
  downtrend: <i className="me-2 fas fa-arrow-down" />,
};

export default function ({
  name,
  trend,
  summary,
  chartClassName,
  color,
  data = [],
  supplement,
}: props) {
  const recycler = new Recycler();
  const containerRef = useRef<HTMLDivElement>(null);

  const drawer = new DeviceDataKPIDrawer(containerRef.current, {
    color: [color?.toString() || '#50bb48'],
    tooltip: {
      trigger: 'axis',
      padding: [2, 5],
      formatter(el: any) {
        const { date, value } = el[0].data;

        return `${dayjs(date).format('HH:mm')}-${value}`;
      },
    },
    ...supplement,
  });
  recycler.register(drawer);

  useEffect(
    function () {
      drawer.init(containerRef.current, {
        series: [
          {
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 2,
              color: color,
            },
            showSymbol: false,
            areaStyle: {
              color: color,
              opacity: 0.1,
            },
            name: name?.toString(),
            data,
          },
        ],
      });

      return function () {
        recycler.drop();
      };
    },
    [containerRef, data],
  );

  return (
    <Fragment>
      <div className="text-muted">{name}</div>
      <h3 className="mb-0">
        {trend && trendIcon[trend]}
        {summary}
      </h3>
      {data?.length > 0 && (
        <div className={chartClassName} ref={containerRef} />
      )}
    </Fragment>
  );
}
