/**
 *  interface for Device Type
*/
export enum ActionTypes {
  ADD_DEVICE_CONNECTOR = '@@deviceConnector/ADD_DEVICE_CONNECTOR',
  ADD_DEVICE_CONNECTOR_SUCCESS = '@@deviceConnector/ADD_DEVICE_CONNECTOR_SUCCESS',
  ADD_DEVICE_CONNECTOR_ERROR = '@@deviceConnector/ADD_DEVICE_CONNECTOR_ERROR',
  GET_DEVICE_CONNECTOR_LIST = '@@deviceConnector/GET_DEVICE_CONNECTOR_LIST',
  GET_DEVICE_CONNECTOR_LIST_SUCCESS = '@@deviceConnector/GET_DEVICE_CONNECTOR_LIST_SUCCESS',
  GET_DEVICE_CONNECTOR_LIST_ERROR = '@@deviceConnector/GET_DEVICE_CONNECTOR_LIST_ERROR',
  DELETE_DEVICE_CONNECTOR = '@@deviceConnector/DELETE_DEVICE_CONNECTOR',
  DELETE_DEVICE_CONNECTOR_SUCCESS = '@@deviceConnector/DELETE_DEVICE_CONNECTOR_SUCCESS',
  DELETE_DEVICE_CONNECTOR_ERROR = '@@deviceConnector/DELETE_DEVICE_CONNECTOR_ERROR',
}
