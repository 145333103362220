import { CSSProperties } from 'react';
// Component
import { GridLoader } from 'react-spinners';
// CSS
import './index.css';

const override: CSSProperties = {
  display: 'block',
  marginBottom: '160px',
};

type props = {
  loading: boolean;
};

export default ({ loading }: props) => {
  return (
    <div className="overflow-auto loading-container">
      <GridLoader
        cssOverride={override}
        size={20}
        color="#123abc"
        loading={loading}
        speedMultiplier={1.5}
      />
    </div>
  );
};
