import { Key } from 'react';
// i18n
import { useTranslation } from 'react-i18next';
// CSS
import './templateContainer.scss';

type Props = {
  id: Key;
  iconClassName: string;
  title: string;
  description: string;
  onClick: any;
};

export default ({
  id,
  iconClassName,
  title = 'title',
  description = '',
  onClick,
}: Partial<Props>) => {
  const { t } = useTranslation();

  return (
    <div
      className="template-container d-flex gutter-t-normal
      border border-blue-hover px-2 py-2 rounded-2
      cursor-pointer"
      onClick={() => onClick && onClick(id)}>
      <div
        className="template-container-icon flex-grow-0 flex-shrink-0
        me-2 rounded-circle px-1 py-1
        text-center">
        <i className={iconClassName} />
      </div>
      <div className="flex-grow-1">
        <header className="mb-2 font-size-16 fw-bold">{t(title)}</header>
        <main>{t(description)}</main>
      </div>
    </div>
  );
};
