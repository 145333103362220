import { Fragment, useState } from 'react';
// i18n
import { useTranslation } from 'react-i18next';
// Component
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormFeedback,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import { useDropzone } from 'react-dropzone';
// Conf
import { companySizeOpts } from './conf';
// Util
import { currying } from 'src/util';

export type FormInputs = {
  name: string;
  description: string;
};

type props = Partial<FormInputs> & {
  onCreate?: Function;
};

export default function ({ name, description, onCreate }: props) {
  const { t } = useTranslation();

  // 表单校验规则
  const formSchema = {
    name: {
      required: 'organization name is required',
      maxLength: {
        value: 16,
        message: 'the maximum length of the organization name is 16',
      },
    },
    description: {
      maxLength: {
        value: 500,
        message: 'the maximum length of the organization description is 500',
      },
    },
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInputs>();

  const [sizeOpen, setSizeOpen] = useState(false);
  const [size, setSize] = useState(name);
  const [industry, setIndustry] = useState(description);
  // 文件上传
  const [files, setFiles] = useState<(File & { preview?: string })[]>([]);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    maxSize: 2 << 20,
    onDrop: acceptedFiles => {
      setFiles(
        acceptedFiles.map(f =>
          Object.assign(f, {
            formattedSize: `${f.size >> 10} KB`,
            preview: URL.createObjectURL(f),
          }),
        ),
      );
    },
  });

  const fs = (function () {
    if (acceptedFiles.length < 1) return '';
    const [f] = acceptedFiles;

    return `${f.name} - ${f.size >> 10} KB`;
  })();

  const handleCreate: SubmitHandler<FormInputs> = data => {
    const information = {
      ...data,
      size,
      industry,
    };

    onCreate && onCreate(information, acceptedFiles[0]);
  };

  return (
    <Fragment>
      <h5 className="mb-2 text-center text-capitalize">
        {t('your organization')}
      </h5>
      <div className="mb-4 text-center text-secondary first-letter-upper">
        {t('OrganizationIsHomeForProject')}
      </div>
      <div className="rounded-3 px-3 py-4 cus-bg-white">
        {/* Name */}
        <FormGroup className="mt-sparse-pre">
          <Label className="text-capitalize">
            <span>{t('organization name')}</span>
          </Label>
          <Input
            className="py-1"
            placeholder={t('Please enter the organization name')}
            defaultValue={name}
            invalid={Boolean(errors.name)}
            {...register('name', formSchema.name)}
          />
          <FormFeedback className="mt-narrow-pre first-letter-upper">
            {t(errors.name?.message || '')}
          </FormFeedback>
        </FormGroup>
        {/* Description */}
        <FormGroup className="mt-sparse-pre">
          <Label>
            <span className="me-2 text-capitalize">
              {t('organization description')}
            </span>
            <span>({t('optional')})</span>
          </Label>
          <Input
            className="py-1"
            placeholder={t('Please enter the organization description')}
            defaultValue={description}
            invalid={Boolean(errors.description)}
            {...register('description', formSchema.description)}
          />
          <FormFeedback className="mt-narrow-pre first-letter-upper">
            {t(errors.description?.message || '')}
          </FormFeedback>
        </FormGroup>
        {/* Size */}
        <FormGroup className="mt-sparse-pre">
          <Label>
            <span className="me-2">{t('Company size')}</span>
            <span>({t('optional')})</span>
          </Label>
          <Dropdown isOpen={sizeOpen} toggle={currying(setSizeOpen, !sizeOpen)}>
            <DropdownToggle tag="div">
              <Button
                className={`clearfix cus-border-gray-300 px-2 py-1 w-100
            text-start cus-bg-white ${
              size ? 'cus-text-black' : 'cus-text-gray-400'
            }`}
                outline>
                <span className="float-start">
                  {companySizeOpts.find(opt => opt.value.toString() == size)
                    ?.label
                    ? companySizeOpts.find(opt => opt.value.toString() == size)
                        ?.label + t('people')
                    : t('Please select company size')}
                </span>
                <i
                  className={`float-end lh-base fas ${
                    sizeOpen ? 'fa-angle-up' : 'fa-angle-down'
                  }`}
                />
              </Button>
            </DropdownToggle>
            <DropdownMenu>
              {companySizeOpts.map(opt => (
                <DropdownItem
                  key={opt.value}
                  onClick={currying(setSize, opt.value)}>
                  {opt.label} {t('people')}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </FormGroup>
        {/* Industry */}
        <FormGroup className="mt-sparse-pre">
          <Label>
            <span className="me-2">{t('Company industry')}</span>
            <span>({t('optional')})</span>
          </Label>
          <Input
            className="py-1"
            placeholder={t('Please select company industry')}
            value={industry}
            onChange={e => setIndustry(e.target.value)}
          />
        </FormGroup>
        {/* Logo */}
        <FormGroup className="mt-sparse-pre">
          <Label>{t('upload logo file')}</Label>
          <div {...getRootProps()}>
            <div className="mb-3" hidden={!files?.length}>
              <img
                className="border rounded-1 cus-h-3"
                src={files?.[0]?.preview}
                alt="..."
              />
            </div>
            <input {...getInputProps()} />
            <Button className="px-2 py-1" outline>
              <i className={`me-2 fas fa-cloud-upload-alt`} />
              {fs || t('Upload logo')}
            </Button>
          </div>
        </FormGroup>
      </div>
      {/* Create */}
      <div className="d-flex justify-content-center mt-4">
        <Button
          className="first-letter-upper"
          color="primary"
          onClick={handleSubmit(handleCreate)}>
          {t('create organization')}
        </Button>
      </div>
    </Fragment>
  );
}
