import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { isEmpty } from "lodash";

import {
    Badge,
    Button,
    Card,
    CardBody, CardHeader, CardSubtitle, CardTitle,
    Col,
    Container, Form, Input, Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row, Table,
} from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";

/** Import Full Calendar  */
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";

//import images


//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import {
    getPermissions as onGetPermissions
} from "../../../store/apps/Authority/actions";

//i18n
import { useTranslation } from "react-i18next";

import {
    addNewEvent as onAddNewEvent,
    deleteEvent as onDeleteEvent,
    getCategories as onGetCategories,
    getEvents as onGetEvents,
    updateEvent as onUpdateEvent,
} from "../../../store/actions";

// import DeleteModal from "./DeleteModal";

//css
import "@fullcalendar/bootstrap/main.css";

//redux
import { useSelector, useDispatch } from "react-redux";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import paginationFactory, {
    PaginationListStandalone, PaginationProvider,
    SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import {Link} from "react-router-dom";
import {useLocation} from "react-router";

interface TestHintPageProps {
    className: string;
}
// TODO: 函数头
const TestHintPage = ({ className }: TestHintPageProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { state } = useLocation();

    const localState:any = state;

    return (
        <React.Fragment>
            <div>
                <Col xl={6}>
                    <Card>
                        <CardBody className="flex align-self-center justify-content-center">
                            <img src="" alt=""/>
                            <h4>{t("LoginError")}</h4>
                            <p>{t("LoginErrorHint")}</p>
                            <button type="submit" className="btn btn-primary w-md">
                                {t("Login")}
                            </button>
                        </CardBody>
                    </Card>
                </Col>
            </div>
        </React.Fragment>
    );
};

export default TestHintPage;
