import { StrictMode, useEffect, useState } from "react";

import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import {
  Button,
  FormGroup,
  Label
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { insure } from "src/util";

export default withTranslation()(withRouter((props: any) => {
  const { t, onBack, onCancel, onSave, onUpdate, connector, type, typeName, match } = props;
  const [alwasSendEmail, setAlwasSendEmail] = useState<boolean>(connector && connector.attribute.alwas_send_email)
  const { wsId } = match.params;

  const handleValidSubmit = (e: any, v: any) => {
    let data = {
      name: v.name,
      workspace_id: wsId,
      attribute: {
        recipients: [v.recipients], alwas_send_email: alwasSendEmail, subject: v.subject, body: v.body
      }
    }
    if(connector && connector.id) {
      onUpdate(connector.id, data);
    }else {
      onSave(data);
    }
  }

  return (<StrictMode>
    <AvForm
      onValidSubmit={(e: any, v: any) => {
        handleValidSubmit(e, v);
      }}
    >
      <header className="flex-shrink-0 mb-5 user-select-none clearfix">
        <span onClick={insure(onBack)} className="float-start font-size-18 fw-bolder cursor-pointer">
          <i className="fas fa-arrow-left me-2" />
          <span className="text-capitalize">{typeName}</span>
        </span>
        <span className="float-end lh-lg text-primary text-capitalize cursor-pointer"
          onClick={insure(onCancel)}>
          {t("cancel")}
        </span>
      </header>
      <main>
        <div className="mb-3">
          <AvField
            name="name"
            defaultValue={connector && connector.name}
            label={t("connector name")}
            placeholder=""
            type="text"
            errorMessage={t("Connector name is required")}
            validate={{ required: { value: true } }}
          />
        </div>
        <div className="mb-3">
          <AvField
            name="recipients"
            defaultValue={connector && connector.attribute.recipients[0]}
            label={t("Recipients")}
            placeholder={t("Enter recipient e-mail address")}
            type="email"
            errorMessage={t("Recipients is required")}
            validate={{ required: { value: true } }}
          />
        </div>
        <FormGroup className="mt-wide-pre mt-4">
          <Label className="first-letter-upper">{t("Always send mail")}</Label>
          <div className="form-check form-switch">
            <input className="form-check-input" type="checkbox" checked={alwasSendEmail} onChange={(e) => { setAlwasSendEmail(e.target.checked) }} />
          </div>
        </FormGroup>
        <div className="mb-3">
          <AvField
            name="subject"
            defaultValue={connector && connector.attribute.subject}
            label={t("Subject text")}
            placeholder=""
            type="text"
            errorMessage={t("Subject text is required")}
            validate={{ required: { value: true } }}
          />
        </div>
        <div className="mb-3">
          <AvField
            name="body"
            defaultValue={connector && connector.attribute.body}
            label={t("Body Text")}
            placeholder=""
            type="text"
            errorMessage={t("Body Text is required")}
            validate={{ required: { value: true } }}
          />
        </div>
      </main>
      <footer className="py-4 clearfix">
        <Button typt="submit" className="float-end first-letter-upper" color="primary">
          <i className="me-2 fas fa-save" />
          {t("save connector")}
        </Button>
      </footer>
    </AvForm>
  </StrictMode>)
}))
