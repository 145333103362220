'use strict';

export default <T, U = any>(
  promise: Promise<T>,
  errorExt?: object,
): Promise<[T | null, U | undefined]> => {
  return promise
    .then<[T, undefined]>((v: T) => [v, undefined])
    .catch<[null, U]>((e: U) => {
      if (errorExt) {
        Object.assign(e, errorExt);
      }
      return [null, e];
    });
};
