import React, { useState } from "react";

import { useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import DetailMenu from "./DetailMenu";
import SwitchDrawer from "./SwitchDrawer";

export default withTranslation()(withRouter(({ t, history }: any) => {
    const { selectedMenu, user } = useSelector((state: any) => ({
        selectedMenu: state.Layout.selectedMenu,
        user: state.login.user
    }))
    let { curr_org, curr_workspace } = user;

    const [drawerVisible, setDrawerVisible] = useState(false)

    const showSwitch = () => {
        setDrawerVisible(true)
    }

    const closeDrawerVisible = () => {
        setDrawerVisible(false)
    }
    let titleDir: any = {
        workspace: curr_workspace.name,
        organization: curr_org.name,
    }

    return (
        <React.StrictMode>
            <React.Fragment>
                <div className="summary-sidebar-detail position-relative d-flex flex-column
                    border-right cus-border-gray-200 py-4 h-100
                    overflow-auto scrollbar-none">
                    <SwitchDrawer hidden={!drawerVisible} onClose={closeDrawerVisible} history={history} />
                    <div className="mb-5">
                        <h4 className="px-12 font-size-16 aaa-b">{titleDir[selectedMenu]}</h4>
                        <div className="px-12 font-size-14 text-blue fw-bold cursor-pointer"
                            onClick={showSwitch}>
                            <i className="fas fa-exchange-alt me-2" />
                            <span className="text-capitalize">{t(`Switch ${selectedMenu}`)}</span>
                        </div>
                    </div>
                    <DetailMenu />
                </div>
            </React.Fragment>
        </React.StrictMode>
    )
}));
