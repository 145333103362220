import { Fragment, useState } from 'react';
// i18n
import { useTranslation } from 'react-i18next';
// Component
import {
  Alert,
  Button,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Progress,
} from 'reactstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
// Util
import { awaitWrap } from 'src/util';
// Api
import { existsUser } from 'src/helpers/iot_backend_helper';

type FormInputs = {
  username: string;
  password: string;
};

type props = {
  onContinue?: Function;
} & Partial<FormInputs>;

export default function ({ username, password, onContinue }: props) {
  const { t } = useTranslation();

  const [e, setE] = useState<string>();
  // Form
  const FormSchema = {
    username: {
      required: 'username is required',
      minLength: {
        value: 2,
        message: 'the minimum username length is 2',
      },
      maxLength: {
        value: 16,
        message: 'the maximum username length is 16',
      },
    },
    password: {
      required: 'password is required',
      minLength: {
        value: 6,
        message: 'the minimum password length is 6',
      },
      maxLength: {
        value: 16,
        message: 'the maximum password length is 16',
      },
    },
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInputs>();
  // Progress
  const progressDefaultStyle = {
    value: 0,
    color: '',
  };
  const [progressStyle, setProgressStyle] = useState(progressDefaultStyle);
  const handleProgressStyle = (val: string) => {
    if (val.length < 6) {
      setProgressStyle({ value: 1, color: 'danger' });
      return;
    }
    if (val.length < 16) {
      setProgressStyle({ value: 2, color: 'success' });
      return;
    }
    if (val.length > 16) {
      setProgressStyle({ value: 3, color: 'danger' });
      return;
    }
  };
  // Submit
  const onSubmit: SubmitHandler<FormInputs> = data => {
    setE('');

    (async function () {
      const p = {
        user_name: data.username,
      };

      const [res, err] = await awaitWrap(existsUser(p, null));

      if (err) {
        if (err.response.data.message != 'not found') {
          alert(err.response.data.message);
          return;
        }
      }

      if (res && res.Code == 200) {
        setE('用户名重复，请重新设置');
        return;
      }

      onContinue && onContinue(data);
    })();
  };

  return (
    <Fragment>
      <div className="mb-4 font-size-20 text-capitalize">{t('register')}</div>
      <div>
        <Alert className="mt-sparse-pre" color="danger" hidden={!e}>
          {e}
        </Alert>
        {/* UserName */}
        <FormGroup className="mt-sparse-pre">
          <Label className="text-capitalize">{t('username')}</Label>
          <Input
            autoComplete="off"
            defaultValue={username}
            invalid={Boolean(errors.username)}
            placeholder={t('enter username to register with')}
            {...register('username', FormSchema.username)}
          />
          <FormFeedback className="first-letter-upper">
            {t(errors.username?.message || '')}
          </FormFeedback>
        </FormGroup>
        {/* Password */}
        <FormGroup className="mt-sparse-pre">
          <Label className="text-capitalize">{t('password')}</Label>
          <Input
            className="mt-narrow-pre"
            autoComplete="new-password"
            type="password"
            defaultValue={password}
            invalid={Boolean(errors.password)}
            placeholder={t('enter password')}
            {...register('password', FormSchema.password)}
            onChange={e => handleProgressStyle(e.target.value)}
          />
          <FormFeedback className="mt-narrow-pre first-letter-upper">
            {t(errors.password?.message || '')}
          </FormFeedback>
          <Progress className="mt-narrow-pre" max={3} {...progressStyle} />
        </FormGroup>
        <FormGroup className="mt-5">
          <Button
            className="w-100 text-capitalize"
            color="primary"
            onClick={handleSubmit(onSubmit)}>
            {t('continue registration')}
          </Button>
        </FormGroup>
      </div>
    </Fragment>
  );
}
