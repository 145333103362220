import { FC, Fragment } from 'react';
// React-dom
import ReactDOM from 'react-dom';
// Router
import { Link, useRouteMatch, withRouter } from 'react-router-dom';
//i18n
import { TFunction, withTranslation } from 'react-i18next';
// Component
import { Button } from 'reactstrap';
// Css
import './guide.scss';
// Redux
import { useSelector } from 'react-redux';

type Props = {
  t: TFunction;
  hidden?: boolean;
  name?: string;
  description?: string;
  guidePath?: string;
  buildPath?: string;
};

const ComponentContent: FC<Props> = ({
  t,
  name,
  description = '',
  guidePath,
  buildPath,
  hidden = false,
}) => {
  const { user } = useSelector((state: any) => ({
    user: state.login.user,
  }));
  const { wsId } = useRouteMatch<any>().params;
  const role =
    user.user_workspaces.find((ws: any) => ws.workspace.id == wsId)?.role ||
    'viewer';

  return (
    <div
      className="position-absolute top-50 start-50 w-normal translate-middle"
      hidden={hidden}>
      <h5 className="mb-5">
        <i className="me-2 fas fa-code-branch" />
        <span className="text-capitalize">
          {t(`create your first ${name}`)}
        </span>
      </h5>
      <div className="mb-3 text-secondary first-letter-upper">
        {t(description)}
      </div>
      <div className="mb-5 text-primary">
        <Link to={guidePath || '/'}>
          <span className="text-capitalize">{t('learn about data flows')}</span>
        </Link>
      </div>
      <div>
        {role == 'viewer' ? (
          <Button className="fw-bold text-capitalize" color="primary" disabled>
            {t('Create Data Flow')}
          </Button>
        ) : (
          <Link to={buildPath || '/'}>
            <Button className="fw-bold text-capitalize" color="primary">
              {t('Create Data Flow')}
            </Button>
          </Link>
        )}
      </div>
    </div>
  );
};
export default withTranslation()(
  withRouter((props: any) => {
    return (
      <Fragment>
        {/*TODO cannot use portal*/}
        {ReactDOM.createPortal(
          <ComponentContent {...props}></ComponentContent>,
          document.body,
        )}
      </Fragment>
    );
  }),
);
