import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
//i18n
import { withTranslation } from "react-i18next";
import { withRouter, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Card, CardBody, Col, Row } from "reactstrap";
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import ConfirmModal from "../components/ConfirmModal"
import Loading from "src/components/Loading"
import {
  deleteIotLogicBlock,
  getIotLogicBlocks
} from "src/helpers/iot_backend_helper";

const LogicBlocks = (props: any) => {
  let { t, match } = props;
  const { orgId } = match.params;
  const [loadding, setLoadding]: [boolean, Function] = useState(true);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentRow, setCurrentRow] = useState<any>(null);
  const [list, setList] = useState([]);

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0)

  const { user } = useSelector((state: any) => ({
    user: state.login.user
  }));
  const role = user.user_organizations.find((org : any)=>org.organization.id == orgId)?.role || "viewer"
  useEffect(() => {
    fetchData({page_size: 10, page_number: 1});
  }, [orgId]);

  // 获取设备类型列表数据
  const fetchData = (params: any) => {
    getIotLogicBlocks({ org_id: orgId, ...params }, null).then((result: any) => {
      if (result && result.Code == 200) {
        let arr = result.Data.map((row: any) => {
          return {
            id: row.id,
            name: row.name,
            description: row.description
          }
        })
        setTotal(result.Total)
        setList(arr);
      }
    }).finally(() => setLoadding(false))
  }

  const handleSort = (field:string, order:"asc"|"desc") => {
    fetchData({page_size:pageSize, page_number:pageNumber, order:`${field}=${order}`})
  }

  const columns = [
    {
      dataField: "name",
      text: props.t('Name'),
      sort: true,
      formatter: (value: any, row: any, index: any) => (
        <div className="flex-row">
          <Link to={`/org/${orgId}/logic-blocks/${row.id}`} className="cursor-pointer">
            {value}
          </Link>
        </div>
      ),
      onSort: handleSort
    },
    {
      dataField: "description",
      text: props.t('Description'),
      sort: true,
      onSort: handleSort
    },
    {
      dataField: "Action",
      text: props.t('Action'),
      formatter: (value: any, row: any, index: any) => (
        <div className="flex-row">
          {
            (role === "editor" || role === "admin") ? <div onClick={() => removeLogicBlock(row)} className="text-danger first-letter-upper">{t("delete")}</div>:
              <div className="text-muted cursor-not-allowed first-letter-upper">{t("delete")}</div>
          }
        </div>
      ),
    },
  ];

  const removeLogicBlock = (row: any) => {
    setIsOpen(true);
    setCurrentRow(row);
  }
  const defaultSorted: any = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const pageOptions: any = {
    page:pageNumber,
    sizePerPage: pageSize,
    totalSize: total, // replace later with size(customers),
    custom: true,
  };

  const handleTableChange = async (type: string, { page, sizePerPage }: any) => {
    if (type == "sort") {
      return
    }
    fetchData({page_size: sizePerPage, page_number: page});
    setPageNumber(page);
    setPageSize(sizePerPage);
  }

  const confirmRemove = () => {
    let { id } = currentRow;
    deleteIotLogicBlock(id, null).then(result => {
      if (result && result.Code == 200) {
        let data = list.filter((item: any) => item.id != currentRow.id);
        setList(data);
        setIsOpen(false);
      }
    }).catch(e => {
      alert(e.response.data.message)
    });
  }

  const confirmModalProps = {
    isOpen: isOpen,
    hideModal: () => setIsOpen(false),
    title: 'Do you really want to delete this Logic block?',
    description: 'This action cannot be undone',
    confirmText: 'delete',
    confirm: confirmRemove
  }
  return (
    <React.Fragment>
      {loadding && <Loading loading={loadding} />}
      <div className="px-4 py-4">
        <div className="flex-row justify-between mb-4 items-center px-2">
          <div className="text-dark font-size-20" style={{ fontWeight: 700 }}>{props.t('Logic Blocks')}</div>
          <div>
            <button
              disabled={role == "viewer"}
              type="button"
              style={{ background: '#1890ff', fontSize: '16px' }}
              className="btn btn-primary waves-effect waves-light"
            >
                <span style={{ fontSize: '18px', marginRight: '10px' }}> +
                  <Link className="text-capitalize" to={`/org/${orgId}/logic-blocks/add`} style={{ color: '#fff' }}> {props.t('create custom logic block')} </Link>
                </span>
            </button>
          </div>
        </div>
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  // columns={columns}
                  // data={productData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={list}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col md="8" style={{ display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center' }}>
                                <div>
                                  {t("_Showing")} 1 - 1 {t("_of")} 1 {t("_assets")}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    // responsive
                                    bordered={false}
                                    striped={false}
                                    remote
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    onTableChange={handleTableChange}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <ConfirmModal {...confirmModalProps} />
    </React.Fragment>
  );
}

LogicBlocks.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withTranslation()(withRouter(LogicBlocks));