import React, {useContext, useState} from "react";

import {ACTION_TYPE} from "../../../../contant";
import {DataFlowContext} from "../../../../reducer";

import {MODE} from "./contant";
import DisplayBar from "./DisplayBar";
import EditBar from "./EditBar";

import {fetchDeviceConnectorInfo} from "./api";

type Props = {
  onBack: Function
}

export default ({onBack}: Partial<Props>) => {
  const [mode, setMode] = useState(MODE.DISPLAY)

  const {state: {deviceConnector}, dispatch} = useContext(DataFlowContext)

  const initialDeviceConnectorInfo = fetchDeviceConnectorInfo(deviceConnector)

  const [deviceConnectorInfo, setDeviceConnectorInfo] = useState(initialDeviceConnectorInfo)

  const handleClear = () => {
    const action = {
      type: ACTION_TYPE.CLEAR_DEVICE_CONNECTOR
    }

    dispatch(action)
    onBack && onBack()
  }

  const gotoEdit = () => {
    setMode(MODE.EDIT)
  }

  const gotoDisplay = () => {
    setMode(MODE.DISPLAY)
  }

  return (<React.StrictMode>
    {mode == MODE.DISPLAY &&
        <DisplayBar deviceConnectorInfo={deviceConnectorInfo} onClear={handleClear} onEdit={gotoEdit}/>}
    {mode == MODE.EDIT && <EditBar onBack={gotoDisplay}/>}
  </React.StrictMode>)
}
