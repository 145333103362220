import { useEffect, useState } from 'react';
// CSS
import styles from './Topbar.module.scss';
//i18n
import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';
// Component
import { Button } from 'reactstrap';
import { useSelector } from 'react-redux';
// APi
import { getIotDataFlow } from 'src/helpers/iot_backend_helper';
// Util
import { awaitWrap } from 'src/util';

type props = {
  disableSave: boolean;
  onSave: Function;
};

export default ({ disableSave, onSave }: props) => {
  const { t } = useTranslation();

  const m = useRouteMatch<any>();
  // did: device_id
  const { orgId, wsId, id: did } = m.params;
  const { user } = useSelector((state: any) => ({
    user: state.login.user
  }));

  const role = (wsId ? user.user_workspaces.find((ws : any)=>ws.workspace.id == wsId)?.role : user.user_organizations.find((org : any)=>org.organization.id == orgId)?.role) || "viewer"

  const [deviceName, setDeviceName] = useState<string>('');
  useEffect(() => {
    (async function () {
      if (!did || did == 'new') return;
      const [res, err] = await awaitWrap(getIotDataFlow(did, null));
      if (err) return;
      if (res.Code != 200) return;
      setDeviceName(res.Data.name);
    })();
  }, [did]);

  const handleSave = () => {
    onSave && onSave();
  };

  return (
    <div className="top-bar-height border-bottom px-3 py-4 clearfix">
      <Link to={`/org/${orgId}/ws/${wsId}/data-flows`}>
        <div
          className={`float-start font-size-20 lh-lg ${styles['h-content']}`}>
          <i className="mdi mdi-arrow-left me-2 cursor-pointer" />
          <span className="fw-bold text-capitalize">
            {deviceName || t('new data flow')}
          </span>
        </div>
      </Link>
      <div className="float-end">
        <Link to={`/org/${orgId}/ws/${wsId}/data-flows`}>
          <Button className="me-2 border-light first-letter-upper" outline>
            {t('close')}
          </Button>
        </Link>
        {(role === 'admin' || role === 'editor') && (
          <Button
            className="text-capitalize"
            disabled={disableSave}
            onClick={handleSave}
            type="button"
            color="primary">
            {t('save data flow')}
          </Button>
        )}
      </div>
    </div>
  );
};
