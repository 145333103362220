import { StrictMode, useEffect, useMemo, Key, useState } from "react";

import { createPortal } from "react-dom";

import { useSelector, useDispatch } from "react-redux";

import ControlBar from "./ControlBar";
import {SwitchSelect, IOption} from "./SwitchSelect";
import NewSwitch from "./NewSwitch";

import style from "./switchDrawer.module.scss"

import { menuList } from "../../optional";
// actions
import { loginSuccess } from "src/store/actions";
import {
    changeWorkspace
} from "src/helpers/iot_backend_helper"

const PrivateDrawer = (props: any) => {
    let { hidden, onClose, history } = props
    const dispatch = useDispatch();
    const [dataList, setDataList] = useState([]);
    const [list, setList] = useState([]);
    const [selectedId, setSelectedId]: [Key, Function] = useState('');
    const { selectedMenu, user } = useSelector((state: any) => {
        return ({
            selectedMenu: state.Layout.selectedMenu,
            user: state.login.user,
        });
    });

    let { organizations, workspaces, curr_org, curr_workspace } = user;

    const handleSearch = (v: string) => {
        setList(dataList.filter((row: any) => row.name.includes(v)))
    }
 
    const onSelect = (selected: IOption) => {
        let data = { ...user }
        let dic: any = {
            organization: { key: "curr_org", url: `/org/${selected.id}/overview` },
            workspace: { key: "curr_workspace", url: `/org/${curr_org.id}/ws/${selected.id}/overview` }
        }
        // 更新redux跟localStorage里的用户数据
        data[dic[selectedMenu].key] = selected;
        if (selectedMenu == "organization") {
            let list = workspaces.filter((row: any) => row.org_id == selected.id)
            data.curr_workspace = list[0]
            data.curr_org_id = selected.id;
            data.curr_wspace_id = list[0].id;
        } else {
            data.curr_wspace_id = selected.id;
        }
       
        setSelectedId(selected.id);
        changeWorkspace({ org_id: data.curr_org_id, workspace_id: data.curr_wspace_id }, null);
        dispatch(loginSuccess({ Data: data }))

        let authUser: any = localStorage.getItem("authUser");
        authUser = JSON.parse(authUser);
        authUser.Data = data;
        localStorage.setItem("authUser", JSON.stringify(authUser));
        history.push(dic[selectedMenu].url);
        onClose();
    }

    useEffect(() => {
        if (selectedMenu == "organization") {
            setSelectedId(curr_org.id)
            setDataList(organizations)
            setList(organizations)
        } else if (selectedMenu == "workspace") {
            setSelectedId(curr_workspace.id)
            setDataList(workspaces.filter((row: any) => row.org_id == curr_org.id))
            setList(workspaces.filter((row: any) => row.org_id == curr_org.id))
        }
    }, [workspaces, organizations]);

    return (<StrictMode>
        {/*custom position: switch-drawer-position*/}
        <div
            className="switch-drawer-position position-absolute
            border cus-bg-white z-10"
            hidden={hidden}>
            <header className={`border-bottom ${style.header}`}>
                <ControlBar areaName={selectedMenu}
                    onSearch={handleSearch} onClose={onClose} />
            </header>
            <main className={`overflow-auto ${style.main}`}>
                <SwitchSelect simplify={selectedMenu == menuList[1]}
                    data={list}
                    selected={selectedId}
                    onSelect={onSelect} />
            </main>
            <footer className={`${style.footer}`}>
                <NewSwitch />
            </footer>
        </div>
    </StrictMode>)
}

export default (props: any) => createPortal(<PrivateDrawer {...props} />, document.body)
