import organizationRoutes from "./organizationRoutes";
import workspaceRoutes from "./workspaceRoutes";

const defaultConfiguration = {
    routes: [],
}

export const loadRouteConfiguration = (menuCategory?: string) => {
    switch (menuCategory) {
        case "organization":
            return organizationRoutes
        case "workspace":
            return workspaceRoutes
        default:
            return defaultConfiguration
    }
}
